<div id="content" #content class="{{message === 'HAS_PARENT' ? 'container-with-parent' : 'container' }} mb-5">
    <div class="resume-wrapper">
        <div class="row">
            <div class="col-md-12 text-center">
                <h2 class="emp-name mb-0">{{resumeDetails?.first_name}} {{resumeDetails?.last_name}}</h2>
                <h4 class="emp-title mb-0">{{resumeDetails?.title}}</h4>
                <ul class="contact-list mt-2">
                    <li class="border-r">{{resumeDetails?.contact.email &&
                        resumeDetails?.contact.email.length > 0 ?
                        resumeDetails?.contact.email[0] : 'Not Indicated'}}</li>
                    <li class="border-r">{{resumeDetails?.contact.phone
                        &&resumeDetails?.contact.phone.length
                        > 0 ?
                        resumeDetails?.contact.phone[0] : 'None Indicated'}}</li>
                    <li class="border-r"><a href="https://www.linkedin.com/in/{{resumeDetails?.vanity_name}}/"
                            target="_blank">
                            {{resumeDetails?.vanity_name}}
                        </a> </li>
                    <li>{{resumeDetails?.socials.twitter?resumeDetails?.socials.twitter :
                        'Not Indicated'}}</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <h4 class="section-title">SUMMARY</h4>
            <div class="col-md-12">

                <p class="section-desc" [innerHTML]="resumeDetails?.about">
                </p>
            </div>
        </div>
        <div class="row" *ngIf="resumeDetails?.visibility?.experience === true && resumeDetails?.experience && resumeDetails?.experience?.length > 0">
            <h4 class="section-title">EXPERIENCE</h4>
            <ng-container *ngFor="let exp of resumeDetails?.experience">
                <div class="col-md-4 mb-4">
                    <p class="exp-period mb-0">{{displayExperienceDurationDateRange(exp)}}</p>
                    <h5 class="exp-title mb-0">{{exp?.title}}</h5>
                    <h5 class="exp-company">{{exp?.company}} {{exp.location ? '(' +
                        exp.location + ')' : ''}}</h5>
                </div>
                <div class="col-md-8 mb-4">
                    <p class="exp-desc" [innerHTML]="exp?.description">
                    </p>
                </div>
            </ng-container>
        </div>
        <div class="row">
        <div class="col-md-6" *ngIf="resumeDetails?.visibility?.skills === true && resumeDetails?.skills && resumeDetails?.skills.length > 0">
            <h4 class="section-title">SKILLS</h4>
          <div class="mb-2 mt-2">
                <ul class="skill-list pl-1">
                    <li *ngFor="let skills of resumeDetails?.skills">
                        {{skills}}
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-6" *ngIf="resumeDetails?.languages && resumeDetails?.visibility?.languages === true">
                <h4 class="section-title">LANGUAGES</h4>
              <div class="mb-2 mt-2">
                    <ul class="skill-list pl-1">
                        <li *ngFor="let lang of resumeDetails?.languages">
                            {{lang}}
                        </li>
                    </ul>
                </div>
        </div>
        </div>
        <div class="row">
        <div class="col-md-6" *ngIf="resumeDetails?.visibility?.education === true && resumeDetails?.education && resumeDetails?.education?.length > 0">
            <h4 class="section-title mt-2 mb-3">EDUCATION</h4>
            <div class="mb-2" *ngFor="let edu of resumeDetails?.education">
                <h5 class="exp-title mb-0">{{edu.degree_name}}: <span class="university">{{edu.field_of_study}}</span>
                </h5>
                <p class="exp-period mb-0"><i>{{displayExperienceDurationDateRange(edu)}}
                        {{displayExperienceDurationDateRange(edu) ? '' : ''}}</i> </p>
                <p class="exp-period mb-0">{{edu.school}}</p>
            </div>
        </div>
        <div class="col-md-6" *ngIf="resumeDetails?.visibility?.projects === true && resumeDetails?.accomplishments.projects.length> 0">
            <h4 class="section-title mt-2 mb-3">PROJECTS</h4>
            <div class="mb-2" *ngFor="let proj of resumeDetails?.accomplishments.projects">
                <h5 class="exp-title mb-0">{{proj.title}}
                </h5>
    
                <p class="exp-period mb-0">{{displayprojectDuration(proj)}}</p>
            </div>
        </div>
        </div>
        <div class="row" *ngIf="resumeDetails?.visibility?.courses === true && resumeDetails?.accomplishments.courses.length> 0">
            <h4 class="section-title mt-2 mb-3">COURSES</h4>
            <div class="col-md-12 mb-2" *ngFor="let course of resumeDetails?.accomplishments.courses">
                <h5 class="exp-title mb-0">{{course.name}}</h5>
                <p class="exp-period mb-0"><i>{{course.number}}</i> </p>
            </div>
        </div>
        <div class="row" *ngIf="resumeDetails?.visibility?.certifications === true && resumeDetails?.certifications && resumeDetails?.certifications?.length > 0">
            <h4 class="section-title mt-2 mb-3">CERTIFICATES</h4>
            <div class="col-md-12 mb-2" *ngFor="let courses of resumeDetails?.certifications">
                <h5 class="exp-title mb-0">{{courses}}</h5>

            </div>
        </div>
        <div class="row" *ngIf="resumeDetails?.visibility?.publications === true && resumeDetails?.accomplishments.publications.length > 0">
            <h4 class="section-title mt-2 mb-3">PUBLICATIONS</h4>
            <div class="col-md-12 mb-2" *ngFor="let pub of resumeDetails?.accomplishments.publications">
                <h5 class="exp-title mb-0">{{pub.name}}</h5>
                <p class="exp-period mb-0"><a href=""><u><i>{{pub.url}}</i></u></a> </p>
            </div>
        </div>
    </div>
</div>