<!-- <button (click)="captureScreenPreview()">Print Preview</button>
<button (click)="removeIframe()">Remove</button>
<button (click)="toggleTemplateSide()" *ngIf="!previewVisibleChild2">Show Preview</button> -->


<div id="content" #content class="{{message === 'HAS_PARENT' ? 'container-with-parent' : 'container' }} mb-5">
   <div class="resume-wrapper">
      <div class="top-section">
         <div class="row">
            <div class="col-md-12">
               <h2 class="emp-name">{{resumeDetails?.first_name}} {{resumeDetails?.last_name}}</h2>
               <h4 class="title mb-0" style="font-weight: 400;">{{resumeDetails?.title}}</h4>
               <ul class="inline-contact pt-2">
                  <li *ngIf="resumeDetails?.visibility?.contactno == true">
                     <span class="text"><span class="test-icon"> <img src="assets/img/phone_icon.png"
                              class="img-fa phone" /></span>
                        {{resumeDetails?.contact.phone &&resumeDetails?.contact.phone.length > 0 ?
                        resumeDetails?.contact.phone[0] : 'None Indicated'}}</span>
                  </li>
                  <li *ngIf="resumeDetails?.visibility?.email == true">
                     <span class="text"> <span class="text-blue"><img src="assets/img/email_icon.png"
                              class="img-fa mail" /></span>
                        {{resumeDetails?.contact.email && resumeDetails?.contact.email.length > 0 ?
                        resumeDetails?.contact.email[0] : 'Not Indicated'}}</span>
                  </li>
                  <li *ngIf="resumeDetails?.visibility?.linkedin == true">
                     <span class="text"> <span class="text-blue"><img src="assets/img/linkdin_icon.png"
                              class="img-fa linkdin" /></span>
                        <a href="https://www.linkedin.com/in/{{resumeDetails?.vanity_name}}/" target="_blank">
                           {{resumeDetails?.vanity_name}}
                        </a></span>
                  </li>
                  <li *ngIf="resumeDetails?.visibility?.twitter == true">
                     <span class="text"> <span class="text-blue"><img src="assets/img/twitter.png"
                              class="img-fa twitter" /></span>
                        {{resumeDetails?.socials.twitter?resumeDetails?.socials.twitter : 'Not Indicated'}}</span>
                  </li>
               </ul>
           </div>
         </div>
      </div>
      <div class="row middle-section">
         <div class="col-md-12">
            <!-- <p class="exp-desc about">{{resumeDetails?.about}}</p> -->
            <div class="exp-desc about" [innerHTML]="resumeDetails?.about"></div>
         </div>
      </div>
      <div class="row bottom-section">
         <div class="col-md-8">
            <div class="left-side">

               <div class="experience" *ngIf="resumeDetails?.visibility?.experience === true && resumeDetails?.experience && resumeDetails?.experience?.length > 0">
                  <h4 class="expsec-title title-top" style="font-weight:400;">Work experience</h4>
                  <div class="exp-list" *ngFor="let exp of resumeDetails?.experience">
                     <div  class="mt-3">
                        <h4 class="exp-title mb-0" style="font-weight:400;">{{exp?.title}}</h4>
                        <h4 class="exp-period">{{exp?.company}} {{exp.location ? '(' + exp.location + ')' : ''}}
                           ({{displayExperienceDurationDateRange(exp)}})</h4>
                        <div class="exp-desc" [innerHTML]="exp?.description"></div>
                        </div>

                  </div>
               </div>
               <div class="row" *ngIf="resumeDetails?.visibility?.certifications === true && resumeDetails?.certifications && resumeDetails?.certifications?.length > 0">
                  <div class="col-md-12 w-100">
                     <div class="certifications">
                        <h4 class="expsec-title title-top" style="font-weight:400;">Certifications</h4>
                        <ul class="mt-2 pl-0">
                           <li *ngFor="let courses of resumeDetails?.certifications">
                              <h4 class="exp-certificate" style="font-weight:400;">{{courses}}</h4>

                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="row" *ngIf="resumeDetails?.visibility?.publications === true && resumeDetails?.accomplishments.publications.length > 0">
                  <div class="col-md-12">
                     <div class="certifications">
                        <h4 class="expsec-title title-top" style="font-weight:400;">Publications</h4>
                        <ul class="mt-2 pl-0">
                           <li *ngFor="let pub of resumeDetails?.accomplishments.publications">
                              <h4 class="exp-certificate" style="font-weight:400;">{{pub?.name}}</h4>
                              <p class="exp-desc">{{pub?.url}}</p>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="right-side">
               <div class="experience" *ngIf="resumeDetails?.visibility?.education === true && resumeDetails?.education && resumeDetails?.education?.length > 0">
                  <h4 class="expsec-title title-top" style="font-weight:400;">Education</h4>
                  <ul class="exp-list">
                     <li *ngFor="let edu of resumeDetails?.education;">
                        <h4 class="course-title" style="font-weight:400;">{{edu.degree_name}} {{edu.field_of_study}}
                        </h4>
                        <h4 class="exp-period">{{edu.school}} </h4>
                        <h4 class="exp-period">{{displayExperienceDurationDateRange(edu)}}
                           {{displayExperienceDurationDateRange(edu) ? '' : ''}}</h4>

                     </li>

                  </ul>
               </div>
               <div class="experience" *ngIf="resumeDetails?.visibility?.projects === true && resumeDetails?.accomplishments.projects.length> 0">
                  <h4 class="expsec-title title-top" style="font-weight:400;">Projects</h4>
                  <ul class="exp-list">
                     <li *ngFor="let proj of resumeDetails?.accomplishments.projects">
                        <h4 class="course-title" style="font-weight:400;">{{proj.title}}</h4>
                        <h4 class="exp-period">{{displayprojectDuration(proj)}}</h4>
                     </li>

                  </ul>
               </div>
               <div class="experience" *ngIf="resumeDetails?.languages && resumeDetails?.visibility?.languages === true">
                     <h4 class="expsec-title title-top" style="font-weight:400;">Languages</h4>
                     <ul class="exp-list">
                        <li *ngFor="let lang of resumeDetails?.languages">
                           <h4 class="course-title" style="font-weight:400;">{{lang}}</h4>                       
                        </li> 
                     </ul>
                     <div *ngIf="resumeDetails?.languages && resumeDetails?.languages.length === 0">
                        (Not Indicated)
                      </div>
                  </div>
               <div class="experience" *ngIf="resumeDetails?.visibility?.courses === true && resumeDetails?.accomplishments.courses.length> 0">
                  <h4 class="expsec-title title-top" style="font-weight:400;">Courses</h4>
                  <ul class="exp-list">
                     <li *ngFor="let course of resumeDetails?.accomplishments.courses">
                        <h4 class="course-title" style="font-weight:400;">{{course?.name}}</h4>
                        <h4 class="exp-period">{{course?.number}}</h4>          
                     </li>

                  </ul>
               </div>
               <div class="skills" *ngIf="resumeDetails?.visibility?.skills === true && resumeDetails?.skills && resumeDetails?.skills.length > 0">
                  <h4 class="expsec-title" style="font-weight:400;">Skills</h4>
                  <ul class="mt-3 pl-0">
                     <li *ngFor="let skills of resumeDetails?.skills">
                        <span>{{skills}}</span>
                     </li>

                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>