
<div class="col-md-12">
    <div class="row">
        <div class="col-md-2 col-lg-2"></div>
        <div class="col-md-8 col-lg-8">
            <div class="row">
                <div class="col-md-12 hdr-page">
                    Create Powerful Resumes
                </div>
                <div class="col-md-12 hdr-page small-title">
                    Free trial for 7 Days
                </div>
                <div class="col-md-12 choose-plan">
                    Choose a plan that’s right for you 
                    <ng-container *ngIf="subscriptionDetails?.exception === 'No subcription'">
                        <span>| </span> <img src="assets/img/credit-card.jpeg" alt="" class="card-img" > 
                        <span style="color: cornflowerblue;font-size: 16px;" > No credit card required</span>
                        <button class="btn btn-primary ml-2 rounded-pill" (click)="gotoTrial()">Start free trial</button>
                    </ng-container>                   
                </div>
            </div>
        </div>
    
    </div>

    <div class="col-md-12">
    <div class="row  license-cards-container ">
        <div class="col-md-10 col-sm-9 mx-auto">
            <div class="row justify-content-center license-cards">
                <div class="col-xl-4 col-lg-5 col-md-6 col-sm-10 mb-4 mb-lg-3 single-license-card">

                    <!-- start free card -->
                    <p-card (click)="selectOptionType('single', $event)" styleClass="{{optionType === 'single' ? 'ngx-color-selected' : 'ngx-color'}}   
                    {{isRegCarddisabled ? 'disabled-card' : 'clickable-card'}}">
                    <div class="row text-center mb-3" *ngIf="subscriptionDetails?.status !== 'canceled'">
                        <div class="col-md-12" *ngIf="subscriptionPlan?.status !== 'trialing'">
                            <span class="{{optionType === 'single' ? 'current-plan' : 'current-plan-blue'}}" *ngIf="subscriptionDetails?.status !== 'trialing' && this.subscriptionPlan?.payment_type == 1">Active Plan</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <span class="p-card-title {{optionType === 'single' ? 'ngx-color-selected' : 'ngx-color'}}">Single
                                License</span>
                        </div>
                    </div>
                    <div class="row">
                            <div class="col-md-12 {{optionType === 'single' ? 'card-hdr-1-selected' : 'card-hdr-1'}}">
                                Ideal for a Sole Sourcing Professional
                            </div>
                    </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="row align-items-center">
                                    <div class="col-md-auto unset-padding-right">
                                        <span class="{{optionType === 'single' ? 'card-price-section-selected' : 'card-price-section'}}">$399</span>
                                    </div>
                                    <div class="col-md-auto">
                                        <span class="">/ year</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row align-items-center">
                                    <div class="col-md-auto unset-padding-right">
                                        <span class="{{optionType === 'single' ? 'card-price-section-selected monthly' : 'card-price-section monthly'}}">$33.25</span>
                                    </div>
                                    <div class="col-md-auto">
                                        <span class="">/ month for 1 License</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- start free button -->
                        <div class="row mt">
                            <div class="col-md-12 text-center">
                                <button class="btn btn-primary payment-button rounded-pill mt-4"
                                    (click)="confirmCheckout('single',1)">Get Started Now</button>
                            </div>
                        </div>
                        <!-- end free button -->

                        <!-- start free benefits enumeration -->
                        <div class="row mt-benefits">
                            
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits">
                                    <span class="danger">Represent Unlimited Candidates</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits">
                                    <span class="danger">Build Unlimited Resumes</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits">
                                    <span class="danger">Single User Unlimited License</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits">
                                    <span class="{{optionType === 'single' ? 'benefits-selected' : 'benefits'}}">
                                            LinkedIn Resume Scraping</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits">
                                    <span class="{{optionType === 'single' ? 'benefits-selected' : 'benefits'}}">
                                            PDF Resume Scraping</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits"><span class="{{optionType === 'single' ? 'benefits-selected' : 'benefits'}}">
                                            Dozens of Amazing Resume Templates</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits"><span class="{{optionType === 'single' ? 'benefits-selected' : 'benefits'}}">
                                            Update Resume sections with GPT-4</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits"><span class="{{optionType === 'single' ? 'benefits-selected' : 'benefits'}}">
                                    One click alignment to a Job Description</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits"><span class="{{optionType === 'single' ? 'benefits-selected' : 'benefits'}}">
                                    Save multiple JD aligned Resumes</span>
                                </div>
                            </div>
                        </div>
                        <!-- end free benefits enumeration -->

                    </p-card>
                    <!-- end free card -->
                </div>
                <div class="col-xl-4 col-lg-5 col-md-6 col-sm-10 mb-4 mb-lg-3 three-license-card ">

                    <!-- start free card -->
                    <p-card (click)="selectOptionType('tripple', $event)" styleClass="{{optionType === 'tripple' ? 'ngx-color-selected' : 'ngx-color'}}   
                    {{isRegCarddisabled ? 'disabled-card' : 'clickable-card'}}">
                    <div class="row text-center mb-3" *ngIf="subscriptionDetails?.status !== 'canceled'">
                        <div class="col-md-12">
                            <span class="{{optionType === 'tripple' ? 'current-plan' : 'current-plan-blue'}}" *ngIf="subscriptionPlan?.payment_type == 3">Active Plan</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <span class="p-card-title {{optionType === 'single' ? 'ngx-color-selected' : 'ngx-color'}}">Three Licenses</span>
                        </div>
                    </div>
                        <div class="row">
                            <div class="col-md-12 {{optionType === 'tripple' ? 'card-hdr-1-selected' : 'card-hdr-1'}}">
                                Ideal for small HR/Sourcing Teams
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="row align-items-center">
                                    <div class="col-md-auto unset-padding-right">
                                        <span class="{{optionType === 'tripple' ? 'card-price-section-selected' : 'card-price-section'}}">$799</span>
                                    </div>
                                    <div class="col-md-auto">
                                        <span class="">/ year</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row align-items-center">
                                    <div class="col-md-auto unset-padding-right">
                                        <span class="{{optionType === 'tripple' ? 'card-price-section-selected monthly' : 'card-price-section monthly'}}">$22.20</span>
                                    </div>
                                    <div class="col-md-auto">
                                        <span class="">/ month for 1 License</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- start free button -->
                        <div class="row mt">
                            <div class="col-md-12 text-center">
                                <button class="btn btn-primary payment-button rounded-pill mt-4"
                                    (click)="confirmCheckout('triple',3)">Get Started Now</button>
                            </div>
                        </div>
                        <!-- end free button -->

                        <!-- start free benefits enumeration -->
                        <div class="row mt-benefits">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits">
                                    <span class="danger">Represent Unlimited Candidates</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits">
                                    <span class="danger">Build Unlimited Resumes</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits">
                                    <span class="danger">3 Users Unlimited License</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits">
                                    <span class="{{optionType === 'tripple' ? 'benefits-selected' : 'benefits'}}">
                                            LinkedIn Resume Scraping</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits">
                                    <span class="{{optionType === 'tripple' ? 'benefits-selected' : 'benefits'}}">
                                            PDF Resume Scraping</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits"><span class="{{optionType === 'tripple' ? 'benefits-selected' : 'benefits'}}">
                                            Dozens of Amazing Resume Templates</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits"><span class="{{optionType === 'tripple' ? 'benefits-selected' : 'benefits'}}">
                                            Update Resume sections with GPT-4</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits"><span class="{{optionType === 'tripple' ? 'benefits-selected' : 'benefits'}}">
                                    One click alignment to a Job Description</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits"><span class="{{optionType === 'tripple' ? 'benefits-selected' : 'benefits'}}">
                                    Save multiple JD aligned Resumes</span>
                                </div>
                            </div>
                        </div>
                        <!-- end free benefits enumeration -->

                    </p-card>
                    <!-- end free card -->
                </div>
                <div class="col-xl-4 col-lg-5 col-md-6 col-sm-10 mb-4 mb-lg-3 ten-license-card ">

                 

                    <!-- start free card -->
                    <p-card (click)="selectOptionType('decuple', $event)" styleClass="{{optionType === 'decuple' ? 'ngx-color-selected' : 'ngx-color'}}   
                    {{isRegCarddisabled ? 'disabled-card' : 'clickable-card'}}">
                    <div class="row text-center mb-3" *ngIf="subscriptionDetails?.status !== 'canceled'">
                        <div class="col-md-12">
                            <span class="{{optionType === 'decuple' ? 'current-plan' : 'current-plan-blue'}}" *ngIf="subscriptionPlan?.payment_type == 10">Active Plan</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <span class="p-card-title {{optionType === 'single' ? 'ngx-color-selected' : 'ngx-color'}}">Ten Licenses</span>
                        </div>
                    </div>
                        <div class="row">
                            <div class="col-md-12 {{optionType === 'decuple' ? 'card-hdr-1-selected' : 'card-hdr-1'}}">
                                Ideal for Enterprise HR/Sourcing Teams
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="row align-items-center">
                                    <div class="col-md-auto unset-padding-right">
                                        <span class="{{optionType === 'decuple' ? 'card-price-section-selected' : 'card-price-section'}}">$1599</span>
                                    </div>
                                    <div class="col-md-auto">
                                        <span class="">/ year</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row align-items-center">
                                    <div class="col-md-auto unset-padding-right">
                                        <span class="{{optionType === 'decuple' ? 'card-price-section-selected monthly' : 'card-price-section monthly'}}">$13.32</span>
                                    </div>
                                    <div class="col-md-auto">
                                        <span class="">/ month for 1 License</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- start free button -->
                        <div class="row mt">
                            <div class="col-md-12 text-center">
                                <button class="btn btn-primary payment-button rounded-pill mt-4"
                                    (click)="confirmCheckout('decuple',10)">Get Started Now</button>
                            </div>
                        </div>
                        
                        <!-- end free button -->



                        <!-- start free benefits enumeration -->
                        <div class="row mt-benefits">

                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits">
                                    <span class="danger">Represent Unlimited Candidates</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits">
                                    <span class="danger">Build Unlimited Resumes</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits">
                                    <span class="danger">
                                        10 Users Unlimited License
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits">
                                    <span class="{{optionType === 'decuple' ? 'benefits-selected' : 'benefits'}}">
                                            LinkedIn Resume Scraping</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits">
                                    <span class="{{optionType === 'decuple' ? 'benefits-selected' : 'benefits'}}">
                                            PDF Resume Scraping</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits"><span class="{{optionType === 'decuple' ? 'benefits-selected' : 'benefits'}}">
                                            Dozens of Amazing Resume Templates</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits"><span class="{{optionType === 'decuple' ? 'benefits-selected' : 'benefits'}}">
                                            Update Resume sections with GPT-4</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits"><span class="{{optionType === 'decuple' ? 'benefits-selected' : 'benefits'}}">
                                    One click alignment to a Job Description</span>
                                </div>
                            </div>
                            <div class="d-flex mt align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="assets/img/item-check.png" alt="" style="width: auto;">
                                </div>
                                <div class="flex-grow-1 ms-3 benefits"><span class="{{optionType === 'decuple' ? 'benefits-selected' : 'benefits'}}">
                                    Save multiple JD aligned Resumes</span>
                                </div>
                            </div>

                        </div>
                        <!-- end free benefits enumeration -->

                    </p-card>
                    <!-- end free card -->
                </div>
            </div>
        </div>

    </div>
</div>
  <!-- start cancel button -->

    <div class="col-md-12 text-center mt-5" *ngIf="showCancel">
        <button [disabled]="cancelProcessing" class="btn btn-danger cancel-button rounded-pill" (click)="confirmCancelSubscription()">Cancel
          <div *ngIf="cancelProcessing" class="spinner-border spinner-border-sm" role="status">
            </div> 
      </button>
    </div>

<!-- start cancel button -->
<p-confirmDialog key="confirmDelSubscriptionDialog" header="Confirmation" 
icon="pi pi-times-circle" rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<!--/////////cancel subsc confirm dialog////////////////-->
<p-dialog header="Header" [(visible)]="visible" [modal]="true" [style]="{ width: '50rem' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <!-- <p-avatar image="https://primefaces.org/cdn/primeng/images/avatar/amyelsner.png" shape="circle" /> -->
            <span>
                <strong> Cancel Subscription!</strong>
            </span>
        </div>
    </ng-template>
    <p class="m-0">
        Your subscription will be cancelled. Your subscription will remain active until
        <strong>{{subscriptionDetails?.ends_at | date:'longDate'}}</strong>.
        If you have any feedback on why you chose to cancel or if there is anything we can do to improve our service,
        please let us know.
    </p>

    <ng-template pTemplate="footer">
        <button icon="pi pi-check" (click)="visible = false"pAutoFocus [autofocus]="true"
            class="mb-2 btn btn-secondary">Don't Cancel</button>
        <button (click)="giveFeedback()" pAutoFocus [autofocus]="true"
            class="mb-2 btn btn-secondary">Continue</button>

    </ng-template>
</p-dialog>

<!--/////////cancel feedback dialog dialog////////////////-->
<p-dialog header="Header" [(visible)]="visibleFeedback" [modal]="true" [style]="{ width: '50rem' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span>
                <strong>
                    <h4>Please let us know why do you want to cancel</h4>
                </strong>
                Your feedback will help us improve our product.
            </span>
        </div>
    </ng-template>
    <form [formGroup]="feedbackForm">
        <div class="row mt-2">
            <div class="col-md-12">
                <div class="form-group">
                    <div>
                        <label for="feedback" class="login-labels">I am leaving because <span
                                class="field-req-ask">*</span></label>
                    </div>
                    <p-dropdown id="feedback" [style]="{width: '100%'}" appendTo="body" [options]="feedbackreason"
                        formControlName="reason" optionLabel="name" [showClear]="true"
                        placeholder="Select a reason"></p-dropdown>
                </div>
            </div>
            <div class="col-md-12 mt-3">
                <div class="form-group">
                    <div>
                        <label for="feedbackDesc" class="login-labels">Additional Remarks(optional)</label>
                    </div>
                    <textarea id="feedbackDesc" formControlName="feedbackDesc"
                        class="form-control mb-3 exp-array"></textarea>
                </div>
            </div>
        </div>
    </form>

    <ng-template pTemplate="footer">
        <button (click)="visibleFeedback = false" pAutoFocus [autofocus]="true"
            class="mb-2 btn btn-secondary">Cancel</button>
        <button (click)="cancelSubscriptionPlan()" pAutoFocus [autofocus]="true"
            class="mb-2 btn btn-secondary">Send</button>

    </ng-template>
</p-dialog>