<div id="content" #content class="{{message === 'HAS_PARENT' ? 'container-with-parent' : 'container' }} mb-5">
    <div class="resume-wrapper">
<div class="row">
    <!-- section-1 -->

    <div class="about col-md-12 d-flex flex-column gap-1">
        <h1 class="fs-1 fw-bold">
            {{resumeDetails?.first_name}} {{resumeDetails?.last_name}}
        </h1>
        <h3 class="lh-5">{{resumeDetails?.title}}</h3>
        <ul class="d-flex flex-row gap-5 flex-wrap mx-0 px-3">
            <li class="contact fw-bold">
                {{resumeDetails?.contact.phone
                &&resumeDetails?.contact.phone.length > 0 ?
                resumeDetails?.contact.phone[0] : 'None Indicated'}}
            </li>
            <a href="" class="contact fw-bold">
                <li class="contact fw-bold">
                    {{resumeDetails?.contact.email && resumeDetails?.contact.email.length > 0 ?
                    resumeDetails?.contact.email : 'Not Indicated'}}
                </li>
            </a>

            <a href="https://www.linkedin.com/in/{{resumeDetails?.vanity_name}}/" target="_blank"
                class="contact fw-bold">
                <li class="contact fw-bold">{{resumeDetails?.vanity_name}}</li>
            </a>

            <li class="contact fw-bold">
                {{resumeDetails?.socials.twitter?resumeDetails?.socials.twitter
                : 'Not Indicated'}}
            </li>
        </ul>
        <p class="text-justify" [innerHTML]="resumeDetails?.about"></p>
    </div>
</div>

<div class="row">
    <!-- section-2 -->

    <div class="experience col-md-12 w-100 d-flex flex-column align-items-start flex-wrap gap-1"
        *ngIf="resumeDetails?.visibility?.experience === true && resumeDetails?.experience && resumeDetails?.experience?.length > 0">
        <div class="w-100 d-flex flex-column ">
            <h6 class="text-uppercase lh-5 mt-4 mb-0">work experience</h6>
            <hr class="custom-hr w-100" />
        </div>

        <div class="w-100 mb-3" *ngFor="let exp of resumeDetails?.experience">
            <div class="w-100 d-flex justify-content-between">
                <h5 class="sub-topic-1 fw-bold">{{exp?.title}}</h5>
                <h5 class="duration">{{displayExperienceDurationDateRange(exp)}}</h5>
            </div>
            <p class="sub-topic-2 fs-5">
                {{exp?.company}} {{exp.location ? '(' + exp.location + ')' : ''}}
            </p>
            <div>
                <p class="fs-6 mt-1" [innerHTML]="exp?.description"></p>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <!-- section-3 -->

    <div class="education col-md-12 w-100 d-flex flex-column align-items-start flex-wrap gap-1"
        *ngIf="resumeDetails?.visibility?.education === true && resumeDetails?.education && resumeDetails?.education?.length > 0">
        <div class="w-100 d-flex flex-column">
            <h6 class="text-uppercase lh-5 mt-2 mb-0">education</h6>
            <hr class="custom-hr w-100" />
        </div>

        <div class="w-100 mb-2" *ngFor="let edu of resumeDetails?.education">
            <div class="w-100 d-flex justify-content-between my-0">
                <h5 class="sub-topic-1 fw-bold my-0">{{edu.school}}</h5>
                <h5 class="duration my-0">
                    {{displayExperienceDurationDateRange(edu)}}
                    {{displayExperienceDurationDateRange(edu) ? '' : ''}}
                </h5>
            </div>
            <p class="sub-topic-2 fs-6 fw-normal my-0">
                {{edu.degree_name}} - {{edu.field_of_study}}
            </p>
        </div>
    </div>
</div>

<div class="row">
    <!-- section-4 -->

    <div class="projects col-md-12 w-100 d-flex flex-column align-items-start flex-wrap gap-1"
        *ngIf="resumeDetails?.visibility?.projects === true && resumeDetails?.accomplishments.projects.length> 0">
        <div class="w-100 d-flex flex-column">
            <h6 class="text-uppercase lh-5 mt-3 mb-0">projects</h6>
            <hr class="custom-hr w-100" />
        </div>

        <div class="w-100 d-flex align-items-center justify-content-between"
            *ngFor="let proj of resumeDetails?.accomplishments.projects">
            <p class="sub-topic-1 fw-bold">{{proj.title}}</p>
            <h5 class="my-0 duration">{{displayprojectDuration(proj)}}</h5>
        </div>
    </div>
</div>

<div class="row">
    <!-- section-5 -->

    <div class="courses col-md-12 w-100 d-flex flex-column align-items-start flex-wrap gap-1"
        *ngIf="resumeDetails?.visibility?.courses === true && resumeDetails?.accomplishments.courses.length> 0">
        <div class="w-100 d-flex flex-column">
            <h6 class="text-uppercase lh-5 mt-3 mb-0">courses</h6>
            <hr class="custom-hr w-100" />
        </div>

        <div class="d-flex gap-2 mb-1" *ngFor="let course of resumeDetails?.accomplishments.courses">
            <p class="fw-bold my-0">{{course.name}}:</p>
            <p class="my-0">{{course.number}}</p>
        </div>
    </div>
</div>

<div class="row">
    <!-- section-6 -->

    <div class="skills col-md-12 w-100 d-flex flex-column align-items-start flex-wrap gap-1">
        <div class="w-100 d-flex flex-column">
            <h6 class="text-uppercase lh-5 mt-3 mb-0">skills & others</h6>
            <hr class="custom-hr w-100" />
        </div>

        <div class="">
            <div class="d-flex gap-2 mb-1 flex-wrap"
                *ngIf="resumeDetails?.visibility?.skills === true && resumeDetails?.skills && resumeDetails?.skills.length > 0">
                <p class="fw-bold">Tools:</p>
                <p class="text-justify" *ngFor="let skills of resumeDetails?.skills">{{skills}},</p>
            </div>
            <div class="d-flex gap-2 mb-1 flex-wrap"
                *ngIf="resumeDetails?.visibility?.certifications === true && resumeDetails?.certifications && resumeDetails?.certifications?.length > 0">
                <p class="fw-bold">Certifications:</p>
                <p *ngFor="let courses of resumeDetails?.certifications">
                    {{courses}},
                </p>
            </div>
            <div class="d-flex gap-2 mb-1 flex-wrap"
                *ngIf="resumeDetails?.languages && resumeDetails?.visibility?.languages === true">
                <p class="fw-bold">Languages:</p>
                <p class="my-0" *ngFor="let lang of resumeDetails?.languages">{{lang}}</p>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <!-- section-7 -->

    <div class="publications col-md-12 w-100 d-flex flex-column align-items-start flex-wrap gap-1"
        *ngIf="resumeDetails?.visibility?.publications === true && resumeDetails?.accomplishments.publications.length > 0">
        <div class="w-100 d-flex flex-column">
            <h6 class="text-uppercase lh-5 mt-3 mb-0">publications</h6>
            <hr class="custom-hr w-100" />
        </div>

        <div class="d-flex gap-2 mb-1" *ngFor="let pub of resumeDetails?.accomplishments.publications">
            <p class="fw-bold">{{pub.name}}:</p>
            <a class="pub-ur m" href="">{{pub.url}}</a>
        </div>
    </div>
</div>
</div>
</div>