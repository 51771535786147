<div id="content" #content class="{{message === 'HAS_PARENT' ? 'container-with-parent' : 'container' }} mb-5">
    <div class="resume-wrapper">
        <div class="row">
            <div class="col-12 text-center">
                <p class="fw-bold fs-1 mb-0">{{resumeDetails?. first_name}} {{resumeDetails?. last_name}}</p>
                <p class="fw-bold fs-3">{{resumeDetails?.title}}</p>
                <p>
                    <!-- Phone -->
                    <span class="fw-bold">Phone: </span>
                    {{resumeDetails?.contact.phone
                        &&resumeDetails?.contact.phone.length
                        > 0 ?
                        resumeDetails?.contact.phone[0] : 'None Indicated'}}

                    <!-- Email -->
                    <span class="fw-bold">Email: </span>
                    <a href="mailto:{{resumeDetails?.contact.email}}" class="text-primary text-dark">
                        {{resumeDetails?.contact.email && resumeDetails?.contact.email.length > 0 ? resumeDetails?.contact.email[0] : 'Not Indicated'}}
                    </a><br>

                    <!-- Twitter -->
                    <span class="fw-bold">Twitter: </span>
                    <span class="text-primary text-dark">{{resumeDetails?.socials.twitter?resumeDetails?.socials.twitter : 'NotIndicated'}}</span>

                    <!-- Linkedin -->
                    <span class="fw-bold">Linkedin: </span>
                    <a  href="https://www.linkedin.com/in/{{resumeDetails?.vanity_name}}/"target="_blank" class="text-primary text-dark">
                        {{resumeDetails?.vanity_name}}</a>
                </p>
            </div>

            <!-- Summery section start-->
            <div class="col-md-12">
                <p class="section-desc text-justify" [innerHTML]="resumeDetails?.about"></p>
            </div>
           <!-- Summery section end-->

            <!-- Education section start -->
            <div class="col-12" *ngIf="resumeDetails?.visibility?.education === true && resumeDetails?.education && resumeDetails?.education?.length > 0">
                <div class="custom-bg-gray rounded">
                   <div class="py-1.5 ms-2 mt-1.5 fs-3 fw-bold">Education</div>
                </div>

                <div class="mt-2 ms-2" *ngFor="let edu of resumeDetails?.education">
                    <div class="row gx-5 justify-content-between align-items-center">
                        <div class="fw-bold fs-6 col col-lg-8">{{edu.school}}</div>
                        <div class="fw-light col-auto fst-italic">{{displayExperienceDurationDateRange(edu)}}
                            {{displayExperienceDurationDateRange(edu) ? '' : ''}}</div>
                    </div>
                    <p class="fw-bold ">{{edu.degree_name}}: <span class="fst-normal">| {{edu.field_of_study}}</span></p>
                </div>
            </div>
            <!-- Education section end -->

            <!-- Experience section start-->
            <div class="col-12" *ngIf="resumeDetails?.visibility?.experience === true && resumeDetails?.experience && resumeDetails?.experience?.length > 0">
                <div class="custom-bg-gray rounded">
                   <div class="py-1.5 ms-2 mt-1.5 fs-3 fw-bold">Experience</div>
                </div>

                <ng-container *ngFor="let exp of resumeDetails?.experience">
                <div class="mt-2 ms-2">
                    <div class="row gx-3 justify-content-between">
                        <div class="col-md-12">
                            <div class="row justify-content-between">
                                <div class="fw-bold fs-6 col col-lg-8">{{exp?.company}} | {{exp?.title}}<span class="fw-normal">, {{exp.location ? '(' +
                                    exp.location + ')' : ''}}</span></div>
                                <div class="fw-light col-auto fst-italic">{{displayExperienceDurationDateRange(exp)}}</div>
                            </div>
                            <p [innerHTML]="exp?.description"></p>
                        </div>
                    </div>
                </div>
                </ng-container>
            </div>
            <!-- Experience section end-->

            <!-- Skill section start-->
            <div class="col-12" *ngIf="resumeDetails?.visibility?.skills === true && resumeDetails?.skills && resumeDetails?.skills.length > 0">
                <div class="custom-bg-gray rounded">
                    <div class="py-1.5 ms-2 mt-1.5 fs-3 fw-bold">Skill</div>
                 </div>

                 <ul class="mt-2 ms-2 ">
                    <li *ngFor="let skills of resumeDetails?.skills">
                        {{skills}}
                    </li>
                </ul>
            </div>
            <!-- Skill section end-->

            <!-- Languages section start -->
            <div class="col-12" *ngIf="resumeDetails?.languages && resumeDetails?.visibility?.languages === true">
                <div class="custom-bg-gray rounded">
                    <div class="py-1.5 ms-2 mt-1.5 fs-3 fw-bold">Language</div>
                 </div>

                 <ul class="mt-2 ms-2 ">
                    <li *ngFor="let lang of resumeDetails?.languages">
                        {{lang}}
                    </li>
                </ul>
            </div>
            <!-- Languages section end -->

            <!-- Projects section start -->
            <div class="col-12" *ngIf="resumeDetails?.visibility?.projects === true && resumeDetails?.accomplishments.projects.length> 0">
                <div class="custom-bg-gray rounded">
                    <section class="py-1.5 ms-2 mt-1.5 fs-3 fw-bold">Project</section>
                </div>

                <div class="mt-2 ms-2" *ngFor="let proj of resumeDetails?.accomplishments.projects">
                    <div class="row gx-5 justify-content-between">
                        <div class="fw-bold col-auto mr-auto"><span>{{proj.title}}</span> | <a href="http://www.github.com" class="text-primary text-dark">(url)</a></div>
                        <div class="fw-light col-auto fst-italic">{{displayprojectDuration(proj)}}</div>
                    </div>
                </div>
            </div>
            <!-- Projects section end -->

            <!-- Publication section start -->
            <div class="col-12" *ngIf="resumeDetails?.visibility?.publications === true && resumeDetails?.accomplishments.publications.length > 0">
                <div class="custom-bg-gray rounded">
                    <section class="py-1.5 ms-2 mt-1.5 fs-3 fw-bold">Publication</section>
                </div>

                <div class="mt-2 ms-2" *ngFor="let pub of resumeDetails?.accomplishments.publications">
                    <div class="fw-bold"><span>Research Paper: {{pub.name}}</span>| <a href={{pub.url}} class="text-primary text-dark">(url)</a></div>
                </div>
            </div>
            <!-- Publication section end -->

            <!-- Courses section start -->
            <div class="col-12 mt-2" *ngIf="resumeDetails?.visibility?.courses === true && resumeDetails?.accomplishments.courses.length> 0">
                <div class="custom-bg-gray rounded">
                    <section class="py-1.5 ms-2 mt-1.5 fs-3 fw-bold">Courses</section>
                </div>
                <ul class="mt-2 ms-2" *ngFor="let course of resumeDetails?.accomplishments.courses">
                    <li><span class="fw-bold">{{course.name}}</span>: {{course.number}}</li>
                </ul>
            </div>
            <!-- Courses section end -->

            <!-- Certification section start -->
            <div class="col-12" *ngIf="resumeDetails?.visibility?.certifications === true && resumeDetails?.certifications && resumeDetails?.certifications?.length > 0">
                <div class="custom-bg-gray rounded">
                    <section class="py-1.5 ms-2 mt-1.5 fs-3 fw-bold">Certification</section>
                </div>
                <ul class="mt-2 ms-2" *ngFor="let courses of resumeDetails?.certifications">
                    <li class="fw-bold">{{courses}}</li>
                </ul>
            </div>
            <!-- Certification section end -->
        </div>

    </div>
</div>