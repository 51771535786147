<section>
    <div class="container">
      <div class="row mt-3">
        <div class="col-8 offset-2 text-center">
            <img style="width: 80%; height: auto" src="assets/img/404.jpg" alt="not found">
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-8 offset-2 text-center">
            <p>Click <a [routerLink]="['/login']">here</a> to login</p>
        </div>
        </div>

    </div>
</section>
