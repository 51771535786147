import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'app/core/local-storage.service';
import { UserService } from 'app/core/user.service';
import { Subscription } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { DataService } from 'app/helpers/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AytHttpParams } from 'app/helpers/http-config';
import { APIEndpoints, Constants } from 'app/helpers/app-settings';
import { EmailValidator } from 'app/helpers/xcv-validator';
import { XcvUtilsService } from 'app/core/xcv-utils.service';
import { PollingService } from 'app/helpers/polling-service';
import { AppUser } from 'app/model/Userprofile';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss']
})
export class PaymentOptionsComponent implements  AfterViewInit, OnInit, OnDestroy {

  public vanity_name = '';
  public name = '';
  public loading = false;
  public optionType = '';
  public origin = '';
  isSelected: boolean = false;
  isPaymentPublic = false;
  public isRegCarddisabled = false;
  public isFreeCarddisabled = false;
  public enterDetailsModal = false;
  public enterDetailForm !: FormGroup;
  public submitted = false;
  public displayDialog: boolean = false;
  public isSmallScreen = false;
  public subscriptionPlan!: any;
  public currentPlanType = Constants.FREE;
  public cancelProcessing = false;
  public polling = false;
  
  private subscription!: Subscription;
  private pollingSubscription: Subscription =  new Subscription;

  constructor(private http: HttpClient, 
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private messageService: MessageService,    
    private datePipe: DatePipe,
    private dataService: DataService,
    private pollingService: PollingService,        
    private confirmationService: ConfirmationService,    
    private router: Router, private activatedRoute: ActivatedRoute, private fb: FormBuilder) {
      this.initEnterDetailForm();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  private subscribeToRecruiterQueryParams() {
    this.subscription = this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.hasOwnProperty('origin') && queryParams.origin !== '') {
        this.origin = queryParams.origin;

        this.isRegCarddisabled = true;
        this.isFreeCarddisabled = true;
      
      }
    });
  }

  ngOnInit(): void {
    this.checkScreenSize();        
    this.subscribeToRecruiterQueryParams();
    if (this.origin && this.origin.toUpperCase() === Constants.RECRUITER) {
      // make this public
      this.isPaymentPublic = true;
      this.subscribeToQueryParams();
      this.enterDetailsModal = true;
    } else {
      // this is client
      // get the plan type subcriptionPlan
      this.subscriptionPlan = this.localStorageService.getData('subcriptionPlan');

      if (this.subscriptionPlan) {
        if (this.subscriptionPlan.status.toUpperCase() === Constants.CANCELED) {
          this.currentPlanType = Constants.FREE;
          this.optionType = 'free';
        } else {
          this.currentPlanType = XcvUtilsService.getPlanType(this.subscriptionPlan.amount);

          if (this.currentPlanType === this.STANDARD) {
            this.optionType = 'regular';
          } else if (this.currentPlanType === this.PREMIUM) {
            this.optionType = 'premium';
          }
        }
      } else {
        this.currentPlanType = Constants.FREE;
        this.optionType = 'free';
      }

      // check local storage
      let lsData: any = this.localStorageService.getData('userVanity');
      if (lsData) {
        this.vanity_name = lsData.vanity_name;
        this.name = lsData.name;
      } else {
        // reroute to login
        this.router.navigate([`login`]);
      }
    }

  }

  private subscribeToQueryParams() {
    this.subscription = this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.hasOwnProperty('optionType') && queryParams.optionType !== '') {
        this.optionType = queryParams.optionType;
      }
    });
  }

  public selectOptionType(opType: string, event: Event): void {
    if (this.isRegCarddisabled || this.isFreeCarddisabled) {
      event.stopPropagation(); // Prevent click event from propagating
    } else {
      this.optionType = opType;
    }
  }

  public redirectToPayment(plan: string): void {
    const externalLink = `${APIEndpoints.BASE_API}/client/create-checkout-session?vanity_name=${this.vanity_name}&plan=${plan}`; // Replace with your external link
    window.location.href = externalLink;
  }  

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.isPaymentPublic) {
        this.dataService.setData('false');
      } else {
        this.dataService.setData('true');
        this.dataService.setName(this.name);
      }
      }, 1000);
  }

  public backToLanding(): void {
    if (this.vanity_name) {
      this.router.navigate(['profile'],{ queryParams: {vanity_name: this.vanity_name}})
    } else {
      this.router.navigate(['login']);
    }
  }

  public goToFreeOption(): void {
    // go to the preview
    this.router.navigate(['resume-edit'],{ queryParams: {vanity_name: this.vanity_name}})

  }

  public initEnterDetailForm(): void {
    this.enterDetailForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['',[Validators.required, EmailValidator()]],
      phone: ['', [Validators.required]],
    });
  }

  private checkScreenSize(): void {
    const isSmallScreen = window.innerWidth <= 767; // Match the CSS media query width
    this.isSmallScreen = isSmallScreen;
    // You can also dynamically apply a CSS class if needed
    if (isSmallScreen) {
        this.displayDialog = false;
    } else {
        this.displayDialog = true;
    }
  }

  public onSubmit(): void {
    // TODO how to capture this info
    this.submitted = true;
    if (this.enterDetailForm.valid) {

      //reroute to recruiter login
      this.resetForm();
      this.router.navigate(['/recruiter-login']);
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `Thank you. Someone from our team will get in touch within the next few days.`, life: 7000});
    }
  }

  private resetForm(): void {
    this. submitted = false;
    this.initEnterDetailForm();
  }

  get FREE() {
    return Constants.FREE;
  }

  get STANDARD() {
    return Constants.STANDARD;
  }

  get PREMIUM() {
    return Constants.PREMIUM;
  }
  
  public confirmCancelSubscription(): void {
    this.confirmationService.confirm({
        message: `<p><img src="cancel.jpg" alt="Cancel Subscription" style="width: 200px; height: 200px;"></p>
        <p>We're sorry to see you go! Your subscription has been cancelled. Your subscription will remain active until <strong>[Insert Date of Next Renewal]</strong>. If you have any feedback on why you chose to cancel or if there is anything we can do to improve our service, please let us know.</p>
        <p>If you have any questions or need further assistance, please don't hesitate to reach out to our customer support team. Thank you for being a part of <Your Website Name>, and we hope to have the opportunity to serve you again in the future.</p>`,
        accept: () => {
            //Actual logic to perform a confirmation
            this.cancelSubscriptionPlan();
        },
        key: "confirmDelSubscriptionDialog"            
    });
  }

  public startPolling(vanity_name: string): void {
    this.polling = true;

    const apiUrl = `${APIEndpoints.BASE_API}/client/verify-plan/`;
    const queryParams = { 'vanity_name': vanity_name};
    const intervalTime = 5000; // 5 seconds

    this.pollingSubscription = this.pollingService.startPolling(
      (response: any) => response.condition === 'met', // Replace with your condition
      apiUrl,
      queryParams,
      intervalTime
    ).subscribe(response => {
      // Handle the response

      if (response && response.hasOwnProperty('status') && response.status === 'canceled') {
          // put back the "plan_type"
          if (response && 
            response.amount === 14.99 ||
            response.amount === 1499 ||
            response.amount === 4.99 ||
            response.amount === 499
          
          ) {
            response.plan_type = Constants.STANDARD;
            response.planType = Constants.STANDARD;
        } else if (response && 
          response.amount === 49.99 ||
          response.amount === 4999 ||
          response.amount === 149 ||
          response.amount === 14900
        ) {
          response.plan_type = Constants.PREMIUM;
          response.planType = Constants.PREMIUM;
        }
    
        this.polling = false;
        this.pollingSubscription.unsubscribe(); // Stop polling when condition is met

        // save the subcriptionPlan in storage
        this.localStorageService.saveData('subcriptionPlan', response);

        //push it in the dataService.setSubscriptionPlan
        this.dataService.setSubscriptionPlan(response);

        // update also the appUser.subscription on the storage, and trigger 'dataService'
        let appUser: AppUser = this.localStorageService.getData('appUser');
        if (appUser) {
          appUser.subscription = response;
          this.localStorageService.saveData('appUser', appUser);
          this.dataService.setAppUser(appUser);
        }

        this.messageService.add({ severity: 'success', summary: 'Success', detail: `Subscription plan successfully cancelled`, life: 5000});

        //// now do the usual get details etc...
        // go to the preview
        this.router.navigate(['resume-edit'],{ queryParams: {'vanity_name': this.vanity_name}})
      }

    });
  }

  private executeCancelSubscriptionPlan(): void {
    let payload: any = {};
    payload.sub_id  = this.subscriptionPlan.sub_id;
  this.cancelProcessing = true;
    this.subscription = this.userService.cancelSubscriptionPlan(payload)
    .subscribe({
      next: response => {
        this.cancelProcessing = false;
        if (response) {
          // after cancelling, poll the "verifyPaymentPlan" to check the status
          // that the state is actually "canceled". When it is, push that on the storage and update the
          // 'dataService.setSubscriptionPlan' for the header
          this.startPolling(this.vanity_name);
        }
      },
      error: error => {
        this.cancelProcessing = false;
        XcvUtilsService.handleError(error, this.messageService);
      }
    });

  }

  public cancelSubscriptionPlan(): void {
    if (this.subscriptionPlan && this.subscriptionPlan.sub_id) {
      if (this.subscriptionPlan.sub_id !== 'sub_dummy') {
        this.executeCancelSubscriptionPlan();
      } else {
        // get the correct subs
        let p = new AytHttpParams();
        p.set('vanity_name', this.vanity_name);
        this.subscription = this.userService.verifyPaymentPlan(p).subscribe({
          next: response => {
            this.subscriptionPlan = response;
            this.executeCancelSubscriptionPlan();
          },
          error: error => {
            XcvUtilsService.handleError(error, this.messageService);
          }
        });
      }

    } else {
      this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `There is an issue with your subscription`, life: 5000});
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }

}
