import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'app/core/local-storage.service';
import { UserService } from 'app/core/user.service';
import { DataService } from 'app/helpers/data.service';
import { Subscription } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppUser, Client, PaymentHistory, Profile, Recruiter, RecruiterClient, SubscriptionDetails} from 'app/model/Userprofile';
import { AytHttpParams } from 'app/helpers/http-config';
import { APIEndpoints, Constants } from 'app/helpers/app-settings';
import { FilterService } from 'primeng/api';
import { XcvUtilsService } from 'app/core/xcv-utils.service';
import { EmailValidator } from 'app/helpers/xcv-validator';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Color, ScaleType } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-company-dashboard',
  templateUrl: './company-dashboard.component.html',
  styleUrls: ['./company-dashboard.component.scss']
})


export class CompanyDashboardComponent implements AfterViewInit, OnInit, OnDestroy {

  public displayDialog: boolean = false;
  public isSmallScreen = false;
  public appUser!: AppUser;
  public dashboardMode!: string;
  public recruiterForm!: FormGroup;
  public submitted = false;
  public processing = false;
  public recruiters: Recruiter[] = [];
  public loading = false;
  public total_recruiters = 0;
  public payment!: number;
  public showChart = false;
  public single: any[] = [];
  public view: any = [1050, 600];
  public colorScheme: Color = {
    name: 'myScheme',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'],
  };
  public isRegCarddisabled = false;
  public isFreeCarddisabled = false;
  public optionType = '';
  // options
  gradient: boolean = true;
  showLegend: boolean = false;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  // legendPosition: string = 'below';
  public company_email: any;
  public pricingPlan!: any;
  private subscription!: Subscription;
  public subscriptionDetails!: SubscriptionDetails;
  public isLoadingPage:boolean = false;
  public paymentHistory!:PaymentHistory;
  public isPayment:boolean = false;


  constructor(private fb: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    private currrencyPipe: CurrencyPipe,
    private userService: UserService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dataService: DataService,
    private filterService: FilterService,
    private localStorageService: LocalStorageService,
    private activatedRoute: ActivatedRoute
  ) {
    this.createRecruiterForm();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }


  ngOnInit(): void {
    this.localStorageService.removeData('subcription')
    this.checkScreenSize();
    this.subscribeToQueryParams();
    this.getAppUser();
   
  }

  ngAfterViewInit() {
    this.getSubsDetails();
    setTimeout(() => {
      this.dataService.setData('true');
      if (this.appUser) {
        this.dataService.setAppUser(this.appUser);
        if (this.appUser.company_name) {
          this.dataService.setName(this.appUser.company_name);;
        }
      }
      this.dataService.setVanity('');
    }, 500);
  }

  private subscribeToQueryParams() {
    this.subscription = this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.hasOwnProperty('payment') && queryParams.payment !== '' && queryParams.hasOwnProperty('company_email') && queryParams.company_email !== '') {
        this.isPayment = true;
        this.payment = queryParams.payment;
        this.company_email = queryParams.company_email;
        
        this.verifyPayment(this.company_email)
        this.getPaymenthistory(this.company_email)
      }
      else if (queryParams.hasOwnProperty('pricing')) {
        if(queryParams.pricing === 'base'){

          this.dashboardMode = Constants.CREATE;      
        }
      }
      else {
        let company = this.localStorageService.getData('appUser');
        
        this.verifyPayment(company.email)
        this.getPaymenthistory(company.email)
 
      }
    });
    this.isLoadingPage = false;
  }

  get CREATE() {
    return Constants.CREATE;
  }

  get DISPLAY() {
    return Constants.DISPLAY;
  }

  private checkScreenSize() {
    const isSmallScreen = window.innerWidth <= 767; // Match the CSS media query width
    this.isSmallScreen = isSmallScreen;
    // You can also dynamically apply a CSS class if needed
    if (isSmallScreen) {
      this.displayDialog = false;

      this.view = [525, 300];
    } else {
      this.displayDialog = true;
    }
  }

  public getAppUser(): void {
    this.appUser = this.localStorageService.getData('appUser');

    if (this.appUser && this.appUser.company_name) {
      this.dataService.setAppUser(this.appUser);
      this.dataService.setName(this.appUser.company_name);;
    }

    if (this.appUser && this.appUser.user_type) {
      this.dataService.setUserType(this.appUser.user_type);;
    }
    if (this.appUser && this.appUser.total_recruiters) {
      this.total_recruiters = parseInt(this.appUser.total_recruiters);

      if (this.appUser.email) {
        this.execGetRecruiters(this.appUser.email, false);
      }
    }

    if (!XcvUtilsService.isNotNullOrEmpty(this.appUser)) {
      setTimeout(() => {
        this.dataService.setData('false');
        this.router.navigate(['recruiter-login'])
      }, 500);
    }
  }
  public getSubsDetails(): void {
    this.subscriptionDetails = this.localStorageService.getData('subcriptionType');
    if (this.subscriptionDetails) {
      this.dataService.setSubscriptionDetails(this.subscriptionDetails);
    }
  }

  public verifyPayment(user_email: any) {
    let p = new AytHttpParams();
    p.set('company_email', user_email);
    this.subscription = this.userService.verifyPaymentPlan(p).subscribe({
      next: response => {
        this.localStorageService.saveData('subcriptionType', response);
        console.log('verify response = ', response);
        // response.payment = true;
        // response.paymenttype = 1;
        this.pricingPlan = response;
        if(this.pricingPlan.payment_type !== 'NA' && this.isPayment == true)
          {
this.notifyPricing(user_email,response.payment_type)
          }
        this.getSubsDetails();
     
        if (response.exception) {
          //do the payment first
          this.dashboardMode = Constants.CREATE;
        } else {
          // theres credit card info, so do the ccontinue
          this.dashboardMode = Constants.DISPLAY;       
        }
        this.isLoadingPage = false;
      },
      error: error => {
        XcvUtilsService.handleError(error, this.messageService);
        this.isLoadingPage = false;
      }
    });
  }

  public getPaymenthistory(company_email: any): void {
    let p = new AytHttpParams();
    p.set('company_email', company_email);
    this.subscription = this.userService.getPaymentHistory(p).subscribe({
      next: response => {
        this.localStorageService.saveData('paymentHistory',response);
        this.getPayment();
      },
      error: error => {
        XcvUtilsService.handleError(error, this.messageService);
      }
    });
  } 

  public notifyPricing(email:any,paytype:any):void
  {
    let company = this.localStorageService.getData('appUser');
    let p = new AytHttpParams();
    p.set('client_email', email);
    p.set('payment_plan', paytype);
    
    this.subscription = this.userService.notifyPricing(p).subscribe({
      next: response => {
       console.log('email sent',response)
      },
      error: error => {

        XcvUtilsService.handleError(error, this.messageService);
      }
    });
  }

  public getPayment(): void {
    this.paymentHistory = this.localStorageService.getData('paymentHistory');
    if (this.paymentHistory) {
      this.dataService.setPayHistoryDetails(this.paymentHistory);
    }
  }

  private createRecruiterForm() {
    this.recruiterForm = this.fb.group({
      email: ['', [Validators.required, EmailValidator()]]
    });
  }

  public continueRegisterRecruiter() {
    let payload: any = {};
    let p = this.localStorageService.getData('payload');

    if (p) {
      payload.email = p.email;
      payload.company_email = p.company_email;
      this.recruiterForm.controls['email'].setValue(p.email); // this is just for cosmetic purpose

      this.processing = true;
      this.subscription = this.userService.registerRecruiter(payload).subscribe({
        next: response => {
          this.processing = false;

          this.messageService.add({ severity: 'success', summary: 'Success', detail: `Recruiter successfully registered`, life: 5000 });

          // at this point, the recruiter count changed, and is not the same with whats on the get the appUser storage
          // call the "company_profile" to get the correct count, replace the count with whats on the appUser
          let p = new AytHttpParams();
          p.set('email', payload.company_email);

          this.subscription = this.userService.getCompanyProfile(p).subscribe({
            next: response => {

              // this reloads the table / recruiter list coming from company profile
              this.renderTable(response);
              this.getLatestRecruiterCount(response);

              //switch dashboardMode to display, since company already created a recruiter
              this.dashboardMode = Constants.DISPLAY;

              //remove from local storage
              this.localStorageService.removeData('payload');

              // clear the form
              this.submitted = false;
              this.recruiterForm.controls['email'].setValue('');
              this.recruiterForm.controls['email'].clearValidators;
              this.recruiterForm.controls['email'].setErrors(null);

            },
            error: error => {
              XcvUtilsService.handleError(error, this.messageService);
            }
          });

        },
        error: error => {
          XcvUtilsService.handleError(error, this.messageService);
          this.processing = false;
        }
      });

    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: `Cannot continue registering recruiter with missing information`, life: 5000 });
      // switch the mode to create
      this.dashboardMode = Constants.CREATE;
    }
  }

  public onSubmit() {
    console.log('>>>>>>> onSubmit', this.total_recruiters);
    this.submitted = true;
    var plan = this.localStorageService.getData('subcriptionType')
    if (plan.status === 'trialing') {
      if (this.total_recruiters < 1) {
        this.addRecruiters();
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `Only 1 recruiter allowed during the trial`, life: 5000 });
      }
    }
    // if (plan.payment_type !== 'NA') {
    //   if (this.total_recruiters < plan.payment_type) {
    //     this.addRecruiters();
    //   }
    //   else {
    //     this.messageService.add({ severity: 'error', summary: 'Error', detail: `Recruiter limit exceeded`, life: 5000 });
    //   }
    // }
    let paydetails = this.localStorageService.getData('paymentHistory')
      var previousPlan = paydetails.p_payment_type[paydetails.p_payment_type.length - 1];
      var planendDate = paydetails.p_valid_till[paydetails.p_valid_till.length - 1];
      var validTill = new Date(planendDate)
      var todayDate = new Date();
    if (plan.status === 'active') {
      

      if (paydetails.p_payment_type.length == 0) {
        if (this.total_recruiters < plan.payment_type) {
          this.addRecruiters();
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: `Recruiter limit exceeded`, life: 5000 });
        }
      }
      else {
        if (parseInt(previousPlan) > parseInt(plan.payment_type)) {
          if (validTill > todayDate) {
            if (this.total_recruiters < previousPlan) {
              this.addRecruiters();
            }
            else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: `Recruiter limit exceeded`, life: 5000 });
            }
          }
          else {
            if (this.total_recruiters < plan.payment_type) {
              this.addRecruiters();
            }
            else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: `Recruiter limit exceeded`, life: 5000 });
            }
          }
        }
        else {
          if (this.total_recruiters < plan.payment_type) {
            this.addRecruiters();
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: `Recruiter limit exceeded`, life: 5000 });
          }
        }
      }

    }
    if (plan.status === 'canceled') {
      if (paydetails.p_payment_type.length !== 0) {
        if(validTill > todayDate){
          if (this.total_recruiters < previousPlan) {
            this.addRecruiters();
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: `Recruiter limit exceeded`, life: 5000 });
          }
        }
      }
    }
  }

  public addRecruiters(): void {
    if (this.recruiterForm.valid) {
      // validate first the recruiter email if already existing
      let p = new AytHttpParams();
      let recEmail = this.recruiterForm.controls['email'].value;
      p.set('email', recEmail);
      this.subscription = this.userService.validateRecruiter(p).subscribe({
        next: response => {

          if (response && response.recruiter_exists === true) {
            this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `Recruiter '${recEmail}' is already registered`, life: 5000 });
          } else {
            // recruiter NOT existing, continue on

            //store payload onlocal storage
            let payload = { email: this.recruiterForm.controls['email'].value, company_email: this.appUser.email };
            this.localStorageService.saveData('payload', payload);

            // do the ckeck here if credit card is accomplished or not by calling a new endpoint
            if (this.appUser.email) {
              this.continueRegisterRecruiter();

            }

          }

        },
        error: error => {
          XcvUtilsService.handleError(error, this.messageService);
        }
      });
    }
  }

  private calculateBill(clients: RecruiterClient[], isRegistered: boolean): number {
    let recruiterCost = 0;

    if (isRegistered) {
      recruiterCost = Constants.RECRUITER_COST;
    }
    return recruiterCost;
    // if (isRegistered) {
    //   recruiterCost = Constants.RECRUITER_COST;
    // }
    // if (clients) {
    //   return ( (clients.length * Constants.CLIENT_COST) + recruiterCost);
    // } else {
    //   return recruiterCost;
    // }
  }

  private calculateClientCount(clients: RecruiterClient[]): number {
    if (clients) {
      return (clients.length);
    } else {
      return 0;
    }
  }

  onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  public createPiechartData(recruiters: Recruiter[]) {
    this.single = [];
    if (recruiters) {

      for (let i = 0; i < recruiters.length; i++) {
        let itm: any = {};
        itm.name = recruiters[i].first_name + ' ' + recruiters[i].last_name;
        itm.value = recruiters[i].bill;
        // itm.value =  '8.99';
        this.single.push(itm);
      }
    }

    this.showChart = true;
  }

  private getLatestRecruiterCount(response: any) {
    let newRecruiterCount = 0;
    if (response && response.recruiters_data) {
      newRecruiterCount = response.recruiters_data.length;
    }

    this.total_recruiters = newRecruiterCount;
    this.appUser.total_recruiters = this.total_recruiters.toString();

    // save on storage
    this.localStorageService.saveData('appUser', this.appUser);
    // update the data service
    this.dataService.setAppUser(this.appUser);
  }

  private renderTable(response: any) {
    if (response) {
      console.log('rectruiters = ', response);
      if (response && response.recruiters_data) {

        this.recruiters = [];
        response.recruiters_data.forEach((rec: any) => {
          let newRec: Recruiter = new Recruiter();

          if (rec.first_name && rec.last_name) {
            rec.registered = true;
          } else {
            rec.registered = false;
          }

          newRec.recruiter_clients = rec.recruiter_clients;
          newRec.first_name = rec.first_name;
          newRec.last_name = rec.last_name;
          newRec.recruiter_email = rec.recruiter_email;
          newRec.created = rec.date_subscribed;
          if (newRec.recruiter_clients) {
            newRec.bill = this.calculateBill(newRec.recruiter_clients, rec.registered);
            // newRec.bill = Constants.RECRUITER_COST;
            newRec.client_count = this.calculateClientCount(newRec.recruiter_clients);

          } else {
            newRec.bill = 0;
            newRec.client_count = 0;
          }

          this.recruiters.push(newRec);
        });
        console.log('this.recruiters = ', this.recruiters);
        this.createPiechartData(this.recruiters);
      }
    }
  }

  public execGetRecruiters(company_email: string, updateRecCount: boolean): void {
    let p = new AytHttpParams();
    p.set('email', company_email);
    this.subscription = this.userService.getCompanyProfile(p).subscribe({
      next: response => {
        this.renderTable(response);
        if (updateRecCount) {
          this.getLatestRecruiterCount(response);
        }
      },
      error: error => {
        XcvUtilsService.handleError(error, this.messageService);
      }
    });

  }

  public checkDateLoggedIn(rec: Recruiter): any {
    if (rec && rec.created) {
      if (rec.date_loggedin === undefined) {
        return 'N/A';
      } else {
        return this.datePipe.transform(rec.date_loggedin, 'd MMM y'); //    
      }
    } else {
      return 'N/A';
    }
  }

  public confirm(recruiter: Recruiter): void {
    let recruiter_email = recruiter.recruiter_email;

    this.confirmationService.confirm({
      message: `Are you sure that you want delete recruiter '${recruiter_email}'?`,
      accept: () => {
        //Actual logic to perform a confirmation
        if (recruiter_email) {
          this.confirmRemoveReceuiter(recruiter_email);
        }
      },
      key: "confirmDelRecDialog"
    });
  }

  public confirmRemoveReceuiter(recruiter_email: string): void {
    let p = new AytHttpParams();
    p.set('recruiter_email', recruiter_email);
    this.subscription = this.userService.deleteRecruiter(p).subscribe({
      next: response => {

        this.messageService.add({ severity: 'success', summary: 'Success', detail: `Recruiter '${recruiter_email}' successfully deleted`, life: 5000 });

        // reload the list
        if (this.appUser.email) {
          this.execGetRecruiters(this.appUser.email, true);
        }
      },
      error: error => {
        XcvUtilsService.handleError(error, this.messageService);
      }
    });
  }

  public confirmResend(recruiter: Recruiter): void {
    let recruiter_email = recruiter.recruiter_email;
    this.confirmationService.confirm({
      message: `Are you sure that you want resend registration email for '${recruiter_email}'?`,
      accept: () => {
        //Actual logic to perform a confirmation
        if (recruiter_email) {
          this.confirmEmailResend(recruiter_email);
        }
      },
      key: "confirmSendEmailDialog"
    });
  }

  public confirmEmailResend(recruiter_email: string): void {
    let payload: any = {};
    payload.email = recruiter_email;
    payload.company_email = this.appUser.email;

    this.subscription = this.userService.resendEmail(payload).subscribe({
      next: response => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: `Invitation email to register successfully re-sent`, life: 5000 });
      },
      error: error => {
        XcvUtilsService.handleError(error, this.messageService);
      }
    });
  }

  public labelFormatting(name: any) { // this name will contain the name you defined in chartData[]
    let self: any = this; // this "this" will refer to the chart component (pun intented :))
    let data = self.series.filter((x: any) => x.name == name); // chartData will be present in
    // series along with the label

    if (data.length > 0) {
      // return `${data[0].name}: ${data[0].value}`;
      return `${data[0].name}`;
    } else {
      return name;
    }
  }

  get CLIENT_COST() {
    return Constants.CLIENT_COST;
  }

  get RECRUITER_COST() {
    return Constants.RECRUITER_COST;
  }

  public addAnotherRecruiter() {
    this.dashboardMode = Constants.CREATE;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
