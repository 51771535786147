<div id="content" #content class="{{message === 'HAS_PARENT' ? 'container-with-parent' : 'container' }} mb-5">
  <div class="resume-wrapper">
    <div class="row pr-2">
      <div class="col-md-6">
        <h2 class="emp-name" style="font-weight: 600;">{{resumeDetails?.first_name}} {{resumeDetails?.last_name}}</h2>
        <h4 class="emp-title">{{resumeDetails?.title}}</h4>
      </div>
      <div class="col-md-6">
     
          <div class="row" *ngIf="resumeDetails?.visibility?.contactno == true">

            <div class="col-md-2"><span class="contact-tag" style="font-weight: 600;">Phone:</span> </div>
            <div class="col-md-8"><span class="contact-desc"> {{
              resumeDetails?.contact.phone &&resumeDetails?.contact.phone.length > 0 ?
              resumeDetails?.contact.phone[0] : 'None Indicated'
              }}</span> </div>
          </div>
          <div class="row" *ngIf="resumeDetails?.visibility?.email == true">
            <div class="col-md-2"><span class="contact-tag" style="font-weight: 600;">Email:</span></div>
            <div class="col-md-8"> <span class="contact-desc">{{
              resumeDetails?.contact.email && resumeDetails?.contact.email.length > 0 ?
              resumeDetails?.contact.email[0] : 'Not Indicated'
              }} </span></div>
          </div>
          <div class="row" *ngIf="resumeDetails?.visibility?.linkedin == true">
            <div class="col-md-2"><span class="contact-tag" style="font-weight: 600;">LinkedIn:</span> </div>
            <div class="col-md-8"><span class="contact-desc"><a href="https://www.linkedin.com/in/{{resumeDetails?.vanity_name}}/"
              target="_blank">
              {{resumeDetails?.vanity_name}}
          </a> </span> </div>
          </div>
          <div class="row" *ngIf="resumeDetails?.visibility?.twitter == true">
            <div class="col-md-2"><span class="contact-tag" style="font-weight: 600;">Twitter:</span></div>
            <div class="col-md-8"><span class="contact-desc">   {{
              resumeDetails?.socials.twitter?
              resumeDetails?.socials.twitter : 'Not Indicated'
              }} </span></div>
          </div>
      </div>
     
    </div>
    <div class="row pr-2">
      <div class="col-md-12 mt-3">
        <h4 class="section-title">Summary</h4>
        <div class="section-wrapper">
          <p [innerHTML]="resumeDetails?.about"></p>
        </div>
      </div>
    </div>
    <div class="row pr-2" *ngIf="resumeDetails?.visibility?.experience === true && resumeDetails?.experience && resumeDetails?.experience?.length > 0">
      <div class="col-md-12 mt-3">
        <h4 class="section-title">Experience</h4>
        <div class="section-wrapper">
          <div class="exp-grid pl-0">
            <div *ngFor="let exp of resumeDetails?.experience">
              <h5 class="exp-period mb-1"><i>{{displayExperienceDurationDateRange(exp)}}</i></h5>
              <h4 class="exp-title mb-0">{{exp?.title}}</h4>
              <h5 class="exp-period">{{exp?.company}}
                {{exp.location ? '(' + exp.location + ')' : ''}}</h5>
              <p [innerHTML]="exp?.description"></p>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pr-2" *ngIf="resumeDetails?.visibility?.education === true && resumeDetails?.education && resumeDetails?.education?.length > 0">
      <div class="col-md-12 mt-3">
        <h4 class="section-title">Education</h4>
        <div class="section-wrapper">
          <ul class="exp-grid pl-0">
            <li *ngFor="let edu of resumeDetails?.education;">
              <h5 class="exp-period mb-1"><i>{{displayExperienceDurationDateRange(edu)}}
                {{displayExperienceDurationDateRange(edu) ? '' : ''}}</i></h5>
              <h4 class="skill-list mb-0">{{edu.degree_name}} {{edu.field_of_study}}</h4>
              <h5 class="exp-period">{{edu.school}}</h5>
           
            </li>
          </ul>
        
        </div>
      </div>
    </div>

    <div class="row pr-2">
      <div class="col-md-6 mt-3" *ngIf="resumeDetails?.visibility?.skills === true && resumeDetails?.skills && resumeDetails?.skills.length > 0">
        <h4 class="section-title">Skills</h4>
        <div class="section-wrapper">
          <ul class="exp-grid pl-0">
            <li *ngFor="let skills of resumeDetails?.skills">
             <h5 class="skill-list"> {{skills}}</h5>
            </li>
  
          </ul>
        
        </div>
      </div>
      <div class="col-md-6 mt-3" *ngIf="resumeDetails?.languages && resumeDetails?.visibility?.languages === true">
       <ng-container *ngIf="resumeDetails?.languages && resumeDetails?.languages.length > 0">
        <h4 class="section-title">Languages</h4>
        <div class="section-wrapper">
        <ul class="lang-list pl-0">
          <li *ngFor="let lang of resumeDetails?.languages">
            <p>{{lang}}</p>
          </li>
        </ul>
        </div>
       </ng-container>
      </div>
    </div>
    <div class="row pr-2">
      <div class="col-md-6 mt-3" *ngIf="resumeDetails?.visibility?.courses === true && resumeDetails?.accomplishments.courses.length> 0">
        <h4 class="section-title">Courses</h4>
        <div class="section-wrapper">
          <ul class="lang-list pl-0">
            <li *ngFor="let course of resumeDetails?.accomplishments.courses">
              <h5 class="exp-period mb-0"><i>{{course.number}}</i></h5>
              <h4 class="pub-title mb-2">{{course.name}}</h4>
            </li>   
         
          </ul>       
        </div>
      </div>
      <div class="col-md-6 mt-3" *ngIf="resumeDetails?.visibility?.projects === true && resumeDetails?.accomplishments.projects.length> 0">
        <h4 class="section-title">Projects</h4>
        <div class="section-wrapper">
          <ul class="lang-list pl-0">
            <li *ngFor="let proj of resumeDetails?.accomplishments.projects">
              <h5 class="exp-period mb-0"><i>{{displayprojectDuration(proj)}}</i></h5>
              <h4 class="pub-title mb-2">{{proj.title}}</h4>
            </li>   
         
          </ul>       
        </div>
      </div>
    </div>
    <div class="row pr-2">
      <div class="col-md-6 mt-3" *ngIf="resumeDetails?.visibility?.certifications === true && resumeDetails?.certifications && resumeDetails?.certifications?.length > 0">
        <h4 class="section-title">Certifications</h4>
        <div class="section-wrapper">
          <ul class="pl-0 lang-list">
            <li *ngFor="let courses of resumeDetails?.certifications">
              <h4 class="pub-title mb-0">{{courses}}</h4>
            </li>
         
          </ul>
        
        </div>
      </div>
      <div class="col-md-6 mt-3" *ngIf="resumeDetails?.visibility?.publications === true && resumeDetails?.accomplishments.publications.length > 0">
        <h4 class="section-title">Publications</h4>
        <div class="section-wrapper">
        <ul class="lang-list pl-0">
          <li *ngFor="let pub of resumeDetails?.accomplishments.publications">        
            <h4 class="pub-title mb-0" >{{pub.name}}</h4>
            <h5 class="exp-period mb-0"><i><a>{{pub.url}}</a></i></h5>
          </li>

        </ul>
        </div>
      </div>
    </div>
  </div>
</div>