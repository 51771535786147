
import { Component, Renderer2, RendererFactory2, ElementRef, ViewChild, Input, EventEmitter, Output, OnInit, OnDestroy} from '@angular/core';
import { LocalStorageService } from 'app/core/local-storage.service';
import { UserService } from 'app/core/user.service';
import { AytHttpParams } from 'app/helpers/http-config';
import { Profile } from 'app/model/Userprofile';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Constants } from 'app/helpers/app-settings';
import { XcvUtilsService } from 'app/core/xcv-utils.service';
import { asBlob } from 'html-docx-js-typescript';
import { saveAs } from 'file-saver';
import { DataService } from 'app/helpers/data.service';
import { MessageService } from 'primeng/api';
import jspdf, { jsPDF } from "jspdf";
import { threadId } from 'worker_threads';
// import * as htmlDocx from 'html-docx-js';

@Component({
  selector: 'app-template1',
  templateUrl: './template1.component.html',
  styleUrls: ['./template1.component.scss']
})
export class Template1Component implements OnInit, OnDestroy {
    @Input() resumeDetails:any;  

    @Input()
    message!: string;

    @Output() previewVisibleEvent = new EventEmitter<string>();    
    @Output() pdfPreviewVisibleEvent = new EventEmitter<string>();    
    @Output() pdfDownloadEvent = new EventEmitter<string>();    

    public hidden = false;
    public pdf: any;
    public vanity_name = '';
    public loading = false;
    public name = '';
    public reloadTemplate = false;
    public previewVisibleChild2 = true;
    public pdfPreviewAction = false;

    public pdfPreviewOn = false;
    private renderer: Renderer2;
    private subscription!: Subscription;

    @ViewChild("content", {static: true}) content!: ElementRef; 
    @ViewChild('iframeContainer', { read: ElementRef, static: true })
  iframeContainer!: ElementRef;
        
    constructor(
      private rendererFactory: RendererFactory2,
      private el: ElementRef,
      private dataService: DataService,
      private messageService: MessageService,
      private localStorageService: LocalStorageService,
      private userservice: 
      UserService,
      private datePipe: DatePipe) {
        this.renderer = this.rendererFactory.createRenderer(null, null);

        // // this section handles subscribing for parent action on pdf download
        // this.subscription = this.dataService.getPdfDownload().subscribe(data => {
        //   let pdfDownloadAction = (/true/i).test(data);
        //   if (pdfDownloadAction) {
        //     this.captureScreen();
        //   }
        // });      

        // this section handles subscribing for parent action on removing pdf preview
        // this.subscription = this.dataService.getRemovePdfPreview().subscribe(data => {
        //   let removePdfPreviewAction = (/true/i).test(data);
        //   if (removePdfPreviewAction) {
        //     this.removeIframe();
        //     this.pdfPreviewOn = false;
        //     this.pdfPreviewVisibleEvent.emit('false');
        //   }
        // });      

        // this section handles subscribing for parent action on showing pdf preview
        // this.subscription = this.dataService.getPdfPreview().subscribe(data => {
        //   this.pdfPreviewAction = (/true/i).test(data);

        //   if (this.pdfPreviewAction) {
        //     setTimeout(() => {
        //       this.captureScreenPreview();
        //       this.pdfPreviewAction = false;
        //       this.pdfPreviewOn = true;
        //       this.pdfPreviewVisibleEvent.emit('true');
        //     }, 500);
        //   }
        // });      

        // this section handles subscribing for parent action on saving changes during edit,
        // which should reload the selected template for it to reflect the latest changes as well
        // this.subscription = this.dataService.getReloadTemplate().subscribe(data => {
        //   this.reloadTemplate = (/true/i).test(data);
        //   if (this.reloadTemplate) {
        //     this.getResumeDetails();
        //   } else {
        //     this.reloadTemplate = false;
        //   }
        // });      
    }

    ngOnInit(): void {
      console.log('template 1 message = ', this.message);
      // this.getResumeDetails();
    }

    public getResumeDetails() {
        // get the vanity name from storage and use to get the detai;
        let userVanity: any = this.localStorageService.getData('userVanity');
        this.vanity_name = userVanity.vanity_name;
        this.name = userVanity.name;

        // check first if there is a stored current resume, if there is, its probably
        // a jd aligned resume and call "specificResume" to get the resume
        // if not do, the default routine
        let cr: any = this.localStorageService.getData('currentResume');

        if (cr) {
          if (XcvUtilsService.isNotNullOrEmpty(cr.id)) {
            // this is a jd aligned resume
            this.executeSpecificResume(cr);
          } else {
            this.executeFetchResume();
          }

        } else {
          this.executeFetchResume();
        }
    }

    public executeSpecificResume(currentResume: any): void {
      let p = new AytHttpParams();
      p.set('vanity_name', currentResume.vanity_name);
      p.set('title', currentResume.name);
      let au: any = this.localStorageService.getData('appUser');
      if (au &&  au.user_type === Constants.RECRUITER) {
        p.set('recruiter_email', au.email);
      }

      this.loading = true;
      this.subscription = this.userservice.getIndividualResume(p)
      .subscribe({
        next: (response: Profile) => {
          if (response) {
            this.loading = false;
              this.resumeData(response);
          }
        },
        error: (_error: any) => {
          this.loading = false;
        }
      });
    }

    public executeFetchResume(): void {
      let p = new AytHttpParams();

      p.set('vanity_name', this.vanity_name);
      let au: any = this.localStorageService.getData('appUser');
      if (au &&  au.user_type === Constants.RECRUITER) {
        p.set('recruiter_email', au.email);
      }

      this.loading = true;
      this.subscription = this.userservice.getResumeDetails(p)
      .subscribe({
        next: (response: Profile) => {
          if (response) {
            this.loading = false;
              this.resumeData(response);
          }
        },
        error: (_error: any) => {
          this.loading = false;
        }
      });
    }

    public doScreenPreview() {
      this.removeIframe();
      this.captureScreenPreview();
    }

    public resumeData(res: Profile) {
      res.full_name = res.first_name + ' ' + res.last_name;
      this.resumeDetails = res;

        // only do the automatic load of pdf preview if tempalte has parent
        if (this.message === Constants.HAS_PARENT) {
          this.removeIframe();
          setTimeout(() => {
            this.captureScreenPreview();
            this.pdfPreviewVisibleEvent.emit('true');

          }, 2000);
        }
    }

    public displayExperienceDurationDateRange(exp: any) {
      let disp = '';
      if (exp && exp.starts_at) {
        disp = XcvUtilsService.convertDateFormat(exp.starts_at.day, exp.starts_at.month, exp.starts_at.year, this.datePipe);
      }
      if (exp && exp.ends_at) {
        disp = disp + ' - ' + XcvUtilsService.convertDateFormat(exp.ends_at.day, exp.ends_at.month, exp.ends_at.year, this.datePipe);
      }

      return disp;
    }

    public displayprojectDuration(proj: any) {
      let disp = '';
      if (proj && proj.starts_at) {
        disp = XcvUtilsService.convertDateFormat(proj.starts_at.day, proj.starts_at.month, proj.starts_at.year, this.datePipe);
      }
      if (proj && proj.ends_at) {
        disp = disp + ' - ' + XcvUtilsService.convertDateFormat(proj.ends_at.day, proj.ends_at.month, proj.ends_at.year, this.datePipe);
      }

      return disp;
    }

    // test for docx, as per sherin    
    // public async downloadDocx( ) {
    //   const htmlString = this.content.nativeElement;
    //   const htmlContent = new XMLSerializer().serializeToString(htmlString);
    //   var converted = await asBlob(htmlContent, {
    //     orientation: 'landscape',
    //     margins: { top: 720 },
    //   });
      
    // //saveAs(converted, 'test.docx');
    //   asBlob(converted.toString()).then(data => saveAs(data.toString(), 'file.docx') // save as docx file
    //   ); 
    //   // const blob = new Blob([converted], { type: 'docx' });
    //   // const url= window.URL.createObjectURL(blob);
    //   // window.open(url);
    // }

  public downloadDocx(element: any, filename = '') {
    var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:office'>";
    var postHtml = "</body></html>";
    var html = preHtml + document.getElementById(element)?.innerHTML + postHtml;

    var blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
    });
    var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

    filename = filename ? filename + '.doc' : 'document.doc';
    var downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    // if ((window.navigator as any).msSaveOrOpenBlob(blob, filename)) {

    // }
    // else {
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    //}
    document.body.removeChild(downloadLink)
  } 

////test code for doc download//////
  // public downloadDocx(element: any, filename:string)
  // {
  //   const content = document.getElementById('content'); // Replace 'div' with the appropriate selector for your content

  //   if (content) {
  //     const wordBlob = htmlDocx.asBlob(content.innerHTML);
  //     const link = document.createElement('a');

  //     link.href = URL.createObjectURL(wordBlob as any);
  //     link.download = 'output.docx';

  //     link.click();
  //   } else {
  //     console.error('Content not found.');
  //   }
  // }

    public captureScreen(): void {
      const data = document.getElementById('content')!;
      this.pdf = new jspdf('portrait', 'mm', 'a4');
      const docWidth = this.pdf.internal.pageSize.getWidth();

      this.pdf.html(data, {
        autoPaging: 'text',
        margin: [10, 4, 10, 1],
        width: docWidth,
        windowWidth: 1000
      }).then(
        () => {
          this.addPageNumbers()
          this.pdf.save(`Resume.pdf`);
          this.pdfDownloadEvent.emit('true');
        });
    }

    public captureScreenPreview(): void {
      const data = document.getElementById('content')!;
      this.pdf = new jspdf('portrait', 'mm', 'a4');
      const docWidth = this.pdf.internal.pageSize.getWidth();

      this.pdf.html(data, {
        autoPaging: 'text',
        margin: [8, 5, 8, 5],
        width: docWidth,
        windowWidth: 1000
      }).then(
        () => {
          this.addPageNumbers()
          // var iframe = document.createElement('iframe');
          // iframe.setAttribute('style','position:absolute;right:0; top:0; bottom:0; height:100%; width:650px; padding:20px;');
          // document.body.appendChild(iframe);
          // iframe.src = this.pdf.output('datauristring');

          let iframe = this.renderer.createElement('iframe');
          iframe.setAttribute('style', Constants.IFRAME_STYLE);
          this.renderer.appendChild(this.iframeContainer.nativeElement, iframe);
          iframe.src = this.pdf.output('datauristring');
        });
    }

    public addPageNumbers(): void {
      var pageCount = this.pdf.internal.getNumberOfPages();
      for (var i = 1; i <= pageCount; i++) {
        // Set the current page
        this.pdf.setPage(i);
        this.pdf.setFontSize(10);
        // Add page number at the bottom
        this.pdf.text('Page ' + i + ' of ' + pageCount, 95, this.pdf.internal.pageSize.height - 4);
      }
    }

    removeIframe(): void {
      const iframes = this.iframeContainer.nativeElement.querySelectorAll('iframe');
      if (iframes.length > 0) {
        this.renderer.removeChild(this.iframeContainer.nativeElement, iframes[0]);
      }
    }

    public toggleTemplateSide(): void {
      if (this.previewVisibleChild2) {
          this.previewVisibleChild2 = false;
          this.previewVisibleEvent.emit('false');
      } else {
          this.previewVisibleChild2 = true;
          this.previewVisibleEvent.emit('true');
      }
    }

    ngOnDestroy(): void {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }

}
