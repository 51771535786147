import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocalStorageService } from 'app/core/local-storage.service';
import { UserService } from 'app/core/user.service';
import { XcvUtilsService } from 'app/core/xcv-utils.service';
import { APIEndpoints, Constants } from 'app/helpers/app-settings';
import { AytHttpParams } from 'app/helpers/http-config';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'app/helpers/data.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-pricing-plan',
  templateUrl: './pricing-plan.component.html',
  styleUrls: ['./pricing-plan.component.scss']
})
export class PricingPlanComponent {

  public optionType = '';
  public isRegCarddisabled = false;
  public isFreeCarddisabled = false;
  public currentPlanType = Constants.SINGLE;
  public subscriptionPlan!: any;
  public subscriptionDetails!: any;
  public subscription!: any;
  public cancelProcessing = false;
  public trialLimit: any;
  public showElement = false;
  public showCancel!:boolean;
  public process!: string;
  public visible: boolean = false;
  public visibleFeedback:boolean = false;
  public feedbackForm!: FormGroup;
  public feedbackreason: any=[] ;
  public selectedreason: any;
  @Output() cancelEvent = new EventEmitter();

  constructor(private localStorageService: LocalStorageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private userService: UserService,
    private dataService: DataService,
    private messageService: MessageService,private fb: FormBuilder) {
      this.createFeedbackForm();
    setTimeout(() => {
      this.showElement = true;
    }, 1000);
  }

  ngOnInit(): void {
    this.feedbackreason = [
      { name: "Budget constraints" },
      { name: "Changed business needs" },
      { name: "Technical issues/challenges" },
      { name: "Better alternatives found" },
      { name: "Low usage/value perception" },
      { name: "Lack of integration/compatibility" },
      { name: "Unmet expectations" },
      { name: "Contractual/legal reasons" }
    ];
    this.showCancel = false;
    let company = this.localStorageService.getData('appUser');
    this.getSubscriptionDetails(company.email);
    this.subscriptionPlan = this.localStorageService.getData('subcriptionType');
    if (this.subscriptionPlan) {
      if (this.subscriptionPlan.payment_type == 1 && this.subscriptionPlan?.status !== 'trialing') {
        this.optionType = 'single'
      }
      if (this.subscriptionPlan.payment_type == 3) {
        this.optionType = 'tripple'
      }
      if (this.subscriptionPlan.payment_type == 10) {
        this.optionType = 'decuple'
      }
    }
  }

  public getSubsDetails(): void {
    this.subscriptionDetails = this.localStorageService.getData('subcriptionType');
    if (this.subscriptionDetails) {
      this.dataService.setSubscriptionDetails(this.subscriptionDetails);
    }
  }
  public createFeedbackForm()
  {
    this.feedbackForm = this.fb.group({
      reason: ['',[Validators.required]],
      feedbackDesc: [''],
      
    });
  }

  public confirmCheckout(type:string,plan:number):void{
let payment_type = parseInt(this.subscriptionDetails.payment_type);
    if(payment_type != plan && this.subscriptionDetails.payment_type !== 'NA')
      {
        if (payment_type < plan) {
          this.process = 'upgrade';

        }
        if (payment_type > plan) {
          this.process = 'downgrade';
       
        }
        this.confirmationService.confirm({
          message: `Are you sure that you want to `+this.process+` to the selected plan?`,
          accept: () => {
            //Actual logic to perform a confirmation
            this.createCheckout(type);
          },
          key: "confirmDelSubscriptionDialog"
        });

      }

    if(this.subscriptionDetails.exception || this.subscriptionDetails.status === 'trialing')
     {
      this.confirmationService.confirm({
        message: `Are you sure that you want to subscribe to the selected plan?`,
        accept: () => {
          //Actual logic to perform a confirmation
          this.createCheckout(type);
        },
        key: "confirmDelSubscriptionDialog"
      });
 
     }
  }

  public createCheckout(type: string): void {
    let company = this.localStorageService.getData('appUser');
    const externalLink = `${APIEndpoints.BASE_API}/recruiter/create_checkout_session?plan=${type}&company_email=${company.email}`; // Replace with your external link
    window.location.href = externalLink;
  }

  public gotoTrial(): void {
    let company = this.localStorageService.getData('appUser');
    const externalLink = `${APIEndpoints.BASE_API}/recruiter/create_checkout_session?plan=single&trial=true&company_email=${company.email}`; // Replace with your external link
    window.location.href = externalLink;
  }

  public getSubscriptionDetails(company_email: any): void {
    let p = new AytHttpParams();
    p.set('company_email', company_email);
    this.subscription = this.userService.verifyPaymentPlan(p).subscribe({
      next: response => {
        this.subscriptionDetails = response;
        this.localStorageService.saveData('subcriptionType', response);
        let subs_details = this.localStorageService.getData('subcriptionType');
        if (this.subscriptionDetails.status === 'trialing') {
          this.getTrialLimit(this.subscriptionDetails)
        }
      
        if(this.subscriptionDetails.status !== 'canceled')
          {
            this.showCancel = true;
          }
          if(subs_details.exception)
            {
              this.showCancel = false;
            }
       
        console.log('subcription details', this.subscriptionDetails);
      },
      error: error => {
        XcvUtilsService.handleError(error, this.messageService);
      }
    });
  }

  public getTrialLimit(subsDetails: any): void {
    //var dateStr = "2024-03-30T12:00:00Z";
    const endDate = new Date(subsDetails.ends_at);
    // Get today's date
    const today = new Date();
    // Calculate the difference in milliseconds
    var difference = endDate.getTime() - today.getTime();
    // Convert milliseconds to days
    var daysDifference = Math.ceil(difference / (1000 * 3600 * 24));
    this.trialLimit = daysDifference;
  }

  public selectOptionType(opType: string, event: Event): void {
    if (this.isRegCarddisabled || this.isFreeCarddisabled) {
      event.stopPropagation(); // Prevent click event from propagating
    } else {
      this.optionType = opType;
    }
  }

  public confirmCancelSubscription(): void {
  this.visible = true;

  }

  public giveFeedback()
  {
  this.visible = false;
  this.visibleFeedback = true;
  console.log(this.selectedreason)
  }

  public cancelSubscriptionPlan(): void {
    this.subscriptionPlan = this.localStorageService.getData('subcriptionType')
    if (this.subscriptionPlan) {
      // get the correct subs
      let company = this.localStorageService.getData('appUser');
      let p = new AytHttpParams();
      p.set('company_email', company.email);
      this.subscription = this.userService.verifyPaymentPlan(p).subscribe({
        next: response => {
          this.subscriptionPlan = response;

          this.executeCancelSubscriptionPlan(this.subscriptionPlan.sub_id);
        },
        error: error => {
          XcvUtilsService.handleError(error, this.messageService);
        }
      });
    } 
    else {
      this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `There is an issue with your subscription`, life: 5000 });
    }
  }

  private executeCancelSubscriptionPlan(sub_id: any): void {
    let payload: any = {};
    let company = this.localStorageService.getData('appUser');
    payload.sub_id = sub_id;
    payload.email = company.email;
    this.cancelProcessing = true;
    this.subscription = this.userService.cancelSubscriptionPlan(payload)
      .subscribe({
        next: response => {
          this.cancelEvent.emit(true)
          this.cancelProcessing = false;
          if (response) {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: `Successfully canceled the plan`, life: 5000 });
            this.router.navigate(['company-dashboard'])
          }
        },
        error: error => {
          this.cancelProcessing = false;
          XcvUtilsService.handleError(error, this.messageService);
        }
      });
  }

}