<div id="content" #content class="{{message === 'HAS_PARENT' ? 'container-with-parent' : 'container' }} mb-5">
    <div class="resume-wrapper">
        <div class="row">
            <div class="col-md-12 text-center heading-row">
                <h1 class="emp-name">{{resumeDetails?.first_name}} {{resumeDetails?.last_name}}</h1>
                <h3 class="emp-title">{{resumeDetails?.title}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center">
                <ul class="mt-3 mb-2 contact-list">
                    <li class="mr-2">{{resumeDetails?.contact.email &&
                        resumeDetails?.contact.email.length > 0 ?
                        resumeDetails?.contact.email[0] : 'Not Indicated'}}</li>
                    <li class="mr-2"><a href="https://www.linkedin.com/in/{{resumeDetails?.vanity_name}}/"
                            target="_blank">
                            {{resumeDetails?.vanity_name}}
                        </a></li>
                    <li class="mr-2">{{resumeDetails?.contact.phone
                        &&resumeDetails?.contact.phone.length
                        > 0 ?
                        resumeDetails?.contact.phone[0] : 'None Indicated'}}</li>
                    <li class="mr-2">{{resumeDetails?.socials.twitter?resumeDetails?.socials.twitter :
                        'Not Indicated'}}</li>
                </ul>
            </div>
        </div>
        <div class="row mt-2">
<div class="col-md-5 title-hr"></div>
<div class="col-md-2 title" style="font-weight: 500;">SUMMARY</div>
<div class="col-md-5 title-hr"></div>
        </div>
        <div class="row">
            <div class="col-md-12 mt-4">
                <p [innerHTML]="resumeDetails?.about" class="about-desc mt-3 "></p>
            </div>
        </div>
        <div class="row mt-2" *ngIf="resumeDetails?.visibility?.experience === true">
            <div class="col-md-5 title-hr"></div>
            <div class="col-md-2 title" style="font-weight: 500;">EXPERIENCE</div>
            <div class="col-md-5 title-hr"></div>
                    </div>
        <div class="row " *ngIf="resumeDetails?.visibility?.experience === true && resumeDetails?.experience && resumeDetails?.experience?.length > 0">        
                <ng-container *ngFor="let exp of resumeDetails?.experience">
                    <h5 class="exp-title mb-1 mt-3">{{exp?.company}} {{exp.location ? '(' +
                        exp.location + ')' : ''}}, {{displayExperienceDurationDateRange(exp)}}</h5>
                    <h5 class="exp-title">{{exp?.title}}</h5>
                    <p class="about-desc" [innerHTML]="exp?.description"></p>

                </ng-container>
            </div>
        <div class="row mt-2" *ngIf="resumeDetails?.visibility?.skills === true">
            <div class="col-md-5 title-hr"></div>
            <div class="col-md-2 title" style="font-weight: 500;">SKILLS</div>
            <div class="col-md-5 title-hr"></div>
                    </div>
        <div class="row" *ngIf="resumeDetails?.visibility?.skills === true && resumeDetails?.skills && resumeDetails?.skills.length > 0">
            <div class="col-md-12 mt-4">
                <ul *ngIf="resumeDetails?.skills && resumeDetails?.skills.length > 0" class="skills-list mt-3">
                    <li *ngFor="let skills of resumeDetails?.skills">{{skills}}</li>
                </ul>
            </div>
        </div>
        <div class="row mt-2" *ngIf="resumeDetails?.visibility?.education === true">
            <div class="col-md-5 title-hr"></div>
            <div class="col-md-2 title" style="font-weight: 500;">EDUCATION</div>
            <div class="col-md-5 title-hr"></div>
                    </div>
        <div class="row" *ngIf="resumeDetails?.visibility?.education === true && resumeDetails?.education && resumeDetails?.education?.length > 0">
                <ng-container *ngFor="let edu of resumeDetails?.education">
                    <h5 class="exp-title mb-1 mt-3">{{edu.school}} &nbsp;</h5>
                    <h5 class="exp-title">{{edu.degree_name}}{{edu.field_of_study}}</h5>
                    <p class="exp-period">{{displayExperienceDurationDateRange(edu)}}
                            {{displayExperienceDurationDateRange(edu) ? '' : ''}}</p>
                </ng-container>
            </div>

        <div class="row mt-2" *ngIf="resumeDetails?.languages && resumeDetails?.visibility?.languages === true">
            <div class="col-md-5 title-hr"></div>
            <div class="col-md-2">
                <div class="title" style="font-weight: 500;">LANGUAGES</div>
            </div>
            <div class="col-md-5 title-hr"></div>
        </div>

        <div class="row" *ngIf="resumeDetails?.languages && resumeDetails?.visibility?.languages === true">
            <div class="col-md-12 mt-4">
                <ul class="skills-list mt-3">
                    <li class="exp-title" *ngFor="let lang of resumeDetails?.languages">{{lang}}</li>
                </ul>
                <div *ngIf="resumeDetails?.languages && resumeDetails?.languages.length === 0">
                    (Not Indicated)
                  </div>
            </div>
        </div>
        <div class="row mt-2" *ngIf="resumeDetails?.visibility?.projects === true && resumeDetails?.accomplishments.projects.length> 0">
            <div class="col-md-5 title-hr"></div>
            <div class="col-md-2 title" style="font-weight: 500;">PROJECTS</div>
            <div class="col-md-5 title-hr"></div>
                    </div>
        <div class="row" *ngIf="resumeDetails?.visibility?.projects === true && resumeDetails?.accomplishments.projects.length> 0">
                <ng-container *ngFor="let proj of resumeDetails?.accomplishments.projects">
                    <h5 class="exp-title mb-1 mt-3">{{proj.title}} &nbsp;</h5>
                    <p class="exp-period">{{displayprojectDuration(proj)}}</p>
                </ng-container>
            </div>
   
        <div class="row mt-2" *ngIf="resumeDetails?.visibility?.certifications === true && resumeDetails?.certifications && resumeDetails?.certifications?.length > 0">
            <div class="col-md-5 title-hr"></div>
            <div class="col-md-2">
                <div class="title" style="font-weight: 500;">CERTIFICATES</div>
            </div>
            <div class="col-md-5 title-hr"></div>
        </div>
        <div class="row" *ngIf="resumeDetails?.visibility?.certifications === true && resumeDetails?.certifications && resumeDetails?.certifications?.length > 0">
            <div class="col-md-12 mt-1">
                <h5 class="normal-title mb-1 mt-3" style="font-weight: normal;letter-spacing: 0.5px;"
                    *ngFor="let courses of resumeDetails?.certifications">{{courses}}</h5>
            </div>
        </div>
        <div class="row mt-2" *ngIf="resumeDetails?.visibility?.publications === true && resumeDetails?.accomplishments.publications.length > 0">
            <div class="col-md-5 title-hr"></div>
            <div class="col-md-2">
                <div class="title" style="font-weight: 500;">PUBLICATIONS</div>
            </div>
            <div class="col-md-5 title-hr"></div>
        </div>
        <div class="row"
            *ngIf="resumeDetails?.visibility?.accomplishments.publications === true && resumeDetails?.accomplishments.publications.length > 0">
            <div class="col-md-12 mt-1">
                <ng-container *ngFor="let pub of resumeDetails?.accomplishments.publications">
                    <h5 class="exp-title mb-1 mt-3" >{{pub.name}}</h5> 
                    <p class="about-desc">{{pub.url}}</p>
                    </ng-container>      
                     
            </div>
        </div>
        <div class="row mt-2" *ngIf="resumeDetails?.visibility?.courses === true && resumeDetails?.accomplishments.courses.length> 0">
            <div class="col-md-5 title-hr"></div>
            <div class="col-md-2">
                <div class="title" style="font-weight: 500;">COURSES</div>
            </div>
            <div class="col-md-5 title-hr"></div>
        </div>
        <div class="row"
            *ngIf="resumeDetails?.visibility?.courses === true && resumeDetails?.accomplishments.courses.length> 0">
            <div class="col-md-12 mt-1">

                <h5 class="exp-title mb-1 mt-3" *ngFor="let course of resumeDetails?.accomplishments.courses">
                    {{course.name}} &nbsp;<span class="exp-period">{{course.number}}</span></h5>
            </div>
        </div>

    </div>
</div>