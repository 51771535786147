<div class="row mt-4">
    <div class="col-12">

        <div class="row">
            <!-- start left -->
            <div class="col">
                <div *ngIf="showClients" class="row ml">

                    <div class="col-12">

                        <div class="row mt-5">
                            <div class="col-12 text-center">
                                <span class="jd-align-header">
                                    List of Candidates
                                </span>
                            </div>
                        </div>

                        <!-- start client search bar -->
                        <div class="row mt mb-3">
                            <div class="col-12">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </span>
                                    <input [(ngModel)]="searchStringClient" (input)="searchClients($event)" type="text" placeholder="Search clients" pInputText />
                                </div>

                            </div>
                        </div>
                        <!-- end clent search bar -->

                        <p-table selectionMode="single" [(selection)]="selectedClient" dataKey="vanity_name"
                        [autoLayout]="true" [loading]="loading"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                        [showCurrentPageReport]="true"
                        [paginator]="true" [rows]="5"                        
            
                        [value]="clientList">                            
                        <ng-template pTemplate="header">
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Title</th>
                                <th style="width: 5%"></th>
                                <!-- <th style="width: 5%"></th> -->
                            </tr>
                        </ng-template>
            
                        <ng-template pTemplate="body" let-client>
                            <tr [pSelectableRow]="client">
                                <td (click)="selectClient(client)">{{client.vanity_name}}</td>
                                <td (click)="selectClient(client)">{{client.first_name}} {{client.last_name}}</td>
                                <td (click)="selectClient(client)">{{client.title}}</td>
                                <td>
                                  <span (click)="confirmRemoveClient(client)">
                                    <i class="fa fa-trash trash fa-lg" aria-hidden="true"></i>
                                  </span>
                                </td>
                            </tr>
                        </ng-template>
            
                        <ng-template pTemplate="emptymessage">
                            <tr>
                              <td [attr.colspan]="3" class="text-center">
                                <img src="assets/img/table-empty-state.png" class="png-empty-table-img">
                              </td>
                            </tr>
                          </ng-template>                            
                    </p-table>

                    </div>

                </div>

                <div *ngIf="!showClients" class="row ml">
                    <div class="col-12">

                        <div class="row mt-5">
                            <div class="col-12 text-center">
                                <span *ngIf="userType === 'RECRUITER'" class="jd-align-header">
                                    {{selectedClient?.first_name}} {{selectedClient?.last_name}} Resumes
                                </span>

                                <span *ngIf="userType === 'CLIENT'" class="jd-align-header">
                                  Your Resumes
                              </span>
                              
                            </div>

                            <div *ngIf="userType === 'RECRUITER'" class="col-12 text-center">
                              <span class="jd-align-header">
                                  ({{selectedClient?.vanity_name}})
                              </span>
                          </div>
                          
                        </div>

                        <div class="row align-items-center mt-3 mb-3">
                            <div class="col-4 text-left">

                        <!-- start resume search bar -->
                        <div class="row">
                            <div class="col-12">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </span>
                                    <input [ngModelOptions]="{standalone: true}" [(ngModel)]="searchStringResume" (input)="searchResumes($event)" type="text" placeholder="Search resumes" pInputText />
                                </div>

                            </div>
                        </div>
                        <!-- end resume search bar -->


                            </div>

                            <div class="col-8 text-end">

                                <button *ngIf="userType === 'RECRUITER'" (click)="backToClient()" style="width:200px !important;" class="btn btn-primary rounded-pill" type="button">
                                    Back to Clients
                                </button>
                                
                            </div>
                        </div>

                        <p-table [value]="resumeListing" [autoLayout]="true" [loading]="loading"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                            [showCurrentPageReport]="true"
                            [paginator]="true" [rows]="5">                            
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Resume Title</th>
                                    <th>Consent Response</th>
                                    <th>Consent Date</th>
                                    <th style="width: 5%"></th>
                                    <th style="width: 5%"></th>
                                    <th style="width: 5%"></th>
                                </tr>
                            </ng-template>
        
                            <ng-template pTemplate="body" let-resume let-even="even" let-odd="odd">
                                <tr>
                                    <td>{{ resume.name }}</td>
                                    <td>{{ resume.consent }}</td>
                                    <td>{{ resume.date  |  date: 'd MMM y'}}</td>
                                    <td>
                                        <span (click)="editJdAlignedResume(resume)" class="custom-link">
                                            <i class="fa fa-pencil-square-o pencil fa-lg" aria-hidden="true"></i>
                                        </span>
                                    </td>
                                    <td>
                                        <span *ngIf="resume.id !== null" (click)="confirm(resume)" class="custom-link">
                                            <i class="fa fa-trash trash fa-lg" aria-hidden="true"></i>
                                        </span>
                                    </td>
                                    <td>
        
                                    </td>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="emptymessage">
                                <tr>
                                  <td [attr.colspan]="5" class="text-center">
                                    <img src="assets/img/table-empty-state.png" class="png-empty-table-img">
                                  </td>
                                </tr>
                              </ng-template>                            
                        </p-table>
                    </div>
                </div>
            </div>
            <!-- end left -->

            <!-- start right -->
            <div class="col">

                <div class="row mr right-side-color">
                    <div class="col-12">

                        <div class="row mt-5">
                            <div class="col-12 text-center">
                                <span *ngIf="!isToJDAlign" class="jd-align-header">
                                    Retrieve Resume
                                </span>

                                <span *ngIf="isToJDAlign" class="jd-align-header">
                                  New JD Aligment
                              </span>

                            </div>
                        </div>
                        <div class="row mb-5 justify-content-md-center">
                            <div class="col-md-6">
                    
                                <form [formGroup]="jdAlignForm">
                                    <div *ngIf="isToJDAlign" class="form-group mt-4">
                                        <label for="jdTitle" class="mb-3 jd-align-label">Resume Title</label>
                                        <input id="jdTitle" type="text" placeholder="" required="" autofocus="" class="form-control border-curve" formControlName="jdTitle">
                                    </div>
                    
                                    <div class="text-danger mt-2" *ngIf="submitted && (jdAlignForm.controls['jdTitle'].invalid || 
                                        (jdAlignForm.controls['jdTitle'].errors && jdAlignForm.controls['jdTitle'].errors['required'])
                                    )">
                                      Job Title is required
                                    </div>
                    
                                    <!-- start vanity name -->
                                    <div *ngIf="userType === 'RECRUITER'" class="form-group mt-4">
                                        <label for="vanity_name" class="mb-3 jd-align-label" >Vanity Name ( last part of LinkedIn url ) </label>
                                        <input id="vanity_name" type="text" placeholder="" required="" autofocus="" class="form-control border-curve" formControlName="vanity_name">
                                        <p class="linkedin-url mt-1 ml-1"> (e.g  https://linkedin.com/in/<span class="url-bold">FirstName-LastName-123</span>)</p>
                                      </div>
                    
                                    <div class="text-danger mt-2" *ngIf="submitted && ( 
                                        (jdAlignForm.controls['vanity_name'].errors && jdAlignForm.controls['vanity_name'].errors['required'])
                                    ) && isToJDAlign">
                                     Vanity Name is required
                                    </div>
                    
                                    <!-- end vanity name -->
                                            <!-- start OR -->
         <div class="row mt-4 text-center" *ngIf="!isToJDAlign">

          <div class="col">
            <div class="centered-line">
              <span>&nbsp;</span>
            </div>
          </div>

          <div class="col-auto">
            <span class="or">OR</span>
          </div>

          <div class="col">
            <div class="centered-line">
              <span>&nbsp;</span>
            </div>
          </div>


        </div> 
        <!-- end OR -->
                                    <!---//pdf upload//-->
                                     <div class="row text-center mt-4" *ngIf="!isToJDAlign">          
                                      <div *ngIf="fileSelected" class="col-12">
                                        <span class="mr-2">{{selectedFile.name}}</span><span (click)="removeSelectedPDF()" class="custom-link">
                                          <i pTooltip="Remove/Replace" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}" class="fa fa-refresh" aria-hidden="true"></i></span> 
                                      </div>
                                    
                          
                                      <!-- start file NOT selected  -->
                                       <div *ngIf="!fileSelected" class="col-12">
                          
                                        <div class="clickable-box" (click)="handleBoxClick()">
                                          <input #fileInput id="fileInput" (click)="fileInput.value = ''" type="file" (change)="handleFileInput($event)" [hidden]="true" accept=".pdf">
                          
                                          <div class="row pt-5 pb-5">
                          
                                            <div class="col-12">
                                              <i class="fa  fa-upload" aria-hidden="true"></i> 
                          
                                            </div>
                          
                                            <div class="col-12">
                                              <span class="upload">Upload PDF</span>
                                            </div>
                                            <p class="linkedin-url mt-1 ml-1"> (PDF resume generated from <span class="url-bold">LinkedIn Profile</span>)</p>
                                          </div>
                                          
                                        </div>
                          
                          
                                     </div>
                                      <!-- end file NOT selected  -->
                           </div> 
                                    <!--//pdf upload end//-->
                    
                                    <div *ngIf="isToJDAlign" class="form-group mt-4">
                                        <label for="jd" class="mb-3 jd-align-label">Job Description</label>
                                        <textarea class="form-control border-curve" formControlName="jd" id="jd" rows="4"></textarea>
                                    </div>
                    
                                    <div class="text-danger mt-2" *ngIf="submitted && (jdAlignForm.controls['jd'].invalid || 
                                    (jdAlignForm.controls['jd'].errors && jdAlignForm.controls['jd'].errors['required'])
                                    )">
                                      Job Description is required
                                    </div>
                    
                                    <div class="row justify-content-md-center">
                                        <div class="col-md-12 text-center"> 
                                            <button [disabled]="processing || processPdf" class="btn btn-primary btn-block mb-2 jd-button rounded-pill submit-btn shadow-sm mt-4 p-2" (click)="executeSubmit()">
                                              {{isToJDAlign ? 'One Click JD Alignment' : 'Retrieve'}}
                                                <div *ngIf="processing || processPdf" class="spinner-border spinner-border-sm" role="status">
                                                </div>
                                            </button>
                                        </div>
                    
                                    </div>
                    
                                </form>
                            </div>
                        </div>
        
                    </div>

                </div>
            
            </div>
            <!-- end right -->

        </div>

        <!-- <div class="row mt-5">
            <div class="col-6 offset-3 text-center">
            </div>
        </div> -->

    </div>
</div>

<!-- start contacts dialog -->
<p-dialog  [(visible)]="clientListModal" [draggable]="true" [closable]="false" [showHeader]="true"
          [blockScroll]="true" [modal]="true" 
          *ngIf="clientListModal" #clientsModal>

    <p-header>
        <div class="row">
            <div class="col-md-12 text-left">
            <h5 class="modal-title" id="exampleModalLabel">Client List</h5>
            </div>
        </div>
    </p-header>

    <div class="row">
        <div class="col-12">


            <p-table selectionMode="single" [(selection)]="selectedClient" dataKey="vanity_name"
            
            [value]="clientList">                            
            <ng-template pTemplate="header">
                <tr>
                    <th>Vanity Name</th>
                    <th>Name</th>
                    <th>Title</th>
                    <!-- <th style="width: 5%"></th>
                    <th style="width: 5%"></th> -->
                </tr>
            </ng-template>


            <ng-template pTemplate="body" let-client>
                <tr (click)="selectClient(client)" [pSelectableRow]="client">
                    <td>{{client.vanity_name}}</td>
                    <td>{{client.first_name}} {{client.last_name}}</td>
                    <td>{{client.title}}</td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="3" class="text-center">
                    <img src="assets/img/table-empty-state.png" class="png-empty-table-img">
                  </td>
                </tr>
              </ng-template>                            
        </p-table>
        </div>
    </div>
        
    <p-footer>
        <div class="row">
          <div class="col-md-12">
            
            <div  class="row text-center">
              <div class="col">
                <button (click)="hideClientList()" type="button" class="btn btn-secondary">Close</button>
              </div>
              <!-- <div class="col-auto">
                <button type="button" class="btn btn-primary" (click)="confirmUpdateContacts()">
                  Update
                </button>
              </div> -->
            </div>
    
          </div>
    
        </div>
      </p-footer>
    
</p-dialog>

<p-confirmDialog key="confirmDelJDResDialog" header="Confirmation" 
icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<p-confirmDialog key="confirmDelClientDialog" header="Confirmation" 
icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<p-dialog [style]="{ 'height': '500px' }" [draggable]="true" [resizable]="true" [closable]="false"  
[modal]="true"  [(visible)]="userAgreementModal" 
[styleClass]="displayDialog ? 'responsive-dialog' : ''"
>

<p-header>
  <div class="row">
    <div class="col-md-12 text-left">
      <h5 class="modal-title" id="exampleModalLabel"></h5>
    </div>
  </div>
</p-header>

<div class="scrollable-content" (scroll)="onScroll($event)">
  <!-- Your big scrollable text goes here -->

  <div class="row">
    <div class="col-12 text-center">
      <h3>
        USER AGREEMENT
      </h3>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        This User Agreement ("Agreement") is a legal agreement between you ("User" or "you") and MLSoft Technologies Inc ("Company" or "we") governing your use of the website and its services offered by the Company. By accessing or using the Company's website, you agree to be bound by this Agreement.
      </span>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <h5>
        User Responsibilities
      </h5>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>1.1</b> Vanity-Name/PDF-Resume: You confirm that you have provided your own LinkedIn vanity-name/pdf_resume or vanity-name/pdf_resume of a client you are authorised to represent accurately and truthfully. You understand and acknowledge that it is your responsibility to ensure that the vanity-name/pdf_resume you provide to the system is correct.
      </span>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>1.2</b> Liability for Incorrect Vanity-Name/PDF-Resume: You shall bear full responsibility for providing an accurate and correct vanity-name/pdf_resume. In the event that an incorrect vanity-name/pdf_resume is provided, the system may inadvertently scrape and collect data belonging to a different individual you are authorised to collet data for. Consequently, the profile may not reflect your or your clients information.
      </span>
    </div>
  </div>
    
  <div class="row mt-3">
    <div class="col-12">
      <h5>
        Rights and Obligations
      </h5>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>2.1</b> Ownership: You acknowledge that you have no ownership rights or claims over the system, its software, or any data collected or stored by the Company.
      </span>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        <b>2.2</b> License: Subject to your compliance with this Agreement, the Company grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the system solely for your personal or professional purposes.
      </span>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>2.3</b> Data Scrapping and Exclusions: The Company reserves the right to employ automated processes to scrape data from LinkedIn profiles. However, the Company does not warrant the accuracy, reliability, or timeliness of the scraped data and shall not be held liable for any errors or discrepancies arising therefrom.
      </span>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>2.4</b> Profile Reflection: The Company strives to ensure that your profile accurately reflects your self. However, in cases where an incorrect vanity-name/pdf_resume is provided, the Company shall not be responsible for reflecting information accurately. The responsibility for maintaining an accurate profile solely rests upon you.
      </span>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <h5>
        Limited Liability and Indemnification
      </h5>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        <b>3.1</b> Limitation of Liability: To the extent permitted by law, the Company, its officers, directors, employees, and affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the system, including any damages resulting from incorrect vanity-name/pdf_resume provision or inaccurate profile reflection.
      </span>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        <b>3.2</b> Indemnification: You agree to indemnify, defend, and hold harmless the Company and its affiliates from and against any claims, liabilities, damages, losses, and expenses (including reasonable attorney fees) arising out of or related to any breach of this Agreement or your use of the system.
      </span>
    </div>
  </div>
    
  <div class="row mt-3">
    <div class="col-12">
      <h5>
        Miscellaneous
      </h5>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        <b>4.1</b> Entire Agreement: This Agreement constitutes the entire understanding and agreement between you and the Company relating to your use of the system and supersedes any prior or contemporaneous agreements, communications, or understandings, whether oral or written.
      </span>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>4.2</b> Severability: If any provision of this Agreement is held to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
      </span>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        <b>4.3</b> Governing Law and Jurisdiction: This Agreement shall be governed by and construed in accordance with the laws of the USA and Canada. Any disputes arising out of or in connection with this Agreement shall be exclusively submitted to the competent courts of the USA/Canada.
      </span>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        By accessing or using the system, you acknowledge that you have read, understood, and agreed to this User Agreement.
      </span>
    </div>
  </div>

</div>

<p-footer>
  <div class="row">
    <div class="col-md-12">
      
      <div  class="row">
        <div class="col">
        </div>
        <div class="col-auto">
          <button #closeContactsModal (click)="cancelAgree()" type="button" class="btn btn-secondary">I do not agree</button>
        </div>
        <div class="col-auto">
          <button [disabled]="agreeBtnDisabled" type="button" class="btn btn-primary" (click)="confirmAgree()">
            I AGREE
            <div *ngIf="processingConfirm" class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Processing...</span>
            </div>
          </button>
        </div>
      </div>

    </div>

  </div>
</p-footer>

</p-dialog>

<!--/////enter new vanity modal//////-->
<!-- <p-dialog [style]="{ 'height': '500px' }" [draggable]="true" [resizable]="true" [closable]="false" [modal]="true"
  [(visible)]="enterVanity" [styleClass]="displayDialog ? 'responsive-dialog' : ''">

  <p-header>
    <div class="row">
      <div class="col-md-12 text-left">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
      </div>
    </div>
  </p-header>

  <div class="row">
    <div class="col-12 text-center">
      <h3>
        ENTER NEW VANITY
      </h3>
    </div>
  </div>

  <div class="row mt">
    <div class="col-md-3"></div>
    <div class="col-md-6">

      <form [formGroup]="newVanityForm">
        <div class="form-group mt-3">
          <label for="newVanity" class="mb-3 jd-align-label">New Vanity Name</label>
          <input id="newVanity" type="text" placeholder="" required="" autofocus="" class="form-control border-curve"
            formControlName="newVanity" (ngModelChange)="updateButtonState()">
          <p class="linkedin-url mt-2 text-center"> (e.g https://linkedin.com/in/<span class="url-bold">fname-lname-123</span>)</p>
        </div>

        <div class="text-danger mt-2" *ngIf="submitted && ( 
          (newVanityForm.controls['newVanity'].errors && newVanityForm.controls['newVanity'].errors['required'] && newVanityForm.controls['newVanity'].dirty)
      ) && !isToJDAlign">
          *New Vanity Name is required
        </div>
        <div class="form-group mt-3">
          <label for="newVanity" class="mb-3 jd-align-label">First Name</label>
          <input id="newVanity" type="text" placeholder="" required="" autofocus="" class="form-control border-curve"
            formControlName="firstName" (ngModelChange)="updateButtonState()">
        </div>

        <div class="text-danger mt-2" *ngIf="submitted && ( 
        (newVanityForm.controls['firstName'].errors && newVanityForm.controls['firstName'].errors['required'] && newVanityForm.controls['firstName'].dirty)
    ) && !isToJDAlign">
          *First Name is required
        </div>
        <div class="form-group mt-3">
          <label for="newVanity" class="mb-3 jd-align-label">Last Name</label>
          <input id="newVanity" type="text" placeholder="" required="" autofocus="" class="form-control border-curve"
            formControlName="lastName" (ngModelChange)="updateButtonState()">
          
        </div>

        <div class="text-danger mt-2" *ngIf="submitted && ( 
      (newVanityForm.controls['lastName'].errors && newVanityForm.controls['lastName'].errors['required'] && newVanityForm.controls['lastName'].dirty)
  ) && !isToJDAlign">
          *Last Name is required
        </div>
      </form>
    </div>
    <div class="col-md-3"></div>
  </div>

  <p-footer>
    <div class="row ">
      <div class="col-md-12">
        <button [disabled]="disablevanitySubmit || processinguseragreement" type="button" class="btn btn-primary"
          (click)="createnewVanity()">
          Submit
          <div *ngIf="processinguseragreement" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Processing...</span>
          </div>
        </button>
        <button #closePublicationModal (click)="closeenterVanity()" type="button"
          class="btn btn-secondary">Cancel</button>
      </div>
    </div>
  </p-footer>

</p-dialog> -->
