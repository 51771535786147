<div #iframeContainer></div>

<section class="{{ message === undefined ? 'template-wrapper' : '' }} ">

  <!--start confirm buttons -->
  <div *ngIf="confirm === 'true'" class="row">
    <div class="row mt-4">
      <!-- <div class="col-8 offset-2"> -->
      <div class="col-4 offset-4">

          <div class="row align-items-center">
              <div class="col text-center">
                <button (click)="executeConfirmResponse('YES')" [disabled]="processingYes"  type="button" class="btn btn-primary  btn-resume confirm">
                  Yes, I Confirm
                  <div *ngIf="processingYes" class="spinner-border spinner-border-sm" role="status">
                  </div>
                </button>
              </div>
              <div class="col text-center">
                <button (click)="executeConfirmResponse('NO')" [disabled]="processingNo"  type="button" class="btn btn-primary  btn-resume not_confirm">
                  No, I do not
                  <div *ngIf="processingNo" class="spinner-border spinner-border-sm" role="status">
                  </div>
                </button>
              </div>
          </div>
      </div>
  </div>

  </div>
  <!--end confirm buttons -->

<div *ngIf="message === undefined && confirm !== 'true'" class="row">
<!-- <div class="row"> -->
    <div class="col-md-4">
    <button type="submit" class="btn btn-primary mt-4 ml-3 btn-back" [routerLink]="['/resume-preview']" [queryParams]="{vanity_name: vanity_name}"> <i class="fa fa-arrow-left" aria-hidden="true"></i> back to edit</button>
  </div>
  <div class="col-md-4">
    <button type="submit" class="btn btn-primary mt-4 pull-right btn-download" (click)="captureScreen()">Download Resume <i class="fa fa-download" aria-hidden="true"></i></button>
  </div>
  <div class="col-md-4">
    <button type="submit" class="btn btn-primary mt-4 pull-right btn-download" (click)="captureScreenPreview()">Resume Preview <i class="fa fa-download" aria-hidden="true"></i></button>
  </div>
</div>

<div class="row {{ message === undefined ? 'mt-4' : '' }} mb-3">

  <!-- start icons -->
  <div class="col-md-12">
    <div class="row mb-3">

      <!-- start preview -->
      <div class="col-auto ml" *ngIf="confirm !== 'true'">
        <img (click)="captureScreenPreview()" *ngIf="!processingPreview && !prevOn" style="width: 130%; height: auto" 
        pTooltip="PDF Preview" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
        class="custom-link" src="assets/img/eye_icon.png" alt="Preview">

        <span (click)="removeIframe()" *ngIf="!processingPreview && prevOn"  class="custom-link" pTooltip="Remove PDF Preview" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}">
          <i class="fa fa-remove large_icon large_icon_color" aria-hidden="true"></i> 
        </span>

        <!-- <span *ngIf="!processingPreview" (click)="!prevOn ? captureScreenPreview() : removeIframe()" class="custom-link" pTooltip="{{prevOn ? 'Remove PDF Preview' : 'PDF Preview'}}" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}">
          <i class="fa fa-file-pdf-o large_icon" aria-hidden="true"></i> 
        </span> -->
        <div *ngIf="processingPreview" class="spinner-border spinner-border-sm" role="status">
        </div>

      </div>
      <!-- end preview -->

      <!-- start pdf -->
      <div class="col-auto ml" *ngIf="confirm !== 'true'">

        <img (click)="captureScreen()" *ngIf="!processingDownload"  
        pTooltip="PDF Download" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
        class="custom-link" src="assets/img/pdf_icon.png" alt="PDF Download">

        <!-- <span *ngIf="!processingDownload" (click)="captureScreen()" class="custom-link" pTooltip="PDF Download" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}">
          <i class="fa fa-download large_icon2" aria-hidden="true"></i> 
        </span> -->

        <div *ngIf="processingDownload" class="spinner-border spinner-border-sm" role="status">
        </div>
      </div>
      <!-- end pdf -->

      <div class="col-auto ml" *ngIf="userType === 'RECRUITER' && appUser && confirm !== 'true' && !default">
        <img *ngIf="!processingDownload"  (click)="generateCoverletter()"
        pTooltip="Generate Cover Letter" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
        class="custom-link cover-letter" src="assets/img/cover_letter.png" alt="PDF Download">
      <div *ngIf="processingDownload" class="spinner-border spinner-border-sm" role="status">
        </div> 
      </div>

      <!-- start doc -->
      <div class="col">
        <!-- <img (click)="downloadDocx('content',resumeDetails?.full_name)" *ngIf="!processingDOC"  
        pTooltip="DOC Download" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
        class="custom-link" src="assets/img/doc_icon.png" alt="DOC Download"> -->

      </div>

      <div *ngIf="processingDOC" class="spinner-border spinner-border-sm" role="status">
      </div>

      <!-- end doc -->

    </div>
  </div>
  <!-- end icons -->

  
    <div class="col-md-12">
      <app-template1 [message]="message" *ngIf="template === 'basic' && resumeDetails" [resumeDetails]="resumeDetails"></app-template1>
      <app-template2 [message]="message" *ngIf="template === 'classic' && resumeDetails" [resumeDetails]="resumeDetails"></app-template2>
      <app-template3 [message]="message" *ngIf="template === 'modern' && resumeDetails" [resumeDetails]="resumeDetails"></app-template3>
      <app-template4
      [message]="message"

      *ngIf="template === 'vintage'" [resumeDetails]="resumeDetails"></app-template4>
      <app-template5
      
      [message]="message"


      *ngIf="template === 'simple'" [resumeDetails]="resumeDetails"></app-template5>
      <app-template6
      
      [message]="message"

      *ngIf="template === 'casual'" [resumeDetails]="resumeDetails"></app-template6>
      <app-template7 
      
      [message]="message"

      *ngIf="template === 'chrono'" [resumeDetails]="resumeDetails"></app-template7>
      <app-template8

      [message]="message"

      
      *ngIf="template === 'horizontal'" [resumeDetails]="resumeDetails"></app-template8>
      <app-template9 
      
      [message]="message"

      *ngIf="template === 'luxe'" [resumeDetails]="resumeDetails"></app-template9>
      <app-template10
      
      [message]="message"

      
      *ngIf="template === 'professional'" [resumeDetails]="resumeDetails"></app-template10>
      <app-template11
      
      [message]="message"

      
      *ngIf="template === 'elegant'" [resumeDetails]="resumeDetails"></app-template11>
      <app-template12 *ngIf="template === 'chronicle'" [message]="message" [resumeDetails]="resumeDetails"></app-template12>
      <app-template13 *ngIf="template === 'smart'" [message]="message" [resumeDetails]="resumeDetails"></app-template13>
      <app-template14 *ngIf="template === 'majestic'" [message]="message" [resumeDetails]="resumeDetails"></app-template14>
      <app-template15 *ngIf="template === 'windsor'" [message]="message" [resumeDetails]="resumeDetails"></app-template15>
      <app-cover-letter
      
      [message]="message"

      
      *ngIf="template === 'cover'" ></app-cover-letter>
    </div>   
    <!-- <div class="col-md-6">
       <iframe  src='{{iframesrc}}' width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen" style="padding:20px;"></iframe>
    </div> -->
  </div>
</section>
<p-dialog  [(visible)]="coverModal" [draggable]="true" [closable]="false" [showHeader]="true"
          [blockScroll]="true" [modal]="true" #coverletterModal *ngIf="coverModal" [style]="{width: '50vw'}">

    <p-header>
        <div class="row">
            <div class="col-md-12 text-left">
              <img (click)="captureCoverletter()" *ngIf="!processingDownload"  
              pTooltip="Download cover letter" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
              class="custom-link" src="assets/img/pdf_icon.png" alt="PDF Download">
            </div>
        </div>
    </p-header>

 <section class="cover-letter">
  <div id="cover-letter" #content class="container cover-container">
    <div class="cover-wrapper" style="padding: 30px 60px 0px 60px;">

      <div class="row">
        <div class="col-md-6">
          <h4 class="mb-4" style="text-transform: capitalize;
          font-size: 20px;"><b>{{covertitle}}</b></h4>
        </div>
        <div class="col-md-6">
          <i style="float:right;font-size: 12px;">{{myDate | date:'longDate'}}</i>
        </div>
      </div>
        <div class="row">
            <div class="col-md-12">
              <p class="cover-desc">{{coverletter.greeting}},</p>
                <p class="cover-desc" style=" text-align: justify;" >{{coverletter.start}}. {{coverletter.body}}.</p>
                <p class="cover-desc mb-1">{{coverletter.ending}},</p>
                <p>{{resumeDetails?.first_name}} {{resumeDetails?.last_name}}</p>
            </div>
        </div>
        </div>
        </div>
 </section>
        
    <p-footer>
        <div class="row">
          <div class="col-md-12">
            
            <div  class="row text-center">
              <div class="col">
                <button (click)="hidecoverModal()" type="button" class="btn btn-secondary mt-2">Close</button>
              </div>
              <!-- <div class="col-auto">
                <button type="button" class="btn btn-primary" (click)="confirmUpdateContacts()">
                  Update
                </button>
              </div> -->
            </div>
    
          </div>
    
        </div>
      </p-footer>
    
</p-dialog>
