import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../core/shared.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { UserService } from '../core/user.service';
import { LocalStorageService } from '../core/local-storage.service';


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  public userIdToEdit: any;
  public loggedInUser: any;
  public vanityForm !: FormGroup;
  public subsDate = new Date();
  public payload: any = {};
  public unique_id = '';
  public first_name = '';
  public last_name = '';
  public processing = false;
  public submitted = false;


  private subscription!: Subscription;


  constructor(private fb: FormBuilder, private http: HttpClient,
    private userService: UserService,
    private actRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private router:Router,private datePipe: DatePipe) { 
   
    }

  ngOnInit(): void {
    this.vanityForm = this.fb.group({
      vanityform: ['', [Validators.required]]
    });

    // get the id from the query params
    this.subscribeToQueryParams();
    console.log('this.submitted = ', this.submitted);
  }

  private subscribeToQueryParams() {
    this.subscription = this.actRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.hasOwnProperty('id') && queryParams.id !== '') {
        this.unique_id = queryParams.id;
        console.log('this.unique_id = ', this.unique_id);
      }
      if (queryParams.hasOwnProperty('first_name') && queryParams.first_name !== '') {
        this.first_name = queryParams.first_name;
        console.log('this.first_name = ', this.first_name);
      }
      if (queryParams.hasOwnProperty('last_name') && queryParams.last_name !== '') {
        this.last_name = queryParams.last_name;
        console.log('this.last_name = ', this.last_name);
      }
    });

  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padNumber(date.getMonth() + 1);
    const day = this.padNumber(date.getDate());
    
    return `${year}-${month}-${day}`;
  }
  
  private padNumber(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }  

  public submitVanityInfo(): void {
    this.submitted = true;
    console.log('submitVanityInfo this.submitted = ', this.submitted);
    if (this.vanityForm.valid) {
      let payload: any = {};
      payload.vanity_name = this.vanityForm.controls['vanityform'].value;
      payload.first_name = this.first_name;
      payload.last_name = this.last_name;
      payload.unique_id = this.unique_id;
      const today = new Date();
      const formattedDate = this.formatDate(today);
      payload.date_subscribed = formattedDate;
  
      this.payload = payload;
      console.log('payloadx = ', this.payload);
  
      this.processing = true;
      this.subscription = this.userService.submitVanity(payload).subscribe({
        next: response => {
          this.processing = false;
          console.log('hey');
          if (response) {
            console.log('response vanity = ', response);
            if (response === 'Client Added') {
  
              // save to local stoage
              const data = { vanity_name: this.payload.vanity_name};
              this.localStorageService.saveData('userVanity', data);
  
              // test get from local
              console.log('gotten from local storeage = ', this.localStorageService.getData('userVanity'));
  
              this.router.navigate(
                ['/profile'],
                { queryParams: { vanity_name: payload.vanity_name } }
              );
  
            } else {
              alert('>>>> vanity name does not exist');
              console.log('>> no response ');
            }
  
          } else {
            console.log('>> no response');
            alert('>>>> vanity login issue');
          }
        },
        error: error => {
          alert('>>>> vanity login issue');
          this.processing = false;
        }
      });
 
    }

  }

  public submitvanityName(): void {
    const vname = this.vanityForm.controls['vanityform'].value;
    const fname = this.vanityForm.controls['firstname'].value;
    const lname = this.vanityForm.controls['lastname'].value;
    const subsdate = this.datePipe.transform(this.subsDate, 'yyyy-MM-dd');

    // const url = 'http://xcv.ngrok.dev/client/fetchResume';
   const url = 'http://20.220.133.246:8000/client/fetchResume';
    let queryParams = new HttpParams();
    queryParams = queryParams.set('public_id', vname);

    
    // this.http.get<any>(url, { params: queryParams }).subscribe(
    //   {
    //     next: response => {
    //       console.log(response)
    //       this.router.navigate(['resume-preview'],{ queryParams: {publicid: vname}})
    //       //this.router.navigate(['resume-preview'])
    //     },
    //     error: error => {}
    //   }

    // )

        this.http.get<any>(url, { params: queryParams }).subscribe(
      {
        next: response => {
          console.log(response)
          this.router.navigate(['resume-preview'],{ queryParams: {publicid: vname}})
          //this.router.navigate(['resume-preview'])
        },
        error: error => {}
      }

    )
   
  }

}
