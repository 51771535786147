<div id="content" #content class="{{message === 'HAS_PARENT' ? 'container-with-parent' : 'container' }} mb-5">
    <div class="resume-wrapper">
    <div class="row">
        <div class="col-md-4 right-col">
            <div class="left-side">
                <div class="about">
                    <h2 class="emp-name">{{resumeDetails?.first_name}} {{resumeDetails?.last_name}}</h2>
                    <h4 style="font-weight: 700;" class="emp-title">{{resumeDetails?.title}}</h4>
                    <p class="emp-about" [innerHTML]="resumeDetails?.about"></p>
                </div>
                <div class="contactInfo">
                    <h4 style="font-weight:600;" class="subtitles">Contacts</h4>
                    <ul class="pl-0">
                        <li class="contact-li" style="font-weight: 500;" *ngIf="resumeDetails?.visibility?.email == true">
                            <span class="text"> <img src="assets/img/email_icon.png" class="img-fa " />
                                <u>{{
                                    resumeDetails?.contact.email && resumeDetails?.contact.email.length > 0 ?
                                    resumeDetails?.contact.email[0] : 'Not Indicated'
                                    }}</u>
                            </span>
                        </li>
                        <li class="contact-li" style="font-weight: 500;" *ngIf="resumeDetails?.visibility?.linkedin == true">
                            <span class="text"><img src="assets/img/linkdin_icon.png" class="img-fa linkdin" />
                                <u><a href="https://www.linkedin.com/in/{{resumeDetails?.vanity_name}}/"
                                        target="_blank">
                                        {{resumeDetails?.vanity_name}}
                                    </a></u>
                            </span>
                        </li>
                        <li class="contact-li" style="font-weight: 500;" *ngIf="resumeDetails?.visibility?.contactno == true">
                            <span class="text"> <img src="assets/img/phone_icon.png" class="img-fa linkdin" />
                                <u> {{
                                    resumeDetails?.contact.phone &&resumeDetails?.contact.phone.length > 0 ?
                                    resumeDetails?.contact.phone[0] : 'None Indicated'
                                    }}</u></span>
                        </li>
                        <li class="contact-li" style="font-weight: 500;" *ngIf="resumeDetails?.visibility?.twitter == true">
                            <span class="text"> <img src="assets/img/twitter.png" class="img-fa " />
                                {{
                                resumeDetails?.socials.twitter?
                                resumeDetails?.socials.twitter : 'Not Indicated'
                                }}</span>
                        </li>
                    </ul>
                </div>
                <div class="skills" *ngIf="resumeDetails?.visibility?.skills === true && resumeDetails?.skills && resumeDetails?.skills.length > 0">
                    <h4 style="font-weight:600;" class="subtitles">Skills</h4>
                    <ul class="padding-left:0;">
                        <li *ngFor="let skills of resumeDetails?.skills">
                            {{skills}}
                        </li>

                    </ul>
                </div>
                <div class="education" *ngIf="resumeDetails?.visibility?.education === true && resumeDetails?.education && resumeDetails?.education?.length > 0">
                    <h4 style="font-weight:600;" class="subtitles">Education</h4>
                    <ul class="padding-left:0;">
                        <li *ngFor="let edu of resumeDetails?.education;">
                            <h4 class="exp-title small">{{edu.degree_name}} {{edu.field_of_study}}</h4>
                            <h5 class="uni-name">{{edu.school}}</h5>
                            <p class="duration">{{displayExperienceDurationDateRange(edu)}}
                                {{displayExperienceDurationDateRange(edu) ? '' : ''}}</p>
                        </li>

                    </ul>
                </div>
                <div class="projects" *ngIf="resumeDetails?.visibility?.projects === true && resumeDetails?.accomplishments.projects.length> 0">
                    <h4 style="font-weight:600;" class="subtitles">Projects</h4>
                    <ul class="padding-left:0;">
                        <li *ngFor="let proj of resumeDetails?.accomplishments.projects">
                            <h4 class="exp-title small">{{proj.title}}</h4>
                            <p class="duration">{{displayprojectDuration(proj)}}</p>
                        </li>

                    </ul>
                </div>
                <div class="languages" *ngIf="resumeDetails?.languages && resumeDetails?.visibility?.languages === true">
                    <h4 style="font-weight:600;" class="subtitles">Languages</h4>
                    <ul>
                        <li *ngFor="let lang of resumeDetails?.languages">
                            <h4 class="pub-title small" style="font-weight:600;">{{lang}}</h4>

                        </li>

                    </ul>
                </div>
                <div class="certificates" *ngIf="resumeDetails?.visibility?.certifications === true && resumeDetails?.certifications && resumeDetails?.certifications?.length > 0">
                    <h4 style="font-weight:600;" class="subtitles">Certificates</h4>
                    <ul>
                        <li *ngFor="let courses of resumeDetails?.certifications">
                            <h4 class="pub-title small" style="font-weight:600;">{{courses}}</h4>

                        </li>

                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="right-side p-3">
                <div class="experience" *ngIf="resumeDetails?.visibility?.experience === true && resumeDetails?.experience && resumeDetails?.experience?.length > 0" >

                    <div class="experience-block" *ngFor="let exp of resumeDetails?.experience">
                        <h4 class="exp-title mb-1">{{exp?.title}}</h4>
                        <p class="duration mb-1">{{exp?.company}}
                            {{exp.location ? '(' + exp.location + ')' : ''}}</p>
                        <h4 class="exp-period" style="font-weight:600;">{{displayExperienceDurationDateRange(exp)}}
                        </h4>
                        <p class="emp-about" [innerHTML]="exp?.description"></p>
                    </div>

                </div>
                <div class="courses" *ngIf="resumeDetails?.visibility?.courses === true && resumeDetails?.accomplishments.courses.length> 0">
                    <h4 style="font-weight:600;" class="subtitles">Courses</h4>
                    <ul>
                        <li *ngFor="let course of resumeDetails?.accomplishments.courses">
                            <h4 class="exp-title small">{{course.name}}</h4>
                            <p class="duration">{{course.number}}</p>
                        </li>

                    </ul>
                </div>
                <div class="publications" *ngIf="resumeDetails?.visibility?.accomplishments.publications === true && resumeDetails?.accomplishments.publications.length > 0">
                    <h4 style="font-weight:600;" class="subtitles">Publications</h4>
                    <ul>
                        <li *ngFor="let pub of resumeDetails?.accomplishments.publications">
                            <h4 class="pub-title small mb-0" style="font-weight:600;">{{pub.name}}</h4>
                            <p class="duration puburl"><a href="">{{pub.url}}</a></p>
                        </li>
                    </ul>
                </div>
            </div>         
        </div>
    </div>
</div>
</div>