<!-- <section>

  <div class="row bg-main">
    <div class="col-md-3"></div>
      <div class="col-md-6 mt-5">

          <form [formGroup]="vanityForm">
            <h2>Enter your Linkedin:</h2>
            <div class="form-group mt-4">
              <label for="vanityform" class="mb-3">Vanity Name</label>
                <input required id="vanityform" type="text" placeholder="Vanity name" required="" autofocus="" class="form-control rounded-pill-vname" formControlName="vanityform">

                <div class="text-danger mt-2" *ngIf="submitted && (vanityForm.controls['vanityform'].invalid || 
                (vanityForm.controls['vanityform'].errors && vanityForm.controls['vanityform'].errors['required'])
                )">
                  Vanity Name is required
                </div>
            </div>
            <button [disabled]="processing" class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill submit-btn shadow-sm mt-4 p-2" (click)="submitVanityInfo()">Submit</button>
          </form>
      </div>
      <div class="col-md-3"></div>
  </div>
  </section>
   -->


  <section>

    <div class="row bg-main">
      <div class="col-md-3"></div>
        <div class="col-md-6 mt-5">

            <form [formGroup]="vanityForm">
              <h2>Enter your Linkedin:</h2>
              <div class="form-group mt-4">
                                  <label for="vanityform" class="mb-3">Vanity Name</label>
                                    <input id="vanityform" type="text" placeholder="Vanity name" required="" autofocus="" class="form-control rounded-pill-vname" formControlName="vanityform">
                                </div>

                                <div class="text-danger mt-2" *ngIf="submitted && (vanityForm.controls['vanityform'].invalid || 
                                (vanityForm.controls['vanityform'].errors && vanityForm.controls['vanityform'].errors['required'])
                                )">
                                  Vanity Name is required
                                </div>
                                <button class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill submit-btn shadow-sm mt-4 p-2" (click)="submitVanityInfo()" [disabled]="processing">Submit</button>

            </form>
        </div>
        <div class="col-md-3"></div>
    </div>
    </section>
    

