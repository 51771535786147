import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TemplatesRoutingModule } from './templates-routing.module';
import { Template1Component } from './template1/template1.component';
import { Template2Component } from './template2/template2.component';
import { TemplateBaseComponent } from './template-base/template-base.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Template3Component } from './template3/template3.component';
import { Template4Component } from './template4/template4.component';
import { Template5Component } from './template5/template5.component';
import { Template6Component } from './template6/template6.component';
import { Template7Component } from './template7/template7.component';
import { Template8Component } from './template8/template8.component';
import { Template9Component } from './template9/template9.component';
import { Template10Component } from './template10/template10.component';
import { TooltipModule } from 'primeng/tooltip';
import { Template11Component } from './template11/template11.component';
import { CoverLetterComponent } from './cover-letter/cover-letter.component';
import { DialogModule } from 'primeng/dialog';
import { Template12Component } from './template12/template12.component';
import { Template13Component } from './template13/template13.component';
import { Template14Component } from './template14/template14.component';
import { Template15Component } from './template15/template15.component';


@NgModule({
  declarations: [
    Template1Component,
    Template2Component,
    TemplateBaseComponent,
    Template3Component,
    Template4Component,
    Template5Component,
    Template6Component,
    Template7Component,
    Template8Component,
    Template9Component,
    Template10Component,
    Template11Component,
    CoverLetterComponent,
    Template12Component,
    Template13Component,
    Template14Component,
    Template15Component
  ],
  imports: [
    TooltipModule,
    CommonModule,
    TemplatesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DialogModule
  ],
  exports: [
    TemplateBaseComponent,
    Template1Component,
    Template2Component
  ]
})
export class TemplatesModule { }
