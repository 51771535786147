import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { LocalStorageService } from 'app/core/local-storage.service';
import { UserService } from 'app/core/user.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { DataService } from 'app/helpers/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUser, Profile, ProfileContact, ProfileExperience, SubscriptionPlan } from 'app/model/Userprofile';
import { AytHttpParams } from 'app/helpers/http-config';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PollingService } from 'app/helpers/polling-service';
import { APIEndpoints, Constants } from 'app/helpers/app-settings';
import { XcvUtilsService } from 'app/core/xcv-utils.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements AfterViewInit, OnInit, OnDestroy {
  public loading = false;
  public vanity_name = '';
  public profile: Profile = new Profile();
  public profileOrig: Profile = new Profile();
  public profileExperience: ProfileExperience[] = [];
  public expinfo: any;
  public skillsinfo: any;
  public langinfo: any;
  public certificateinfo:any;
  public resumeDetails: any = {};
  public stay = false;
  public name = '';
  public resumeForm !: FormGroup;
  public contact: ProfileContact = new ProfileContact();
  public polling = false;
  public updating = false;

  private subscription!: Subscription;

  private pollingSubscription: Subscription =  new Subscription;

  constructor(private http: HttpClient, 
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private messageService: MessageService,    
    private pollingService: PollingService,        
    private datePipe: DatePipe,
    private dataService: DataService,
    private userservice: UserService,
    private router: Router, private activatedRoute: ActivatedRoute, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.subscribeToQueryParams();
  }

  private subscribeToQueryParams() {
    this.loading = true;
    this.subscription = this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.hasOwnProperty('vanity_name') && queryParams.vanity_name !== '') {
        this.vanity_name = queryParams.vanity_name;
        // this.vanity_name = 'mujahidsultan';
        // this.vanity_name = 'eve-violenta-431879217';
        // this.vanity_name = 'thomas-vettese-28aa79265';
        // this.vanity_name = 'nandhini-karuppiah-206188193';

        // save to local stoage
        const data = { vanity_name: this.vanity_name};
        this.localStorageService.saveData('userVanity', data);

          // create the appUser and also store on storage
          let appUser: AppUser = {};
          appUser.vanity_name = this.vanity_name;
          appUser.user_type = Constants.CLIENT;
          this.localStorageService.saveData('appUser', appUser);

          // set on the header
          this.dataService.setAppUser(appUser);
        
        // check first if this is coming from payment. If it is, it needs to poll
        // if (queryParams.hasOwnProperty('payment') && queryParams.payment === 'success') {
        if (
          queryParams.hasOwnProperty('payment') && queryParams.payment === 'standard'
          || 
          queryParams.hasOwnProperty('payment') && queryParams.payment === 'premium'
        ) {

          //DO NOT POLL. create a dummy subscription plan with active status
          let dummySubsPlan: SubscriptionPlan = {};
          dummySubsPlan.status = 'active';
          dummySubsPlan.sub_id = 'sub_dummy';
          if (queryParams.payment === 'standard') {
            dummySubsPlan.amount = 499;
          } else if (queryParams.payment === 'premium') {
            dummySubsPlan.amount = 4999
          }

          // establish the plan type
          this.generateSubscriptionType(dummySubsPlan);
          
          this.localStorageService.saveData('subcriptionPlan', dummySubsPlan);

          // create the appUser and also store on storage with dummy subcriptionPlan
          let appUser: AppUser = {};
          appUser.vanity_name = this.vanity_name;
          appUser.subscription = dummySubsPlan;
          appUser.user_type = Constants.CLIENT;
          this.localStorageService.saveData('appUser', appUser);

            // set on the header
            this.dataService.setAppUser(appUser);

          // after initial subacription, this section is adding the token value based on subscription type 
          let p = new AytHttpParams();
          p.set('vanity_name', this.vanity_name);
          if (queryParams.payment === 'standard') {
            p.set('number_of_tokens', Constants.STARTING_TOKEN_STANDARD);
          } else {
            p.set('number_of_tokens', Constants.STARTING_TOKEN_PREMIUM);
          }
          // add the token here, depending on the subscription
          this.subscription = this.userService.updateTokens(p).subscribe({
            next: response => {
              if (response) {

                // go to the preview
                // this.router.navigate(['resume-preview'],{ queryParams: {vanity_name: this.vanity_name}})
                this.router.navigate(['resume-edit'],{ queryParams: {vanity_name: this.vanity_name}})
                // this.startPolling(this.vanity_name);
              }
            },
            error: error => {
              XcvUtilsService.handleError(error, this.messageService);
            }
          });

        } else {
          // call the api to check whether the person as paid or not
          let p = new AytHttpParams();
          p.set('vanity_name', this.vanity_name);
      
          this.loading = true;
          this.polling = true;
          this.subscription = this.userService.verifyPaymentPlan(p)
          .subscribe({
            next: (response: any) => {
              this.loading = false;
              if (response) {
                // check for exception. If exception (non payment), stay here get the detail
                console.log('response payment = ', response);
                if (
                  (response.hasOwnProperty('exception') && response.exception === 'No subcription')
                  ||
                  (response.status === 'canceled')
                  ) {
                  this.stay = true;

                  if (response && response.status && response.status === 'canceled') {
                    // establish the plan type
                    this.generateSubscriptionType(response);

                    // still save the subscription plan info on storage even though its cancelled
                    const subcriptionPlan = response;
                    this.localStorageService.saveData('subcriptionPlan', subcriptionPlan);

                    // create the appUser and also store on storage with canceled subcriptionPlan 
                    let appUser: AppUser = {};
                    appUser.vanity_name = this.vanity_name;
                    appUser.subscription = subcriptionPlan;
                    appUser.user_type = Constants.CLIENT;
                    this.localStorageService.saveData('appUser', appUser);

                    // set on the header
                    this.dataService.setAppUser(appUser);
                  }

                  this.getRDetails(this.vanity_name);
                } else if (response && response.hasOwnProperty('status')  && response.status === 'active') {

                  // establish the plan type
                  this.generateSubscriptionType(response);

                  // save the subscription plan info on storage
                  const subcriptionPlan = response;

                  this.localStorageService.saveData('subcriptionPlan', subcriptionPlan);

                  // get the detail so you can get the name and save in storage
                  let p = new AytHttpParams();
                  p.set('vanity_name', this.vanity_name);
                  this.loading = true;
                  this.subscription = this.userService.getResumeDetails(p)
                  .subscribe({
                    next: (response: Profile) => {
                      if (response) {
                        // save to local stoage the name
                        this.name = response.first_name + ' ' + response.last_name;
                        const data = { 
                          vanity_name: this.vanity_name, 
                          name: this.name
                        };
                        this.localStorageService.saveData('userVanity', data);

                        // create the appUser and also store on storage with subcriptionPlan 
                        let appUser: AppUser = {};
                        appUser.vanity_name = this.vanity_name;
                        appUser.first_name = response.first_name;
                        appUser.last_name = response.last_name;
                        appUser.subscription = subcriptionPlan;
                        appUser.user_type = Constants.CLIENT;
                        this.localStorageService.saveData('appUser', appUser);

                        // set on the header
                        this.dataService.setAppUser(appUser);

                        // go to the preview
                        // this.router.navigate(['resume-preview'],{ queryParams: {vanity_name: this.vanity_name}})
                        this.router.navigate(['resume-edit'],{ queryParams: {vanity_name: this.vanity_name, startprofile:true}})
                      }
                    },
                    error: (_error: any) => {
                      this.loading = false;
                    }
                  });
                  // this.updateUser();
                }
              }
            },
            error: (_error: any) => {
            }
          });
        }

      } else {
        // go to login if theres no vanity_name
        this.router.navigate(['login'])
      }
    });
  }
  public updateUser()
  {
    let au: AppUser = this.localStorageService.getData('appUser');
    console.log("local storage=>",au)
    let payload: any = {};
    payload = {
      "vanity_name" : au.vanity_name,
      "payload": {
        "section_attr": {
          "first_name": "au",
          "last_name": "Atapattu"
        }
      }
    }
    this.subscription = this.userservice.updateResume(payload)
    .subscribe({
      next: response => {
        this.updating = false;
        if (response) {
//console.log("update resume", response)
        }
      },
      error: error => {
        this.updating = false;
      }
    });
  }

  public generateSubscriptionType(response: any) {
    if (response && 
        response.amount === 14.99 ||
        response.amount === 1499 ||
        response.amount === 4.99 ||
        response.amount === 499
      
      ) {
        response.plan_type = Constants.STANDARD;
        response.planType = Constants.STANDARD;
    } else if (response && 
      response.amount === 49.99 ||
      response.amount === 4999 ||
      response.amount === 149 ||
      response.amount === 14900
    ) {
      response.plan_type = Constants.PREMIUM;
      response.planType = Constants.PREMIUM;
    }
    
  }


  public getRDetails(id?: any) {
    let p = new AytHttpParams();
    if (id) {
      p.set('vanity_name', id);
    } else {
      p.set('vanity_name', 'hassaan-ahmad-21b571222');
    }
    this.loading = true;
    this.subscription = this.userService.getResumeDetails(p)
    .subscribe({
      next: (response: any) => {

        if (response && response.hasOwnProperty('info') && 
            response.info === 'Client does not exist') {
              // redirect to login
              this.router.navigate(['login']);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: response.info, life: 5000});
        } else {

          this.loading = false;
          this.polling = false;

          if (response) {
              //init 
              this.doPostretrieve(response);
          }

        }
      },
      error: (_error: any) => {
        if (_error && _error.hasOwnProperty('error') && _error.error.hasOwnProperty('exception') && 
        _error.error.exception === 'Invalid vanity name') {
          // reroute to login
          this.messageService.add({ severity: 'error', summary: 'Error', detail: _error.error.exception, life: 5000});
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: `Error encountered`, life: 5000});
        }
        this.router.navigate(['login']);
        this.loading = false;
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
        this.dataService.setData('true');
        console.log('ngAfterViewInit setting this.profile.first_name = ', this.profile.first_name);
        this.dataService.setName(this.name);
        this.dataService.setVanity(this.vanity_name);
      }, 1000);
  }

  private doPostretrieve(response: any) {
    console.log('>>>> doPostretrieve');
    this.profile = new Profile(); 

    this.profile.contact = new ProfileContact();
    let emails: string[] = [];

    this.profile.contact.email = emails;
    this.profile = response;

    this.dataService.setName(this.profile.first_name + ' ' + this.profile.last_name);

    if (this.profile.first_name && this.profile.last_name) {
      this.name = this.profile.first_name + ' ' + this.profile.last_name;

      // save to local stoage the name
      const data = { 
        vanity_name: this.vanity_name, 
        name: this.name
      };

      console.log('.. will clear storage');
      // this.localStorageService.clear();


      let au: AppUser = this.localStorageService.getData('appUser');
      if (au) {
        au.first_name = this.profile.first_name;
        au.last_name = this.profile.last_name;
        this.localStorageService.saveData('appUser', au);
        this.dataService.setAppUser(au)
      }

      this.localStorageService.saveData('userVanity', data);

      console.log('>> saved userVanity = ', this.localStorageService.getData('userVanity'));
    }

    // process the object
    // 1. make the languages to string
    if (this.profile.languages && this.profile.languages.length > 0) {
        this.profile.languagesDisplay = this.profile.languages.join(', ');
        this.profile.languagesDisplay = this.trimSpacesBetweenCommas(this.profile.languages.join(', '));
        this.profile.languagesDisplay = this.addSpaceBetweenCommas(this.profile.languagesDisplay);
    } else {
        this.profile.languagesDisplay = '';
    }

    // 2. make the skills to string
    if (this.profile.skills && this.profile.skills.length > 0) {
        this.profile.skillsDisplay = this.trimSpacesBetweenCommas(this.profile.skills.join(', '));
        this.profile.skillsDisplay = this.addSpaceBetweenCommas(this.profile.skillsDisplay);
    } else {
        this.profile.skillsDisplay = '';
    }


    if (this.profile.experience) {
        // generate id
        let n = 0;
        this.profile.experience.forEach((ex: any) => {
            n = n + 1;
            ex.expId = n;
            if (!ex.description || ex.description === '') {
                ex.description = '(None)';
            }
        });

        this.profileExperience = this.profile.experience;
    }

    this.profileOrig = Object.assign({}, this.profile);
    this.prepopulatefields();
  }

  public convertDateFormat(day: number, month: number, year: number): any {
    const date = new Date(year, month - 1, day);
    return this.datePipe.transform(date, 'MMM d, y'); //    
  }

  private isObject(object: any) {
    return object != null && typeof object === 'object';
  }

  private removeEmptyStringsFromArray(array: string[]): string[] {
    return array.filter((str) => str.trim() !== '');
  }

  private addSpaceBetweenCommas(inputString: string): string {
    return inputString
      .split(',')
      .map((value) => value.trim())
      .join(', ');
  }

  private trimSpacesBetweenCommas(inputString: string): string {
    return inputString
      .split(',')
      .map((value) => value.trim())
      .join(',');
  }  

  public prepopulatefields(): void {
      this.resetFormGroups();
      this.expinfo = this.profile.experience;
      this.skillsinfo = this.profile.skills;
      this.langinfo = this.profile.languages;
      this.certificateinfo = this.profile.certifications;

      this.expinfo.forEach((exp: any) => {
          this.addExp(exp)
      })
      
      this.langinfo.forEach((lang: any) => {
          this.addLang(lang)
      })
      this.skillsinfo.forEach((skills: any) => {
          this.addSkills(skills)
      })

      if (this.certificateinfo) {
          this.certificateinfo.forEach((certificates:any) => {
              this.addCertificate(certificates)
          })
      }

      if (this.profile.socials) {
          this.contact.facebook = this.profile.socials.facebook;
          this.contact.twitter = this.profile.socials.twitter;
      }

      if (this.profile.contact) {
          this.contact.email = this.profile.contact.email;
          this.contact.phone = this.profile.contact.phone;
      }

      if (this.contact) {
          if (this.contact.facebook === '' || this.contact.facebook === 'None' ) {
              this.contact.facebook = '(Not Indicated)';
          }
          if (this.contact.twitter === '' || this.contact.twitter === 'None' ) {
              this.contact.twitter = '(Not Indicated)';
          }
      }
  }

  addExp(experience: any) {
    this.experience.push(this.createExpGroup(experience));
  }

  addLang(languages: any) {
      this.languages.push(this.createLangGroup(languages));
  }

  addCertificate(certifications: any) {
      this.certifications.push(this.createCertificateGroup(certifications));
  }

  public addSkills(skills: any) {
    this.skills.push(this.createskillGroup(skills));
  }

  createCertificateGroup(certifications: any): FormGroup {
    return this.fb.group({
        certifications: [certifications, [Validators.required]],
    })
  }

  createskillGroup(skills: any): FormGroup {
    return this.fb.group({
        skills: [skills, [Validators.required]],
    })
  }

  createLangGroup(languages: any): FormGroup {
    return this.fb.group({
        languages: [languages, [Validators.required]],
    })
  }

  createExpGroup(experience: any): FormGroup {
    return this.fb.group({
        experience: [experience.name, [Validators.required]],
    })
  }

  get experience(): FormArray {
      return <FormArray>this.resumeForm.get('experienceArray');
  }

  get skills(): FormArray {
    return <FormArray>this.resumeForm.get('skillsArray');
  }

  get certifications(): FormArray {
    return <FormArray>this.resumeForm.get('certificateArray');
  }

  get languages(): FormArray {
    return <FormArray>this.resumeForm.get('langArray');
  }

  public displayExperienceDurationDateRange(exp: any) {
    let disp = '';
    if (exp && exp.starts_at) {
      disp = this.convertDateFormat(exp.starts_at.day, exp.starts_at.month, exp.starts_at.year);
    }
    if (exp && exp.ends_at) {
      disp = disp + ' - ' + this.convertDateFormat(exp.ends_at.day, exp.ends_at.month, exp.ends_at.year);
    }

    return disp;
  }

  public resetFormGroups(): void {
    this.resumeForm = this.fb.group({
        experienceArray: this.fb.array([]),
        skillsArray: this.fb.array([]),
        langArray: this.fb.array([]),
        certificateArray:this.fb.array([])
    });
  }

  public startPolling(vanity_name: string): void {
    this.polling = true;
    console.log('>>> starts polling');

    const apiUrl = `${APIEndpoints.BASE_API}/client/verify-plan`;
    const queryParams = { 'vanity_name': vanity_name};
    const intervalTime = 5000; // 5 seconds

    this.pollingSubscription = this.pollingService.startPolling(
      (response: any) => response.condition === 'met', // Replace with your condition
      apiUrl,
      queryParams,
      intervalTime
    ).subscribe(response => {
      // Handle the response
      if (response && response.hasOwnProperty('status') && response.status === 'active') {
        console.log('>>> polling terminated');
        this.polling = false;
        this.pollingSubscription.unsubscribe(); // Stop polling when condition is met

        // save the subcriptionPlan in storage
        this.localStorageService.saveData('subcriptionPlan', response);

        //// now do the usual get details etc...
        // go to the preview
        // this.router.navigate(['resume-preview'],{ queryParams: {'vanity_name': this.vanity_name}})
        this.router.navigate(['resume-edit'],{ queryParams: {'vanity_name': this.vanity_name}})
      } else {
        console.log('>>> polling...');

      }
    });
  }
  
  public navigateTo(path: any, optionType?: any): void {
    if (optionType) {
      this.router.navigate([`${path}`],
      { queryParams: { optionType: `${optionType}`}}
      );
    } else {
      this.router.navigate([`${path}`]);
    }
  }

  public goToFreeOption(): void {
    // go to the preview
    // this.router.navigate(['resume-preview'],{ queryParams: {vanity_name: this.vanity_name}})
    this.router.navigate(['resume-edit'],{ queryParams: {vanity_name: this.vanity_name}})

  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }    
  }

}
