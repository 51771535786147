<section>

  <div *ngIf="loading" class="container">
    <div class="col-md-12 justify-content-center d-flex mt-5">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <div *ngIf="!loading" class="container">
  
      <!-- <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#basic" type="button" role="tab" aria-controls="home" aria-selected="true">Linkedin Resume</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#linkedin" type="button" role="tab" aria-controls="profile" aria-selected="false">ChatGPT Resume</button>
        </li>
      
      </ul> -->
      <div class="row">
        <div class="col-md-12 justify-content-center d-flex mt-5">
          <h1 class="title-xl">Your Resume</h1>
          </div>
          </div>
          <div class="row">
            <div class="col-md-12 justify-content-center d-flex mt-2">
              <button type="submit" class="btn btn-primary mt-4 mb-2 btn-resume edit">Edit </button>
              <button type="submit" class="btn btn-success mt-4 mb-2 btn-resume chatgpt" (click)="getAllVanities()">Edit with ChatGPT</button>
              <!-- <button type="submit" class="btn btn-primary mt-4 pull-right mb-2 btn-resume save" (click)="postResumeDetails()">Save</button> -->
              <button type="submit" class="btn btn-primary mt-4 pull-right mb-2 btn-resume save" (click)="updateProfile()">Save</button>
            </div>
          </div>
          <div class="cv" id="content" #content>
          <div class="container-fluid">
            <form [formGroup]="resumeForm">
              <div class="row">
                <div class="col-md-8">
        
                  <!-- header -->
                  <header class="cv-header">

                    <div class="row">
                      <div class="col-md-12">

                        <div class="img-edit-wrapper">
                          <img class="cv-header__img"id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'"
                          data-zoomable> 
                          <div class="overlay">
                            <a class="icon" title="User Profile" (click)="fileInput.click()">
                              <i class="fa fa-pencil-square-o edit-profile"></i>
                              <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" class="image-upload" #fileInput (change)="uploadFile($event)" />
                            </a>
                          </div>
                        </div>
    
                      </div>
                    </div>
      
                    <div class="row">
                      <div class="col-md-12">
                        <div class="cv-header__text-container">
                          <h1 class="cv-header__name">{{profile.first_name}} {{profile.last_name}}</h1>
                        </div>
                      </div>
                    </div>

                  
                    <div class="row align-items-center">

                      <div class="col-md-auto">
                        <div *ngIf="!isEditTitle" class="cv-header__text-container">
                          <p class="cv-header__job-title">{{profile.title}}
                          </p>
                        </div>

                        <div *ngIf="isEditTitle">
                          <input type="text" class="form-control" id="title" aria-describedby="emailHelp"
                          [ngModelOptions]="{standalone: true}" [(ngModel)]="profile.title"
                          placeholder="Job Title..">
                        </div>

                      </div>

                      <div class="col-md-auto">
                        <i *ngIf="!isEditTitle" class="fa fa-pencil-square-o edit-icon" aria-hidden="true"
                        (click)="editprofile('title')"></i>
                        <i *ngIf="isEditTitle"  class="fa fa-times edit-icon" aria-hidden="true"
                        (click)="editprofile('title')"></i>
                        <i *ngIf="isEditTitle" class="fa fa-check edit-icon-20" aria-hidden="true"
                        (click)="updateProfile()"></i>
                      </div>

                    </div>
  
                  </header>
        
                  <!-- content -->
                  <section class="cv-section">
                    <h2 class="cv-section__title"><i class="fa fa-user fa-fw"></i>Profile
                      <i *ngIf="!isEditProfile && !isRemove" class="fa fa-pencil-square-o edit-icon" aria-hidden="true"
                        (click)="editprofile('profile')"></i>
                      <i *ngIf="isEditProfile" class="fa fa-check edit-icon" aria-hidden="true"
                        (click)="editprofile('profile')"></i>
                    </h2>
                    <div class="cv-section__wrapper">
                      <p *ngIf="!isEditProfile" class="cv-section__description">{{resumeDetails?.profile}}</p>
                      <div class="form-group">
                        <textarea *ngIf="isEditProfile" class="form-control" [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="resumeDetails.profile" id="exampleFormControlTextarea1" rows="4"></textarea>
                      </div>
                    </div>
              
                    <button type="button" class="btn btn-info mt-3" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="showRevisedText('profile')">
                      Revise with GPT
                  </button>
                  </section>
        
                  <section class="cv-section">
                    <h2 class="cv-section__title"><i class="fa fa-user fa-fw"></i>Revised Profile
                      <i *ngIf="!isEditrevisedProfile" class="fa fa-pencil-square-o edit-icon" aria-hidden="true"
                        (click)="editprofile('revprofile')"></i>
                      <i *ngIf="isEditrevisedProfile" class="fa fa-check edit-icon" aria-hidden="true"
                        (click)="editprofile('revprofile')"></i>
                    </h2>
                    <div class="cv-section__wrapper">
                      <p class="cv-section__description" *ngIf="!isEditrevisedProfile">{{resumeDetails?.revisedProfile}} <br><br>
                      <div class="form-group">
                        <textarea *ngIf="isEditrevisedProfile" class="form-control" [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="resumeDetails.revisedProfile" id="exampleFormControlTextarea1" rows="4"></textarea>
                      </div>
                    </div>
                    <button type="button" class="btn btn-info mt-3" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="showRevisedText('rprofile')">
                      GPT revised
                  </button>
                  </section>
        
                  <!-- experience -->
                  <section class="cv-section">
                    <h2 class="cv-section__title"><i class="fa fa-briefcase fa-fw"></i> Experience
                      <i *ngIf="!isEditexperience" class="fa fa-pencil-square-o edit-icon" aria-hidden="true"
                        (click)="editprofile('experience')"></i>
                      <i *ngIf="isEditexperience" class="fa fa-check edit-icon" aria-hidden="true"
                        (click)="updateProfile2()"></i>
                    </h2>


                    <!-- start experience  display -->
                    <ng-container *ngIf="!isEditexperience">
                      <!-- <div class="cv-section__wrapper" *ngFor="let exp of resumeDetails?.experience.slice(0,3);"> -->
                      <div class="cv-section__wrapper" *ngFor="let exp of profile?.experience;">
        
                        <div class="row cv-section__experience">
                          <div class="col-md-12">

                            <!-- start title/duration date date -->
                            <div class="row">
                              <div class="col-md-12">
                                <span class="cv-section__role">
                                  {{exp?.title}}
                                </span>
                                <span class="cv-section__starts">
                                  ({{
                                    displayExperienceDurationDateRange(exp)
                                  }})
                                </span>
                              </div>
                            </div>
                            <!-- end title/duration date date -->

                            <!-- start company/location -->
                            <div class="row">
                              <div class="col-md-12">
                                <span class="cv-section__comp">
                                  {{exp?.company}} </span>
                                <span *ngIf="exp?.location" class="cv-section__comp">
                                  ({{exp?.location}})
                                </span>
                              </div>
                            </div>
                            <!-- end company/location -->

                            <!-- start company_linkedin_profile_url -->
                            <div *ngIf="exp?.company_linkedin_profile_url" class="row">
                              <div class="col-md-12">
                                <a href="{{exp?.company_linkedin_profile_url}}" target="_blank" class="cv-section__comp">
                                  {{exp?.company_linkedin_profile_url}} </a>
                              </div>
                            </div>
                            <!-- end company_linkedin_profile_url -->

                            <!-- start description -->
                            <div class="row">
                              <div class="col-md-12">
                                <span class="cv-section__description">{{exp?.description}}</span>
                              </div>
                            </div>
                            <!-- end description -->
                          </div>


                        </div>
                      </div>
                    </ng-container>
                    <!-- end experience  display -->


                    <!-- start experience edit -->
                    <ng-container *ngIf="isEditexperience">
                      <div class="cv-section__wrapper" *ngFor="let exp of profile?.experience; let i = index">
                        <!-- freelance -->
                        <div class="cv-section__experience">
                          <div class="form-group mb-3">
                            <label for="jobtitle">Job Title</label>
                            <input type="text" class="form-control" id="jobtitle" aria-describedby="emailHelp"
                              [ngModelOptions]="{standalone: true}" [(ngModel)]="exp.title"
                              placeholder="Enter title">
        
                          </div>


                          <!-- <div class="form-group mb-3">
                            <label for="period">Period</label>
                            <input type="text" class="form-control" id="period" aria-describedby="emailHelp"
                              [ngModelOptions]="{standalone: true}" [(ngModel)]="resumeDetails.experience[i].year"
                              placeholder="Enter period">
        
                          </div>

                          <div class="form-group mb-3">
                            <label for="jobdesc">Job Description</label>
                            <input type="text" class="form-control" id="jobdesc" aria-describedby="emailHelp"
                              [ngModelOptions]="{standalone: true}" [(ngModel)]="resumeDetails.experience[i].desc"
                              placeholder="Enter description">
        
                          </div> -->


                        </div>
                      </div>
                    </ng-container>
                    <!-- end experience edit -->


                  </section>
        
                  <!-- education -->
                  <section class="cv-section">
                    <h2 class="cv-section__title"><i class="fa fa-graduation-cap fa-fw"></i> Education
                      <i *ngIf="!isEditedducation" class="fa fa-pencil-square-o edit-icon" aria-hidden="true"
                        (click)="editprofile('education')"></i>
                      <i *ngIf="isEditedducation" class="fa fa-check edit-icon" aria-hidden="true"
                        (click)="editprofile('education')"></i>
                    </h2>
                    <ng-container *ngIf="!isEditedducation">
                      <div class="cv-section__wrapper" *ngFor="let exp of resumeDetails?.education.slice(0,3);">
        
                        <!-- ravensbourne -->
                        <div class="cv-section__experience">
                          <p class="cv-section__role">{{exp?.name}}</p>
                          <p class="cv-section__period">{{exp?.year}}</p>
                          <p class="cv-section__description">{{exp?.desc}}</p>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isEditedducation">
                      <div class="cv-section__wrapper" *ngFor="let exp of resumeDetails?.education; let i = index">
                        <!-- freelance -->
                        <div class="cv-section__experience">
                          <div class="form-group mb-3">
                            <label for="jobtitle">University/Institute</label>
                            <input type="text" class="form-control" id="jobtitle" aria-describedby="emailHelp"
                              [ngModelOptions]="{standalone: true}" [(ngModel)]="resumeDetails.education[i].name"
                              placeholder="Enter title">
        
                          </div>
                          <div class="form-group mb-3">
                            <label for="period">Period of Study</label>
                            <input type="text" class="form-control" id="period" aria-describedby="emailHelp"
                              [ngModelOptions]="{standalone: true}" [(ngModel)]="resumeDetails.education[i].year"
                              placeholder="Enter period">
        
                          </div>
                          <div class="form-group mb-3">
                            <label for="jobdesc">Description</label>
                            <input type="text" class="form-control" id="jobdesc" aria-describedby="emailHelp"
                              [ngModelOptions]="{standalone: true}" [(ngModel)]="resumeDetails.education[i].desc"
                              placeholder="Enter description">
        
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </section>
        
                </div>
        
                <!-- sidebar -->
                <aside class="col-md-4 cv-sidebar">
        
                  <!-- details -->
                  <section class="cv-sidebar-section">
                    <h2 class="cv-sidebar-section__title">Details
                      <i *ngIf="!isDetails" class="fa fa-pencil-square-o edit-icon right-bar" aria-hidden="true"
                        (click)="editprofile('details')"></i>
                      <i *ngIf="isDetails" class="fa fa-check edit-icon right-bar" aria-hidden="true"
                        (click)="editprofile('details')"></i>
                    </h2>
                    <p class="cv-sidebar-section__description" *ngIf="!isDetails">
                      {{resumeDetails?.details.address}}<br>
                      <a href="tel:0-123-456-7890" rel="nofollow">{{resumeDetails?.details.contact}}</a> <br>
                      <a href="mailto:example@selcukcura.com" rel="nofollow">{{resumeDetails?.details.email}}</a> <br>
        
                    </p>
                    <div *ngIf="isDetails">
                      <div class="form-group mb-3">
                        <label for="address">Address</label>
                        <input type="text" class="form-control" id="address" aria-describedby="emailHelp"
                          [ngModelOptions]="{standalone: true}" [(ngModel)]="resumeDetails.details.address"
                          placeholder="Enter Address">
        
                      </div>
                      <div class="form-group mb-3">
                        <label for="contactno">Contact No</label>
                        <input type="text" class="form-control" id="contactno" aria-describedby="emailHelp"
                          [ngModelOptions]="{standalone: true}" [(ngModel)]="resumeDetails.details.contact"
                          placeholder="Enter Contact details">
        
                      </div>
                      <div class="form-group mb-3">
                        <label for="email">Email</label>
                        <input type="text" class="form-control" id="email" aria-describedby="emailHelp"
                          [ngModelOptions]="{standalone: true}" [(ngModel)]="resumeDetails.details.email"
                          placeholder="Enter email">
        
                      </div>
        
                    </div>
        
                  </section>
        
        
                  <!-- tools -->
                  <section class="cv-sidebar-section">
                    <h2 class="cv-sidebar-section__title">Tools
                      <i *ngIf="!isTools" class="fa fa-pencil-square-o edit-icon right-bar" aria-hidden="true"
                        (click)="editprofile('tools')"></i>
                      <i *ngIf="isTools" class="fa fa-check edit-icon right-bar" aria-hidden="true"
                        (click)="editprofile('tools')"></i>
                    </h2>
                    <p *ngIf="!isTools" class="cv-sidebar-section__description"><span
                        *ngFor="let tool of resumeDetails?.tools;let i = index;let last = last">{{tool}}<span *ngIf="!last">, </span></span></p>
                    <div *ngIf="isTools">
                      <ng-container *ngFor="let tool of resumeDetails?.tools;let i = index; trackBy: trackByFn">
                        <div class="form-group mb-3">
                          <label for="tool">Tool {{i+1}}</label>
                          <input type="text" class="form-control" id="tool" aria-describedby="emailHelp"
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="resumeDetails?.tools[i]"
                            placeholder="Enter title">
        
                        </div>
                      </ng-container>
                    </div>
        
                  </section>
                  
        
                  <!-- interests -->
                  <section class="cv-sidebar-section">
                    <h2 class="cv-sidebar-section__title">Interests
                      <i *ngIf="!isSkills" class="fa fa-pencil-square-o edit-icon right-bar" aria-hidden="true"
                      (click)="editprofile('interests')"></i>
                    <i *ngIf="isSkills" class="fa fa-check edit-icon right-bar" aria-hidden="true"
                      (click)="editprofile('interests')"></i>
                    </h2>
                    <p class="cv-sidebar-section__description">Minimalism, Animation, Concept Development, Responsive Design, UI
                      Design, UX Design, Interaction Design, Photography.</p>
                  </section>
        


                  <!-- start skills -->
                  <section *ngIf="updating" class="cv-sidebar-section">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </section>

                  <section *ngIf="!updating" class="cv-sidebar-section">
                    <h2 class="cv-sidebar-section__title">Skills
                      <i *ngIf="!isSkills" class="fa fa-pencil-square-o edit-icon right-bar" aria-hidden="true"
                        (click)="editprofile('skills')"></i>

                      <i *ngIf="isSkills"  class="fa fa-times edit-icon right-bar" aria-hidden="true"
                        (click)="editprofile('skills')"></i>



                      <i *ngIf="isSkills" class="fa fa-check edit-icon-20" aria-hidden="true"
                        (click)="updateProfile()"></i>
                    </h2>
                    <p *ngIf="!isSkills" class="cv-sidebar-section__description">{{profile.skillsDisplay ? profile.skillsDisplay : '(None)'}}</p>
                    <div *ngIf="isSkills" class="form-group">
                      <textarea  class="form-control" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="profile.skillsDisplay" id="textAreaSlikks" rows="4"></textarea>
                    </div>
                  </section>


                  <!-- <section *ngIf="!updating"  class="cv-sidebar-section">
                    <h2 class="cv-sidebar-section__title">Skills
                      <i *ngIf="!isSkills" class="fa fa-pencil-square-o edit-icon right-bar" aria-hidden="true"
                        (click)="editprofile('skills')"></i>
                      <i *ngIf="isSkills" class="fa fa-check edit-icon right-bar" aria-hidden="true"
                        (click)="editprofile('skills')"></i>
                    </h2>
                    <p *ngIf="!isSkills" class="cv-sidebar-section__description"><span
                        *ngFor="let skill of resumeDetails?.skills;let i = index;let last = last">{{skill}}<span *ngIf="!last">, </span></span></p>
                    <div *ngIf="isSkills">
                      <ng-container *ngFor="let skill of resumeDetails?.skills;let i = index;trackBy: trackByFn">
                        <div class="form-group mb-3">
                          <label for="skill{{i+1}}">Skill {{i+1}}</label>
                          <input type="text" class="form-control" id="skill{{i+1}}" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="resumeDetails?.skills[i]" placeholder="Enter Skill">
                        </div>
                      </ng-container>
                    </div>
                  </section> -->
                  <!-- end skills -->

                  <!-- start languages -->
                  <section *ngIf="updating" class="cv-sidebar-section">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </section>

                  <section *ngIf="!updating" class="cv-sidebar-section">
                    <h2 class="cv-sidebar-section__title">Languages
                      <i *ngIf="!isEditlanguage" class="fa fa-pencil-square-o edit-icon right-bar" aria-hidden="true"
                        (click)="editprofile('language')"></i>
                      <i *ngIf="isEditlanguage"  class="fa fa-times edit-icon right-bar" aria-hidden="true"
                        (click)="editprofile('language')"></i>
                      <i *ngIf="isEditlanguage" class="fa fa-check edit-icon-20" aria-hidden="true"
                        (click)="updateProfile()"></i>
                    </h2>
                    <p *ngIf="!isEditlanguage" class="cv-sidebar-section__description">{{profile.languagesDisplay ? profile.languagesDisplay : '(None)'}}</p>
                    <div *ngIf="isEditlanguage" class="form-group">
                      <textarea  class="form-control" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="profile.languagesDisplay" id="textAreaLanguage" rows="4"></textarea>
                    </div>
                  </section>
                  <!-- end languages -->

                  <!-- start country -->
                  <section *ngIf="updating" class="cv-sidebar-section">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </section>
                  <section *ngIf="!updating" class="cv-sidebar-section">
                    <h2 class="cv-sidebar-section__title">Country
                      <i *ngIf="!isEditCountry" class="fa fa-pencil-square-o edit-icon right-bar" aria-hidden="true"
                        (click)="editprofile('country')"></i>

                      <i *ngIf="isEditCountry"  class="fa fa-times edit-icon right-bar" aria-hidden="true"
                        (click)="editprofile('country')"></i>

                      <i *ngIf="isEditCountry" class="fa fa-check edit-icon-20" aria-hidden="true"
                        (click)="updateProfile()"></i>
                    </h2>
                      <p *ngIf="!isEditCountry" class="cv-sidebar-section__description">{{profile.country}}</p>
                      <div *ngIf="isEditCountry" class="form-group">
                          <input type="text" class="form-control" id="textCountry" aria-describedby="emailHelp"
                          [ngModelOptions]="{standalone: true}" [(ngModel)]="profile.country"
                          placeholder="Enter Country">
                      </div>
                  </section>
                  <!-- end country -->

                </aside>
              </div>
            </form>
          </div>
  
  
        
      </div>
      <!-- cv -->
    </div>
  
  </section>
  <!-- Modal for gpt suggessions-->
  <div class="modal fade bd-example-modal-lg" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Revised with GPT 4</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
        {{gptrevisedData}}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="saveGptData()">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  
  
  
  
  
  
  