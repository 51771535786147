import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ResumeListComponent } from './resume-list/resume-list.component';
import { ResumeDetailsComponent } from './resume-details/resume-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserDetailsComponent } from './user-details/user-details.component';
import {HttpClientModule} from '@angular/common/http';
import { GptModalComponent } from './gpt-modal/gpt-modal.component';
import { ResumePreviewComponent } from './resume-preview/resume-preview.component';
import {DatePipe} from '@angular/common';
import {CurrencyPipe} from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { EditorModule } from 'primeng/editor';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { PasswordModule } from 'primeng/password';
import { MenubarModule } from 'primeng/menubar';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CarouselModule } from 'primeng/carousel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JdAlignmentComponent } from './jd-alignment/jd-alignment.component';
import { LandingComponent } from './landing/landing.component';
import { PaymentOptionsComponent } from './payment-options/payment-options.component';
import { ConfirmationService } from 'primeng/api';
import { RecruiterLoginComponent } from './recruiter-login/recruiter-login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CompanyRegisterComponent } from './company-register/company-register.component';
import { CompanyDashboardComponent } from './company-dashboard/company-dashboard.component';
import { RecruiterCreateComponent } from './recruiter-create/recruiter-create.component';
import { ResumeEditComponent } from './resume-edit/resume-edit.component';
import { TemplatesModule } from './templates/templates.module';
import { PricingPlanComponent } from './pricing-plan/pricing-plan.component';
import { CompanyCreateComponent } from './company-create/company-create.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResumeListComponent,
    ResumeDetailsComponent,
    UserDetailsComponent,
    GptModalComponent,
    ResumePreviewComponent,
    JdAlignmentComponent,
    LandingComponent,
    PaymentOptionsComponent,
    RecruiterLoginComponent,
    ResetPasswordComponent,
    NotFoundComponent,
    CompanyRegisterComponent,
    CompanyDashboardComponent,
    RecruiterCreateComponent,
    ResumeEditComponent,
    PricingPlanComponent,
    CompanyCreateComponent
  ],
  imports: [
    InputSwitchModule,
    CarouselModule,
    NgxChartsModule,
    MenubarModule,
    PasswordModule,
    TabViewModule,
    TableModule,
    ListboxModule,
    EditorModule,
    TooltipModule,
    CheckboxModule,
    CalendarModule,
    CardModule,
    AvatarModule,
    AvatarGroupModule,
    DropdownModule,
    ToastModule,
    BrowserAnimationsModule,
    ButtonModule,
    DialogModule,
    ConfirmDialogModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TemplatesModule,
    HttpClientModule,
    NgbModule
  ],
  providers: [ConfirmationService, DatePipe, CurrencyPipe,
    { provide: 'apiKey', useValue: 'YOUR_API_KEY' },
    { provide: 'authorize', useValue: 'true' }, 
    { provide: 'isServer', useValue: 'true' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
