import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Renderer2, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, RendererFactory2 } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'app/core/local-storage.service';
import { UserService } from 'app/core/user.service';
import { XcvUtilsService } from 'app/core/xcv-utils.service';
import { Constants } from 'app/helpers/app-settings';
import { DataService } from 'app/helpers/data.service';
import { AytHttpParams } from 'app/helpers/http-config';
import { XcvUtils } from 'app/helpers/xcv-utils';
import { Profile } from 'app/model/Userprofile';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import jspdf, { jsPDF } from "jspdf";


@Component({
  selector: 'app-template2',
  templateUrl: './template2.component.html',
  styleUrls: ['./template2.component.scss']
})


export class Template2Component implements OnInit {
  @Input() resumeDetails:any;  
  @Input()
  message!: string;

  public reloadTemplate = false;
  public previewVisibleChild2 = true;
  public cameFromreload = true;
  public vanity_name = '';
  public loading = false;
  public name = '';
  public pdf: any;
  public pdfPreviewAction = false;
  public pdfPreviewOn = false;

  private subscription!: Subscription;

  constructor(
    private datePipe: DatePipe,
    private el: ElementRef,
    private dataService: DataService,
    private localStorageService: LocalStorageService,
    private userservice: UserService) {

   }

    ngOnInit(): void {
    }

    public displayExperienceDurationDateRange(exp: any) {
      let disp = '';
      if (exp && exp.starts_at) {
        disp = XcvUtilsService.convertDateFormat(exp.starts_at.day, exp.starts_at.month, exp.starts_at.year, this.datePipe);
      }
      if (exp && exp.ends_at) {
        disp = disp + ' - ' + XcvUtilsService.convertDateFormat(exp.ends_at.day, exp.ends_at.month, exp.ends_at.year, this.datePipe);
      }

      return disp;
    }

    public displayprojectDuration(proj: any) {
      let disp = '';
      if (proj && proj.starts_at) {
        disp = XcvUtilsService.convertDateFormat(proj.starts_at.day, proj.starts_at.month, proj.starts_at.year, this.datePipe);
      }
      if (proj && proj.ends_at) {
        disp = disp + ' - ' +  XcvUtilsService.convertDateFormat(proj.ends_at.day, proj.ends_at.month, proj.ends_at.year, this.datePipe);
      }

      return disp;
    }

    ngOnDestroy(): void {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }

}
