<ng-container>
    <div *ngIf="polling" class="row">
      <div class="col-md-12 text-center">
        <div class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <div *ngIf="!polling" class="row">
        <div class="col-md-12">

            <!-- start blue section -->
            <div class="row">
                <div class="col-md-12">
                    <div class="box">

                        <div class="row mt-hdr-1 justify-content-center">
                            <div class="col-md-8 text-center">
                                <span class="hdr-1">
                                    Let AI refine you a resume and align it to a Job Description!
                                </span>
                            </div>
                        </div>

                        <div class="row mt justify-content-center">
                            <div class="col-md-8 text-center">
                                <span class="hdr-2">
                                    Align a resume to a Job Description with one click
                                </span>
                            </div>
                        </div>

                        <div class="row mt margin-btn-custom-buttom margin-btn-custom-top justify-content-center">
                            <div class="col-md-4 text-center">
                                <!-- goes to free -->
                                <button (click)="goToFreeOption()" class="btn btn-primary landing-button rounded-pill">Edit and format</button>
                            </div>
                            <div class="col-md-4 text-center">
                                <button (click)="navigateTo('payment', 'regular')" class="btn btn-primary landing-button rounded-pill">Improve with GPT</button>
                            </div>
                            <div class="col-md-4 text-center">
                                <button (click)="navigateTo('payment', 'premium')" class="btn btn-primary landing-button rounded-pill">Align with JD</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <!-- end blue section -->

            <!-- start resume section -->
            <div class="row">
                <div class="col-md-12">
                    <section>

                        <div *ngIf="loading" class="container">
                          <div class="col-md-12 justify-content-center d-flex mt-5">
                            <div class="spinner-border spinner-border-sm" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </div>
                      
                        <div *ngIf="!loading" class="container">
                          <div class="row">
                      
                            <div class="col-md-12 justify-content-center d-flex mt-5">
                              <h1 class="title-xl">Resume</h1>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12 justify-content-center d-flex mt-2">
                            </div>
                          </div>
                          <div class="cv mb-5" id="content" #content>
                            <div class="container-fluid">
                      
                              <form [formGroup]="resumeForm">
                                <div class="row">
                                  <div class="col-md-12">
                                    <h1 class="user-name">{{profile.first_name}} {{profile.last_name}}</h1>
                                  </div>
                                  <div class="col-md-12">
                                    <h4 class="title">{{profile.title}}</h4>
                                  </div>
                                </div>
                      
                                <!-- start contacts section -->
                                <div class="row">
                                  <div *ngIf="contact && contact.email && contact.email.length > 0" class="col-md-auto">
                                    <p class="contact"><i class="fa fa-envelope" aria-hidden="true"></i>
                                      <a href = "mailto: {{contact.email[0]}}">{{contact.email[0]}}</a>
                                    </p>
                                  </div>
                      
                                  <div *ngIf="contact && contact.email && contact.email.length === 0" class="col-md-auto">
                                    <p class="contact"><i class="fa fa-envelope" aria-hidden="true"></i>
                                      (Not Indicated)
                                    </p>
                                  </div>
                                  
                                  <div class="col-md-auto">
                                    <p class="contact"> <i class="fa fa-linkedin" aria-hidden="true"></i> 
                                      <a href="https://www.linkedin.com/in/{{profile.vanity_name}}/" target="_blank">
                                        {{profile.vanity_name}}
                                      </a>
                                    </p>
                                  </div>
                                  <div class="col-md-auto">
                                    <p class="contact"><i class="fa fa-twitter" aria-hidden="true"></i> {{contact.twitter}}</p>
                                  </div>
                                  <div class="col-md-auto">
                                    <p class="contact"><i class="fa fa-phone" aria-hidden="true"></i>
                                      {{contact && contact.phone && contact.phone.length > 0 ?  contact.phone[0] : '(Not Indicated)'}}
                                    </p>
                                  </div>
                      
                                </div>
                                <!-- end contacts section -->
                      
                                <!-- start about section -->
                                <div class="row mb-3">
                      
                                  <!-- start about display -->
                                  <div class="col-md-11">
                                    <!-- <span class="cv-about">
                                      {{profile.about}}
                                    </span> -->
                                    <div class="cv-about" [innerHTML]="profile.about"></div>
                                  </div>
                                  <!-- end about display -->
                      
                                </div>
                                <!-- end about section -->
                      
                                <!-- start experience section -->
                                <div class="row mt-4 resume-data">
                                  <div class="col-md-9">
                                    <!-- experience -->
                                    <section class="cv-section" *ngIf="profile?.experience">
                                      <div class="row align-items-center">
                                        <div class="col-md-6">
                                          <h2 class="cv-section__title">Experience
                                          </h2>
                                        </div>
                                        <div class="col-md-6">
                                        </div>
                                      </div>
                      
                                      <ng-container>
                      
                                        <!-- <div class="cv-section__wrapper"> -->
                                        <div class="cv-section__wrapper" *ngFor="let exp of profile?.experience;">
                      
                                          <div class="cv-section__experience">
                                            <p class="cv-section__role">{{exp?.company}}
                                              {{exp.location ? '(' + exp.location + ')' : ''}}
                                            </p>
                      
                                            <span *ngIf="exp?.company_linkedin_profile_url">
                                              <a href="{{exp?.company_linkedin_profile_url}}" target="_blank" class="cv-section__comp">
                                                {{exp?.company_linkedin_profile_url}} </a>
                                            </span>
                      
                                            <p class="cv-section__experience_title">{{exp?.title}}</p>
                      
                                            <p class="cv-section__period">
                                              {{displayExperienceDurationDateRange(exp)}}
                                            </p>
                      
                                            <!-- <p class="cv-about">
                                              {{exp?.description}}
                                            </p> -->
                                            <div class="cv-about" [innerHTML]="exp?.description"></div>

                                          </div>
                                        </div>
                                      </ng-container>

                                    </section>
                                    <!-- experience -->
                                    <section class="cv-section" *ngIf="resumeDetails?.Experience?.Projects.length > 0">
                                      <h2 class="cv-section__title">Projects
                      
                                      </h2>
                                      <ng-container>
                                        <div class="cv-section__wrapper">
                      
                                          <div class="cv-section__experience">
                                            <p class="cv-section__role">dfdf</p>
                                            <p class="cv-section__period">4545</p>
                                            <ul class="cv-section__description">
                                              <li>dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                                standard dummy text ever since the 1500s,
                                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                              </li>
                                              <li>dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                                standard dummy text ever since the 1500s,
                                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                              </li>
                                            </ul>
                      
                                          </div>
                                        </div>
                                      </ng-container>
                      
                                    </section>
                                    <!-- content -->
                                    <section class="cv-section">
                                      <div class="row">
                                        <div class="col-md-6">
                                          <h2 class="cv-section__title">Courses / Certifications
                      
                                          </h2>
                                        </div>
                                        <div class="col-md-6">
                                        </div>
                                      </div>
                      
                                      <div class="cv-section__wrapper">
                      
                                        <!-- start courses / certifications display -->
                                        <ng-container>
                                          <ul *ngIf="profile.certifications" class="course-list">
                                            <li *ngFor="let courses of profile.certifications">{{courses}}</li>
                                          </ul>
                                          <div *ngIf="!profile.certifications">
                                            (None Specified)
                                          </div>
                                        </ng-container>
                                        <!-- end courses / certifications display -->
                                      </div>
                      
                                    </section>
                      
                                    <section class="cv-section" style="display: none;">
                                      <h2 class="cv-section__title"><i class="fa fa-user fa-fw"></i>Revised Profile
                      
                                      </h2>
                                      <div class="cv-section__wrapper">
                                        <p class="cv-section__description">im good too <br><br>
                      
                                      </div>
                                    </section>
                      
                                  </div>
                      
                                  <!-- sidebar -->
                                  <aside class="col-md-3 cv-sidebar">
                      
                      
                                    <!-- skills -->
                                    <section class="cv-sidebar-section">
                                      <div class="row align-items-center">
                                        <div class="col-md-6">
                                          <h2 class="cv-section__title">Skills
                                          </h2>
                                        </div>
                                        <div class="col-md-6">
                                        </div>
                                      </div>
                      
                                      <!-- start skills display -->
                                      <ng-container>
                                        <ul *ngIf="profile.skills && profile.skills.length > 0" class="list-inline">
                                          <li class="list-inline-item" *ngFor="let skills of profile.skills| slice:0:7;">{{skills}}
                                          </li>
                                        </ul>
                                        <div *ngIf="profile.skills && profile.skills.length === 0">
                                          (Not Indicated)
                                        </div>
                      
                                      </ng-container>
                                      <!-- end skills display -->
                      
                                    </section>
                      
                                    <!-- languages -->
                                    <section class="cv-sidebar-section"
                                      *ngIf="profile.languages && profile.languages.length > 0">
                                      <div class="row align-items-center">
                                        <div class="col-md-6">
                                          <h2 class="cv-section__title">Languages</h2>
                                        </div>
                                        <div class="col-md-6">
                                        </div>
                                      </div>
                      
                                      <!-- start languages display -->
                                      <ng-container>
                                        <ul class="lang-list">
                                          <li *ngFor="let lang of profile.languages">{{lang}} </li>
                                        </ul>
                                      </ng-container>
                                      <!-- end languages display -->
                      
                                    </section>
                      
                                    <!--education-->
                                    <section class="cv-sidebar-section" *ngIf="profile.education">
                                      <h2 class="cv-section__title">Education
                      
                                      </h2>
                      
                                      <div class="ng-container" *ngFor="let edu of profile?.education;">
                      
                                        <div class="row mb">
                                          <div class="col-md-12">
                                            <p class="cv-section__role">{{edu.degree_name}}</p>
                                            <p class="cv-section__description">{{displayExperienceDurationDateRange(edu)}} 
                                              
                                              {{displayExperienceDurationDateRange(edu) ? '|' : ''}} 
                                              
                                              <span
                                                class="university">{{edu.school}}</span></p>
                                            <p class="cv-section__description">{{edu.field_of_study}}</p>
                                          </div>
                                        </div>
                        
                                      </div>
                      
                                      
                      
                                    </section>
                      
                                    <!-- soft skills -->
                                    <section class="cv-sidebar-section">
                                      <h2 class="cv-section__title">Soft skills
                      
                                      </h2>
                                      <ul class="lang-list">
                                        <li>Contrary </li>
                                        <li>popular belief</li>
                                        <li>belief</li>
                                      </ul>
                                    </section>
                      
                                  </aside>
                                </div>
                                <!-- end experience section -->
                      
                              </form>
                            </div>
                          </div>
                      
                        </div>
                      </section>
                      

                </div>
            </div>
            <!-- end resume section-->

        </div>
    </div>
</ng-container>