<div id="content" #content class="{{message === 'HAS_PARENT' ? 'container-with-parent' : 'container' }} mb-5">
    <div class="resume-wrapper">
        <div class="row">
            <div class="col-12">
                <p class="fw-bold fs-1 mb-0 custom_blue_text">{{resumeDetails?. first_name}} {{resumeDetails?. last_name}}</p>
                <p class="fw-bold fs-3 custom_blue_text">{{resumeDetails?.title}}</p>
                <p class="fs-6">
                    <!-- Phone -->
                    <span class="fw-bold custom_blue_text">Phone: </span>
                    <span class="custom_gray_text">{{resumeDetails?.contact.phone
                        &&resumeDetails?.contact.phone.length
                        > 0 ?
                        resumeDetails?.contact.phone[0] : 'None Indicated'}}
                    </span>

                    <!-- Email -->
                    <span class="fw-bold custom_blue_text">Email: </span>
                    <span class="text-primary custom_gray_text text-secondary col-12">
                        {{resumeDetails?.contact.email && resumeDetails?.contact.email.length > 0 ? resumeDetails?.contact.email[0] : 'Not Indicated'}}
                    </span>

                    <!-- Twitter -->
                    <span class="fw-bold custom_blue_text">Twitter: </span> 
                    <span class="text-primary text-secondary custom_gray_text">{{resumeDetails?.socials.twitter?resumeDetails?.socials.twitter :
                        'Not Indicated'}}</span>, 

                    <!-- Linkedin -->
                    <span class="fw-bold custom_blue_text">Linkedin: </span>
                    <a  href="https://www.linkedin.com/in/{{resumeDetails?.vanity_name}}/"target="_blank" class="text-primary custom_gray_text text-secondary">
                        {{resumeDetails?.vanity_name}}</a>
                    </p>
                    </div>
              

            <!-- Summery section start-->
            <div class="col-12 text_justify">
                <p class="section-desc custom_gray_text" [innerHTML]="resumeDetails?.about"></p>
            </div>
           <!-- Summery section end-->

            <!-- Education section start -->
            <div class="col-12" *ngIf="resumeDetails?.visibility?.education === true && resumeDetails?.education && resumeDetails?.education?.length > 0">
                
                <div class="py-1.5 mt-1.5 fs-3 fw-bold custom_blue_text">Education</div>
                

                <div class="mt-2 " *ngFor="let edu of resumeDetails?.education">
                    <div class="gx-5 justify-content-between align-items-center">
                        <div class="fw-bold fs-6 mr-auto">{{edu.school}}</div>
                        <div class="fw-light fst-italic fs-6 custom_gray_text">{{displayExperienceDurationDateRange(edu)}}
                            {{displayExperienceDurationDateRange(edu) ? '' : ''}}</div>
                    </div>
                    <p class="fw-bold custom_gray_text fs-6">{{edu.degree_name}}:| {{edu.field_of_study}}</p>
                </div>
            </div>
            <!-- Education section end -->

            <!-- Experience section start-->
            <div class="col-12" *ngIf="resumeDetails?.visibility?.experience === true && resumeDetails?.experience && resumeDetails?.experience?.length > 0">
                
                <div class="py-1.5  mt-1.5 fs-3 fw-bold custom_blue_text">Experience</div>

                <ng-container *ngFor="let exp of resumeDetails?.experience">
                <div class="mt-2 ">
                    <div class="gx-3 justify-content-between">
                        <div class="col-md-12">
                            <div class="justify-content-between">
                                <div class="fw-bold fs-6 mr-auto">{{exp?.company}} | {{exp?.title}}, {{exp.location ? '(' +
                                    exp.location + ')' : ''}}</div>
                                <div class="fw-light custom_gray_text fst-italic">{{displayExperienceDurationDateRange(exp)}}</div>
                            </div>
                            <p [innerHTML]="exp?.description" class="text_justify"></p>
                        </div>
                    </div>
                </div>
                </ng-container>
            </div>
            <!-- Experience section end-->

            <!-- Skill section start-->
            <div class="col-12" *ngIf="resumeDetails?.visibility?.skills === true && resumeDetails?.skills && resumeDetails?.skills.length > 0">

                    <div class="py-1.5  mt-1.5 fs-3 fw-bold custom_blue_text">Skill</div>

                 <ul class="mt-2  list-unstyled custom_gray_text">
                    <li *ngFor="let skills of resumeDetails?.skills">
                        {{skills}}
                    </li>
                </ul>
            </div>
            <!-- Skill section end-->

            <!-- Languages section start -->
            <div class="col-12" *ngIf="resumeDetails?.languages && resumeDetails?.visibility?.languages === true">

                <div class="py-1.5  mt-1.5 fs-3 fw-bold custom_blue_text">Language</div>


                 <ul class="mt-2  list-unstyled custom_gray_text">
                    <li *ngFor="let lang of resumeDetails?.languages">
                        {{lang}}
                    </li>
                </ul>
            </div>
            <!-- Languages section end -->

            <!-- Projects section start -->
            <div class="col-12" *ngIf="resumeDetails?.visibility?.projects === true && resumeDetails?.accomplishments.projects.length> 0">

                <div class="py-1.5  mt-1.5 fs-3 fw-bold custom_blue_text">Project</div>


                <div class="mt-2 " *ngFor="let proj of resumeDetails?.accomplishments.projects">
                    <div class="gx-5 justify-content-between">
                        <div class="fw-bold mr-auto"><span>{{proj.title}}</span></div>
                        <div class="fw-light fst-italic custom_gray_text">{{displayprojectDuration(proj)}}</div>
                    </div>
                </div>
            </div>
            <!-- Projects section end -->

            <!-- Publication section start -->
            <div class="col-12" *ngIf="resumeDetails?.visibility?.publications === true && resumeDetails?.accomplishments.publications.length > 0">

                <div class="py-1.5  mt-1.5 fs-3 fw-bold custom_blue_text">Publication</div>

                <div class="mt-2 " *ngFor="let pub of resumeDetails?.accomplishments.publications">
                    <div class="fw-bold"><span>{{pub.name}}</span>| <a href={{pub.url}} class="text-primary text-secondary">(url)</a></div>
                </div>
            </div>
            <!-- Publication section end -->

            <!-- Courses section start -->
            <div class="col-12" *ngIf="resumeDetails?.visibility?.courses === true && resumeDetails?.accomplishments.courses.length> 0">

                <div class="py-1.5  mt-1.5 fs-3 fw-bold custom_blue_text">Courses</div>

                <ul class="mt-2  list-unstyled" *ngFor="let course of resumeDetails?.accomplishments.courses">
                    <li><span class="fw-bold">{{course.name}}</span><span class="custom_gray_text">: {{course.number}}</span></li>
                </ul>
            </div>
            <!-- Courses section end -->

            <!-- Certification section start -->
            <div class="col-12" *ngIf="resumeDetails?.visibility?.certifications === true && resumeDetails?.certifications && resumeDetails?.certifications?.length > 0">

                <div class="py-1.5  mt-1.5 fs-3 fw-bold custom_blue_text">Certification</div>

                <ul class="mt-2  list-unstyled" *ngFor="let courses of resumeDetails?.certifications">
                    <li class="fw-bold custom_gray_text">{{courses}}</li>
                </ul>
            </div>
            <!-- Certification section end -->
        </div>
</div>
</div>