<!-- <div class="row">
   
      <div class="col-md-3 mb-4">
        <button type="submit" class="btn btn-primary mt-4 pull-right btn-download" (click)="downloadDocx('content','test')">Download Doc <i class="fa fa-download" aria-hidden="true"></i></button>
      </div>
    
</div> -->

<div #iframeContainer></div>

<!-- <button (click)="captureScreenPreview()">Print Preview</button>
    <button (click)="removeIframe()">Remove</button>
    <button (click)="toggleTemplateSide()" *ngIf="!previewVisibleChild2">Show Preview</button> -->

<div [hidden]="hidden">

  <div class="cv page-break {{message === 'HAS_PARENT' ? 'container-with-parent' : 'container' }}" id="content" #content
    style="page-break-after: always;margin-bottom: 20px;">


    <div class="row">
      <div class="col-md-12">
        <h1 class="user-name" style="color: #3474d1;">{{resumeDetails?.first_name}} {{resumeDetails?.last_name}}</h1>
      </div>
      <div class="col-md-12">
        <h4 class="title">{{resumeDetails?.title}}</h4>
      </div>
    </div>
    <!-- start contacts section -->
    <div class="row pb-4 align-items-center">
      <div class="col-auto">
        <div class="row">
          <div class="col-auto">
            <span class="contact2 email">
              <img src="assets/img/email_icon.png" class="img-fa " />
              {{
              resumeDetails?.contact.email && resumeDetails?.contact.email.length > 0 ?
              resumeDetails?.contact.email[0] : 'Not Indicated'
              }}
            </span>
          </div>
          <div class="col-auto" >
            <span class="contact2"> <img src="assets/img/linkdin_icon.png" class="img-fa linkdin" />
              <a href="https://www.linkedin.com/in/{{resumeDetails?.vanity_name}}/" target="_blank">
                {{resumeDetails?.vanity_name}}
              </a>
            </span>
          </div>
          <div class="col-auto" >
            <span class="contact2"><img src="assets/img/twitter.png" class="img-fa " /> {{
              resumeDetails?.socials.twitter?
              resumeDetails?.socials.twitter : 'Not Indicated'
              }}</span>
          </div>
          <div class="col-auto" >
            <span class="contact2"><img src="assets/img/phone_icon.png" class="img-fa linkdin" />
              {{
              resumeDetails?.contact.phone &&resumeDetails?.contact.phone.length > 0 ?
              resumeDetails?.contact.phone[0] : 'None Indicated'
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- end contacts section -->
    <!-- start about section -->
    <div class="row mb-3">
      <!-- start about display -->
      <div class="cv-about" [innerHTML]="resumeDetails?.about"></div>
      <!-- end about display -->
    </div>
    <!-- end about section -->
    <!-- start experience section -->
    <div class="row mt-4 resume-data">
      <div class="col-md-9">
        <!-- experience -->
        <section class="cv-section">
          <div class="row align-items-center" *ngIf="resumeDetails?.experience && resumeDetails?.visibility?.experience === true">
            <div class="col-md-6">
              <div class="row align-items-center">
                <div class="col-auto">
                  <h2 class="cv-section__title">Experience
                  </h2>
                </div>
                <div class="col">
                </div>
              </div>
            </div>
            <div class="col-md-6">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ng-container *ngIf="
              resumeDetails?.experience && 
              resumeDetails?.visibility?.experience === true
              ">
              <div class="cv-section__wrapper" id="experience" *ngFor="let exp of resumeDetails?.experience">
                <div class="cv-section__experience">
                  <h4 class="cv-section__role">{{exp?.company}}
                    {{exp.location ? '(' + exp.location + ')' : ''}}
                  </h4>
                  <p *ngIf="exp?.company_linkedin_profile_url" class="mb-2 link">
                    <a href="{{exp?.company_linkedin_profile_url}}" target="_blank" class="cv-section__comp">
                      {{exp?.company_linkedin_profile_url}} </a>
                  </p>
                  <p class="cv-section__experience_title">{{exp?.title}}</p>
                  <p class="cv-section__period">
                    {{displayExperienceDurationDateRange(exp)}}
                  </p>
                  <div class="cv-about" [innerHTML]="exp?.description"></div>

                </div>
              </div>
            </ng-container>
              <div class="row mt-2" *ngIf="
              resumeDetails?.certifications && 
              resumeDetails?.visibility?.certifications === true
              ">
                <div class="col-md-6">
                  <h2 class="cv-section__title" *ngIf="resumeDetails?.certifications.length > 0">Certifications
                  </h2>
                </div>
                <div class="col-md-12">
                  <div class="cv-section__wrapper">
                    <!-- start courses / certifications display -->
                    <ul *ngIf="resumeDetails?.certifications" class="cv-section__period pl-1">
                      <!-- <li *ngFor="let courses of profile.certifications">{{courses}}</li> -->
                      <li *ngFor="let courses of resumeDetails?.certifications">{{courses}}</li>
                    </ul>
                    <div *ngIf="!resumeDetails?.certifications">
                      (None Specified)
                    </div>
                    <!-- end courses / certifications display -->
                  </div>
                </div>
              </div>
              <div class="row mt-3" *ngIf="resumeDetails?.accomplishments.publications && resumeDetails?.visibility.publications === true">
                <div class="col-md-6">
                  <h2 class="cv-section__title" *ngIf="resumeDetails?.accomplishments.publications.length > 0">
                    Publications
                  </h2>
                </div>
              </div>
              <div class="cv-section__wrapper" *ngIf="resumeDetails?.accomplishments.publications && resumeDetails?.visibility.publications === true">
                <!-- start publication display -->
                <ul *ngIf="resumeDetails?.accomplishments.publications" class="pl-1">
                  <li *ngFor="let pub of resumeDetails?.accomplishments.publications">
                    <h4 class="cv-section__period">{{pub?.name}}</h4>
                    <p class="pub-url mb-1">
                      {{pub.url}}
                    </p>
                  </li>
                </ul>
                <div *ngIf="!resumeDetails?.accomplishments.publications">
                  (None Specified)
                </div>
                <!-- end publications display -->
              </div>
            </div>

          </div>
          <!-- certifications -->
        </section>
        <!--publications-->
        <section>

        </section>
      </div>
      <!-- sidebar -->
      <aside class="col-md-3 cv-sidebar">
        <!-- skills -->
        <section class="cv-sidebar-section" *ngIf="resumeDetails?.skills && resumeDetails?.visibility?.skills === true">
          <div class="row align-items-center">
            <div class="col-md-6">
              <h2 class="cv-section__title">Skills
              </h2>
            </div>
          </div>
          <!-- start skills display -->
          <ul *ngIf="resumeDetails?.skills && resumeDetails?.skills.length > 0" class="lang-list pl-1">
            <li *ngFor="let skills of resumeDetails?.skills" style="font-weight: 600;">
              {{skills}}
            </li>
          </ul>
          <div *ngIf="resumeDetails?.skills && resumeDetails?.skills.length === 0">
            (Not Indicated)
          </div>
          <!-- end skills display -->
        </section>
        <!-- languages -->
        <section class="cv-sidebar-section" *ngIf="resumeDetails?.languages && resumeDetails?.visibility?.languages === true">
          <div class="row align-items-center">
            <div class="col-md-6">
              <h2 class="cv-section__title">Languages</h2>
            </div>
          </div>
          <!-- start languages display -->
          <ul *ngIf="resumeDetails?.languages && resumeDetails?.languages.length > 0" class="lang-list pl-1">
            <li *ngFor="let lang of resumeDetails?.languages">{{lang}} </li>
          </ul>
          <div *ngIf="resumeDetails?.languages && resumeDetails?.languages.length === 0">
            (Not Indicated)
          </div>
          <!-- end languages display -->
        </section>
        <!--education-->
        <section class="cv-sidebar-section" *ngIf="resumeDetails?.education && resumeDetails?.visibility?.education === true">
          <h2 class="cv-section__title">Education
          </h2>
          <div class="ng-container" *ngFor="let edu of resumeDetails?.education;">
            <div class="row mb">
              <div class="col-md-12">
                <p class="cv-section__role">{{edu.degree_name}}</p>
                <p class="cv-section__description">{{displayExperienceDurationDateRange(edu)}}
                  {{displayExperienceDurationDateRange(edu) ? '|' : ''}}

                </p>
                <p class="mb-1"> <span class="university">{{edu.school}}</span></p>
                <p class="cv-section__description">{{edu.field_of_study}}</p>
              </div>
            </div>
          </div>
        </section>
        <!--courses-->

        <section class="cv-sidebar-section" *ngIf="resumeDetails?.accomplishments.courses && resumeDetails?.visibility.courses === true">
          <h2 class="cv-section__title" *ngIf="resumeDetails?.accomplishments.courses.length> 0">Courses
          </h2>
          <div class="ng-container" *ngFor="let course of resumeDetails?.accomplishments.courses.slice(0,7);;">
            <div class="row mb">
              <div class="col-md-12">
                <p class="cv-section__description">{{course?.name}}</p>
                <p class="cv-section__description">
                  <span class="university">{{course?.number}}</span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <!--projects-->
        <section class="cv-sidebar-section" *ngIf="resumeDetails?.accomplishments.projects && resumeDetails?.visibility.projects === true">
          <h2 class="cv-section__title" *ngIf="resumeDetails?.accomplishments.projects.length> 0">Projects
          </h2>
          <div class="ng-container" *ngFor="let proj of resumeDetails?.accomplishments.projects">
            <div class="row mb">
              <div class="col-md-12">
                <p class="cv-section__description">{{proj.title}}</p>
                <p class="cv-section__description"> <span class="university">
                    {{displayprojectDuration(proj)}}</span>
                </p>

              </div>
            </div>
          </div>
        </section>
      </aside>
    </div>
    <!-- end experience section -->
  </div>
</div>
<!-- 2nd page canvas -->