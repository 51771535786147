<div id="content" #content class="{{message === 'HAS_PARENT' ? 'container-with-parent' : 'container' }} mb-5">
    <div class="resume-wrapper">
        <div class="top-section">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="emp-name">{{resumeDetails?.first_name}} {{resumeDetails?.last_name}}</h2>
                    <h4 class="emp-title">{{resumeDetails?.title}}</h4>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-md-9 pr-0">
                <div class="bottom-section">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="text-14" [innerHTML]="resumeDetails?.about"></p>
                        </div>
                    </div>
                    <div class="row mt-3 mb-4" *ngIf="resumeDetails?.visibility?.experience === true && resumeDetails?.experience && resumeDetails?.experience?.length > 0" >
                        <div class="col-md-12">
                            <h4 class="title-blue">Work History</h4>
                            <hr class="mt-0">
                            <div class="row mt-2">
                                <ng-container *ngFor="let exp of resumeDetails?.experience">
                                    <div class="col-md-3">
                                        <p class="text-14 mt-1">
                                            {{displayExperienceDurationDateRange(exp)}}
                                        </p>
                                    </div>
                                    <div class="col-md-9">
                                        <p class="mb-0 exp-title"><b>{{exp?.title}}</b></p>
                                        <p class="comp-name mb-1"><i>{{exp?.company}} {{exp.location ? '(' +
                                                exp.location + ')' : ''}}</i></p>
                                        <p class="text-14" [innerHTML]="exp?.description"></p>
                                    </div>
                                </ng-container>

                            </div>
                        </div>

                    </div>
                    <div class="row mt-3 mb-4" *ngIf="resumeDetails?.visibility?.courses === true && resumeDetails?.accomplishments.courses.length> 0">
                        <div class="col-md-12">
                            <h4 class="title-blue">Courses</h4>
                            <hr class="mt-0 mb-2">
                            <ng-container *ngFor="let course of resumeDetails?.accomplishments.courses">
                                <h5 class="mb-0 work-title"><b>{{course.name}}</b></h5>
                                <p class="comp-name mb-1"><i>{{course.number}}</i></p>
                            </ng-container>

                        </div>
                    </div>
                    <div class="row mt-3 mb-4" *ngIf="resumeDetails?.visibility?.certifications === true && resumeDetails?.certifications && resumeDetails?.certifications?.length > 0">
                        <div class="col-md-12">
                            <h4 class="title-blue">Certificates</h4>
                            <hr class="mt-0 mb-2">
                            <ng-container *ngFor="let courses of resumeDetails?.certifications">
                                <h5 class="mb-0 work-title"><b>{{courses}}</b></h5>
                            </ng-container>
                        </div>
                    </div>
                    <div class="row mt-3 mb-4" *ngIf="resumeDetails?.visibility?.publications === true && resumeDetails?.accomplishments.publications.length > 0">
                        <div class="col-md-12">
                            <h4 class="title-blue">Publications</h4>
                            <hr class="mt-0 mb-2">
                            <ng-container *ngFor="let pub of resumeDetails?.accomplishments.publications">
                                <h5 class="mb-0 work-title"><b>{{pub.name}}</b></h5>
                                <p class="comp-name mb-1"><i>{{pub.url}}</i></p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="right-section">
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <h4 class="title-blue">Personal Info</h4>
                            <hr class="mt-0">
                            <ul class="pl-0">
                                <li class="education">
                                    <p class="mb-1 contact-info"><b>Email:</b></p>
                                    <p class="contact-info"> {{resumeDetails?.contact.email &&
                                        resumeDetails?.contact.email.length > 0 ?
                                        resumeDetails?.contact.email[0] : 'Not Indicated'}}</p>
                                </li >
                                <li class="education">
                                    <p class="mb-1 contact-info"><b>Phone:</b></p>
                                    <p class="contact-info">{{resumeDetails?.contact.phone
                                        &&resumeDetails?.contact.phone.length
                                        > 0 ?
                                        resumeDetails?.contact.phone[0] : 'None Indicated'}}</p>
                                </li>
                                <li class="education">

                                    <p class="mb-1 contact-info"><b>LinkedIn:</b></p>
                                    <p class="contact-info"> <a
                                            href="https://www.linkedin.com/in/{{resumeDetails?.vanity_name}}/"
                                            target="_blank">
                                            {{resumeDetails?.vanity_name}}
                                        </a></p>
                                </li>
                                <li class="education">
                                    <p class="mb-1 contact-info"><b>Twitter:</b></p>
                                    <p class="contact-info">
                                        {{resumeDetails?.socials.twitter?resumeDetails?.socials.twitter :
                                        'Not Indicated'}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mb-2" *ngIf="resumeDetails?.visibility?.education === true && resumeDetails?.education && resumeDetails?.education?.length > 0">
                        <div class="col-md-12">
                            <h4 class="title-blue">Education</h4>
                            <hr class="mt-0">
                            <ul class="pl-0 mt-2">
                                <li class="education" *ngFor="let edu of resumeDetails?.education">
                                    <h5 class="mb-0 work-title"><b>{{edu.degree_name}} {{edu.field_of_study}}</b></h5>
                                    <p class="comp-name mb-1"><i>{{displayExperienceDurationDateRange(edu)}}
                                            {{displayExperienceDurationDateRange(edu) ? '' : ''}}</i></p>
                                    <p class="text-14 uni-name">{{edu.school}} </p>
                                </li>


                            </ul>
                        </div>
                    </div>
                    <div class="row mb-2" *ngIf="resumeDetails?.visibility?.skills === true && resumeDetails?.skills && resumeDetails?.skills.length > 0">
                        <div class="col-md-12">
                            <h4 class="title-blue">Skills</h4>
                            <hr class="mt-0">
                            <ul class="pl-0 mt-2">
                                <li class="li-14" *ngFor="let skills of resumeDetails?.skills">{{skills}}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mb-2" *ngIf="resumeDetails?.languages && resumeDetails?.visibility?.languages === true">
                        <div class="col-md-12">
                            <h4 class="title-blue">Languages</h4>
                            <hr class="mt-0">
                            <ul class="pl-0 mt-2">
                                <li class="li-14" *ngFor="let lang of resumeDetails?.languages">{{lang}}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mb-2" *ngIf="resumeDetails?.visibility?.projects === true && resumeDetails?.accomplishments.projects.length> 0">
                        <div class="col-md-12">
                            <h4 class="title-blue">Projects</h4>
                            <hr class="mt-0">
                            <ul class="pl-0 mt-2">
                                <li class="education" *ngFor="let proj of resumeDetails?.accomplishments.projects">
                                    <h5 class="mb-0 work-title"><b>{{proj.title}}</b></h5>                              
                                    <p class="text-14 uni-name">{{displayprojectDuration(proj)}}</p>
                                </li>


                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>