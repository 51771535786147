<div id="content" #content class="{{message === 'HAS_PARENT' ? 'container-with-parent' : 'container' }} mb-5">
    <div class="resume-wrapper">
        <div class="row">
            <div class="col-md-4">
                <div class="left_Side">
                    <div class="profileText">

                        <h2>{{resumeDetails?.first_name}} {{resumeDetails?.last_name}} </h2>
                        <p class="profile-title">{{resumeDetails?.title}}</p>
                    </div>

                    <div class="contactInfo">
                        <h4 class="title">Contact Info</h4>
                        <ul class="pl-0">
                            <li *ngIf="resumeDetails?.visibility?.contactno == true">

                                <span class="text"> <span class="text-blue">Phone:</span> {{
                                    resumeDetails?.contact.phone &&resumeDetails?.contact.phone.length > 0 ?
                                    resumeDetails?.contact.phone[0] : 'None Indicated'
                                    }}</span>
                            </li>
                            <li *ngIf="resumeDetails?.visibility?.email == true">

                                <span class="text" style="word-break: break-all;"><span class="text-blue">Email:</span> {{
                                    resumeDetails?.contact.email && resumeDetails?.contact.email.length > 0 ?
                                    resumeDetails?.contact.email[0] : 'Not Indicated'
                                    }}</span>
                            </li>

                            <li *ngIf="resumeDetails?.visibility?.linkedin == true">

                                <span class="text"><span class="text-blue">LinkedIn: </span> <a
                                        href="https://www.linkedin.com/in/{{resumeDetails?.vanity_name}}/"
                                        target="_blank">
                                        {{resumeDetails?.vanity_name}}
                                    </a></span>
                            </li>
                            <li *ngIf="resumeDetails?.visibility?.twitter == true">

                                <span class="text"><span class="text-blue">Twitter: </span>{{
                                    resumeDetails?.socials.twitter?
                                    resumeDetails?.socials.twitter : 'Not Indicated'
                                    }}</span>
                            </li>

                        </ul>
                    </div>
                    <div class="contactInfo education" *ngIf="resumeDetails?.visibility?.education === true && resumeDetails?.education && resumeDetails?.education?.length > 0
                        ">
                        <h4 class="title2">Education</h4>
                        <ul *ngIf="resumeDetails?.education" class="pl-0">
                            <li *ngFor="let edu of resumeDetails?.education;">
                                <h5 class="duration">{{displayExperienceDurationDateRange(edu)}}
                                    {{displayExperienceDurationDateRange(edu) ? '' : ''}}</h5>
                                <h4>{{edu.degree_name}} in {{edu.field_of_study}}</h4>
                                <h4 class="uni-name">{{edu.school}}</h4>
                            </li>

                        </ul>
                    </div>
                    <div class="contactInfo education" *ngIf="resumeDetails?.languages && resumeDetails?.visibility?.languages === true">
                        <h4 class="title2">Languages</h4>
                        <ul class="pl-0">
                            <ng-container *ngIf="resumeDetails?.languages && resumeDetails?.languages.length > 0">
                                <li *ngFor="let lang of resumeDetails?.languages">
                                    <span class="text">{{lang}}</span>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="!resumeDetails?.languages && resumeDetails?.languages.length == 0">
                                <li>
                                    <span class="text">(Not Indicated)</span>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    <div class="contactInfo education" *ngIf="resumeDetails?.visibility?.courses === true && resumeDetails?.accomplishments.courses.length> 0
                        ">
                        <h4 class="title">Courses</h4>
                        <ul>
                            <li *ngFor="let course of resumeDetails?.accomplishments.courses">
                                <h4 class="course-name ">{{course.name}}</h4>
                                <h4 class="uni-name">{{course.number}}</h4>
                            </li>

                        </ul>
                    </div>
                    <div class="contactInfo education" *ngIf="resumeDetails?.visibility?.projects === true && resumeDetails?.accomplishments.projects.length> 0
                        ">
                        <h4 class="title2">Projects</h4>
                        <ul>
                            <li *ngFor="let proj of resumeDetails?.accomplishments.projects">
                                <h4 class="course-name mt-4">{{proj.title}}</h4>
                                <h4 class="uni-name">{{displayprojectDuration(proj)}}</h4>
                            </li>

                        </ul>
                    </div>
                    <div class="contactInfo education" *ngIf="resumeDetails?.visibility?.skills === true && resumeDetails?.skills && resumeDetails?.skills.length > 0">
                        <h4 class="title2">Skills</h4>
                        <ul>
                            <li *ngFor="let skills of resumeDetails?.skills">
                                <h4 class="course-name mt-4">{{skills}}</h4>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="right_Side">
                    <div class="about">
                        <h2 class="title2">Profile</h2>
                        <p [innerHTML]="resumeDetails?.about" class="about-desc"></p>
                    </div>
                    <div class="about" *ngIf="resumeDetails?.visibility?.experience === true && resumeDetails?.experience && resumeDetails?.experience?.length > 0">
                        <h2 class="title2">Experience</h2>
                        <div class="box" *ngFor="let exp of resumeDetails?.experience">
                            <div class="year_company text mr-1">
                                <h4>{{displayExperienceDurationDateRange(exp)}}</h4>
                                <h4>{{exp?.title}}</h4>
                            </div>
                            <div class="text">
                                <p class="company">{{exp?.company}}
                                    {{exp.location ? '(' + exp.location + ')' : ''}}</p>
                                <p style="font-weight: 400;" class="exp-desc" [innerHTML]="exp?.description"></p>
                            </div>
                        </div>

                    </div>
                    <div class="about skills" *ngIf="resumeDetails?.visibility?.certifications === true && resumeDetails?.certifications && resumeDetails?.certifications?.length > 0">
                        <h2 class="title2">Certifications</h2>
                        <div class="box" *ngFor="let courses of resumeDetails?.certifications">
                            <div class="text">
                                <p class="course">{{courses}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="about skills" *ngIf="resumeDetails?.visibility?.publications === true && resumeDetails?.accomplishments.publications.length > 0">
                        <h2 class="title2">Publications</h2>
                        <div class="box" *ngFor="let pub of resumeDetails?.accomplishments.publications">
                            <div class="text">
                                <p class="course mb-1">{{pub.name}}</p>
                                <p class="mb-0"><a href="">{{pub.url}}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>