<div id="content" #content class="{{message === 'HAS_PARENT' ? 'container-with-parent' : 'container' }} mb-5">
    <div class="resume-wrapper">
        <div class="row">
            <div class="col-md-8">
                <h2 class="emp-name" style="font-weight:700;">{{resumeDetails?.first_name}} {{resumeDetails?.last_name}}
                </h2>
                <h4 class="emp-title" style="font-weight:500;">{{resumeDetails?.title}}</h4>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-10">
                <p class="text-10" [innerHTML]="resumeDetails?.about"></p>
            </div>
            <div class="col-md-2">
                <ul class="pl-0">
                    <li class="contact">
                        <p class="mb-0 contact-info"><b>Email :</b></p>
                        <p class="mb-2 "><b> {{resumeDetails?.contact.email &&
                                resumeDetails?.contact.email.length > 0 ?
                                resumeDetails?.contact.email[0] : 'Not Indicated'}}</b></p>
                    </li>
                    <li class="contact">
                        <p class="mb-0 contact-info"><b>Phone :</b></p>
                        <p class="mb-2"><b>{{resumeDetails?.contact.phone
                                &&resumeDetails?.contact.phone.length
                                > 0 ?
                                resumeDetails?.contact.phone[0] : 'None Indicated'}}</b></p>
                    </li>
                    <li class="contact">

                        <p class="mb-0 contact-info"><b>LinkedIn :</b></p>
                        <p class="mb-2"><b> <a href="https://www.linkedin.com/in/{{resumeDetails?.vanity_name}}/"
                                    target="_blank">
                                    {{resumeDetails?.vanity_name}}
                                </a></b></p>
                    </li>
                    <li class="contact">
                        <p class="mb-0 contact-info"><b>Twitter :</b></p>
                        <p class="mb-2"><b>
                                {{resumeDetails?.socials.twitter?resumeDetails?.socials.twitter :
                                'Not Indicated'}}</b></p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9">
                <div class="row" *ngIf="resumeDetails?.visibility?.experience === true && resumeDetails?.experience && resumeDetails?.experience?.length > 0">
                    <div class="col-md-12">
                        <h4 class="section-title mb-2" style="font-weight:500;">Work experience</h4>
                        <div class="exp-list pl-0">
                            <li *ngFor="let exp of resumeDetails?.experience">
                                <h4 class="exp-title mb-1 mt-2" style="font-weight:700;">{{exp?.title}}</h4>
                                <h4 class="exp-period">{{exp?.company}} {{exp.location ? '(' + exp.location + ')' :
                                    ''}},
                                    ({{displayExperienceDurationDateRange(exp)}})</h4>
                                <p class="exp-desc" [innerHTML]="exp?.description"></p>
                            </li>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="resumeDetails?.visibility?.projects === true && resumeDetails?.accomplishments.projects.length> 0">
                    <div class="col-md-12">
                        <h4 class="section-title mb-2 mt-0">Projects</h4>
                        <ng-container *ngFor="let proj of resumeDetails?.accomplishments.projects">
                            <h4 class="exp-title mb-1 mt-2">{{proj.title}}</h4>
                            <h4 class="exp-period">{{displayprojectDuration(proj)}}</h4>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="row" *ngIf="resumeDetails?.visibility?.education === true && resumeDetails?.education && resumeDetails?.education?.length > 0">
                    <div class="col-md-12">
                        <h4 class="section-title mb-2">Education</h4>
                        <ng-container *ngFor="let edu of resumeDetails?.education;">
                            <h4 class="exp-title mb-1 mt-2">{{edu.degree_name}} {{edu.field_of_study}}</h4>
                            <h4 class="exp-period">{{edu.school}} {{displayExperienceDurationDateRange(edu)}}
                                {{displayExperienceDurationDateRange(edu) ? '' : ''}}</h4>
                        </ng-container>
                    </div>
                </div>
                <div class="row" *ngIf="resumeDetails?.visibility?.skills === true && resumeDetails?.skills && resumeDetails?.skills.length > 0">
                    <div class="col-md-12">
                        <h4 class="section-title mb-2 mt-3">Skills</h4>
                        <p><span *ngFor="let skills of resumeDetails?.skills" class="skill-list">{{skills}}, </span></p>
                    </div>
                </div>
                <div class="row" *ngIf="resumeDetails?.visibility?.courses === true && resumeDetails?.accomplishments.courses.length> 0">
                    <div class="col-md-12">
                        <h4 class="section-title mb-2 mt-3">Courses</h4>
                        <ng-container *ngFor="let course of resumeDetails?.accomplishments.courses">
                            <h4 class="exp-title mb-1 mt-2">{{course.name}}</h4>
                            <h4 class="exp-period">{{course.number}}</h4>
                        </ng-container>
                    </div>
                </div>
                <div class="row" *ngIf="resumeDetails?.visibility?.certifications === true && resumeDetails?.certifications && resumeDetails?.certifications?.length > 0">
                    <div class="col-md-12">
                        <h4 class="section-title mb-2 mt-3">Certifications</h4>
                        <ng-container *ngFor="let courses of resumeDetails?.certifications">
                            <h4 class="exp-title mb-1 mt-2">{{courses}}</h4>
                        </ng-container>
                    </div>
                </div>
                <div class="row" *ngIf="resumeDetails?.visibility?.publications === true && resumeDetails?.accomplishments.publications.length > 0">
                    <div class="col-md-12">
                        <h4 class="section-title mb-2 mt-3">Publications</h4>
                        <ng-container *ngFor="let pub of resumeDetails?.accomplishments.publications">
                            <h4 class="exp-title mb-1 mt-2">{{pub.name}}</h4>
                            <h4 class="url"><i>{{pub.url}}</i></h4>
                        </ng-container>
                    </div>
                </div>
                <div class="row" *ngIf="resumeDetails?.languages && resumeDetails?.visibility?.languages === true">
                    <div class="col-md-12">
                        <h4 class="section-title mb-2 mt-3">Languages</h4>
                        <p *ngFor="let lang of resumeDetails?.languages">{{lang}}</p>
                        <div *ngIf="resumeDetails?.languages && resumeDetails?.languages.length === 0">
                            (Not Indicated)
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>