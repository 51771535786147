<section>

  <div *ngIf="loading" class="container">
    <div class="col-md-12 justify-content-center d-flex mt-5">
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>


  <div *ngIf="!loading" class="{{message === undefined ? 'container' : ''}}">
    <!-- <div class="row justify-content-end ">
      <div class="col-md-3 text-end">
        <p-dropdown class="custom-dropdown" [options]="resumeListng" [(ngModel)]="selectedResume" 
        optionLabel="name" [showClear]="true" placeholder="Select a Resume" (onChange)="selectResume($event)">
        </p-dropdown>
        
      </div>
    </div> -->


    <div class="row">

      <div class="col-md-12 justify-content-center d-flex {{message === undefined ? 'mt-5' : 'mt-5 mb-adjustment'}}">
        <span class="prewview-header">
          Resume: {{title ? title : 'Default'}}</span>
      </div>
    </div>

    <div *ngIf="message === undefined" class="row">
      <div class="col-md-12 justify-content-center d-flex mt-2">
        <button [disabled]="updating" (click)="updateProfile(false)" type="submit"
          class="btn btn-success mt-4 mb-2 btn-resume chatgpt">Save
          <div *ngIf="updating" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        <button (click)="navigateTo('/resume-list')" type="button"
          class="btn btn-primary mt-4 pull-right mb-2 btn-resume save">Templates</button>


        <button (click)="gotoJDAlign()" *ngIf="
        
        (userType === 'CLIENT' && appUser && 
        appUser.subscription && appUser.subscription.plan_type === 'PREMIUM'
        && isClientValid())
        || 
        userType === 'RECRUITER'
        " type="button" class="btn btn-primary mt-4 mb-2 btn-resume edit">JD Align</button>

        <button (click)="openSelecTemplateModal()" type="button"
          class="btn btn-primary mt-4 pull-right mb-2 btn-resume save">Templates</button>
        <button (click)="togglePreviewSide()" type="button"
          class="btn btn-primary mt-4 pull-right mb-2 btn-resume save">Hide Preview</button>
        <button (click)="removeTemplate()" type="button"
          class="btn btn-primary mt-4 pull-right mb-2 btn-resume save">Remove Template</button>

      </div>
    </div>


    <!-- start save icon -->
    <div class="{{templateSelected ? 'cv-save-icon-no-maxwidth' : 'cv-save-icon'}} mb-75" id="saveicon" #saveicon>
      <div class="container-fluid">

        <div class="row align-items-center">
          <div class="col-auto">

            <img (click)="updateProfile(false)" *ngIf="!updating" pTooltip="Save Changes"
              tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}" class="custom-link" src="assets/img/save_icon.png"
              alt="Save">

            <div *ngIf="updating" class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>

          </div>

          <div class="col-auto">

            <!-- <span (click)="openSectionsModal()" class="custom-link" pTooltip="Open Sections" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}">
              <i class="fa fa-eye large_icon2 large_icon_color" aria-hidden="true"></i> 
            </span> -->

            <img (click)="openSectionsModal()" style="width: 105%; height: auto" pTooltip="Select Resume Sections"
              tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}" class="custom-link check-circle"
              src="assets/img/check_circle.png" alt="Sections">


          </div>

        </div>





      </div>
    </div>
    <!-- end save icon -->


    <div class="{{templateSelected ? 'cv-no-max-width' : 'cv'}}  mb-5" id="content" #content>
      <div class="container-fluid">

        <form [formGroup]="resumeForm">
          <div class="row" *ngIf="!isEditName">
            <div class="col-md-10">
              <h1 class="user-name">{{profile.first_name}} {{profile.last_name}}</h1>
            </div>
            <div class="col-auto">
              <i *ngIf="!isEditName" (click)="editResume('name')" pTooltip="Edit Title"
                tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}" class="fa fa-pencil-square-o edit-icon-nofloat2 mt-3"
                aria-hidden="true"></i>

            </div>
          </div>
          <div class="row" *ngIf="isEditName">
            <div class="col-md-5">
              <input id="newfname" placeholder="" type="text" class=" mb-3 form-control curved"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="profile.first_name" *ngIf="isEditName">
            </div>
            <div class="col-md-5">
              <input id="newLname" placeholder="" type="text" class=" mb-3 form-control curved"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="profile.last_name" *ngIf="isEditName">
            </div>
            <div class="col-auto">
              <i *ngIf="isEditName" pTooltip="Update About" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                class="fa fa-check edit-icon right-bar mt-3" aria-hidden="true"
                (click)="updateProfile(false, 'name')"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-md-10">
              <h4 class="title" *ngIf="!isEditTitle">{{profile.title}}</h4>
              <input id="newTitle" placeholder="" type="text" class=" mb-3 form-control curved"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="profile.title" *ngIf="isEditTitle">
            </div>
            <div class="col-auto">
              <i *ngIf="!isEditTitle" (click)="editResume('title')" pTooltip="Edit Title"
                tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}" class="fa fa-pencil-square-o edit-icon-nofloat2"
                aria-hidden="true"></i>
              <i *ngIf="isEditTitle" pTooltip="Update About" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                class="fa fa-check edit-icon right-bar" aria-hidden="true" (click)="updateProfile(false, 'title')"></i>
            </div>
          </div>

          <!-- start contacts section -->
          <div class="row pb-4 align-items-center">

            <div class="col-auto">

              <div class="row">
                <div *ngIf="contact && contact.email && contact.email.length > 0 && profile?.visibility?.email == true" class="col-auto">
                  <span class="contact2"><i class="fa fa-envelope" aria-hidden="true"></i>
                    <a href="mailto: {{contact.email[0]}}">{{contact.email[0]}}</a>
                  </span>
                </div>

                <div *ngIf="contact && contact.email && contact.email.length === 0 && profile?.visibility?.email == true" class="col-auto">
                  <span class="contact2"><i class="fa fa-envelope" aria-hidden="true"></i>
                    (Not Indicated)
                  </span>
                </div>

                <div class="col-auto" *ngIf="profile?.visibility?.linkedin == true">
                  <span class="contact2"> <i class="fa fa-linkedin" aria-hidden="true"></i>
                    <a href="https://www.linkedin.com/in/{{profile.vanity_name}}/" target="_blank">
                      {{profile.vanity_name}}
                    </a>
                  </span>
                </div>

                <div class="col-auto" *ngIf="profile?.visibility?.twitter == true">
                  <span class="contact2"><img src="assets/img/twitter.png" class="img-fa " />{{contact.twitter}}</span>
                </div>

                <div class="col-auto" *ngIf="profile?.visibility?.contactno == true">
                  <span class="contact2"><i class="fa fa-phone" aria-hidden="true"></i>
                    {{contact && contact.phone && contact.phone.length > 0 ? contact.phone[0] : '(Not Indicated)'}}
                  </span>
                </div>

              </div>


            </div>

            <div class="col-auto" *ngIf="(profile?.visibility?.email || profile?.visibility?.contactno || profile?.visibility?.linkedin || profile?.visibility?.twitter) == true">
              <i (click)="updateContacts()" pTooltip="Edit Contacts" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                class="fa fa-pencil-square-o edit-icon-nofloat2" aria-hidden="true"></i>
            </div>

          </div>
          <!-- end contacts section -->
          <!-- start about section -->
          <div class="row mb-3">
            <!-- start about display -->
            <div *ngIf="!this.isEditAbout" class="col-10">
              <!-- <span class="cv-about">
                {{profile.about}}
              </span> -->
              <div class="cv-about" [innerHTML]="profile.about"></div>
            </div>
            <!-- end about display -->

            <!-- start about edit -->
            <div *ngIf="this.isEditAbout" class="col-10">
              <div class="form-group">
                <!-- <textarea class="form-control" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="profile.about" id="aboutTextarea" rows="5"></textarea> -->

                <p-editor [ngModelOptions]="{standalone: true}" [(ngModel)]="profile.about"
                  [style]="{'height':'320px'}">
                  <ng-template pTemplate="header">
                    <span class="ql-formats">
                      <!-- <button type="button" class="ql-bold" aria-label="Bold"></button>
                          <button type="button" class="ql-italic" aria-label="Italic"></button>
                          <button type="button" class="ql-underline" aria-label="Underline"></button> -->
                      <button type="button" class="ql-list" value="bullet" aria-label="Bullet"></button>
                    </span>
                  </ng-template>
                </p-editor>

              </div>
            </div>
            <!-- end about edit -->

            <!--start about buttons -->
            <div class="col-2">
              <i *ngIf="!this.isEditAbout" pTooltip="Edit About" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                class="fa fa-pencil-square-o edit-icon right-bar" aria-hidden="true" (click)="editResume('about')"></i>

              <i *ngIf="this.isEditAbout" pTooltip="Update About" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                class="fa fa-check edit-icon right-bar" aria-hidden="true" (click)="updateProfile(false, 'about')"></i>
            </div>
            <!--end about buttons -->

          </div>

          <!-- start about gpt button -->
          <div *ngIf="enableGPT()" class="row mb-3">
            <div class="col-md-12">
              <!-- <button [disabled]="gptGenerating" type="button" class="btn btn-info mt-3"  data-bs-toggle="modal" data-bs-target="#myModal" (click)="showRevisedText('about')">
                Revise with GPT
            </button> -->

              <div class="row align-items-center">

                <div class="col-auto">
                  <button [disabled]="gptGenerating" type="button" class="btn btn-info"
                    (click)="showRevisedText('about')">
                    Revise with GPT
                  </button>
                </div>

                <div class="col-auto">
                  <i pTooltip="Undo" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                    (click)="profile.aboutShowUndo ? aboutUndo() : ''"
                    class="fa fa-undo edit-icon-nofloat2 {{profile.aboutShowUndo ? '' : 'fa-disabled'}}"
                    aria-hidden="true"></i>
                </div>

                <div class="col">
                  <i pTooltip="Redo" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                    (click)="profile.aboutShowRedo ? aboutRedo() : ''"
                    class="fa fa-repeat edit-icon-nofloat2 {{profile.aboutShowRedo ? '' : 'fa-disabled'}}"
                    aria-hidden="true"></i>
                </div>

              </div>

            </div>
          </div>
          <!-- end about gpt button -->
          <!-- end about section -->

          <!-- start experience section -->
          <div class="row mt-4 resume-data">
            <div class="col-md-9">
              <!-- experience -->
              <section class="cv-section" *ngIf="
              profile?.experience && 
              profile?.visibility?.experience === true
              ">
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <h2 class="cv-section__title">Experience
                        </h2>
                      </div>
                      <div class="col">

                        <!-- <i *ngIf="this.isEditAbout"  class="fa fa-times edit-icon-common" aria-hidden="true"
                      (click)="editResume('about')"></i> -->

                        <i pTooltip="Add Experience" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                          (click)="showAddExperience()" class="fa fa-plus edit-icon-nofloat" aria-hidden="true"></i>


                      </div>

                    </div>



                  </div>
                  <div class="col-md-6">
                    <!-- <i *ngIf="!this.isEditexperience" class="fa fa-pencil-square-o edit-icon right-bar"
                      aria-hidden="true" (click)="editResume('experience')"></i>
                    <i *ngIf="this.isEditexperience" class="fa fa-check edit-icon right-bar" aria-hidden="true"
                      (click)="editResume('experience')"></i> -->
                  </div>
                </div>

                <ng-container *ngIf="!this.isEditexperience">

                  <!-- <div class="cv-section__wrapper"> -->
                  <div class="cv-section__wrapper" *ngFor="let exp of profile?.experience">

                    <div class="cv-section__experience">
                      <p class="cv-section__role">{{exp?.company}}
                        {{exp.location ? '(' + exp.location + ')' : ''}}

                        <i pTooltip="Remove Experience" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                          class="fa fa-times edit-icon-nofloat2 pl" aria-hidden="true" (click)="confirm(exp)"></i>

                        <i pTooltip="Edit Experience" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                          class="fa fa-pencil-square-o edit-icon-nofloat2 pl" aria-hidden="true"
                          (click)="editExperience(exp)"></i>

                      </p>

                      <span *ngIf="exp?.company_linkedin_profile_url">
                        <a href="{{exp?.company_linkedin_profile_url}}" target="_blank" class="cv-section__comp">
                          {{exp?.company_linkedin_profile_url}} </a>
                      </span>

                      <p class="cv-section__experience_title">{{exp?.title}}</p>

                      <p class="cv-section__period">
                        {{displayExperienceDurationDateRange(exp)}}
                      </p>

                      <div class="cv-about" [innerHTML]="exp?.description"></div>


                      <ng-container *ngIf="enableGPT()">

                        <div class="row mb-3">
                          <div class="col-md-12">

                            <div class="row mt-3 align-items-center">

                              <div class="col-auto">
                                <button [disabled]="gptGenerating" type="button" class="btn btn-info"
                                  (click)="showRevisedText('experience', exp.expId )">
                                  Revise with GPT
                                </button>
                              </div>

                              <div class="col-auto">
                                <i pTooltip="Undo" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                                  (click)="exp.experienceGPTCache?.expShowUndo ? expUndo(exp) : ''"
                                  class="fa fa-undo edit-icon-nofloat2 {{exp.experienceGPTCache?.expShowUndo ? '' : 'fa-disabled'}}"
                                  aria-hidden="true"></i>
                              </div>

                              <div class="col">
                                <i pTooltip="Redo" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                                  (click)="exp.experienceGPTCache?.expShowRedo ? expRedo(exp) : ''"
                                  class="fa fa-repeat edit-icon-nofloat2 {{exp.experienceGPTCache?.expShowRedo ? '' : 'fa-disabled'}}"
                                  aria-hidden="true"></i>
                              </div>

                            </div>

                          </div>
                        </div>

                      </ng-container>

                      <!-- <ul class="cv-section__description">
                        <li *ngFor="let desc of resumeDetails.Experience.Publications; index as i;">{{desc?.name}}
                        </li>
                      </ul> -->

                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="this.isEditexperience">
                  <div class="row">
                    <div class="col-md-11">

                      <div formArrayName="experienceArray"
                        *ngFor="let experience of experience.controls; let i = index">
                        <div [formGroupName]="i" class="d-flex">
                          <textarea formControlName="experience" class="form-control mb-3 exp-array"
                            placeholder="Add experience"></textarea>
                          <div (click)="removeGroup(i)"><i class="fa fa-times" aria-hidden="true"></i></div>
                        </div>

                      </div>
                      <button type="button" class="btn btn-outline-primary btn-add" (click)="addNewExperience()"><i
                          class="fa fa-plus" aria-hidden="true"></i>
                        Add Experience</button>

                    </div>

                  </div>
                </ng-container>

              </section>
              <!-- experience -->
              <section class="cv-section" *ngIf="resumeDetails.Experience.Projects.length > 0">
                <h2 class="cv-section__title">Projects

                </h2>
                <ng-container>
                  <div class="cv-section__wrapper">

                    <div class="cv-section__experience">
                      <p class="cv-section__role">dfdf</p>
                      <p class="cv-section__period">4545</p>
                      <ul class="cv-section__description">
                        <li>dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                          standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        </li>
                        <li>dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                          standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        </li>
                      </ul>

                    </div>
                  </div>
                </ng-container>

              </section>
              <!-- content -->
              <section class="cv-section" *ngIf="
              profile?.certifications && 
              profile?.visibility?.certifications === true
              ">

                <div class="row align-items-center">
                  <div class="col-auto">
                    <h2 class="cv-section__title">Certifications
                    </h2>
                  </div>

                  <div class="col">
                    <i pTooltip="Add/Edit Certifications" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                      *ngIf="!this.isEditcourses" class="fa fa-pencil-square-o edit-icon-nofloat" aria-hidden="true"
                      (click)="editResume('courses')"></i>
                    <i pTooltip="Update Certifications" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                      *ngIf="this.isEditcourses" class="fa fa-check edit-icon-nofloat" aria-hidden="true"
                      (click)="updateProfile(false, 'certifications')"></i>

                  </div>

                </div>

                <div class="cv-section__wrapper">

                  <!-- start courses / certifications display -->
                  <ng-container *ngIf="!this.isEditcourses">
                    <ul *ngIf="profile.certifications" class="course-list">
                      <!-- <li *ngFor="let courses of profile.certifications">{{courses}}</li> -->
                      <li *ngFor="let courses of certifications.getRawValue()">{{courses.certifications}}</li>

                    </ul>
                    <div *ngIf="!profile.certifications">
                      (None Specified)
                    </div>
                  </ng-container>
                  <!-- end courses / certifications display -->

                  <!-- start courses / certifications edit -->
                  <ng-container *ngIf="this.isEditcourses">
                    <div class="row">
                      <div class="col-md-12">

                        <div formArrayName="certificateArray"
                          *ngFor="let certificates of certifications.controls ; let i = index">
                          <div [formGroupName]="i" class="d-flex">
                            <input formControlName="certifications" class="form-control mb-3 exp-array"
                              placeholder="Add Certifications" />
                            <div (click)="removecertificate(i)"><i pTooltip="Remove Certification"
                                tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}" class="fa fa-times"
                                aria-hidden="true"></i></div>
                          </div>

                        </div>
                        <button type="button" class="btn btn-outline-primary btn-add" (click)="addNewCert()"><i
                            class="fa fa-plus" aria-hidden="true"></i>
                          Add Certifications</button>

                      </div>

                    </div>
                  </ng-container>
                  <!-- end courses / certifications edit -->





                </div>

              </section>

              <!-- start publications -->
              <section class="cv-section" *ngIf="
              profile.visibility && profile.visibility.publications === true">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <h2 class="cv-section__title">Publications
                    </h2>
                  </div>

                  <!-- hide for now until ready to code again the full cycle (edit/add/remove/display) -->
                  <div class="col">
                    <i pTooltip="Add Publication" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                      (click)="showAddPublication()" class="fa fa-plus edit-icon-nofloat" aria-hidden="true"></i>
                  </div>



                </div>


                <div class="cv-section__wrapper">

                  <!-- start publications display -->
                  <ng-container *ngIf="!this.isEditpublications">
                    <ul *ngIf="profile?.accomplishments?.publications" class="course-list">

                      <li *ngFor="let pub of profile?.accomplishments?.publications">
                        {{pub.name}}

                        <i pTooltip="Remove Publication" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                          class="fa fa-times edit-icon-nofloat2 pl" aria-hidden="true"
                          (click)="confirmRemovePublication(pub)"></i>

                        <i pTooltip="Edit Publication" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                          class="fa fa-pencil-square-o edit-icon-nofloat2 pl" aria-hidden="true"
                          (click)="editPublication(pub)"></i>

                      </li>

                    </ul>
                    <div *ngIf="!profile?.accomplishments?.publications">
                      (None Specified)
                    </div>
                  </ng-container>
                  <!-- end publications display -->

                  <!-- start publications edit -->
                  <!-- <ng-container *ngIf="this.isEditpublications">
                    <div class="row">
                      <div class="col-md-12">

                        <div formArrayName="publicationsArray"
                          *ngFor="let pub of publications.controls ; let i = index">
                          <div [formGroupName]="i" class="d-flex">
                            <input formControlName="publications" class="form-control mb-3 exp-array"
                              placeholder="Add Publications" />
                            <div (click)="removepublication(i)"><i pTooltip="Remove Publication" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}" class="fa fa-times" aria-hidden="true"></i></div>
                          </div>

                        </div>
                        <button type="button" class="btn btn-outline-primary btn-add" (click)="addNewPub()"><i
                            class="fa fa-plus" aria-hidden="true"></i>
                          Add Publications</button>

                      </div>

                    </div>
                  </ng-container> -->
                  <!-- end publicationsedit -->





                </div>

              </section>


              <!-- end publications -->




              <section class="cv-section" style="display: none;">
                <h2 class="cv-section__title"><i class="fa fa-user fa-fw"></i>Revised Profile

                </h2>
                <div class="cv-section__wrapper">
                  <p class="cv-section__description">im good too <br><br>

                </div>
              </section>

            </div>

            <!-- sidebar -->
            <aside class="col-md-3 cv-sidebar">


              <!-- skills -->
              <section class="cv-sidebar-section" *ngIf="
              profile?.skills && 
              profile?.visibility?.skills === true
              ">
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <h2 class="cv-section__title">Skills
                    </h2>
                  </div>
                  <div class="col-md-6">
                    <i pTooltip="Edit Skills" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}" *ngIf="!this.isEditskills"
                      class="fa fa-pencil-square-o edit-icon right-bar" aria-hidden="true"
                      (click)="editResume('skills')"></i>
                    <i pTooltip="Update Skills" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}" *ngIf="this.isEditskills"
                      class="fa fa-check edit-icon right-bar" aria-hidden="true"
                      (click)="updateProfile(false, 'skills')"></i>
                  </div>
                </div>

                <!-- start skills display -->
                <ng-container *ngIf="!this.isEditskills">
                  <ul *ngIf="skills && skills.getRawValue().length > 0" class="list-inline">
                    <li class="list-inline-item" *ngFor="let skills of skills.getRawValue()">
                      {{skills.skills}}
                    </li>
                  </ul>
                  <div *ngIf="skills && skills.getRawValue().length === 0">
                    (Not Indicated)
                  </div>

                </ng-container>
                <!-- end skills display -->

                <!-- start skills edit -->
                <ng-container *ngIf="this.isEditskills">
                  <div class="row">
                    <div class="col-md-12">

                      <div formArrayName="skillsArray" *ngFor="let skills of skills.controls; let i = index">
                        <div [formGroupName]="i" class="d-flex">
                          <input formControlName="skills" class="form-control mb-3 exp-array"
                            placeholder="Add skills" />
                          <div (click)="removeskill(i)"><i pTooltip="Remove Skill"
                              tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}" class="fa fa-times" aria-hidden="true"></i>
                          </div>
                        </div>

                      </div>
                      <button type="button" class="btn btn-outline-primary mb btn-add" (click)="addNewskill()"><i
                          class="fa fa-plus" aria-hidden="true"></i>
                        Add Skills</button>

                    </div>

                  </div>
                </ng-container>
                <!-- end skills edit -->


                <!-- start skills GPT -->
                <ng-container>
                  <div class="row">
                    <div class="col-md-12">

                      <button *ngIf="enableGPT()" [disabled]="gptGenerating" type="button" class="btn btn-info mb-1"
                        (click)="showRevisedText('skills')">
                        Revise with GPT
                      </button>

                    </div>
                  </div>

                </ng-container>
                <!-- end skills GPT -->

              </section>

              <!-- languages -->
              <section class="cv-sidebar-section" *ngIf="
              profile?.languages && 
              profile?.visibility?.languages === true
              ">
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <h2 class="cv-section__title">Languages</h2>
                  </div>
                  <div class="col-md-6">
                    <i pTooltip="Edit Languages" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                      *ngIf="!this.isEditlanguages" class="fa fa-pencil-square-o edit-icon right-bar" aria-hidden="true"
                      (click)="editResume('langs')"></i>
                    <i pTooltip="Update Languages" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                      *ngIf="this.isEditlanguages" class="fa fa-check edit-icon right-bar" aria-hidden="true"
                      (click)="updateProfile(false, 'languages')"></i>
                  </div>
                </div>

                <!-- start languages display -->
                <ng-container *ngIf="!this.isEditlanguages">
                  <ul *ngIf="languages && languages.getRawValue().length > 0" class="lang-list">
                    <li *ngFor="let lang of languages.getRawValue()">{{lang.languages}} </li>
                  </ul>

                  <div *ngIf="languages && languages.getRawValue().length === 0">
                    (Not Indicated)
                  </div>

                </ng-container>
                <!-- end languages display -->

                <!-- start languages edit -->
                <ng-container *ngIf="this.isEditlanguages">
                  <div class="row">
                    <div class="col-md-12">

                      <div formArrayName="langArray" *ngFor="let langs of languages.controls; let i = index">
                        <div [formGroupName]="i" class="d-flex">
                          <input formControlName="languages" class="form-control mb-3 exp-array"
                            placeholder="Add language" />
                          <div (click)="removelanguage(i)"><i pTooltip="Remove Language"
                              tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}" class="fa fa-times" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                      <button type="button" class="btn btn-outline-primary btn-add mt-2" (click)="addNewLanguage()"><i
                          class="fa fa-plus" aria-hidden="true"></i>
                        Add Language</button>
                    </div>
                  </div>
                </ng-container>
                <!-- end languages edit -->

              </section>

              <!--education-->
              <section class="cv-sidebar-section" *ngIf="
              profile?.education && 
              profile?.visibility?.education === true
              ">

                <div class="row align-items-center">
                  <div class="col-auto">
                    <h2 class="cv-section__title" *ngIf="profile?.education">Education
                    </h2>
                  </div>

                  <div class="col">
                    <i pTooltip="Add Education" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                      (click)="showAddEducation()" class="fa fa-plus edit-icon-nofloat" aria-hidden="true"></i>
                  </div>
                </div>

                <div class="ng-container" *ngFor="let edu of profile?.education;">

                  <div class="row mb">
                    <div class="col-md-12">
                      <p class="cv-section__role">{{edu.degree_name}}

                        <i pTooltip="Remove Education" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                          class="fa fa-times edit-icon-nofloat2 pl" aria-hidden="true"
                          (click)="confirmRemoveEducation(edu)"></i>

                        <i pTooltip="Edit Education" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                          class="fa fa-pencil-square-o edit-icon-nofloat2 pl" aria-hidden="true"
                          (click)="editEducation(edu)"></i>


                      </p>
                      <p class="cv-section__description">{{displayExperienceDurationDateRange(edu)}}

                        {{displayExperienceDurationDateRange(edu) ? '|' : ''}}

                        <span class="university">{{edu.school}}</span>
                      </p>
                      <p class="cv-section__description">{{edu.field_of_study}}</p>
                    </div>
                  </div>

                </div>



              </section>

              <!--courses-->
              <section class="cv-sidebar-section" *ngIf="

              profile.visibility && profile.visibility.courses === true">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <h2 class="cv-section__title" *ngIf="profile?.accomplishments?.courses">Courses
                    </h2>
                  </div>

                  <div class="col">
                    <i pTooltip="Add Course" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}" (click)="showAddCourse()"
                      class="fa fa-plus edit-icon-nofloat" aria-hidden="true"></i>

                  </div>

                </div>


                <div class="ng-container" *ngFor="let course of profile?.accomplishments?.courses;">
                  <div class="row mb">
                    <div class="col-md-12">
                      <p class="cv-section__description">{{course.name}}


                        <i pTooltip="Remove Course" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                          class="fa fa-times edit-icon-nofloat2 pl" aria-hidden="true"
                          (click)="confirmRemoveCourse(course)"></i>

                        <i pTooltip="Edit Course" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                          class="fa fa-pencil-square-o edit-icon-nofloat2 pl" aria-hidden="true"
                          (click)="editCourse(course)"></i>


                      </p>
                      <p class="cv-section__description">
                        <span class="university">{{course.number}}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <!--projects-->
              <section class="cv-sidebar-section" *ngIf="
              profile.visibility && profile.visibility.projects === true">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <h2 class="cv-section__title" *ngIf="profile?.accomplishments?.projects">Projects
                    </h2>
                  </div>

                  <div class="col">
                    <i pTooltip="Add Project" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}" (click)="showAddProject()"
                      class="fa fa-plus edit-icon-nofloat" aria-hidden="true"></i>
                  </div>
                </div>


                <div class="ng-container" *ngFor="let proj of profile?.accomplishments?.projects">
                  <div class="row mb">
                    <div class="col-md-12">
                      <p class="cv-section__description">{{proj.title}}

                        <i pTooltip="Remove Project" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                          class="fa fa-times edit-icon-nofloat2 pl" aria-hidden="true"
                          (click)="confirmRemoveProject(proj)"></i>

                        <i pTooltip="Edit Project" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}"
                          class="fa fa-pencil-square-o edit-icon-nofloat2 pl" aria-hidden="true"
                          (click)="editProject(proj)"></i>

                      </p>
                      <p class="cv-section__description"> <span class="university">
                          {{displayprojectDuration(proj)}}</span>
                      </p>

                    </div>
                  </div>
                </div>
              </section>


              <!-- soft skills -->
              <!-- <section class="cv-sidebar-section">
                <h2 class="cv-section__title">Soft skills

                </h2>
                <ul class="lang-list">
                  <li>Contrary </li>
                  <li>popular belief</li>
                  <li>belief</li>
                </ul>
              </section> -->

            </aside>
          </div>
          <!-- end experience section -->

        </form>
      </div>
    </div>

  </div>
</section>

<!-- start gpt dialog -->
<p-dialog [(visible)]="showModal" [draggable]="true" [closable]="true" [showHeader]="true" [blockScroll]="true"
  [modal]="true" [styleClass]="displayDialog ? 'responsive-dialog' : ''" *ngIf="showModal" #confirmationModal>
  <p-header>
    <div class="row">
      <div class="col-md-12 text-left">
        <h5 class="modal-title" id="exampleModalLabel">Revised with GPT</h5>
      </div>
    </div>

    <div *ngIf="selectedSection && selectedSection === 'experience'" class="col-md-12">
      <span class="size-18px">{{selectedCompny}}
      </span>
    </div>

    <div *ngIf="selectedSection && selectedSection === 'experience'" class="col-md-12">
      <span class="size-18px">{{selectedTitle}}
      </span>
    </div>

  </p-header>

  <div *ngIf="gptGenerating" class="row">
    <div class="col-md-12 justify-content-center d-flex">
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <div *ngIf="!gptGenerating" class="row">

    <div *ngIf="!isGPTArray(gptrevisedData)" class="col-md-12">
      <!-- <span class="cv-about">
        {{gptrevisedData}}
      </span> -->
      <div class="cv-about" [innerHTML]="gptrevisedData"></div>
    </div>

    <div *ngIf="isGPTArray(gptrevisedData)" class="col-md-12">
      <ul class="course-list">
        <li *ngFor="let data of gptrevisedData">{{data}}</li>
      </ul>
    </div>

  </div>

  <p-footer>
    <div class="row">
      <div class="col-md-12">

        <div *ngIf="!gptGenerating" class="row">
          <div class="col">
          </div>

          <div class="col-auto">
            <button #closeModal (click)="showModal = false" type="button" class="btn btn-secondary">Close</button>
          </div>

          <div class="col-auto">

            <!-- <button [disabled]="updating" type="button" class="btn btn-primary" (click)="updateProfile(true, selectedSection)">Apply Revised
              <div *ngIf="updating" class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button> -->


            <button [disabled]="updating" type="button" class="btn btn-primary"
              (click)="updateProfile(true, selectedSection)">Apply Revised
              <div *ngIf="updating" class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>



          </div>

        </div>

      </div>

    </div>
  </p-footer>
</p-dialog>
<!-- end gpt dialog -->



<!-- start add course dialog -->
<form [formGroup]="courseForm" (ngSubmit)="confirmAddCourse()">

  <p-dialog [(visible)]="addCourseModal" [draggable]="true" [closable]="true" [showHeader]="true" [blockScroll]="true"
    [modal]="true" [styleClass]="displayDialog ? 'responsive-dialog' : ''" *ngIf="addCourseModal" #courseModal>

    <p-header>
      <div class="row">
        <div class="col-md-12 text-left">
          <h5 class="modal-title" id="couModalLabel">{{ !courseAddMode ? 'Edit' : 'Add'}} Course</h5>
        </div>
      </div>
    </p-header>


    <div class="row">
      <div class="col-md-12">


        <div class="row">
          <div class="col-md-10">

            <!-- start name -->
            <div class="form-group">
              <label for="name" class="mb">Course Name</label>
              <input id="name" placeholder="Ex. Advanced Machine Learning - Winter 2001" type="text"
                class="form-control curved" formControlName="name">
            </div>
            <div class="text-danger mt-2" *ngIf="submitted && (courseForm.controls['name'].invalid || 
              (courseForm.controls['name'].errors && courseForm.controls['name'].errors['required'])
            )">
              Course Name is required
            </div>
            <!-- end name -->

            <!-- start number -->
            <div class="form-group">
              <label for="number" class="mt-3 mb">Course Number</label>
              <input id="number" placeholder="Ex. CSC 2535 (U of Toronto)" type="text" class="form-control curved"
                formControlName="number">
            </div>
            <div class="text-danger mt-2" *ngIf="submitted && ( 
              (courseForm.controls['number'].errors && courseForm.controls['number'].errors['required'])
            )">
              Course Number is required
            </div>
            <!-- end number -->

          </div>
        </div>


      </div>
    </div>



    <p-footer>
      <div class="row">
        <div class="col-md-12">

          <div class="row">
            <div class="col">
            </div>

            <div class="col-auto">
              <button #closeCourseModal (click)="cancelShowAddCourse()" type="button"
                class="btn btn-secondary">Close</button>
            </div>

            <div class="col-auto">

              <button type="submit" class="btn btn-primary">
                {{!courseAddMode ? 'Save' : 'Add'}}
              </button>

            </div>

          </div>

        </div>

      </div>
    </p-footer>

  </p-dialog>

</form>
<!-- end add course dialog -->


<!-- start add publication dialog -->
<form [formGroup]="publicationForm" (ngSubmit)="confirmAddPublication()">
  <p-dialog [(visible)]="addPublicationModal" [draggable]="true" [closable]="true" [showHeader]="true"
    [blockScroll]="true" [modal]="true" [styleClass]="displayDialog ? 'responsive-dialog' : ''"
    *ngIf="addPublicationModal" #publicationModal>


    <p-header>
      <div class="row">
        <div class="col-md-12 text-left">
          <h5 class="modal-title" id="pubModalLabel">{{ !publicationAddMode ? 'Edit' : 'Add'}} Publication</h5>
        </div>
      </div>
    </p-header>

    <div class="row">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-10">

            <!-- start name -->
            <div class="form-group">
              <label for="name" class="mb">Publication Name</label>
              <input id="name" placeholder="Ex. Probabilistic Partitive Partitioning (PPP)" type="text"
                class="form-control curved" formControlName="name">
            </div>
            <div class="text-danger mt-2" *ngIf="submitted && (publicationForm.controls['name'].invalid || 
              (publicationForm.controls['name'].errors && publicationForm.controls['name'].errors['required'])
            )">
              Publication Name is required
            </div>
            <!-- end name -->

            <!-- start url -->
            <div class="form-group">
              <label for="url" class="mt-3 mb">Publication URL</label>
              <input id="url" placeholder="Ex. https://arxiv.org/abs/2003.04372" type="text" class="form-control curved"
                formControlName="url">
            </div>
            <div class="text-danger mt-2" *ngIf="submitted && ( 
              (publicationForm.controls['url'].errors && publicationForm.controls['url'].errors['required'])
            )">
              Publication URL is required
            </div>

            <div class="text-danger mt-2" *ngIf="submitted && ( 
              (publicationForm.controls['url'].errors && publicationForm.controls['url'].errors['invalidUrl'])
            )">
              Publication URL is not valid
            </div>

            <!-- end url -->

            <!-- start publisher -->
            <div class="form-group">
              <label for="publisher" class="mt-3 mb">Publisher</label>
              <input id="publisher" placeholder="Ex. 2020 IEEE 13th International Conference on Cloud Computing (CLOUD)"
                type="text" class="form-control curved" formControlName="publisher">
            </div>
            <!-- end publisher -->



            <!-- start published_on  -->

            <div class="row align-items-center">

              <div class="col-md-12">

                <!-- start labels -->
                <div class="row">
                  <div class="col-md-12">
                    <label for="published_on" class="mt-3 mb">Published On</label>
                  </div>
                  <!-- <div class="col-md-4">
                    <label for="endsAt" class="mt-3 mb">Ends</label>
                  </div>
                  <div class="col-md-4">
                  </div> -->
                </div>
                <!-- end labels -->

                <!-- start inputs -->


                <div class="row align-items-center">
                  <div class="col-4">
                    <p-calendar [disabled]="!hasPublishOn" appendTo="body" [dateFormat]="'yy/mm/dd'"
                      [(ngModel)]="published_on" [ngModelOptions]="{ standalone: true }" id="published_on"></p-calendar>

                    <div class="col">
                      <p-checkbox (change)="execTogglePublishOn()" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="hasPublishOn" [binary]="true" inputId="hasPublishOn"></p-checkbox>
                      <label class="pl" for="current">Has Published Date</label>
                    </div>

                  </div>
                  <!-- <div class="col-md-4">
                    <p-calendar *ngIf="!current" appendTo="body" [dateFormat]="'yy/mm/dd'" 
                    [(ngModel)]="endsAt" [ngModelOptions]="{ standalone: true }"
                    id="endsAAt"></p-calendar>
                  </div>
                  <div class="col-md-4">
                    <p-checkbox (change)="toggleEndsAt()" [ngModelOptions]="{standalone: true}" [(ngModel)]="current" [binary]="true" inputId="current"></p-checkbox>
                    <label class="pl" for="current">Current</label>
                  </div> -->
                </div>
                <!-- end inputs -->

                <!-- start errors -->
                <div class="row">
                  <div class="col-md-12">
                    <!-- <div class="text-danger mt-2" *ngIf="submitted && isNotNullOrEmpty(published_on) === false">
                      Published on is required
                    </div> -->
                  </div>
                </div>

                <!-- end errors -->
              </div>
            </div>


            <!-- end published_on -->


            <!-- start description -->

            <label for="description" class="mt-3 mb">Description</label>

            <p-editor id="description" formControlName="description" placeholder="Ex. description"
              [style]="{'height':'320px'}">
              <ng-template pTemplate="header">
                <span class="ql-formats">
                  <!-- <button type="button" class="ql-bold" aria-label="Bold"></button>
                  <button type="button" class="ql-italic" aria-label="Italic"></button>
                  <button type="button" class="ql-underline" aria-label="Underline"></button> -->
                  <button type="button" class="ql-list" value="bullet" aria-label="Bullet"></button>
                </span>
              </ng-template>
            </p-editor>


            <!-- end description -->
          </div>
        </div>

      </div>
    </div>

    <p-footer>
      <div class="row">
        <div class="col-md-12">

          <div class="row">
            <div class="col">
            </div>

            <div class="col-auto">
              <button #closePublicationModal (click)="cancelShowAddPublication()" type="button"
                class="btn btn-secondary">Close</button>
            </div>

            <div class="col-auto">

              <button type="submit" class="btn btn-primary">
                {{!publicationAddMode ? 'Save' : 'Add'}}
              </button>

            </div>

          </div>

        </div>

      </div>
    </p-footer>

  </p-dialog>
</form>
<!-- end add publication dialog -->

<!-- start add project dialog -->
<form [formGroup]="projectForm" (ngSubmit)="confirmAddProject()">
  <p-dialog [(visible)]="addProjectModal" [draggable]="true" [closable]="true" [showHeader]="true" [blockScroll]="true"
    [modal]="true" [styleClass]="displayDialog ? 'responsive-dialog' : ''" *ngIf="addProjectModal" #projectnModal>

    <p-header>
      <div class="row">
        <div class="col-md-12 text-left">
          <h5 class="modal-title" id="projModalLabel">{{ !projectAddMode ? 'Edit' : 'Add'}} Project</h5>
        </div>
      </div>
    </p-header>


    <div class="row">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-10">

            <!-- start title -->
            <div class="form-group">
              <label for="title" class="mb">Title</label>
              <input id="title" placeholder="Ex. Cloud Cost Optimization" type="text" class="form-control curved"
                formControlName="title">
            </div>
            <div class="text-danger mt-2" *ngIf="submitted && (projectForm.controls['title'].invalid || 
              (projectForm.controls['title'].errors && projectForm.controls['title'].errors['required'])
            )">
              Title is required
            </div>
            <!-- end title -->

            <!-- start url -->
            <div class="form-group">
              <label for="url" class="mt-3 mb">URL</label>
              <input id="url" placeholder="Ex. https://www.linkedin.com/company/165799/" type="text"
                class="form-control curved" formControlName="url">
            </div>
            <div class="text-danger mt-2" *ngIf="submitted && (projectForm.controls['url'].invalid || 
              (projectForm.controls['url'].errors && projectForm.controls['url'].errors['invalidUrl'])
            )">
              URL is not valid
            </div>
            <!-- end url -->

            <!-- start duration option -->
            <div class="row mt-3">
              <div class="col-md-12">
                <p-checkbox id="hasStartsAt" (change)="execToggleStartsAt()" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="hasStartsAt" [binary]="true" inputId="hasStartsAt"></p-checkbox>
                <label class="pl" for="hasStartsAt">Duration</label>
              </div>
            </div>
            <!-- end duration option -->

            <!-- start labels -->
            <div *ngIf="hasStartsAt" class="row">
              <div class="col-md-4">
                <label for="startsAt" class="mt-3 mb">Starts</label>
              </div>
              <div class="col-md">
                <label for="endsAt" class="mt-3 mb">Ends</label>
              </div>
            </div>
            <!-- end labels -->

            <!-- start inputs -->
            <div class="row align-items-center">
              <div class="col-md-4">
                <p-calendar *ngIf="hasStartsAt" appendTo="body" [dateFormat]="'yy/mm/dd'" [(ngModel)]="startsAt"
                  [ngModelOptions]="{ standalone: true }" id="startsAt"></p-calendar>
              </div>
              <div *ngIf="hasStartsAt" class="col-md-4">
                <p-calendar [disabled]="current" appendTo="body" [dateFormat]="'yy/mm/dd'" [(ngModel)]="endsAt"
                  [ngModelOptions]="{ standalone: true }" id="endsAAt"></p-calendar>
              </div>

              <div *ngIf="hasStartsAt" class="col-md">
                <p-checkbox (change)="toggleEndsAt()" [ngModelOptions]="{standalone: true}" [(ngModel)]="current"
                  [binary]="true" inputId="current"></p-checkbox>
                <label class="pl" for="current">Current</label>
              </div>

            </div>
            <!-- end inputs -->

            <!-- start description -->
            <div class="row">
              <div class="col-md-12">
                <label for="description" class="mt-3 mb">Description</label>

                <p-editor id="description" formControlName="description"
                  placeholder="Ex. Cloud cost optimization refers to the process of.." [style]="{'height':'320px'}">
                  <ng-template pTemplate="header">
                    <span class="ql-formats">
                      <button type="button" class="ql-list" value="bullet" aria-label="Bullet"></button>
                    </span>
                  </ng-template>
                </p-editor>

              </div>

            </div>
            <!-- end description -->

          </div>
        </div>

      </div>
    </div>


    <p-footer>
      <div class="row">
        <div class="col-md-12">

          <div class="row">
            <div class="col">
            </div>

            <div class="col-auto">
              <button #closeProjectModal (click)="cancelShowAddProject()" type="button"
                class="btn btn-secondary">Close</button>
            </div>

            <div class="col-auto">

              <button type="submit" class="btn btn-primary">
                {{!projectAddMode ? 'Save' : 'Add'}}
              </button>

            </div>

          </div>

        </div>
      </div>
    </p-footer>


  </p-dialog>
</form>
<!-- end add project dialog -->

<!-- start add education dialog -->
<form [formGroup]="educationForm" (ngSubmit)="confirmAddEducation()">
  <p-dialog [(visible)]="addEducationModal" [draggable]="true" [closable]="true" [showHeader]="true"
    [blockScroll]="true" [modal]="true" [styleClass]="displayDialog ? 'responsive-dialog' : ''"
    *ngIf="addEducationModal" #educationModal>

    <p-header>
      <div class="row">
        <div class="col-md-12 text-left">
          <h5 class="modal-title" id="eduModalLabel">{{ !educationAddMode ? 'Edit' : 'Add'}} Education</h5>
        </div>
      </div>
    </p-header>

    <div class="row">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-10">

            <!-- start degree name -->
            <div class="form-group">
              <label for="degree_name" class="mb">Degree Name</label>
              <input id="degree_name" placeholder="Ex. Ph.D." type="text" class="form-control curved"
                formControlName="degree_name">
            </div>
            <div class="text-danger mt-2" *ngIf="submitted && (educationForm.controls['degree_name'].invalid || 
              (educationForm.controls['degree_name'].errors && educationForm.controls['degree_name'].errors['required'])
            )">
              Degree Name is required
            </div>
            <!-- end degree name -->

            <!-- start field_of_study -->
            <div class="form-group">
              <label for="field_of_study" class="mt-3 mb">Field of Study</label>
              <input id="field_of_study" placeholder="Ex. Computer Science" type="text" class="form-control curved"
                formControlName="field_of_study">
            </div>
            <div class="text-danger mt-2" *ngIf="submitted && (educationForm.controls['field_of_study'].invalid || 
              (educationForm.controls['field_of_study'].errors && educationForm.controls['field_of_study'].errors['required'])
            )">
              Field of Study is required
            </div>
            <!-- end field_of_study -->

            <!-- start school -->
            <div class="form-group">
              <label for="school" class="mt-3 mb">School</label>
              <input id="school" placeholder="Ex. Ryerson University" type="text" class="form-control curved"
                formControlName="school">
            </div>
            <div class="text-danger mt-2" *ngIf="submitted && (educationForm.controls['school'].invalid || 
              (educationForm.controls['school'].errors && educationForm.controls['school'].errors['required'])
            )">
              Field of Study is required
            </div>
            <!-- end school -->

            <!-- start activities_and_societies -->
            <div class="form-group">
              <label for="activities_and_societies" class="mt-3 mb">Activities</label>
              <input id="activities_and_societies" placeholder="Ex. Database group" type="text"
                class="form-control curved" formControlName="activities_and_societies">
            </div>
            <!-- end activities_and_societies -->

            <!-- start grade -->
            <div class="form-group">
              <label for="grade" class="mt-3 mb">Grade</label>
              <input id="grade" placeholder="Ex. Cumulative GPA 4.22" type="text" class="form-control curved"
                formControlName="grade">
            </div>
            <!-- end grade -->

            <!-- start company linkedin profile url -->
            <div class="form-group">
              <label for="school_linkedin_profile_url" class="mt-3 mb">School LinkedIn Profile URL</label>
              <input id="school_linkedin_profile_url" placeholder="Ex. https://www.linkedin.com/company/165799/"
                type="text" class="form-control curved" formControlName="school_linkedin_profile_url">
            </div>
            <div class="text-danger mt-2" *ngIf="submitted && (educationForm.controls['school_linkedin_profile_url'].invalid || 
              (educationForm.controls['school_linkedin_profile_url'].errors && educationForm.controls['school_linkedin_profile_url'].errors['invalidUrl'])
            )">
              School LinkedIn Profile URL is not valid
            </div>
            <!-- end company linkedin profile url -->

            <!-- start duration option -->
            <div class="row mt-3">
              <div class="col-md-12">
                <p-checkbox id="hasStartsAt" (change)="execToggleStartsAt()" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="hasStartsAt" [binary]="true" inputId="hasStartsAt"></p-checkbox>
                <label class="pl" for="hasStartsAt">Duration</label>
              </div>
            </div>
            <!-- end duration option -->

            <!-- start labels -->
            <div *ngIf="hasStartsAt" class="row">
              <div class="col-md-4">
                <label for="startsAt" class="mt-3 mb">Starts</label>
              </div>
              <div class="col-md">
                <label for="endsAt" class="mt-3 mb">Ends</label>
              </div>
            </div>
            <!-- end labels -->

            <!-- start inputs -->
            <div class="row align-items-center">
              <div class="col-md-4">
                <p-calendar *ngIf="hasStartsAt" appendTo="body" [dateFormat]="'yy/mm/dd'" [(ngModel)]="startsAt"
                  [ngModelOptions]="{ standalone: true }" id="startsAt"></p-calendar>
              </div>
              <div *ngIf="hasStartsAt" class="col-md-4">
                <p-calendar [disabled]="current" appendTo="body" [dateFormat]="'yy/mm/dd'" [(ngModel)]="endsAt"
                  [ngModelOptions]="{ standalone: true }" id="endsAAt"></p-calendar>
              </div>

              <div *ngIf="hasStartsAt" class="col-md">
                <p-checkbox (change)="toggleEndsAt()" [ngModelOptions]="{standalone: true}" [(ngModel)]="current"
                  [binary]="true" inputId="current"></p-checkbox>
                <label class="pl" for="current">Current</label>
              </div>

            </div>
            <!-- end inputs -->


            <!-- start description -->
            <div class="row">
              <div class="col-md-12">
                <label for="description" class="mt-3 mb">Description</label>

                <p-editor id="description" formControlName="description" placeholder="Ex. Skills: Machine Learning"
                  [style]="{'height':'320px'}">
                  <ng-template pTemplate="header">
                    <span class="ql-formats">
                      <button type="button" class="ql-list" value="bullet" aria-label="Bullet"></button>
                    </span>
                  </ng-template>
                </p-editor>


              </div>

            </div>
            <!-- end description -->


          </div>
        </div>


      </div>
    </div>

    <p-footer>
      <div class="row">
        <div class="col-md-12">

          <div class="row">
            <div class="col">
            </div>

            <div class="col-auto">
              <button #closeEducationModal (click)="cancelShowAddEducation()" type="button"
                class="btn btn-secondary">Close</button>
            </div>

            <div class="col-auto">

              <button type="submit" class="btn btn-primary">
                {{!educationAddMode ? 'Save' : 'Add'}}
              </button>

            </div>

          </div>

        </div>
      </div>
    </p-footer>


  </p-dialog>
</form>
<!-- end add education dialog -->

<!-- start add experience dialog -->
<p-dialog [(visible)]="addExperienceModal" [draggable]="true" [closable]="true" [showHeader]="true" [blockScroll]="true"
  [modal]="true" [styleClass]="displayDialog ? 'responsive-dialog' : ''" *ngIf="addExperienceModal" #experienceModal>
  <p-header>
    <div class="row">
      <div class="col-md-12 text-left">
        <h5 class="modal-title" id="exampleModalLabel">{{ !experienceAddMode ? 'Edit' : 'Add'}} Experience</h5>
      </div>
    </div>
  </p-header>

  <div class="row">
    <div class="col-md-12">

      <div class="row">
        <div class="col-md-10">
          <form [formGroup]="experienceForm">

            <!-- start company name -->
            <div class="form-group">
              <label for="company" class="mb">Company Name</label>
              <input id="company" placeholder="Ex. Deloitte" type="text" class="form-control curved"
                formControlName="company">
            </div>
            <div class="text-danger mt-2" *ngIf="submitted && (experienceForm.controls['company'].invalid || 
              (experienceForm.controls['company'].errors && experienceForm.controls['company'].errors['required'])
            )">
              Company Name is required
            </div>
            <!-- end company name -->

            <!-- start title -->
            <div class="form-group">
              <label for="title" class="mt-3 mb">Title</label>
              <input id="title" placeholder="Ex. Accountant" type="text" class="form-control curved"
                formControlName="title">
            </div>
            <div class="text-danger mt-2" *ngIf="submitted && (experienceForm.controls['title'].invalid || 
              (experienceForm.controls['title'].errors && experienceForm.controls['title'].errors['required'])
            )">
              Title is required
            </div>
            <!-- end title -->

            <!-- start location -->
            <div class="form-group">
              <label for="location" class="mt-3 mb">Location</label>
              <input id="location" placeholder="Ex. Toronto, Ontario, Canada" type="text" class="form-control curved"
                formControlName="location">
            </div>
            <!-- end location -->

            <!-- start company linkedin profile url -->
            <div class="form-group">
              <label for="company_linkedin_profile_url" class="mt-3 mb">Company URL</label>
              <input id="company_linkedin_profile_url" placeholder="Ex. https://www.linkedin.com" type="text"
                class="form-control curved" formControlName="company_linkedin_profile_url">
            </div>
            <div class="text-danger mt-2" *ngIf="submitted && (experienceForm.controls['company_linkedin_profile_url'].invalid || 
              (experienceForm.controls['company_linkedin_profile_url'].errors && experienceForm.controls['company_linkedin_profile_url'].errors['invalidUrl'])
            )">
              URL is not valid
            </div>
            <!-- end company linkedin profile url -->

            <div class="row align-items-center">

              <div class="col-md-12">

                <!-- start labels -->
                <div class="row">
                  <div class="col-md-4">
                    <label for="startsAt" class="mt-3 mb">Starts</label>
                  </div>
                  <div class="col-md-4">
                    <label for="endsAt" class="mt-3 mb">Ends</label>
                  </div>
                  <div class="col-md-4">
                  </div>
                </div>
                <!-- end labels -->

                <!-- start inputs -->
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <p-calendar appendTo="body" [dateFormat]="'yy/mm/dd'" [(ngModel)]="startsAt"
                      [ngModelOptions]="{ standalone: true }" id="startsAt"></p-calendar>
                  </div>
                  <div class="col-md-4">
                    <p-calendar *ngIf="!current" appendTo="body" [dateFormat]="'yy/mm/dd'" [(ngModel)]="endsAt"
                      [ngModelOptions]="{ standalone: true }" id="endsAAt"></p-calendar>
                  </div>
                  <div class="col-md-4">
                    <p-checkbox (change)="toggleEndsAt()" [ngModelOptions]="{standalone: true}" [(ngModel)]="current"
                      [binary]="true" inputId="current"></p-checkbox>
                    <label class="pl" for="current">Current</label>
                  </div>
                </div>
                <!-- end inputs -->

                <!-- start errors -->
                <div class="row">
                  <div class="col-md-4">
                    <div class="text-danger mt-2" *ngIf="submitted && isNotNullOrEmpty(startsAt) === false">
                      Starts at is required
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="text-danger mt-2" *ngIf="!current && submitted && 
                    isNotNullOrEmpty(endsAt) === false
                    ">
                      Ends at is required if current
                    </div>
                  </div>
                  <div class="col-md-4">

                  </div>
                </div>

                <!-- end errors -->
              </div>
            </div>

            <!-- start description -->
            <div class="form-group">
              <label for="description" class="mt-3 mb">Description</label>
              <!-- 
              <textarea id="description" placeholder="Ex. Compiling, analyzing, and reporting financial data. Creating periodic reports, such as balance sheets, profit & loss statements, etc" class="form-control curved" formControlName="description"
                  id="aboutTextarea" rows="5"></textarea> -->

              <p-editor id="description" formControlName="description"
                placeholder="Ex. Compiling, analyzing, and reporting financial data. Creating periodic reports, such as balance sheets, profit & loss statements, etc"
                [style]="{'height':'320px'}">
                <ng-template pTemplate="header">
                  <span class="ql-formats">
                    <!-- <button type="button" class="ql-bold" aria-label="Bold"></button>
                      <button type="button" class="ql-italic" aria-label="Italic"></button>
                      <button type="button" class="ql-underline" aria-label="Underline"></button> -->
                    <button type="button" class="ql-list" value="bullet" aria-label="Bullet"></button>
                  </span>
                </ng-template>
              </p-editor>

            </div>
            <div class="text-danger mt-2" *ngIf="submitted && (experienceForm.controls['description'].invalid || 
              (experienceForm.controls['description'].errors && experienceForm.controls['description'].errors['required'])
            )">
              Description is required
            </div>
            <!-- end description -->

          </form>
        </div>
      </div>

    </div>
  </div>

  <p-footer>
    <div class="row">
      <div class="col-md-12">

        <div class="row">
          <div class="col">
          </div>

          <div class="col-auto">
            <button #closeExperienceModal (click)="cancelShowAddExperience()" type="button"
              class="btn btn-secondary">Close</button>
          </div>

          <div class="col-auto">

            <button type="button" class="btn btn-primary" (click)="confirmAddExperience()">
              {{!experienceAddMode ? 'Save' : 'Add'}}
            </button>

          </div>

        </div>

      </div>

    </div>
  </p-footer>

</p-dialog>
<!-- end add experience dialog -->

<p-confirmDialog key="confirmDelExperienceDialog" header="Confirmation" icon="pi pi-exclamation-triangle"
  rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<p-confirmDialog key="confirmDelPublicationDialog" header="Confirmation" icon="pi pi-exclamation-triangle"
  rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<p-confirmDialog key="confirmDelCourseDialog" header="Confirmation" icon="pi pi-exclamation-triangle"
  rejectButtonStyleClass="p-button-text"></p-confirmDialog>


<p-confirmDialog key="confirmDelEducationDialog" header="Confirmation" icon="pi pi-exclamation-triangle"
  rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<p-confirmDialog key="confirmDelProjectDialog" header="Confirmation" icon="pi pi-exclamation-triangle"
  rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<!-- start contacts dialog -->
<p-dialog [(visible)]="showContactsModal" [draggable]="true" [closable]="true" [showHeader]="true" [blockScroll]="true"
  [modal]="true" [styleClass]="displayDialog ? 'responsive-dialog' : ''" *ngIf="showContactsModal" #contactsModal>

  <p-header>
    <div class="row">
      <div class="col-md-12 text-left">
        <h5 class="modal-title" id="exampleModalLabel">Edit Contacts</h5>
      </div>
    </div>
  </p-header>

  <div class="row">
    <div class="col-md-12">

      <!-- start contact selector -->
      <div class="row">
        <div class="{{isSmallScreen ? 'col-12' : 'col-8'}}">
          <div>
            <label for="contact-dd" class="mb">Please select the contact type to edit</label>
          </div>

          <p-dropdown id="contact-dd" appendTo="body" [options]="contactsTypeList" [(ngModel)]="selectedContactType"
            (onChange)="selectContact($event)" optionLabel="name">
          </p-dropdown>

        </div>
      </div>
      <!-- end contact selector -->


      <!-- contact.phone -->

      <!-- start phone -->

      <div *ngIf="selectedContactType.id === 'phone'" class="row pt-3">
        <div class="{{isSmallScreen ? 'col-12' : 'col-8'}}">
          <div class="row">

            <div class="col-12 pb-3">

              <div class="form-group">
                <div class="row">
                  <div class="col-12">
                    <label for="newPhone" class="mb">Add New Phone</label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="row">

                      <div class="col">
                        <input id="newPhone" placeholder="Ex. 647-123-4567" type="text" class="form-control curved"
                          [ngModelOptions]="{standalone: true}" [(ngModel)]="newPhone">
                      </div>

                      <div class="col-auto">
                        <button (click)="addNewPhone()" type="button" class="btn btn-primary">
                          Add
                        </button>
                      </div>

                    </div>
                  </div>
                </div>

              </div>

            </div>

            <div class="col-12">
              <p-listbox #acc appendTo="body" (click)="selectPhone()" [options]="phones"
                [(ngModel)]="selectedPhone"></p-listbox>
            </div>

            <div class="col-12 pt-3">
              <button (click)="removePhone()" type="button" class="btn btn-primary">
                Remove Selected Phone
              </button>

            </div>

          </div>

        </div>
      </div>

      <!-- end phone -->


      <!-- start twitter -->
      <div *ngIf="selectedContactType.id === 'twitter'" class="row pt-3">
        <div class="{{isSmallScreen ? 'col-12' : 'col-8'}}">
          <div class="row">

            <div class="{{isSmallScreen ? 'col-12' : 'col-8'}}">
              <div class="row">

                <div class="col-12 pb-3">

                  <div class="row">
                    <div class="col-12">
                      <label for="twitter" class="mb">Twitter</label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <input id="newEmail" placeholder="" type="text" class="form-control curved"
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="contact.twitter">
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- end twitter -->

      <!-- start email -->
      <div *ngIf="selectedContactType.id === 'email'" class="row pt-3">
        <div class="{{isSmallScreen ? 'col-12' : 'col-8'}}">
          <div class="row">

            <div class="col-12 pb-3">

              <div class="form-group">
                <div class="row">
                  <div class="col-12">
                    <label for="newEmail" class="mb">Add New Email</label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="row">


                      <div class="col">
                        <input id="newEmail" placeholder="Ex. name@domain.com" type="text" class="form-control curved"
                          [ngModelOptions]="{standalone: true}" [(ngModel)]="newEmail">
                      </div>

                      <div class="col-auto">
                        <button (click)="addNewEmail()" type="button" class="btn btn-primary">
                          Add
                        </button>
                      </div>

                    </div>
                  </div>
                </div>

              </div>

            </div>

            <div class="col-12">
              <p-listbox #acc appendTo="body" (click)="selectEmail()" [options]="emails"
                [(ngModel)]="selectedEmail"></p-listbox>
            </div>

            <div class="col-12 pt-3">
              <button (click)="removeEmail()" type="button" class="btn btn-primary">
                Remove Selected Email
              </button>

            </div>

          </div>

        </div>
      </div>
      <!-- end email -->

    </div>
  </div>

  <p-footer>
    <div class="row">
      <div class="col-md-12">

        <div class="row">
          <div class="col">
          </div>
          <div class="col-auto">
            <button #closeContactsModal (click)="cancelEditContacts()" type="button"
              class="btn btn-secondary">Close</button>
          </div>
          <div class="col-auto">
            <button type="button" class="btn btn-primary" (click)="confirmUpdateContacts()">
              Update
            </button>
          </div>
        </div>

      </div>

    </div>
  </p-footer>

</p-dialog>
<!-- end contacts dialog -->

<!-- start template carousel dialog -->
<p-dialog [(visible)]="showCarouselModal" [draggable]="true" [closable]="true" [showHeader]="true" [blockScroll]="true"
  [modal]="true" [styleClass]="displayDialog ? 'responsive-dialog' : ''" *ngIf="showCarouselModal" #carouselsModal>

  <p-header>
    <div class="row">
      <div class="col-md-12 text-left">
        <h5 class="modal-title" id="exampleModalLabel">Select Template</h5>
      </div>
    </div>
  </p-header>

  <div class="row">
    <div class="col-md-12">

      <!-- start carousel content -->

      <p-carousel [value]="products" [numVisible]="3" [numScroll]="3" [circular]="false"
        [responsiveOptions]="responsiveOptions">
        <ng-template let-product pTemplate="item">
          <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
            <div class="mb-3">
              <!-- <img src="https://primefaces.org/cdn/primeng/images/demo/product/{{ product.image }}" [alt]="product.name" class="w-6 shadow-2" /> -->
              <!-- <img src="{{product.image}}" [alt]="product.name" class="image-thumbnail w-6 shadow-2" /> -->

              <!-- <span *ngIf="product.code === 'basic' || product.code === 'classic'" (click)="selectTemplate(product)"  class="custom-link">
                      <figure><img class="img-thumbnail thnail" src="{{product.image}}" alt="Random Image"></figure>
                    </span>

                    <span *ngIf="product.code !== 'basic' && product.code !== 'classic'">
                      <figure><img class="img-thumbnail thnail" src="{{product.image}}" alt="Random Image"></figure>
                    </span> -->

              <span (click)="selectTemplate(product)" class="custom-link">
                <figure><img class="img-thumbnail thnail" src="{{product.image}}" alt="Random Image"></figure>
              </span>

            </div>
            <div>
              <h4 class="mb-1">{{ product.name }}</h4>
              <!-- <h6 class="mt-0 mb-3">{{ '$' + product.price }}</h6> -->
              <!-- <p-tag [value]="product.inventoryStatus" [severity]="getSeverity(product.inventoryStatus)"></p-tag> -->
              <!-- <div class="car-buttons mt-5">
                        <p-button type="button" styleClass="p-button p-button-rounded mr-2" icon="pi pi-search"></p-button>
                        <p-button type="button" styleClass="p-button-success p-button-rounded mr-2" icon="pi pi-star-fill"></p-button>
                    </div> -->
            </div>
          </div>
        </ng-template>
      </p-carousel>
      <!-- end carousel content -->
    </div>
  </div>

  <p-footer>
    <div class="row">
      <div class="col-md-12">

        <div class="row">
          <div class="col">
          </div>
          <div class="col-auto">
          </div>
          <div class="col-auto">
          </div>
        </div>

      </div>

    </div>
  </p-footer>

</p-dialog>
<!-- end template carousel dialog -->

<!-- start section visible dialog -->
<p-dialog [(visible)]="sectionsModal" [draggable]="true" [closable]="true" (onHide)="closeSections()"
  [showHeader]="true" [blockScroll]="true" [modal]="true" [styleClass]="displayDialog ? 'responsive-dialog' : ''"
  #sectionModal>

  <p-header>
    <div class="row">
      <div class="col-md-12 text-left">
        <h5 class="modal-title" id="sectionsModalLabel">Select Resume Sections</h5>
      </div>
    </div>
  </p-header>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-5">

          <div class="row align-items-center">
            <div class="col-auto">
              <p-inputSwitch (onChange)="toggleSection('courses')" [(ngModel)]="coursesVisible"
                inputId="coursesVisible"></p-inputSwitch>

            </div>
            <div class="col unset-padding-left">
              <label for="coursesVisible">Courses</label>
            </div>
          </div>

        </div>
        <div class="col">

          <div class="row align-items-center">
            <div class="col-auto">
              <p-inputSwitch (onChange)="toggleSection('experience')" [(ngModel)]="experienceVisible"
                inputId="experienceVisible"></p-inputSwitch>

            </div>
            <div class="col unset-padding-left">
              <label for="experienceVisible">Experience</label>
            </div>
          </div>

        </div>

      </div>

      <div class="row">
        <div class="col-5">
          <div class="row align-items-center">
            <div class="col-auto">
              <p-inputSwitch (onChange)="toggleSection('projects')" [(ngModel)]="projectsVisible"
                inputId="projectsVisible"></p-inputSwitch>
            </div>
            <div class="col unset-padding-left">
              <label for="projectsVisible">Projects</label>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row align-items-center">
            <div class="col-auto">
              <p-inputSwitch (onChange)="toggleSection('skills')" [(ngModel)]="skillsVisible"
                inputId="skillsVisible"></p-inputSwitch>

            </div>
            <div class="col unset-padding-left">
              <label for="skillsVisible">Skills</label>
            </div>
          </div>

        </div>

      </div>

      <div class="row">
        <div class="col-5">

          <div class="row align-items-center">
            <div class="col-auto">
              <p-inputSwitch (onChange)="toggleSection('publications')" [(ngModel)]="publicationsVisible"
                inputId="publicationsVisible"></p-inputSwitch>

            </div>
            <div class="col unset-padding-left">
              <label for="publicationsVisible">Publications</label>
            </div>
          </div>

        </div>
        <div class="col">

          <div class="row align-items-center">
            <div class="col-auto">
              <p-inputSwitch (onChange)="toggleSection('education')" [(ngModel)]="educationVisible"
                inputId="educationVisible"></p-inputSwitch>

            </div>
            <div class="col unset-padding-left">
              <label for="educationVisible">Education</label>
            </div>
          </div>

        </div>

      </div>

      <div class="row">
        <div class="col-5">

          <div class="row align-items-center">
            <div class="col-auto">
              <p-inputSwitch (onChange)="toggleSection('certifications')" [(ngModel)]="certificationsVisible"
                inputId="certificationsVisible"></p-inputSwitch>

            </div>
            <div class="col unset-padding-left">
              <label for="certificationsVisible">Certifications</label>
            </div>
          </div>

        </div>
        <div class="col">

          <div class="row align-items-center">
            <div class="col-auto">
              <p-inputSwitch (onChange)="toggleSection('languages')" [(ngModel)]="languagesVisible"
                inputId="languagesVisible"></p-inputSwitch>

            </div>
            <div class="col unset-padding-left">
              <label for="languagesVisible">Languages</label>
            </div>
          </div>

        </div>

      </div>

      <div class="row mt-3 mb-2">
        <div class="col-md-12">
          <h5 class="modal-contacts" id="sectionsModalLabel">Contacts</h5>
        </div>
      </div>

      <div class="row">
        <div class="col-md-5">

          <div class="row align-items-center">
            <div class="col-auto">
              <p-inputSwitch (onChange)="toggleSection('email')" [(ngModel)]="emailVisible"
                inputId="emailVisible"></p-inputSwitch>

            </div>
            <div class="col unset-padding-left">
              <label for="emailVisible">Email</label>
            </div>
          </div>

        </div>
        <div class="col-md-5">

          <div class="row align-items-center">
            <div class="col-auto">
              <p-inputSwitch (onChange)="toggleSection('linkedin')" [(ngModel)]="lnkdinVisible"
                inputId="lnkdinVisible"></p-inputSwitch>

            </div>
            <div class="col unset-padding-left">
              <label for="lnkdinVisible">Linkedin</label>
            </div>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-md-5">

          <div class="row align-items-center">
            <div class="col-auto">
              <p-inputSwitch (onChange)="toggleSection('contactno')" [(ngModel)]="contactnoVisible"
                inputId="contactnoVisible"></p-inputSwitch>

            </div>
            <div class="col unset-padding-left">
              <label for="contactnoVisible">Phone No</label>
            </div>
          </div>

        </div>
        <div class="col-md-5">

          <div class="row align-items-center">
            <div class="col-auto">
              <p-inputSwitch (onChange)="toggleSection('twitter')" [(ngModel)]="twitterVisible"
                inputId="twitterVisible"></p-inputSwitch>

            </div>
            <div class="col unset-padding-left">
              <label for="twitterVisible">Twitter</label>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>

  <p-footer>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col">
          </div>
          <div class="col">
            <button #closeProjectModal (click)="sectionsModal = false" type="button"
              class="btn btn-secondary">Close</button>
          </div>

        </div>

      </div>
    </div>
  </p-footer>
</p-dialog>
<!-- end section visible dialog -->