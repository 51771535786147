<div class="row" *ngIf="isLoadingPage">
    <div class="col-md-12">
        <div class="load-container">
            <div class="outer-ring">
            </div>
          </div>
    </div>
</div>

<!-- start create recruiter mode -->
<div *ngIf="dashboardMode === CREATE && !isLoadingPage" class="row pb-db">
    <app-pricing-plan (cancelEvent)='verifyPayment(appUser.email)'></app-pricing-plan>
</div>
<!-- end create recruiter mode -->

<!-- start diplay recruiter/s mode -->
<div *ngIf="dashboardMode === DISPLAY && !isLoadingPage" class="row">
    <div class="col-12">

        <div class="row dashboard-background text-center mt-3">
            <div class="col-12">

                <div class="row mt-3 mb-3">
                    <div class="col-12">
                        <span class="dashboard">
                            Company Dashboard
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="dashboardMode === DISPLAY && showChart" class="container">
            <div class="row">
                <div class="col-12 d-flex justify-content-center">

                </div>
            </div>
        </div>

        <!-- start add btn -->
        <div class="row mt-table">
            <div class="col-10 offset-1">
                <div class="row">
                   <div class="col-md-6">
                        <form [formGroup]="recruiterForm" (ngSubmit)="onSubmit()">

                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <div>
                                            <label for="email" class="login-labels">Email:</label>
                                        </div>
        
        
                                        <input class="form-control curved" type="text" id="email" formControlName="email">
                                        <div class="text-danger mt-2" *ngIf="submitted && (
                                        (recruiterForm.controls['email'].errors && recruiterForm.controls['email'].errors['required'])
                                        )">
                                          Email is required
                                        </div>
                                        <div class="text-danger mt-2" *ngIf="submitted && (
                                        (recruiterForm.controls['email'].errors && recruiterForm.controls['email'].errors['invalidEmail'])
                                        )">
                                          Email format is invalid
                                        </div>
                                              
                                    </div>
                                </div>
                                <div class="col-md-4">
                                   
                                        <button [disabled]="processing" class="btn btn-primary form-control rounded-pill" type="submit">Add Recruiter
                                          <div *ngIf="processing" class="spinner-border spinner-border-sm" role="status">
                                          </div>
                                        </button>
                                    
                                </div>
                            </div>
                        
          
                        </form>
                   </div>
                        <!-- <button (click)="addAnotherRecruiter()" style="width: 200px !important;"
                            class="btn btn-primary form-control rounded-pill" type="button">Add Recruiter
                        </button> -->

                </div>
            </div>
        </div>
        <!-- end add btn -->

        <!-- start table -->
        <div class="row mt-2">
            <div class="col-10 offset-1">

                <p-table dataKey="vanity_name" [autoLayout]="true" [loading]="loading"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                    [showCurrentPageReport]="true" [paginator]="true" [rows]="5" [value]="recruiters">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Recruiter</th>
                            <th>Email</th>
                            <th># of Candidates</th>
                            <!-- <th>Monthly Bill</th> -->
                            <th>Created</th>
                            <th>LoggedIn</th>
                            <th style="width: 5%"></th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-rec>
                        <tr>
                            <td *ngIf="rec.first_name && rec.last_name">{{rec.first_name}} {{rec.last_name}}</td>
                            <td *ngIf="!rec.first_name && !rec.last_name">(NOT YET REGISTERED)</td>

                            <td>{{rec.recruiter_email}}</td>
                            <td>{{rec.client_count}}</td>
                            <!-- <td>{{rec.client_count}} (${{rec.client_count * CLIENT_COST}}) </td> -->
                            <!-- <td>{{rec.bill | currency }}</td> -->
                            <td>{{rec.created | date: 'd MMM y'}}</td>

                            <td *ngIf="rec.first_name && rec.last_name"> Yes </td>
                            <td *ngIf="!rec.first_name && !rec.last_name">
                                <span (click)="confirmResend(rec)" class="custom-link">
                                    <i class="fa fa-envelope-o fa-lg" aria-hidden="true"></i>
                                </span>
                            </td>

                            <td>
                                <span (click)="confirm(rec)" class="custom-link">
                                    <i class="fa fa-trash trash fa-lg" aria-hidden="true"></i>
                                </span>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td [attr.colspan]="7" class="text-center">
                                <img src="assets/img/table-empty-state.png" class="png-empty-table-img">
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

            </div>
        </div>
        <!-- end table -->

    </div>
</div>
<!-- end diplay recruiter/s mode -->

<p-confirmDialog key="confirmDelRecDialog" header="Confirmation" icon="pi pi-exclamation-triangle"
    rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<p-confirmDialog key="confirmSendEmailDialog" header="Confirmation" icon="pi pi-exclamation-triangle"
    rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<ng-template #customTooltip let-model="model">
    <div class="custom-tooltip">
        <p>{{ model.data.name }}</p>
        <p>{{ model.data.value | currency: 'USD' }}</p>
    </div>
</ng-template>