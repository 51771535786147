import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ResumeListComponent } from './resume-list/resume-list.component';
import { ResumeDetailsComponent } from './resume-details/resume-details.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { ResumePreviewComponent } from './resume-preview/resume-preview.component';
import { JdAlignmentComponent } from './jd-alignment/jd-alignment.component';
import { LandingComponent } from './landing/landing.component';
import { PaymentOptionsComponent } from './payment-options/payment-options.component';
import { RecruiterLoginComponent } from './recruiter-login/recruiter-login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CompanyRegisterComponent } from './company-register/company-register.component';
import { CompanyDashboardComponent } from './company-dashboard/company-dashboard.component';
import { RecruiterCreateComponent } from './recruiter-create/recruiter-create.component';
import { ResumeEditComponent } from './resume-edit/resume-edit.component';
//import { Template1Component } from './template1/template1.component';
import { AuthGuard } from './helpers/auth.guard';
import { PricingPlanComponent } from './pricing-plan/pricing-plan.component';
import { CompanyCreateComponent } from './company-create/company-create.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'token-expired', component: LoginComponent },
  { path: 'recruiter-login', component: RecruiterLoginComponent },
  { path: 'recruiter-create', component: RecruiterCreateComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'password-reset', component: ResetPasswordComponent },
  { path: 'resume-list', component: ResumeListComponent, canActivate: [AuthGuard]},
  { path: 'template-list', component: ResumeListComponent, canActivate: [AuthGuard]},
  { path: 'resumedetails', component: ResumeDetailsComponent, canActivate: [AuthGuard]},
  { path: 'alignment', component: JdAlignmentComponent, canActivate: [AuthGuard]},
  // { path: 'profile', component: ResumePreviewComponent,data: {profile_data: 'some value'}},
  { path: 'profile', component: LandingComponent, data: {profile_data: 'some value'}},
  { path: 'payment', component: PaymentOptionsComponent, canActivate: [AuthGuard]},
  { path: 'company-register', component: CompanyRegisterComponent },
  { path: 'companyregister', component: CompanyRegisterComponent },
  { path: 'company-login', component: RecruiterLoginComponent },
  { path: 'companylogin', component: RecruiterLoginComponent },
  { path: 'company-create', component: CompanyCreateComponent },
  { path: 'company-dashboard', component: CompanyDashboardComponent, canActivate: [AuthGuard]},
  { path: 'companyDashboard', component: CompanyDashboardComponent, canActivate: [AuthGuard]},
  { path: 'pricing-plan', component: PricingPlanComponent, canActivate:[AuthGuard]},

  // { path: 'vanity-name', component: UserDetailsComponent},
  { path: 'vanity-name', component: LoginComponent},
  { path: 'user-details', component: UserDetailsComponent, canActivate: [AuthGuard]},
  { path: 'resume-edit', component: ResumeEditComponent, canActivate: [AuthGuard]},
  { path: 'resume-preview', component: ResumePreviewComponent, canActivate: [AuthGuard], data: {profile_data: 'some value'}},
  {
    path: 'templates',
    loadChildren: () =>
      import(`./templates/templates.module`).then((m) => m.TemplatesModule),
  },
  //{ path: 'template1', component: Template1Component}
  { path: 'not-found', component: NotFoundComponent },  
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
