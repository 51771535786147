<p-toast></p-toast>

<!-- start header -->


<!-- lg screen header -->
<div *ngIf="isLoggedIn" class="container d-none d-lg-block">
  <div class="container-fluid ">
<header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 ">
  
  <span (click)="clientLogin()" class="d-flex align-items-center  mb-2 mb-md-0 mr-5 text-dark text-decoration-none">
    <img src="assets/img/xcv-logo-header.svg" alt="xcv-logo" class="img-logo">
  </span>

  <ul class="nav mb-2 justify-content-between mb-md-0 " style="padding-left: 20px;">
    <li class="home-text"><span (click)="reRouteToHome()" class="nav-link   link-dark cursor-pointer">Home</span></li>
    <li *ngIf="appUser && appUser.user_type !== 'RECRUITER'" class="payment-plans-text"><span (click)="reRouteToPricing()" class="nav-link  link-dark cursor-pointer">Payment Plans</span></li>
    <li class="support-text" (click)="openSupportModal()"><span  data-bs-toggle="modal"  data-bs-target="#exampleModal" class="nav-link link-dark cursor-pointer">Support</span></li>
  </ul>

  <div class="text-end  align-items-center justify-content-end">
    <div class="d-flex align-items-start">
            <span  class="px-1 d-block link-dark text-decoration-none "  aria-expanded="false">
                <p-avatar shape="circle" size="large" [style]="{ 'background-color': '#A12D81', color: '#ffffff' }"
                            label="{{initials.substring(0, 2)}}"></p-avatar>
            </span>
            <div class="text-end">
              <div class="text-end">Hi {{name}}</div>
              <div *ngIf="appUser && (appUser.email || appUser.vanity_name)" class="text-end">
                  <span class="subs_status">
                      ({{appUser.user_type === 'CLIENT' ? appUser.vanity_name : appUser.email}})
                  </span>
              </div>

              <div *ngIf="appUser && appUser.user_type === 'COMPANY'" class="dropdown text-end">
                <a href="#" class="d-block link-dark text-decoration-none " id="dropdownUser1" data-bs-toggle="dropdown">
                  <div><span class="sub-header-text  cursor-pointer" href="#"><i class="fa fa-chevron-down "></i> Details</span></div>
                </a>
                <ul class="dropdown-menu text-small" aria-labelledby="dropdownUser1" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 34px, 0px);" data-popper-placement="bottom-start">
                  <li><span class="dropdown-item">
                    Recruiters : {{appUser.total_recruiters ? appUser.total_recruiters :0}}
                </span></li>
                <ng-container *ngIf="showStatusdetails">
                <li *ngIf="!subscriptionDetails?.exception"><a class="dropdown-item">Status:
                    <span *ngIf="subscriptionDetails?.status === 'active'">Active</span>
                    <span *ngIf="subscriptionDetails?.status === 'canceled'">Cancelled</span>
                    <span *ngIf="subscriptionDetails?.status === 'trialing'">Trial</span>
                    <span *ngIf="!subscriptionDetails?.status">#</span>
                  </a></li>
                <li *ngIf="!(subscriptionDetails?.exception)"><a class="dropdown-item">No. of Licenses:
                  <span *ngIf="subscriptionDetails.status === 'trialing'">1</span>
                  <span *ngIf="subscriptionDetails.status !== 'trialing'">{{noOfLicenses}}</span>
                  <!-- <span *ngIf="subscriptionDetails.payment_type == 1">1</span>
                  <span *ngIf="subscriptionDetails?.payment_type == 3">3</span>
                  <span *ngIf="subscriptionDetails?.payment_type == 10">10</span>   
                  <span *ngIf="!subscriptionDetails?.payment_type">0</span> -->
                </a></li>
                <li *ngIf="!subscriptionDetails?.exception"><a class="dropdown-item">
                  <span *ngIf="subscriptionDetails?.status === 'active'">Renewal Date: {{subscriptionDetails.ends_at | date: 'longDate'}}</span>
                  <span *ngIf="subscriptionDetails?.status === 'canceled'">Valid Till: {{subscriptionDetails.ends_at | date: 'longDate'}}</span>
                  <span *ngIf="subscriptionDetails?.status === 'trialing'">Trial End Date: In {{trialLimit}} days</span> 
                </a></li>
              </ng-container>
                </ul>
              </div>

              
              <div><span (click)="reRouteToLogin()" class="sub-header-text  cursor-pointer" href="#"><i class="fa fa-sign-out"></i> Log out</span></div>
            </div>
    </div>
  </div>
</header>
</div>
</div>


<!-- md screen header -->
<div *ngIf="isLoggedIn" class="container d-block d-lg-none mt-xs-2 mt-sm-3 mt-md-4 mb-md-4 mb-sm-2">
<nav class="navbar navbar-expand-lg  navbar-light " #navbarNavDropdown>
  <div class="container-fluid ">

    
    <!-- Logo Section -->
    <span (click)="clientLogin()" class="text-dark text-decoration-none">
      <img src="assets/img/xcv-logo-header.svg" alt="xcv-logo" class="img-logo">
    </span>
    <!-- End -->

    <!-- Header Links Section -->
    <div class="d-none d-sm-block"><ul class="nav  mb-2 justify-content-center mb-md-0 ">
      <li><span (click)="reRouteToHome()" class="nav-link   link-dark cursor-pointer">Home</span></li>
      <li *ngIf="appUser && appUser.user_type !== 'RECRUITER'" ><span (click)="reRouteToPricing()" class="nav-link   link-dark cursor-pointer">Payment Plans</span></li>
      <li (click)="openSupportModal()"><span  data-bs-toggle="modal"  data-bs-target="#exampleModal" class="nav-link  link-dark cursor-pointer">Support</span></li>
    </ul></div>
    <!-- End -->


    <!-- Hide on the small screens -->
    <div class="col-md-3 text-end d-flex align-items-center justify-content-end d-none d-lg-block">
      <div class="d-flex align-items-start">
              <span  class="px-1 d-block link-dark text-decoration-none "  aria-expanded="false">
                  <p-avatar shape="circle" size="large" [style]="{ 'background-color': '#A12D81', color: '#ffffff' }"
                              label="{{initials.substring(0, 2)}}"></p-avatar>
              </span>
              <div class="text-end">
                <div class="text-end">Hi {{name}}</div>
                <div *ngIf="appUser && (appUser.email || appUser.vanity_name)" class="text-end">
                    <span class="subs_status">
                        ({{appUser.user_type === 'CLIENT' ? appUser.vanity_name : appUser.email}})
                    </span>
                </div>

                <div class="dropdown text-end" *ngIf="appUser && appUser.user_type === 'COMPANY'">
                  <a href="#" class="d-block link-dark text-decoration-none " id="dropdownUser1" data-bs-toggle="dropdown">
                    <div><span class="subs_status cursor-pointer" href="#"><i class="fa fa-cog"></i> Details</span></div>
                  </a>
                  <ul class="dropdown-menu text-small" aria-labelledby="dropdownUser1" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 34px, 0px);" data-popper-placement="bottom-start">
                    <li><span class="dropdown-item">
                      Recruiters : {{appUser.total_recruiters ? appUser.total_recruiters :0}}
                  </span></li>
                  <ng-container *ngIf="showStatusdetails">
                  <li *ngIf="!subscriptionDetails?.exception"><a class="dropdown-item">Status:
                      <span *ngIf="subscriptionDetails?.status === 'active'">Active</span>
                      <span *ngIf="subscriptionDetails?.status === 'canceled'">Cancelled</span>
                      <span *ngIf="subscriptionDetails?.status === 'trialing'">Trial</span>
                      <span *ngIf="!subscriptionDetails?.status">#</span>
                    </a></li>
                  <li *ngIf="!subscriptionDetails?.exception"><a class="dropdown-item">No. of Licenses:
                    <span *ngIf="subscriptionDetails.status === 'trialing'">1</span>
                    <span *ngIf="subscriptionDetails.status !== 'trialing'">{{noOfLicenses}}</span>
                    <!-- <span *ngIf="subscriptionDetails.payment_type == 1">1</span>
                    <span *ngIf="subscriptionDetails?.payment_type == 3">3</span>
                    <span *ngIf="subscriptionDetails?.payment_type == 10">10</span>   
                    <span *ngIf="!subscriptionDetails?.payment_type">0</span> -->
                  </a></li>
                  <li *ngIf="!subscriptionDetails?.exception"><a class="dropdown-item">
                    <span *ngIf="subscriptionDetails?.status === 'active'">Renewal Date: {{subscriptionDetails.ends_at | date: 'longDate'}}</span>
                    <span *ngIf="subscriptionDetails?.status === 'canceled'">Valid Till: {{subscriptionDetails.ends_at | date: 'longDate'}}</span>
                    <span *ngIf="subscriptionDetails?.status === 'trialing'">Trial End Date: In {{trialLimit}} days</span> 
                  </a></li>
                </ng-container>
                  </ul>
                </div>

                
                <div><span (click)="reRouteToLogin()" class="subs_status cursor-pointer" href="#"><i class="fa fa-sign-out"></i> Log out</span></div>
              </div>
      </div>
    </div>
    <!-- End -->


    <!-- Small Screen Drop down Section -->
    <button (click)="toggleNavbarCollapsing()" class="navbar-toggler" type="button" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="container">
      <div class="row justify-content-end">
     <div [class.collapse]="navbarCollapsed" class=" justify-content-center bg-light shadow mt-2 d-lg-none d-xl-none p-2" style="border-radius: 10px; max-width: 450px;" id="navbarNavDropdown">
      <div class="container-fluid" style="max-width: 450px;">
        <div class="row">
          <div class="col-12">
            <ul class="navbar-nav p-2 d-lg-none d-xl-none">
              <li class="nav-item">
                <div class="d-flex text-black">
                  <div class="flex-shrink-0">
                    <p-avatar shape="circle" size="large" [style]="{ 'background-color': '#A12D81', color: '#ffffff' }"
                            label="{{initials.substring(0, 2)}}"></p-avatar>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h6 class="mb-1">Hi {{name}}</h6>
                    <p class="mb-2 pb-1" style="color: #2b2a2a;">({{appUser.user_type === 'CLIENT' ? appUser.vanity_name : appUser.email}})</p>
                  </div>
                </div>
              </li>
              <li *ngIf="appUser && appUser.user_type === 'COMPANY'" class="nav-item pl-5">
                <a class="nav-link link-dark " id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="fa fa-caret-down fa-lg"></i>  Details
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li><span class="dropdown-item">
                    Recruiters : {{appUser.total_recruiters ? appUser.total_recruiters :0}}
                </span></li>
                <ng-container *ngIf="showStatusdetails">
                <li *ngIf="!subscriptionDetails?.exception"><a class="dropdown-item">Status:
                    <span *ngIf="subscriptionDetails?.status === 'active'">Active</span>
                    <span *ngIf="subscriptionDetails?.status === 'canceled'">Cancelled</span>
                    <span *ngIf="subscriptionDetails?.status === 'trialing'">Trial</span>
                    <span *ngIf="!subscriptionDetails?.status">#</span>
                  </a></li>
                <li *ngIf="!subscriptionDetails?.exception"><a class="dropdown-item">No. of Licenses:
                  <span *ngIf="subscriptionDetails.status === 'trialing'">1</span>
                  <span *ngIf="subscriptionDetails.status !== 'trialing'">{{noOfLicenses}}</span>
                  <!-- <span *ngIf="subscriptionDetails?.payment_type == 3">3</span>
                  <span *ngIf="subscriptionDetails?.payment_type == 10">10</span>   
                  <span *ngIf="!subscriptionDetails?.payment_type">0</span> -->
                </a></li>
                <li *ngIf="!subscriptionDetails?.exception"><a class="dropdown-item">
                  <span *ngIf="subscriptionDetails?.status === 'active'">Renewal Date: {{subscriptionDetails.ends_at | date: 'longDate'}}</span>
                  <span *ngIf="subscriptionDetails?.status === 'canceled'">Valid Till: {{subscriptionDetails.ends_at | date: 'longDate'}}</span>
                  <span *ngIf="subscriptionDetails?.status === 'trialing'">Trial End Date: In {{trialLimit}} days</span> 
                </a></li>
              </ng-container>
                </ul>
              </li>
              <li class="nav-item">
                <span (click)="reRouteToLogin()" class="cursor-pointer"><i class="fa fa-sign-out"></i>  Log out</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
    <!--End  -->

  </div>
</nav>

<!-- show this on small screens as a new line -->
<div class="d-block d-sm-none"><ul class="nav  mb-2 justify-content-center mb-md-0 ">
  <li><span (click)="reRouteToHome()" class="nav-link   link-dark cursor-pointer">Home</span></li>
  <li *ngIf="appUser && appUser.user_type !== 'RECRUITER'"><span (click)="reRouteToPricing()" class="nav-link   link-dark cursor-pointer">Payment Plans</span></li>
  <li (click)="openSupportModal()"><span  data-bs-toggle="modal"  data-bs-target="#exampleModal" class="nav-link  link-dark cursor-pointer">Support</span></li>
</ul></div>
</div>

<div (click)="onDocumentClick($event)" class="overlay" *ngIf="isNavbarExpanded"></div>




<!-- end header -->
<router-outlet></router-outlet>

<!-- start footer -->
<ng-container>
  <div *ngIf="isLoggedIn" class="row footer mt-3 pt-3 pb-3 header-border">
    <div class="col ml">

      <p class="contact">
        <a href="https://www.facebook.com/profile.php?id=61558094197171" target="_blank">
        <span>
          <i class="fa fa-facebook" aria-hidden="true"></i> 
        </span>
      </a>
      <a href="https://twitter.com/xCV_ai3" target="_blank">
        <span class="ml-1">
          <i class="fa fa-twitter" aria-hidden="true"></i>
        </span>
      </a>
      <a href="https://www.linkedin.com/company/xcv-ai/" target="_blank">
        <span class="ml-1">
          <i class="fa fa-linkedin" aria-hidden="true"></i> 
        </span>
      </a>
      <a href="https://www.instagram.com/xcvai3/" target="_blank">
        <span class="ml-1">
          <i class="fa fa-instagram" aria-hidden="true"></i> 
        </span>
      </a>
      </p>

    </div>
    <div class="col text-end mr">
      <span class="rights-reserved">
        &copy;2023 All rights reserved
      </span>
    </div>

  </div>
</ng-container>
<!-- end footer -->

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Support</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal" 
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form #form="ngForm" novalidate>
          <!-- Other fields -->     
          <div class="mb-3">
            <label for="emailInput" class="form-label">Email address*</label>
            <input
              type="email"
              class="form-control"
              id="emailInput"
              [(ngModel)]="email"
              name="email"
              aria-describedby="emailHelp"
              value="appUser.email"
              [disabled]="(appUser.user_type !== 'CLIENT')"
              #emailField="ngModel"
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            />
          </div>
          <div class="mb-3">
            <label for="subject" class="form-label">Subject*</label>
            <input
              type="text"
              class="form-control"
              id="subject"
              [(ngModel)]="subject"
              name="subject"
              #subjectField="ngModel"
              required
            />
            <!-- Validation message -->
            <!-- <div *ngIf="subjectField.invalid && subjectField.touched" class="text-danger">
              Subject is required.
            </div> -->
          </div> 
          <div class="mb-3">
            <label for="descriptionTextarea" class="form-label"
              >Message</label
            >
            <textarea
              class="form-control"
              id="descriptionTextarea"
              rows="3"
              [(ngModel)]="message"
              name="message"
            ></textarea>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button
        type="button"
        class="btn btn-primary"
        (click)="logFormData()"
        data-bs-dismiss="modal"
        [disabled]="form.invalid"
      >
        Send
      </button>
      </div>
    </div>
  </div>
</div>
