import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../core/shared.service';
import { Subscription } from 'rxjs';
import { UserService } from '../core/user.service';
import { DataService } from 'app/helpers/data.service';
import { LocalStorageService } from 'app/core/local-storage.service';
import { MessageService } from 'primeng/api';
import { AytHttpParams } from 'app/helpers/http-config';
import { Constants } from 'app/helpers/app-settings';
import { AppUser } from 'app/model/Userprofile';
import { XcvUtilsService } from 'app/core/xcv-utils.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterViewInit, OnInit {
  public SignupForm !:FormGroup;
  public isShowForm:boolean = false;
  public loggedInUser:any;
  public id = '';
  public unique_id = '';
  public first_name = '';
  public last_name = '';
  public forVanity = false;
  public vanityForm !: FormGroup;
  public processing = false;
  public processingConfirm = false;
  public submitted = false;
  public payload: any = {};
  public customDialogClass: string = 'curved';
  public displayDialog: boolean = false;
  public isSmallScreen = false;
  public userAgreementModal = false;
  public agreeBtnDisabled = true;
  public selectedFile!: File;
  public fileSelected = false;
  public token = '';
  public vanityName:any;
  public loading:boolean = false;
  private subscription!: Subscription;


  // linkedInCredentials = {
  //   clientId: "86lukxm5jr2z3u",
  //   redirectUrl: "/login",
  //   scope: "r_liteprofile%20r_emailaddress%20w_member_social" // To read basic user profile data and email
  // };


  constructor(private fb: FormBuilder,
    private router:Router,
    private messageService: MessageService,    
    private localStorageService: LocalStorageService,
    private http:HttpClient,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,    
    private dataService: DataService,
    private sharedService: SharedService) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  private checkScreenSize() {
      const isSmallScreen = window.innerWidth <= 767; // Match the CSS media query width
      this.isSmallScreen = isSmallScreen;
      // You can also dynamically apply a CSS class if needed
      if (isSmallScreen) {
          this.displayDialog = false;
      } else {
          this.displayDialog = true;
      }
  }

  ngOnInit(): void {
    this.checkScreenSize();
    this.subscribeToQueryParams();
    this.vanityForm = this.fb.group({
      vanityform: ['', [Validators.required]]
    });

    // Do not clean, other will be using storage
    //localStorage.clear();

    this.SignupForm = this.fb.group({
      username: [''],
      password: [''],
    });
  }

  private subscribeToQueryParams() {
    this.subscription = this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.hasOwnProperty('id') && queryParams.id !== '') {
        this.id = queryParams.id;
        this.unique_id = this.id;
      }
      if (queryParams.hasOwnProperty('first_name') && queryParams.first_name !== '') {
        this.first_name = queryParams.first_name;
      }
      if (queryParams.hasOwnProperty('last_name') && queryParams.last_name !== '') {
        this.last_name = queryParams.last_name;
      }
      if (queryParams.hasOwnProperty('token') && queryParams.token !== '') {
        this.token = queryParams.token;
      }

      if (XcvUtilsService.isNotNullOrEmpty(this.token)) {
        // came from email link, but the token has expired
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `The token has expired.`, life: 7000});
      }

      if (this.id && this.first_name && this.last_name) {
        this.forVanity = true;
      } else {
        this.forVanity = false;
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.dataService.setData('false');
    }, 10);
  }

  public recruiterLogin(): void {
    this.router.navigate(['/recruiter-login']);
  }

  private padNumber(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }  

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padNumber(date.getMonth() + 1);
    const day = this.padNumber(date.getDate());
    return `${year}-${month}-${day}`;
  }

  public submitVanityInfo(): void {

    if(this.vanityForm.dirty)
    {
      this.submitted = true;
      if (this.vanityForm.valid) {
  
        // check first if vanity is valid
        let v: string = this.vanityForm.controls['vanityform'].value;
        if (this.isVanityValid2(this.first_name, this.last_name, v)) {
  
          // use the new endpoint to validate the vanity name from the backend
          let p = new AytHttpParams();
          p.set('vanity_name', v);
          this.processing = true;
          this.subscription = this.userService.verifyVanity(p).subscribe({
            next: response => {
              this.forVanity = false;
              this.userAgreementModal = true;
            },
            error: error => {
              this.processing = false;
              XcvUtilsService.handleError(error, this.messageService);
            }
          });
  
        } else {
          this.messageService.add({ severity: 'warn', summary: 'Warn', detail: 'Vanity name is invalid', life: 5000});
        }
   
      }
    }
    else
    {
      if (this.selectedFile && this.fileSelected) {
        let generatedVanity = this.first_name+'-'+this.last_name;
        let p = new AytHttpParams();
        p.set('vanity_name', generatedVanity);
        
        this.subscription = this.userService.verifyVanity(p).subscribe({
          next: response => {
            this.messageService.add({ severity: 'warn', summary: 'Warn', detail: 'Vanity name already exists', life: 5000});
          },
          error: error => {
          
            //XcvUtilsService.handleError(error, this.messageService);
          this.updateResume(generatedVanity);
          }
        });    
 
        // this is for file upload, ignore the vanity form
  
        //this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `Uploading for ${this.selectedFile.name} (${this.selectedFile.size} bytes), when the backend is ready. Remove the pdf to use vanity.`, life: 5000});
      }
    }

  }

  public updateResume(vanity:any):void {
    //console.log('test')
  //  let user = this.localStorageService.getData('userClient');
  let payload = {
    vanity_name: vanity,
    payload: {
      section_attr: {
        first_name: this.first_name,
  last_name: this.last_name
      }
    }
  }
    this.subscription = this.userService.updateResume(payload).subscribe({
      next: response => {
      this.parsePDF();
      },
      error: error => {
        this.processing = false;
        XcvUtilsService.handleError(error, this.messageService);
      }
    });
  }

  public parsePDF(){
    this.loading = true;
           const reader = new FileReader();
        reader.readAsDataURL(this.selectedFile);
        reader.onload = () => {
          //console.log(reader.result);
          let payload: any = {};
          var base64result = (<string>reader.result).split(',')[1];
          payload.base64encoded = base64result;
          // console.log(base64result)
          this.subscription = this.userService.parsePDF(payload).subscribe({
            next: response => {
              // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Resume has been successfully created', life: 5000});
              this.userAgreementModal = true;
              this.vanityName = response.vanity_name;
              this.loading = false;
            },
            error: error => {
              this.processing = false;
              XcvUtilsService.handleError(error, this.messageService);
              this.loading = false;
            }
          });
  
        };
  }

  public isVanityValid(fn: string, ln: string, vanity: string): boolean {
    let isValid = true;

    const inputString = vanity; // Your input string
    const firstName = fn; // First name to search for (case-insensitive)
    const lastName = ln; // Last name to search for (case-insensitive)    

    // Create a case-insensitive regex pattern
    const regexPattern = new RegExp(`${firstName}.*${lastName}`, 'i');

    // Test the input string against the regex pattern
    // const isMatch = regexPattern.test(inputString);

    isValid = regexPattern.test(inputString);

    // if (isMatch) {
    //   console.log('Both first name and last name are present.');
    // } else {
    //   console.log('Either first name or last name is missing.');
    // }    
    return isValid;

  }

  public isVanityValid2(fn: string, ln: string, vanity: string) {
    let valid = false;

    const inputString = vanity; // Your input string
    const firstName = fn; // First name to search for (case-insensitive)
    const lastName = ln; // Last name to search for (case-insensitive)
    
    // Create a case-insensitive regex pattern for the full name
    const fullNamePattern = new RegExp(`${firstName}.*${lastName}`, 'i');
    
    // Test the input string against the full name regex pattern
    const isFullNameMatch = fullNamePattern.test(inputString);
    
    if (isFullNameMatch) {
      console.log('Full name is valid.');
      valid = true;
    } else {


      // Create case-insensitive regex patterns for first name and last name individually
      const firstNamePattern = new RegExp(firstName, 'i');
      const lastNamePattern = new RegExp(lastName, 'i');
    
      // Test the input string against the first name and last name regex patterns
      const isFirstNameMatch = firstNamePattern.test(inputString);
      const isLastNameMatch = lastNamePattern.test(inputString);
    
      if (isFirstNameMatch && isLastNameMatch) {
        valid = true;
        console.log('Both first name and last name are present.');
      } else {
        valid = false;
        console.log('Either first name or last name is missing.');
      }
    }
    
    return valid;
  }


  public loginNew() {

    this.subscription = this.userService.authLogin()
    .subscribe({
      next: response => {
        if (response) {

          console.log('response = ', response);

        }
      },
      error: error => {}
    });


  }

// public login() {
//  this.http.get<any>("http://localhost:3000/users").subscribe(res=>{
//   const user = res.find((a:any)=>{
//     return a.uname === this.SignupForm.value.username && a.pwd === this.SignupForm.value.password
//   })
//   this.loggedInUser = user;
//   if(user){
//     alert("Login success");
//     this.SignupForm.reset();
//     this.sharedService.userToEdit = user.id;
//     this.router.navigate(['user-details'])
//   }
//   else {
//     alert("user not found")
//   }
//  },err=>{
//   alert("Something went wrong")
//  })
//   }

login() {
  //window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86lukxm5jr2z3u&scope=r_liteprofile&state=123456&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fresumedetails`;
  this.router.navigate(['resume-preview'])
}


// getprofile(){
//   this.http.get<any>('https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86lukxm5jr2z3u&scope=r_liteprofile&state=123456&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fresume-details').subscribe(
//     {
//       next: response => {
    
//         localStorage.setItem('data',response)
//         this.router.navigate(['resume-list'])
//       },
//       error: error => console.log(error)
//     }

//   )
// }

  public executeSubmitVanityInfo(): void {
    this.processing = false;
    // vanity name is valid, then proceed to submit

    let payload: any = {};
    if(this.vanityForm.controls['vanityform'].value)
    {
      payload.vanity_name = this.vanityForm.controls['vanityform'].value;
    }
    else
    {
      payload.vanity_name =this.vanityName;
    }
    
    payload.first_name = this.first_name;
    payload.last_name = this.last_name;
    payload.unique_id = this.unique_id;
    const today = new Date();
    const formattedDate = this.formatDate(today);
    payload.date_subscribed = formattedDate;
    // payload.llm_tokens = Constants.STARTING_TOKEN;
    payload.tokens_consumed = "0";
    payload.tokens_limit = "0";

    this.payload = payload;
    this.processing = true;
    this.subscription = this.userService.submitVanity(payload).subscribe({
      next: response => {
        this.processing = false;
        this.processingConfirm = false;
        if (response) {
          if (response === 'Candidate Added') {
            // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Resume has been successfully created', life: 5000});

            // save to local stoage
            const data = { vanity_name: this.payload.vanity_name};
            this.localStorageService.saveData('userVanity', data);

            // save to local storage the new app user
            let user: AppUser = { vanity_name: this.payload.vanity_name};
            user.user_type = Constants.CLIENT;
            this.localStorageService.saveData('appUser', user);

            // set on the header
            this.dataService.setAppUser(user);

            // test get from local
            console.log('gotten from local storeage = ', this.localStorageService.getData('userVanity'));

            this.router.navigate(
              ['/profile'],
              { queryParams: { vanity_name: payload.vanity_name } }
            );

          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Vanity Name', life: 5000});
            console.log('>> no response ');
          }

        } else {
          console.log('>> no response');
          alert('>>>> vnilty login issue');
        }
      },
      error: error => {
        this.processing = false;
        this.processingConfirm = false;
        XcvUtilsService.handleError(error, this.messageService);
      }
    });
  }

  public cancelAgree(): void {
    this.userAgreementModal = false;
    this.agreeBtnDisabled = true;
    this.router.navigate(
      ['/login']);
  }

  public confirmAgree(): void {
    this.processingConfirm = true;
    // this.updateResume();
    this.executeSubmitVanityInfo();
  }

  public onScroll(event: Event) {
    // Check if the user has scrolled to the very bottom
    const element = event.target as HTMLElement;
    let m = element.scrollHeight - element.scrollTop;

    // i dont know where the .5 diff is coming from
    if ( (element.scrollHeight - element.scrollTop) === element.clientHeight
      || 
      (m - element.clientHeight < 15 )
      ) {
      // User has scrolled to the very bottom
      this.agreeBtnDisabled = false;
      // this.messageService.add({ severity: 'success', summary: '', detail: `Thank you for reading user agreement`, life: 5000});
    }
  }

  public clientLogin() {
    this.rerouteTasks();
    this.router.navigate(['login'])
  }

  public register(): void {
    this.router.navigate(['company-register']);
    // this.router.navigate(['payment'],{ queryParams: {'optionType': 'premium', 'origin': 'recruiter'}})
  }

  private rerouteTasks(): void {
    // remove from storage.
    this.localStorageService.removeData('userVanity');
    this.localStorageService.removeData('userClient');
    this.localStorageService.removeData('subcriptionPlan');
    this.localStorageService.removeData('appUser');
  }

  public handleBoxClick(): void {
    // Trigger the file input when the box is clicked
    const fileInput = document.getElementById('fileInput');

    if (fileInput) {
      // fileInput.value = null;
      fileInput.click();
    }
  }

  public handleFileInput(event: any): void {
    this.selectedFile = event.target.files[0];

    if (this.selectedFile) {
      this.fileSelected = true;

      // You can now work with the selected PDF file
      // For example, you can upload it to a server or process it further.
    }
  }

  public cancelVanityLogin() {
    this.forVanity = false;
  }

  get TOOLTIP_DEFAULT_POSITION() {
    return Constants.TOOLTIP_DEFAULT_POSITION;
  }

  public removeSelectedPDF(): void {
    this.fileSelected = false;
  }

}
