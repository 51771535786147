<div id="containerBody2">

  <div class="color-layer">

    <div class="row align-items-center mt-3">

      <ng-container *ngIf="isSmallScreen">
        <div class="col">

          <div class="row pl justify-content-center">
            <div class="col-auto">
              <span (click)="clientLogin()" class="custom-link">
                <img src="assets/img/xcv-logo-header.svg" alt="xcv-logo">
              </span>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="row pr text-end justify-content-center">
            <div class="col-auto">
            <!-- <button (click)="register()" class="btn btn-primary btn-block rounded-pill2 recruiter-login-btn">Register For Free</button> -->
            <button (click)="recruiterLogin()" class="btn btn-primary btn-block rounded-pill2 recruiter-login-btn">HR / Recruiter Login</button>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!isSmallScreen">
        <div class="col-6">

          <div class="row pl-3 justify-content-center">
            <div class="col-12">

              <span (click)="clientLogin()" class="custom-link">
                <img src="assets/img/xcv-logo-header.svg" alt="xcv-logo">
              </span>
                  
            </div>
          </div>
        </div>
  
        <div class="col-6">

          <div class="row pr-3 text-end justify-content-center">
            <div class="col-12">
              <!-- <button (click)="register()" class="btn btn-primary btn-block rounded-pill2 recruiter-login-btn">Register For Free</button> -->
                   <button (click)="recruiterLogin()" class="btn btn-primary btn-block rounded-pill2 recruiter-login-btn">HR / Recruiter Login</button>
            </div>
          </div>
        </div>

      </ng-container>

    </div>

    <div class="row justify-content-md-center pt-180px">
      <div class="col-md-5 login-title">
        Let AI refine you a resume
      </div>
    </div>
  
    <div class="row mt-5 justify-content-md-center">
      <div class="col-auto login-title2">
        Align a RESUME to any JOB DESCRIPTION with one click -
        Increase your placement velocity 10 fold!
      </div>
    </div>
    <!-- <div class="row mt-5 justify-content-md-center">
      <div class="col-auto login-title3">
        Increase your placement velocity 10 fold
      </div>
    </div> -->


    <div class="row mt-5">
  
      <div class="col-md-3">
      </div>
      
      <div class="col-md-6">

        <div class="row mt-3 justify-content-center align-items-center">

          <div class="col-auto">
            <a class="btn btn-primary btn-block rounded-pill shadow-sm p-2" href="http://api.xcv.ai/client/authorize/">Login with Linkedin</a>
            <!-- <button (click)="recruiterLogin()" class="btn btn-primary btn-block rounded-pill shadow-sm p-2">HR / Recruiter Login</button> -->
          </div>

          <div class="col-auto">
            <span class="learn-more">
             <a href="assets/static/learn-more/index.html" class="learn-more"></a>
            </span>
          </div>

        </div>

      </div>
  
      <div class="col-md-3">
      </div>
  
    </div>
  
    <div class="row justify-content-md-center mt-5">
      <div class="col-auto">

        <div class="row align-items-center justify-content-md-center">
          <div class="col">
            <img src="assets/img/three-person-group.png" alt="32K">
          </div>

          <div class="col">


            <div class="row">
              <div class="col-md-auto">
                <img src="assets/img/4K+.png" alt="Image 4" class="login-4k">
              </div>
            </div>

            <div class="row">
              <div class="col-md-auto">
                <span class="users-worldwide">
                  professionals served
                </span>
              </div>
            </div>

          </div>
          

          <!-- <div class="col-md-5 pl-3">
            <div class="image-container">
              <img src="assets/img/person1.png" alt="Image 1" class="overlay-image">
              <img src="assets/img/person2.png" alt="Image 2" class="overlay-image2">
              <img src="assets/img/person3.png" alt="Image 3" class="overlay-image3">
            </div>
          </div>

          <div class="col-md-7">
            
            <div class="row">
              <div class="col-md-auto">
                <img src="assets/img/4K+.png" alt="Image 4" class="login-4k">
              </div>
            </div>

            <div class="row">
              <div class="col-md-auto">
                <span class="users-worldwide">
                  users worldwide
                </span>
              </div>
            </div>
            
          </div> -->

        </div>
        
      </div>

    </div>
  

    <div class="container mt-5">
      <div class="row justify-content-between">
        <div class="col">
          <p class="contact">
            <a class="social-links" href="https://www.facebook.com/profile.php?id=61558094197171" target="_blank">
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a class="social-links ml-1" href="https://twitter.com/xCV_ai3" target="_blank">
              <!-- <i class="fa fa-twitter ml-1" aria-hidden="true"></i> -->
              <img src="assets/img/twitter-white.png" class="twit-img" alt="">
            </a>
            <a class="social-links" href="https://www.linkedin.com/company/xcv-ai/" target="_blank">
              <i class="fa fa-linkedin ml-1" aria-hidden="true"></i>
            </a>
            <a class="social-links" href="https://www.instagram.com/xcvai3/" target="_blank">
              <i class="fa fa-instagram ml-1" aria-hidden="true"></i>
            </a>
            <a class="social-links" href="https://youtube.com/@xCVai?si=gkjVhNjyxrAsXFA8" target="_blank">
              <i class="fa fa-youtube-play ml-1" aria-hidden="true"></i>
            </a>
          </p>
        </div>
        <div class="col text-end">
          <span class="privacy-policy" (click)="showPrivacyModal()">Privacy Policy</span>
        </div>
      </div>
    </div>
    
  </div>

</div>

<div class="card flex justify-content-center">
  <p-dialog 
      header="Privacy & Policy" 
      [modal]="true"
      [(visible)]="privacyModal" 
      [style]="{ width: '50rem' }" 
      [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
          <h5 class="mb-3">
            Introduction, Acceptance, Definitions, and Modifications</h5>
          <p class="mb-3">
            Welcome to the xcv.ai website located at https:// xcv.ai/ (the “Website”) which includes all subdomains
            present and future. We hope you enjoy your visit and/or use of the Website.
          </p>
          <p class="mb-3">
            By visiting and/or using the Website, you agree to be bound by the terms of this Privacy Policy (the
              “Policy”). By submitting Personal Information to us or on or through the Website or via other means, you
              consent to xcv.ai’s collection, use, and disclosure of such Personal Information (as that term is defined
              hereinbelow) in accordance with this Policy (as amended from time to time) and as permitted or required by
              law. If you are an individual using the Website on behalf of a corporation, partnership, or other legal
              organization (hereinafter “Legal Entity”), you represent and warrant that you have the capacity and
              authority to accept the Policy on behalf of the Legal Entity, and hereby agree to be the collection, use,
              and disclosure of Personal Information on behalf of that Legal Entity.
          </p>
          <p class="mb-3">
            The Website is owned and operated by xcv.ai Inc., a federally-incorporated corporation in the Province of
              Ontario, Canada. Where the present Policy refers to “ xcv.ai”, it may refer to xcv.ai and/or its officers,
              directors, employees, affiliates, agents, or representatives, depending on the context. Any reference to
              “we”, “our”, or “us” in this Policy shall also refer to xcv.ai.
          </p>
          <p class="mb-3">
            In this Policy, a Website visitor or the Legal Entity they represent (depending on the context), may be
              referred to as “you” or “your”.
          </p>
          <p class="mb-3">
            xcv.ai reserves the right, at any time and without prior notice, to modify or replace any of the Policies.
              Any changes to the Policy can be found at this URL. It is your responsibility to check the Policy
              periodically for changes. Your use of the Website following the posting of any changes to the Policy
              constitutes acceptance of those changes. If we make any substantial changes to the Policy that materially
              affect the way we treat your Personal Information, we will use commercially reasonable efforts to notify
              you by posting a prominent notice upon your first visit to the Website following those changes.
          </p>
          <p class="mb-3">
            If you have any questions about the Policy or if you wish to inquire about and/or access any Personal
            Information xcv.ai holds about you,
            please contact: info@ xcv.ai
          </p>
          <p class="mb-1 mt-2"> xcv Inc.</p>
          <p class="mb-1 mt-2"> 18 Wynford Drive,</p>
          <p class="mb-1 mt-2"> Suite 306</p>
          <p class="mb-1 mt-2"> Toronto</p>
          <p class="mb-3 mt-2"> ON M3C 3S2</p>
          
          <h5 class="mb-3">
            General Statement of Privacy and Protection of Personal Information
          </h5>
          <p class="mb-3">
            xcv.ai takes your privacy and security of Personal Information very seriously. We have provided a secure
            environment and this strict Policy that describes the limited ways the information is used and the limited
            access to such information.
          </p>
          <h5 class="mb-3">
            What Does This Privacy Policy Cover?
          </h5>
          <p class="mb-3">
            This Policy covers the treatment of personally-identifiable information, i.e. any information that
              identifies an individual or Legal Entity or can be used in conjunction with other information to identify
              an individual or Legal Entity (“Personal Information”) when you voluntarily submit this information and
              other information gathered by xcv.ai when you are using or accessing the Website. This Policy also covers
              xcv.ai’s treatment of any Personal Information that xcv.ai may share with its business partners or other
              third parties under very limited circumstances.
          </p>
          <p class="mb-3">
            This Policy does not apply to the general practices and treatment of information (whether personal or not)
              by third parties that xcv.ai does not own or control, including but not limited to any third-party
              websites or services that you elect to access through the Website or via a link from the Website
              (“Third-Party Website”), or to individuals that xcv.ai does not manage or employ. While we attempt to
              facilitate access or link only to those Third-Party Websites that share our respect for your privacy, we
              cannot take responsibility for the content or privacy policies of those Third-Party Websites. We encourage
              you to carefully review the privacy policies of any Third-Party Website you access.
          </p>
          <p class="mb-3">
            Finally, this Policy does not cover any Personal Information you send to xcv.ai via any non-secured mode
              of communications such as email, Facebook or Twitter, as communications via these methods may be subject
              to interception, loss, or alteration. While we welcome your comments and suggestions sent to us in this
              manner, we encourage you to carefully examine what Personal Information you send to xcv.ai via these
              methods.
          </p>
          <h5 class="mb-3">
            No Collection of Personal Information as a Matter of Course
          </h5>
          <p class="mb-3">
            xcv.ai does not, as a matter of course, gather any of your Personal Information while you are visiting the
              Website, unless you are asked to provide such information, for example by submitting information through a
              contact form. Personal Information is only gathered with your informed consent. Where you have provided
              Personal Information, it will only be used for the stated purpose or use, as described in the next section
              of this Policy.
          </p>
          
          <h5 class="mb-3">
            Personal Information Collected and its Uses
          </h5>
          <p class="mb-3">
            The following is an exhaustive list of Personal Information collected from you when you use the Website
            and what we use it for. You acknowledge that you are submitting this Personal Information with your
            informed consent. When you submit Personal Information about the Legal Entity you represent, you represent
            and warrant that you have the capacity and authority to submit such Personal Information on their behalf.
          </p>
          <p class="mb-3">
            “Chat / Message Information”: If you use our online chat services, you will be asked to enter Personal
              Information including your name, email, and phone number. We only use this Chat / Message Information to
              respond to your message if the chat is offline or further communicate with you (if necessary) after a live
              chat session.
          </p>
          <p class="mb-3">
            “Registration Information”: If you request an xcv.ai free trial, you will be asked to enter the following
              Personal Information: your first and last names, organization or company name, phone number, and email
              address. We use this Registration Information to communicate with you about your free trial, to determine
              your eligibility for your free trial, and if accepted, to create an account for you in order to use and
              manage your free trial.
            </p>
            <p class="mb-3">
              “Registration Information”: If you request an xcv.ai free trial, you will be asked to enter the following
              Personal Information: your first and last names, organization or company name, phone number, and email
              address. We use this Registration Information to communicate with you about your free trial, to determine
              your eligibility for your free trial, and if accepted, to create an account for you in order to use and
              manage your free trial.
            </p>
            <h5 class="mb-3">
              Security of Information
            </h5>
            <p class="mb-3">
              xcv.ai uses only industry best practices (physical, electronic, and procedural) in keeping any data
              collected (including Personal Information) secure. The Website is hosted on xcv.ai’s own servers and as
              such is protected by our very high standard of security.
            </p>
            
            <h5 class="mb-3">
              Non-disclosure of Personal Information to Third Parties
            </h5>
            <p class="mb-3">
              Except as provided in the previous section in this Policy on Security of Information, the next paragraphs
              of this section, and the next section in this Policy on Email Communication, xcv.ai does not divulge any
              Personal Information gathered via the Website to third parties. Moreover, xcv.ai does not sell, rent,
              trade license or otherwise transfer any Personal Information regarding its Website visitors or users to
              third parties. Only the employees, agents, subcontractors, officers, directors, or assigns of xcv.ai (or
              of xcv.ai’s affiliates) are responsible for the management and development of the Website, and only these
              individuals have access to the information collected there. These employees, agents, subcontractors,
              officers, directors or assigns all have been instructed to comply with the Policy.
            </p>
            <p class="mb-3">
              Notwithstanding anything in the Policy to the contrary, we may share any information we have collected
              about you or that you have submitted:
            </p>
            <p class="mb-3">
              (1) in response to subpoenas, court orders, or legal process, or to establish, protect, or exercise our
              legal rights or defend against legal claims or demands;
            </p>
            <p class="mb-3">
              (2) if we believe it is necessary in order to investigate, prevent, or take action regarding illegal
              activities, fraud, or situations involving potential threats to the safety of any person, or any violation
              of any other agreement you may have with xcv.ai;
            </p>
            <p class="mb-3">
              (3) if we believe it is necessary to investigate, prevent, or take action regarding situations that
              involve abuse of the Website infrastructure or the Internet in general (such as voluminous spamming,
              denial of service attacks, or attempts to compromise the security of the Website infrastructure or the
              Website generally);
            </p>
            <p class="mb-3">
              (4) to a parent company, subsidiaries, joint ventures, or other companies under common control with xcv.ai
              (in which case we will require such entities to honor this Policy);
            </p>
            <p class="mb-3">
              (5) if xcv.ai merges with another entity, is subject to a corporate reorganisation, or sells or transfers
              all or part of our business, assets, or shares (in which case we will require such entity to assume our
              obligations under this Policy, or inform you that you are covered by a new privacy policy).
            </p>
            <p class="mb-3">
              Where you share or like content from the Website via social media such as your Facebook, Youtube,
              LinkedIn, or Twitter (“Third-Party Service”) account, we may transfer Personal Information to the
              Third-Party Service (your Third-Party Service login credentials) if you are not already logged in to the
              Third-Party Service on the computer or device being used. Your Personal Information is transferred
              securely and only used in order to share or like the Website content.
            </p>
            <h5 class="mb-3">
              Email Communications and Compliance with Anti-Spam Laws
            </h5>
            <p class="mb-3">
              xcv.ai’s practices with regards to its email practices are designed to be compliant with anti-spam laws,
              specifically, the law unofficially called “CASL”, or Canada’s Anti-Spam Law. If you believe you have
              received an email in violation of this law, please contact us using the contact information further up in
              this Policy.
            </p>
            <h5 class="mb-3">
              Limited Gathering of Information for Statistical, Analytical, and Security Purposes
            </h5>
            <p class="mb-3">
              xcv.ai automatically collects certain information using third-party analytics programs Google Analytics
              (the “Analytics Programs”) to help us understand how our users use the Website, but none of this
              information identifies you personally. For example, each time you visit the Website, we may automatically
              collect your IP address, browser and computer type, access times, the web page from which you came, and
              the web page(s) you access (as applicable). We use information collected via the Analytics Programs in
              this manner only to better understand your needs and the needs of the Website and its users in the
              aggregate. xcv.ai also makes use of information gathered for statistical purposes to keep track of the
              number of visits to the Website and the specific pages on the Website with a view to introducing
              improvements.
            </p>
            <p class="mb-3">
              Your IP address and other relevant information may be used in order to trace any fraudulent or criminal
              activity or any activity in violation of any other agreement you may have with xcv.ai.
            </p>
            <h5 class="mb-3">
              Verification, Correction, and Deletion of Personal Information
            </h5>
            <p class="mb-3">
              You have the right to (i) verify what Personal Information xcv.ai holds about you; (ii) ask for your
              Personal Information to be corrected or updated; and (iii) withdraw your consent to the use by xcv.ai of
              your Personal Information and have it deleted from our records. If you wish to inquire about and verify
              and/or correct Personal Information xcv.ai holds about you, or if you wish to have all your Personal
              Information permanently deleted from our records, please contact us using the contact information further
              up in this Policy. Please note that deletion of your Personal Information may make it impossible for you
              to use the Website, or certain portions thereof including but not limited to your free trial. If you
              request deletion of your Personal Information, xcv.ai reserves the right to retain some of your Personal
              Information for a reasonable time in order to satisfy our legal obligations, or where we reasonably
              believe that we have a legitimate reason to do so.
            </p>
            <h5 class="mb-3">
              Transfer of Personal Information Outside Your Home Jurisdiction
            </h5>
            <p class="mb-3">
              Website users may access the Website from any country in the world. xcv.ai and its third-party vendors,
              including service providers, are located in the United States, Canada, and Mexico. You acknowledge that
              xcv.ai and our third-party vendors may be located in jurisdictions or countries where the privacy laws may
              not be as protective as those in your home country, state, province, or other governmental jurisdiction,
              and consent to the transfer of your Personal Information to such countries. Without limiting the
              generality of the foregoing, Website visitors in the European Union acknowledge and agree that their
              Personal Information may be transferred to the United States.
            </p>
            <h5 class="mb-3">
              Tracking Technology (“Cookies”)
            </h5>
            <p class="mb-3">
              Website users may access the Website from any country in the world. xcv.ai and its third-party vendors,
              including service providers, are located in the United States, Canada, and Mexico. You acknowledge that
              xcv.ai and our third-party vendors may be located in jurisdictions or countries where the privacy laws may
              not be as protective as those in your home country, state, province, or other governmental jurisdiction,
              and consent to the transfer of your Personal Information to such countries. Without limiting the
              generality of the foregoing, Website visitors in the European Union acknowledge and agree that their
              Personal Information may be transferred to the United States.
            </p>
            <p class="mb-3">
              xcv.ai uses tracking technology (“cookies” and related technology such as tags, pixels, and web beacons)
              on the Website and by visiting or using the Website you agree to their use. Cookies are small text files
              placed on your hard drive or memory when you visit a website, in order to track use and to improve the
              user experience by storing certain data on your computer or device.
            </p>
            <h5 class="mb-3">
              Risks Associated with the Internet
            </h5>
            <p class="mb-3">
              Despite xcv.ai’s best efforts to ensure that third parties will not access or obtain your Personal
              Information through your use of the Website, complete confidentiality and security cannot currently be
              guaranteed on the Internet. Communication via the Internet is subject to interception, loss, or
              alteration. You acknowledge and agree that xcv.ai cannot be held responsible for damages resulting from
              the transmission of confidential information or Personal Information over the Internet and that such
              communications are at your own risk.
            </p>
            <h5 class="mb-3">
              Links to Third-Party Websites
            </h5>
            <p class="mb-3">
              From time to time xcv.ai may provide links to other websites or services not covered by the present
              Policy. We encourage you to read the privacy policy of any website or service linked to from the Website
              and to be careful when submitting any information, including your Personal Information, to them.
            </p>
            <h5 class="mb-3">
              Limitation of Liability
            </h5>
            <p class="mb-3">
              xcv.ai, its affiliates, or their respective officers, directors, employees, affiliates, agents, partners,
              principals, licensors, representatives, successors and assigns, will not be held liable for any losses or
              damages, direct or indirect, pecuniary or not pecuniary, resulting from the misuse of any information
              collected by any third party, or any misuse of any information collected through the Website, not in
              violation of the Policy.
            </p>
            <h5 class="mb-3">
              Compliance with Privacy Laws
            </h5>
            <p class="mb-3">
              This Policy and xcv.ai’s practices, in general, are designed to be in compliance with Canada’s Personal
              Information Protection and Electronic Documents Act (“PIPEDA”, S.C. 2000, c. 5) and Ontario’s An Act
              Respecting the Protection Of Personal Information in the Private Sector (R.S.Q. c. P-39.1). If you have
              any questions regarding this compliance, please contact us using the contact information further up in
              this Policy.
            </p>
  </p-dialog>
</div>


<p-dialog [style]="{ 'height': '500px' }" [draggable]="true" [resizable]="true" [closable]="false"  
[modal]="true"  [(visible)]="userAgreementModal" 
[styleClass]="displayDialog ? 'responsive-dialog' : ''"
>

<p-header>
  <div class="row">
    <div class="col-md-12 text-left">
      <h5 class="modal-title" id="exampleModalLabel"></h5>
    </div>
  </div>
</p-header>

<div class="scrollable-content" (scroll)="onScroll($event)">
  <!-- Your big scrollable text goes here -->

  <div class="row">
    <div class="col-12 text-center">
      <h3>
        USER AGREEMENT
      </h3>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        This User Agreement ("Agreement") is a legal agreement between you ("User" or "you") and MLSoft Technologies Inc ("Company" or "we") governing your use of the website and its services offered by the Company. By accessing or using the Company's website, you agree to be bound by this Agreement.
      </span>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <h5>
        User Responsibilities
      </h5>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>1.1</b> Vanity-Name: You confirm that you have provided your own LinkedIn vanity-name accurately and truthfully. You understand and acknowledge that it is your responsibility to ensure that the vanity-name you provide to the system is correct and corresponds to your identity.
      </span>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>1.2</b> Liability for Incorrect Vanity-Name: You shall bear full responsibility for providing an accurate and correct vanity-name. In the event that an incorrect vanity-name is provided, the system may inadvertently scrape and collect data belonging to a different individual. Consequently, your profile may not reflect your own personal information.
      </span>
    </div>
  </div>
    
  <div class="row mt-3">
    <div class="col-12">
      <h5>
        Rights and Obligations
      </h5>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>2.1</b> Ownership: You acknowledge that you have no ownership rights or claims over the system, its software, or any data collected or stored by the Company.
      </span>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        <b>2.2</b> License: Subject to your compliance with this Agreement, the Company grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the system solely for your personal or professional purposes.
      </span>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>2.3</b> Data Scrapping and Exclusions: The Company reserves the right to employ automated processes to scrape data from LinkedIn profiles. However, the Company does not warrant the accuracy, reliability, or timeliness of the scraped data and shall not be held liable for any errors or discrepancies arising therefrom.
      </span>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>2.4</b> Profile Reflection: The Company strives to ensure that your profile accurately reflects your self. However, in cases where an incorrect vanity-name is provided, the Company shall not be responsible for reflecting your personal information accurately. The responsibility for maintaining an accurate profile solely rests upon you.
      </span>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <h5>
        Limited Liability and Indemnification
      </h5>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        <b>3.1</b> Limitation of Liability: To the extent permitted by law, the Company, its officers, directors, employees, and affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the system, including any damages resulting from incorrect vanity-name provision or inaccurate profile reflection.
      </span>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        <b>3.2</b> Indemnification: You agree to indemnify, defend, and hold harmless the Company and its affiliates from and against any claims, liabilities, damages, losses, and expenses (including reasonable attorney fees) arising out of or related to any breach of this Agreement or your use of the system.
      </span>
    </div>
  </div>
    
  <div class="row mt-3">
    <div class="col-12">
      <h5>
        Miscellaneous
      </h5>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        <b>4.1</b> Entire Agreement: This Agreement constitutes the entire understanding and agreement between you and the Company relating to your use of the system and supersedes any prior or contemporaneous agreements, communications, or understandings, whether oral or written.
      </span>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>4.2</b> Severability: If any provision of this Agreement is held to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
      </span>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        <b>4.3</b> Governing Law and Jurisdiction: This Agreement shall be governed by and construed in accordance with the laws of the USA and Canada. Any disputes arising out of or in connection with this Agreement shall be exclusively submitted to the competent courts of the USA/Canada.
      </span>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        By accessing or using the system, you acknowledge that you have read, understood, and agreed to this User Agreement.
      </span>
    </div>
  </div>

</div>

<p-footer>
  <div class="row">
    <div class="col-md-12">
      
      <div  class="row">
        <div class="col">
        </div>
        <div class="col-auto">
          <button #closeContactsModal (click)="cancelAgree()" type="button" class="btn btn-secondary">I do not agree</button>
        </div>
        <div class="col-auto">
          <button [disabled]="agreeBtnDisabled || processingConfirm" type="button" class="btn btn-primary" (click)="confirmAgree()">
            I AGREE
            <div *ngIf="processingConfirm" class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Processing...</span>
            </div>
          </button>
        </div>
      </div>

    </div>

  </div>
</p-footer>

</p-dialog>

<!---P-dialog for getting vanity-name/pdf from user-->>
<p-dialog [styleClass]="customDialogClass" [draggable]="true" [resizable]="false" [closable]="false"  
[modal]="true" header="" [(visible)]="forVanity" 
[styleClass]="displayDialog ? 'responsive-dialog' : ''"
>

<div class="row">
  <div class="col-md-12">

    <span (click)="cancelVanityLogin()"  class="custom-link" pTooltip="Cancel Login" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}">
      <i class="fa fa-remove large_icon" aria-hidden="true"></i> 
    </span>

  </div>
</div>

  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8">

        <form [formGroup]="vanityForm">
          <div class="row">
            <div class="col-md-12 text-center">

              <span class="enter-vanity">
                Enter Your Vanity Name
              </span>
            </div>

            <div class="col-md-12 text-center">
              <span class="linkedin-url">
                (e.g https://linkedin.com/in/<span class="url-bold">fname-lname-123</span>)
             </span>

            </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-center curved">
              <div class="form-group mt-4">
                  <input  id="vanityform" type="text" required="" autofocus="" class="form-control curved" formControlName="vanityform">
              </div>
              <div class="text-danger mt-2" *ngIf="submitted && (vanityForm.controls['vanityform'].invalid || 
                (vanityForm.controls['vanityform'].errors && vanityForm.controls['vanityform'].errors['required'])
                )">
                Vanity Name is required
              </div>

            </div>
          </div>

          <!-- start OR -->
         <div class="row mt-4 text-center">

            <div class="col">
              <div class="centered-line">
                <span>&nbsp;</span>
              </div>
            </div>

            <div class="col-auto">
              <span class="or">OR</span>
            </div>

            <div class="col">
              <div class="centered-line">
                <span>&nbsp;</span>
              </div>
            </div>


          </div> 
          <!-- end OR -->

          <!-- ////upload file section ////-->
          <div class="row text-center mt-4">          
            <div *ngIf="fileSelected" class="col-12">
              <span class="mr-2">{{selectedFile.name}}</span><span (click)="removeSelectedPDF()" class="custom-link">
                <i pTooltip="Remove/Replace" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}" class="fa fa-refresh" aria-hidden="true"></i></span> 
            </div> 
          

            <!-- start file NOT selected  -->
            <div *ngIf="!fileSelected" class="col-12">

              <div class="clickable-box" (click)="handleBoxClick()">
                <input #fileInput id="fileInput" (click)="fileInput.value = ''" type="file" (change)="handleFileInput($event)" [hidden]="true" accept=".pdf">

                <div class="row pt-5 pb-5">

                  <div class="col-12">
                    <i class="fa  fa-upload" aria-hidden="true"></i> 

                  </div>

                  <div class="col-12">
                    <span class="upload">Upload PDF</span>
                  </div>
                </div>
                
              </div>


            </div> 
            <!-- end file NOT selected  -->
 </div> 
 <!--///////////////////////////////////////////////////////////-->
          <div class="row text-center">
            <div class="col-12">

              <button class="btn btn-primary btn-block mb-4 rounded-pill submit-btn shadow-sm mt-4 p-2" (click)="submitVanityInfo()" [disabled]="processing || loading">Upload
                <div *ngIf="processing || loading" class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Processing...</span>
                </div>
              </button>

            </div>

          </div>



        </form>
    </div>
    <div class="col-md-2"></div>
  </div>
 
</p-dialog>
