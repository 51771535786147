import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-template12',
  templateUrl: './template12.component.html',
  styleUrls: ['./template12.component.scss']
})
export class Template12Component {
  @Input() resumeDetails: any;
  @Input() message!: string;

  constructor(private datePipe: DatePipe) { }

  public displayExperienceDurationDateRange(exp: any) {
    let disp = '';
    if (exp && exp.starts_at) {
      disp = this.convertDateFormat(exp.starts_at.day, exp.starts_at.month, exp.starts_at.year);
    }
    if (exp && exp.ends_at) {
      disp = disp + ' - ' + this.convertDateFormat(exp.ends_at.day, exp.ends_at.month, exp.ends_at.year);
    }
    return disp;
  }

  public displayprojectDuration(proj: any) {
    let disp = '';
    if (proj && proj.starts_at) {
      disp = this.convertDateFormat(proj.starts_at.day, proj.starts_at.month, proj.starts_at.year);
    }
    if (proj && proj.ends_at) {
      disp = disp + ' - ' + this.convertDateFormat(proj.ends_at.day, proj.ends_at.month, proj.ends_at.year);
    }

    return disp;
  }

  public convertDateFormat(day: number, month: number, year: number): any {
    const date = new Date(year, month - 1, day);
    return this.datePipe.transform(date, 'MMM d, y'); //    
  }

}
