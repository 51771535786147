import { ConfirmationService, FilterService, MessageService } from 'primeng/api';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { UserService } from 'app/core/user.service';
import { DataService } from 'app/helpers/data.service';
import { LocalStorageService } from 'app/core/local-storage.service';
import { AppUser } from 'app/model/Userprofile';
import { Constants } from 'app/helpers/app-settings';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailValidator } from 'app/helpers/xcv-validator';
import { XcvUtilsService } from 'app/core/xcv-utils.service';
import { AytHttpParams } from 'app/helpers/http-config';

@Component({
  selector: 'app-resume-edit',
  templateUrl: './resume-edit.component.html',
  styleUrls: ['./resume-edit.component.scss']
})
export class ResumeEditComponent implements AfterViewInit, OnInit, OnDestroy {
  parentMessage = "HAS_PARENT";
  parentMsg = 'RELOAD';
  parentPreviewEnabled = 'TRUE';
  public hasTemplateSelected = false;
  public selectedTemplate = '';
  public previewVisible = true;
  public processing = false;
  public userType = '';
  public appUser!: AppUser;
  public vanity_name = '';
  public client: any;
  public pdfPreviewVisible = false;
  public processingPdfPreview = false;
  public pdfDownloadProcessing = false;
  public confirmAuthModal = false;
  public confirmAuthForm!: FormGroup;
  public isSmallScreen = false;
  public displayDialog: boolean = false;
  public submitted = false;
  public clientEmail = '';
  public title = '';
  public recruiter_email = '';
  public allowAuthButton = true;
  public deletingClient = false;
  public startprofile = false;

  private subscription!: Subscription;

  constructor(private http: HttpClient,
    private fb: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    private currrencyPipe: CurrencyPipe,
    private userService: UserService,
    private messageService: MessageService,    
    private confirmationService: ConfirmationService,            
    private dataService: DataService,
    private filterService: FilterService,
    private localStorageService: LocalStorageService,
    private activatedRoute: ActivatedRoute
    ) {
      this.createConfirmAuthForm();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  private createConfirmAuthForm() {
    this.confirmAuthForm = this.fb.group({
      email: ['',[Validators.required, EmailValidator()]]
    });
  }

  private checkScreenSize() {
    const isSmallScreen = window.innerWidth <= 767; // Match the CSS media query width
    this.isSmallScreen = isSmallScreen;
    // You can also dynamically apply a CSS class if needed
    if (isSmallScreen) {
        this.displayDialog = false;
    } else {
        this.displayDialog = true;
    }
}

  ngOnInit(): void {
    this.checkScreenSize();
    this.subscribeToQueryParams();                

    // get the user
    this.appUser = this.localStorageService.getData('appUser');

    // 1. get the user type
    if (this.appUser) {
      if (this.appUser.user_type) {
        this.userType = this.appUser.user_type;
      }
    }

    // 2. get the vanity
    if (!this.vanity_name) {
      let userVanity: any = this.localStorageService.getData('userVanity');
      this.vanity_name = userVanity.vanity_name;
    }

    // 3. get the userClient if CLIENT
    if (this.userType === Constants.CLIENT) {
      this.client = this.localStorageService.getData('userClient');
    }
  }

  private subscribeToQueryParams() {
    this.subscription = this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.hasOwnProperty('title') && queryParams.title !== '') {
        this.title = queryParams.title;
      }

      if (queryParams.hasOwnProperty('recruiter_email') && queryParams.recruiter_email !== '') {
        this.recruiter_email = queryParams.recruiter_email;
      }

      if (queryParams.hasOwnProperty('vanity_name') && queryParams.vanity_name !== '') {
        this.vanity_name = queryParams.vanity_name;
      }

      if (queryParams.hasOwnProperty('startprofile') && queryParams.recruiter_email !== '') {
        this.startprofile = queryParams.startprofile;
      }


    });
}

  ngAfterViewInit() {
    console.log('userType = ', this.userType);
    if (this.appUser && this.appUser.subscription) {
      console.log('plan_type = ', this.appUser.subscription.plan_type);
    }
    console.log('this.client = ', this.client);
    console.log('isClientValid = ', this.isClientValid());

  }


  public pdfDownloadTriggered($event: any) {
    this.pdfDownloadProcessing = false;
  }

  public pdfPreviewToggleTriggered($event: any) {

    this.processingPdfPreview = false; 
    console.log('>>> it went here = ', $event);

    if ( (/true/i).test($event)) { // this means the pdf preview is there (visible)
      this.pdfPreviewVisible = true;
    } else {
      this.pdfPreviewVisible = false;

    }

  } 

  public previewToggleTriggered($event: string) {
    // hide the left pane
    this.previewVisible =  (/true/i).test($event);
  }

  public clientRetrievedTriggered($event: string) {
    if ( (/true/i).test($event)) {
      let userClient: any = this.localStorageService.getData('userClient');
      this.client = userClient;
    }
  }

  public resumeRetrievedTriggered($event: string) {
    if ((/true/i).test($event)) {
      // get the latest 'currentResume' from storage to determine whether to allow confirm authorization or not
      let currentResume: any = this.localStorageService.getData('currentResume');
      if (currentResume && currentResume.id === null) {
        // this is default
        this.allowAuthButton = false;
      } else {
        this.allowAuthButton = true;
      }
    }
  }

  public clientEmailTriggered($event: string) {
    console.log('GGGGGGGGGG client email = ', $event);
    this.clientEmail = $event;
  }

  public saveTriggered($event: string) {
    this.processing = false;
    // this.messageService.add({ severity: 'success', summary: 'Success', detail: `Triggering template reload`, life: 5000});

    // this.dataService.setReloadTemplate('true');

  }

  public templateRemoved($event: string) {
    // the removal of the template from local storage was done from child
    this.hasTemplateSelected = false;

  }

  public templateSelected($event: string) {
    this.selectedTemplate = $event;

    this.messageService.add({ severity: 'success', summary: 'Success', detail: `Template Selected`, life: 5000});
    console.log('seleted template = ', this.selectedTemplate);

    this.hasTemplateSelected = false;
    setTimeout(() => {
      this.hasTemplateSelected = true;
    }, 500);

  }

  receiveMessage($event: string) {
    // this.parentMessage = $event;
    // this.messageService.add({ severity: 'success', summary: 'Success', detail: `${this.parentMessage}`, life: 5000});
  }

  // this triggers the save button
  public triggerSaveProfile() {
    this.processing = true;
    this.dataService.setPreviewSave('true');
  }

  // this triggers the jd align button
  public triggerJDAlign() {
    this.dataService.setGoToJDAlign('true');
  }

  // this triggers the template selection button
  public triggerTemplateSelection() {
    this.dataService.setSelectTemplate('true');
  }

  public triggerTemplateDelete() {
    this.dataService.setRemoveTemplate('true');
    this.pdfPreviewVisible = false;
  }


  // start 
  public triggerHidePreviewSide() {
    if (this.previewVisible) {
      // this.previewVisible = false;
      this.dataService.setHidePreview('true');
    }
  }

  public triggerShowPreviewSde() {
    this.previewVisible = true;
  }

  public triggerPdfPreview() {
    console.log('setting pdfPreview to true');
    this.dataService.setPdfPreview('true');
    this.processingPdfPreview = true;
  }

  public triggerCancelPdfPreview() {
    console.log('setting pdfPreview to false');
    this.dataService.setRemovePdfPreview('true');
  }

  public triggerPdfDownload() {
    this.pdfDownloadProcessing = true;
    this.dataService.setPdfDownload('true');
  }

  public isClientValid(): boolean {
    if (this.client) {
      let tokenLinit = Number(this.client.tokens_limit);
      let tokenConsumed = Number(this.client.tokens_consumed);
      if ( tokenConsumed < tokenLinit &&
        tokenLinit > 0
        ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  get CLIENT() {
    return  Constants.CLIENT;
  }

  get RECRUITER() {
    return  Constants.RECRUITER;
  }

  public showConfirmAthModal() {
    this.resetConfirmAuthForm();
    this.confirmAuthForm.controls['email'].setValue(this.clientEmail);
    this.confirmAuthModal = true;
  }

  public executeAuthConfirm() {
    this.submitted = true;
    
    if (this.confirmAuthForm.valid) {

      let currentResume = this.localStorageService.getData('currentResume');

      if (currentResume) {

        this.processing = true;

        let payload: any = {};
  
        payload.template_type = this.selectedTemplate;
        payload.vanity_name = this.vanity_name;
        payload.client_email = this.confirmAuthForm.controls['email'].value;
        payload.recruiter_email = this.appUser.email;
        payload.resume_title = currentResume.name;
  
        this.processing = true;

        console.log('payload = ', payload);

        this.subscription = this.userService.requestConsent(payload).subscribe({
          next: response => {
            this.processing = false;
            this.confirmAuthModal = false;
            this.messageService.add({ severity: 'success', summary: 'Success', detail: `Consent request sent`, life: 5000});

          },
          error: error => {
            XcvUtilsService.handleError(error, this.messageService);
            this.processing = false;
          }
        });
  
  
      } else {
        this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `No current resume`, life: 5000});
      }


    }
  }

  public resetConfirmAuthForm() {
    this.confirmAuthForm.controls['email'].setValue(null);
    this.confirmAuthForm.setErrors(null);
    this.submitted = false;
  }

public reloadResume() {
  let p = new AytHttpParams();
  let userClient = this.localStorageService.getData('userClient')
  p.set('vanity_name', this.vanity_name);
  if (this.appUser.email) {
    p.set('recruiter_email', this.appUser.email);
  }

  this.deletingClient = true;
  this.subscription = this.userService.deleteClient(p).subscribe({
    next: response => {
      if (response) {
        //this.messageService.add({ severity: 'success', summary: 'Success', detail: `Resume ${this.vanity_name} successfully refreshed`, life: 5000});
        this.router.navigate(['/login'],{ queryParams: { id:userClient.unique_id, first_name:userClient.first_name, last_name:userClient.last_name } });
      }
    },
    error: error => {
      XcvUtilsService.handleError(error, this.messageService);
    }
  });

}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
