import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TemplateBaseComponent } from './template-base/template-base.component';
import { Template1Component } from './template1/template1.component';
import { Template2Component } from './template2/template2.component';
import { Template3Component } from './template3/template3.component';

const routes: Routes = [
  {
    path: '',
    component: TemplateBaseComponent,
    children: [
      { path: 'template-1', component: Template1Component },
      { path: 'template-2', component: Template2Component },
      { path: 'template-3', component: Template3Component }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TemplatesRoutingModule { }
