<section class="image-gallery-wrapper">
  <!-- <div class="row">
    <div class="col-md-6 mt-5">
        <div class="form-group has-search">
            <span class="fa fa-search form-control-feedback"></span>
            <input type="text" class="form-control searchbar" placeholder="Search">
          </div>
    </div>
  </div> -->

	<div class="row gallery mt-5 mb-5">
		<div class="col-lg-2 col-md-2 col-xs-6 thumb">
			<a [routerLink]="['/templates']" [queryParams]="{ template: 'basic' }">
				<figure><img class="img-thumbnail" src="/assets/img/thumbnails/template1.png" alt="Random Image"></figure>
				<p class="resume-name">Basic</p>
			</a>
		</div>
		<div class="col-lg-2 col-md-2 col-xs-6 thumb">
			<a [routerLink]="['/templates']" [queryParams]="{ template: 'modern' }">
				<figure><img class="img-thumbnail" src="/assets/img/thumbnails/template2.png" alt="Random Image"></figure>
			</a>
			<p class="resume-name">Modern</p>			
		</div>
		<div class="col-lg-2 col-md-2 col-xs-6 thumb">
			<a [routerLink]="['/templates']" [queryParams]="{ template: 'classic' }">
				<figure><img class="img-thumbnail" src="/assets/img/thumbnails/template3.png" alt="Random Image"></figure>
				<p class="resume-name">Classic</p>
			</a>		
		</div>
		<div class="col-lg-2 col-md-2 col-xs-6 thumb">
			<a [routerLink]="['/templates']" [queryParams]="{ template: 'vintage' }">
				<figure><img class="img-thumbnail" src="/assets/img/thumbnails/template4.png" alt="Random Image"></figure>
				<p class="resume-name">Vintage</p>
			</a>		
		</div>
		<!-- <div class="col-lg-2 col-md-2 col-xs-6 thumb">
			<a [routerLink]="['/templates']" [queryParams]="{ template: 'simple' }">
				<figure><img class="img-thumbnail" src="/assets/img/thumbnails/template5.png" alt="Random Image"></figure>
				<p class="resume-name">Simple</p>
			</a>
		
		</div> -->
		<div class="col-lg-2 col-md-2 col-xs-6 thumb">
			<a [routerLink]="['/templates']" [queryParams]="{ template: 'casual' }">
				<figure><img class="img-thumbnail" src="/assets/img/thumbnails/template6.png" alt="Random Image"></figure>
				<p class="resume-name">Casual</p>
			</a>
		
		</div>	
  </div>
  <div class="row gallery mt-2 mb-5">
	<div class="col-lg-2 col-md-2 col-xs-6 thumb">
		<a [routerLink]="['/templates']" [queryParams]="{ template: 'chrono' }">
			<figure><img class="img-thumbnail" src="/assets/img/thumbnails/template7.png" alt="Random Image"></figure>
			<p class="resume-name">Chrono</p>
		</a>
	
	</div>
	<div class="col-lg-2 col-md-2 col-xs-6 thumb">
		<a [routerLink]="['/templates']" [queryParams]="{ template: 'horizontal' }">
			<figure><img class="img-thumbnail" src="/assets/img/thumbnails/template8.png" alt="Random Image"></figure>
			<p class="resume-name">Horizontal</p>
		</a>
	
	</div>
	<div class="col-lg-2 col-md-2 col-xs-6 thumb">
		<a [routerLink]="['/templates']" [queryParams]="{ template: 'luxe' }">
			<figure><img class="img-thumbnail" src="/assets/img/thumbnails/template9.png" alt="Random Image"></figure>
			<p class="resume-name">Luxe</p>
		</a>
	
	</div>
	<div class="col-lg-2 col-md-2 col-xs-6 thumb">
		<a [routerLink]="['/templates']" [queryParams]="{ template: 'professional' }">
			<figure><img class="img-thumbnail" src="/assets/img/thumbnails/template10.png" alt="Random Image"></figure>
			<p class="resume-name">Professional</p>
		</a>
	
	</div>
	<div class="col-lg-2 col-md-2 col-xs-6 thumb">
		<a [routerLink]="['/templates']" [queryParams]="{ template: 'chronicle' }">
			<figure><img class="img-thumbnail" src="/assets/img/thumbnails/template12.png" alt="Random Image"></figure>
			<p class="resume-name">Chronical</p>
		</a>
	
	</div>
  </div>
 
</section>