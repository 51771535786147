import { Component, Input } from '@angular/core';
import { UserService } from 'app/core/user.service';
import { DataService } from 'app/helpers/data.service';
import { MessageService } from 'primeng/api';
import { XcvUtilsService } from 'app/core/xcv-utils.service';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'app/core/local-storage.service';

@Component({
  selector: 'app-cover-letter',
  templateUrl: './cover-letter.component.html',
  styleUrls: ['./cover-letter.component.scss']
})
export class CoverLetterComponent {
  @Input() message!: string;
  public jdAlignObject: any;
  private subscription!: Subscription;
  public processing = false;
  constructor(private dataService: DataService, private userService: UserService,
    private messageService: MessageService, private localStorageService: LocalStorageService) {
      this.jdAlignObject = this.localStorageService.getData('currentResume');
     }


  ngOnInit(): void {
    // console.log("jdobject=>",this.jdAlignObject)
    let payload:any = {}
    payload.vanity_name = this.jdAlignObject.vanity_name;
    payload.JD_title = this.jdAlignObject.name;
    payload.JD = this.jdAlignObject.jd;

    this.getCoverLetterDetails(payload);
  }

  // public jdAlignCover(): void {
  //   this.jdAlignObject = this.dataService.getJDAlign();
  //   this.getCoverLetterDetails(this.jdAlignObject);
  // }

  public getCoverLetterDetails(payload:any):void {
    this.subscription = this.userService.getCoverletter(payload).subscribe({
      next: response => {
        this.processing = false;    
        if (response) {
console.log("response=>",response)
        }
      },
      error: error => {
        this.processing = false;

        XcvUtilsService.handleError(error, this.messageService);
      }      
    });

  }

}
