import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-gpt-modal',
  templateUrl: './gpt-modal.component.html',
  styleUrls: ['./gpt-modal.component.scss']
})
export class GptModalComponent implements OnInit {
  @Input() public user:any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    console.log(this.user);
  }



}
