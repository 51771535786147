import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import jspdf from "jspdf";
import html2canvas from 'html2canvas';
import { ActivatedRoute, Router } from '@angular/router';
import { Profile, ProfileExperience, UserProfile } from '../model/Userprofile'
import { Subscription } from 'rxjs';
import { UserService } from '../core/user.service';
import { AytHttpParams } from '../helpers/http-config';
import { LocalStorageService } from '../core/local-storage.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-resume-details',
  templateUrl: './resume-details.component.html',
  styleUrls: ['./resume-details.component.scss']
})
export class ResumeDetailsComponent implements OnInit, OnDestroy {
  
  public resumeDetails: any
  public test: any;
  public isEditProfile: boolean = false;
  public isEditrevisedProfile: boolean = false;
  public resumeForm !: FormGroup;
  public isEditexperience: boolean = false;
  public isEditedducation: boolean = false;
  public isDetails: boolean = false;
  public isSkills: boolean = false;
  public skills: any = [];
  public isTools: boolean = false;
  public isEditCountry: boolean = false;
  public isEditlanguage: boolean = false;
  public isEditTitle: boolean = false;
  public isRemove: boolean = false;
  public closeResult!: string;
  public gptrevisedData!: any;
  public payloadV: any;
  private subscription!: Subscription;
  public showModal:boolean = false;
  public IsmodelShow:boolean = false;
  public code!:string;
  public vanity_name = '';
  public profile: Profile = new Profile();
  public profileOrig: Profile = new Profile();
  public profileExperience: ProfileExperience[] = [];

  public loading = false;
  public updating = false;

  public revised = {
    profile: "banner adverts and high-impact adverts in the advertising industry.test 123",
    revisedprofile: " East London. I have over two and a half years of agency experience developing websites",
    skills: [
      "Java",
      "Kubernetes"
    ],
    experience: [
      {
        "name": "Freelance  Front-end Developer",
        "year": "February 2018-Present",
        "desc": "Web design and development, logo animation, SEO, content creation & photography."
      }],
    education: [
      {
        "name": "Ravensbourne University London-Web Media BA",
        "year": "2012-2015",
        "desc": "First-class honours degree."
      }],
    details: {
      "address": "East London, UK",
      "contact": "012 345 67890",
      "email": "example@steset.com"
    },
    tools: [
      "Photoshop",
      "dreamviwer",

    ]
  }


  @ViewChild('fileInput') el!: ElementRef;
  @ViewChild('content', { static: false }) content!: ElementRef;
  imageUrl: any = 'https://selcukcura.com/wp-content/themes/selfolio-2018/img/profile-img/selcuk-cura-young.jpg';
  editFile: boolean = true;
  removeUpload: boolean = false;


  constructor(private http: HttpClient, private fb: FormBuilder, private cd: ChangeDetectorRef,
    private localStorageService: LocalStorageService, private datePipe: DatePipe,
    private router: Router, private userservice: UserService,private activatedRoute: ActivatedRoute) { 
      this.activatedRoute.queryParams.subscribe(params => {
        this.code = params['code'];
     
    });
    }

  get experience(): FormArray {
    return <FormArray>this.resumeForm.get('experienceArray');
  }


  ngOnInit(): void {
    // this.getauth();
    //this.getprofileData();
    // this.getRDetails();
    this.resumeForm = this.fb.group({
      profile: ['', Validators.required],
      revisedprofile: ['', Validators.required],
      experienceArray: this.fb.array([])
    });
    this.subscribeToQueryParams();
  }

  private subscribeToQueryParams() {
    this.loading = true;
    this.subscription = this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.hasOwnProperty('vanity_name') && queryParams.vanity_name !== '') {
        // this.vanity_name = queryParams.vanity_name;
        this.vanity_name = 'mujahidsultan';

        

          // save to local stoage
          const data = { vanity_name: this.vanity_name};
          this.localStorageService.saveData('userVanity', data);

          // test get from local
          console.log('gotten from local storeage = ', this.localStorageService.getData('userVanity'));

          // now get the resume
          this.getRDetails(this.vanity_name);
      }
    });
  }

  // getfname(){


  //   this.http.get<any>('https://api.linkedin.com/v2/me').subscribe(
  //       {
  //         next: response => {
  //           console.log(response);
  //           this.router.navigate(['resume-list'])
  //         },
  //         error: error => {}
  //       }
  
  //     )
   
  // }

  getauth(){
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded',
  //   'Access-Control-Allow-Headers': '*',
  //   'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
  //   'Access-Control-Allow-Origin':'*',
 
  // });

  const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Access-Control-Allow-Headers','*').set('Access-Control-Allow-Methods','GET, POST, OPTIONS').set('Access-Control-Allow-Origin','https://www.linkedin.com/');
    const body = 'grant_type=authorization_code&client_secret=5bJoDCpDjuDq1aRW&client_id=86lukxm5jr2z3u&redirect_uri=http%3A%2F%2F20.220.133.246%3A8000%2F&state=760iz0bjh9gy71asfFqa&code='
    const body2 ='';
   
    this.http.post<any>('https://www.linkedin.com/oauth/v2/accessToken', body+this.code, {headers}).subscribe(
        {
          next: response => {
       
         
          },
          error: error => {
        }}
  
      )
   
  }

  

  // getprofileData(){
  //   let token = 'AQUoUI9MyZvIVGaBETqVpnSwCp3pTAE1uc5NAZ9ghxpmrD3jUVgQH7BTWp3dOVMHOHx6q53iGaywDDK8ffHZ_AnrSNgaaRE2f-6X2YXFMgwakY5BnPxXFK8-19JPstdUqyI99yhUCxnwBe8Tzrj_FArt20mcnMJ9XWFXR255jDOysezLwAsJJaj7iQWe0esqmHXcpfAvcoTqL-iR7Z33FwYP7b29A1k6BQqCf6yujUkh_hpuhrTWn-mHtyZ2zOyPiGgh7Y-hCB1uz4R-8N5cDrCdSP6RoG_1Sqvp4kmjwjtBmTtOB6T0Klrjsd8AoY5c8GjsjAwYL5vY-wbQ0SudTfjtB9hIdg'
  //   const headers = new HttpHeaders({'Authorization': 'Bearer'+token});
  //   const body = 'grant_type=authorization_code&code='
  //   const body2 ='&client_secret=5bJoDCpDjuDq1aRW&client_id=86lukxm5jr2z3u&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fresumedetails&state=760iz0bjh9gy71asfFqa';
   
  //   this.http.get<any>('https://api.linkedin.com/v2/me',{ headers }).subscribe(
  //       {
  //         next: response => {
       
         
  //         },
  //         error: error => {
  //       }}
  
  //     )
   
  // }
  
  // public getResumeDetails() {
  //   this.http.get<any>("http://localhost:3000/resume").subscribe(res => {
  //     this.resumeData(res)

  //   }, err => {
  //     alert("Something went wrong")
  //   })
  // }


  public getAllVanities() {
    this.subscription = this.userservice.getAllClients()
    .subscribe({
      next: response => {
        if (response) {
          console.log('response all clients = ', response);

        }
      },
      error: error => {}
    });
 
  }

  public updateProfile2() {
    console.log('profile experience = ', this.profile.experience);
  }


  public updateProfile() {
    // before updating, check which field changed

    this.checkChanged(this.profile);

    if (this.profile.hasChanged) {
    // create payload
    let payload: any = {};
    payload.vanity_name = this.profile.vanity_name;
    payload.payload = {};

    let sec_att: any = {};
    let subsec_att: any = {};


    // for title section
    if (this.profile.titleChanged) {
      sec_att.title = this.profile.title;
    }

    // for country section
    if (this.profile.countryChanged) {
    subsec_att.country = this.profile.country;

    }

    // for languages section
    if (this.profile.languagesChanged) {
      subsec_att.languages = this.profile.languages;
    }

    // for skills section
    if (this.profile.skillsChanged) {
      subsec_att.skills = this.profile.skills;


      // let arraySkills: string[] = [];
      // if (this.profile.skillsDisplay && this.profile.skillsDisplay.trim() != '') {
      //   arraySkills = this.profile.skillsDisplay.split(',');
      //   subsec_att.skills = arraySkills;
      // }
    }

    payload.payload.section_attr = sec_att;
    payload.payload.subsection_attr = subsec_att;



    console.log('payload = ', payload);

    this.confirmUpdateProfile(payload);

    } else {
      alert('Nothing was modified');
    }
  }

  public displayExperienceDurationDateRange(exp: any) {
    let disp = '';
    if (exp && exp.starts_at) {
      disp = this.convertDateFormat(exp.starts_at.day, exp.starts_at.month, exp.starts_at.year);
    }
    if (exp && exp.ends_at) {
      disp = disp + ' - ' + this.convertDateFormat(exp.ends_at.day, exp.ends_at.month, exp.ends_at.year);
    }

    return disp;
  }

  public convertDateFormat(day: number, month: number, year: number): any {
    const date = new Date(year, month - 1, day);
    return this.datePipe.transform(date, 'yyyy MMM dd'); //    
  }

  private closeInputFields() {
    this.isEditCountry = false;
    this.isEditlanguage = false;
    this.isSkills = false;
    this.isEditTitle = false;
  }

  public confirmUpdateProfile(payload: any) {
    this.updating = true;
    this.subscription = this.userservice.updateResume(payload)
    .subscribe({
      next: response => {
        this.updating = false;
        if (response) {
          this.closeInputFields();
          // alert
          // alert('Resume Updated');

          // reload
          this.getRDetails(this.vanity_name)

        }
      },
      error: error => {
        this.updating = false;
      }
    });

  }



  private deepEqual(object1: any, object2: any, field?: string): boolean {


    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (field) {
      console.log('keys1 = ', keys1);
      console.log('keys2 = ', keys2);
    }
  
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];


      if (field) {
        console.log('val1 = ', val1);
        console.log('val2 = ', val2);
      }
  

      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        areObjects && !this.deepEqual(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        return false;
      }
    }
  
    return true;
  }
  
  private isObject(object: any) {
    return object != null && typeof object === 'object';
  }

  private removeEmptyStringsFromArray(array: string[]): string[] {
    return array.filter((str) => str.trim() !== '');
  }

  private addSpaceBetweenCommas(inputString: string): string {
    return inputString
      .split(',')
      .map((value) => value.trim())
      .join(', ');
  }

  private trimSpacesBetweenCommas(inputString: string): string {
    return inputString
      .split(',')
      .map((value) => value.trim())
      .join(',');
  }  

  private checkChanged(profile: Profile) {
    profile.hasChanged = false;
    console.log('>>> check changed executed');

    // about
    if (profile.aboutOrig !== profile.about) {
      profile.aboutChanged = true;
      profile.hasChanged = true;
    } else {
      profile.aboutChanged = false;
    }

    // contact
    if (!this.deepEqual(profile.contactOrig, profile.contact)) {
      profile.contactChanged = true;
      profile.hasChanged = true;
    } else {
      profile.contactChanged = false;
    }

    // country
    if (profile.countryOrig !== profile.country) {
      profile.countryChanged = true;
      profile.hasChanged = true;
    } else {
      profile.countryChanged = false;
    }

    // education array
    if (!this.deepEqual(profile.educationOrig, profile.education)) {
      profile.educationChanged = true;
      profile.hasChanged = true;
    } else {
      profile.educationChanged = false;
    }

    // experience array
    if (!this.deepEqual(profile.experienceOrig, profile.experience)) {
      profile.experienceChanged = true;
      profile.hasChanged = true;
    } else {
      profile.experienceChanged = false;
    }

    // first_name
    if (profile.first_nameOrig !== profile.first_name) {
      profile.first_nameChanged = true;
      profile.hasChanged = true;
    } else {
      profile.first_nameChanged = false;
    }

    // languages array
    if (profile.languagesDisplay) {
      let languageValue: string = this.trimSpacesBetweenCommas(profile.languagesDisplay);
      this.profile.languages = languageValue.split(',');
      this.profile.languages = this.removeEmptyStringsFromArray(this.profile.languages);
      if (this.profile.languages) {
        this.profile.languages.forEach((s: string) => {
          s.trim();
        });
      }
      if (!this.deepEqual(profile.languagesOrig, profile.languages, 'languages')) {
        profile.languagesChanged = true;
        profile.hasChanged = true;
      } else {
        profile.languagesChanged = false;
      }
    }



    // last_name
    if (profile.last_nameOrig !== profile.last_name) {
      profile.last_nameChanged = true;
      profile.hasChanged = true;
    } else {
      profile.last_nameChanged = false;
    }

    // lcoation
    if (profile.lcoationOrig !== profile.lcoation) {
      profile.locationChanged = true;
      profile.hasChanged = true;
    } else {
      profile.locationChanged = false;
    }

    // skills array
    if (profile.skillsDisplay) {
      let skillValue: string = this.trimSpacesBetweenCommas(profile.skillsDisplay);
      this.profile.skills = skillValue.split(',');
      this.profile.skills = this.removeEmptyStringsFromArray(this.profile.skills);
      if (this.profile.skills) {
        this.profile.skills.forEach((s: string) => {
          s.trim();
        });
      }
      if (!this.deepEqual(profile.skillsOrig, profile.skills, 'skills')) {
        profile.skillsChanged = true;
        profile.hasChanged = true;
      } else {
        profile.skillsChanged = false;
      }
    }

    // title
    if (profile.titleOrig !== profile.title) {
      profile.titleChanged = true;
      profile.hasChanged = true;
    } else {
      profile.titleChanged = false;
    }

  }


  
  private loadOrigDetailValues(profile: Profile) {
    profile.aboutOrig = profile.about;
    profile.contactOrig = profile.contact;
    profile.countryOrig = profile.country;
    profile.educationOrig = profile.education;
    profile.experienceOrig = profile.experience;
    profile.first_nameOrig = profile.first_name;
    profile.languagesOrig = profile.languages;
    profile.last_nameOrig = profile.last_name;
    profile.lcoationOrig = profile.lcoation;
    profile.skillsOrig = profile.skills;
    profile.titleOrig = profile.title;
  }


  public getRDetails(id?: any) {
    let p = new AytHttpParams();
    if (id) {
      p.set('public_id', id);
    } else {
      p.set('public_id', 'hassaan-ahmad-21b571222');
    }
    this.loading = true;
    this.subscription = this.userservice.getResumeDetails(p)
    .subscribe({
      next: response => {
        this.loading = false;
        if (response) {
          this.profile = response;

          // this puts a copy the original values which will be used for comparison later
          this.loadOrigDetailValues(this.profile);

          // process the object
          // 1. make the languages to string
          if (this.profile.languages && this.profile.languages.length > 0) {
            this.profile.languagesDisplay = this.profile.languages.join(', ');
            this.profile.languagesDisplay = this.trimSpacesBetweenCommas(this.profile.languages.join(', '));
            this.profile.languagesDisplay = this.addSpaceBetweenCommas(this.profile.languagesDisplay);
          } else {
            this.profile.languagesDisplay = '';
          }

          // 2. make the skills to string
          if (this.profile.skills && this.profile.skills.length > 0) {
            this.profile.skillsDisplay = this.trimSpacesBetweenCommas(this.profile.skills.join(', '));
            this.profile.skillsDisplay = this.addSpaceBetweenCommas(this.profile.skillsDisplay);
          } else {
            this.profile.skillsDisplay = '';
          }


          if (this.profile.experience) {
            this.profileExperience = this.profile.experience;
          }


          this.profileOrig = Object.assign({}, this.profile);


          console.log('response resume details = ', response);

        }
      },
      error: error => {
        this.loading = false;
      }
    });

    // this.http.get<any>('http://127.0.0.1:8000/client/fetchResume?public_id=sherin-atapattu-9b99b5131').subscribe(res => {
    //   this.resumeData(res)

    // }, err => {
    //   alert("Something went wrong")
    // })
  }


  
  resumeData(res: any) {
    this.resumeDetails = res[0];
  }

  public postResumeDetails() {
    let body = this.resumeDetails
    this.http.put<any>('http://localhost:3000/resume/' + body.id, body).subscribe(
      {
        next: response => {
         
          this.router.navigate(['resume-list'])
        },
        error: error => {}
      }

    )
  }

  editprofile(section: string) {
    if (section === "profile") {
      this.isEditProfile = !this.isEditProfile;
    }
    if (section === "revprofile") {
      this.isEditrevisedProfile = !this.isEditrevisedProfile;
    }
    if (section === "experience") {
      this.isEditexperience = !this.isEditexperience;

    }
    if (section === "education") {
      this.isEditedducation = !this.isEditedducation;

    }
    if (section === "details") {
      this.isDetails = !this.isDetails;

    }
    if (section === "skills") {
      this.isSkills = !this.isSkills;

    }
    if (section === "tools") {
      this.isTools = !this.isTools;

    }
    if (section === "country") {
      this.isEditCountry = !this.isEditCountry;
    }

    if (section === "language") {
      this.isEditlanguage = !this.isEditlanguage;
    }
    if (section === "title") {
      this.isEditTitle = !this.isEditTitle;
    }
        

  }
  trackByFn(index: number, item: any) {
    return index;
  }

  uploadFile(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.resumeForm.patchValue({
          file: reader.result
        });
        this.editFile = false;
        this.removeUpload = true;
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }

  public captureScreen() {
    this.isRemove = true;
    var data = document.getElementById('content')!;  //Id of the table
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      let imgWidth = 208;
      let pageHeight = 295;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL("image/jpeg", 1.0)
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
      let position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('Resume.pdf'); // Generated PDF   
    });
    this.isRemove = false;
  }

  public showRevisedText(section: any): void {
    this.showModal = true;
    if (section == 'profile') {
      this.gptrevisedData = this.revised.profile;
      this.payloadV = 'profile'


    }
    else if (section == 'rprofile') {
      this.gptrevisedData = this.revised.revisedprofile;
      this.payloadV = 'revisedprofile'
    }
  }

  saveGptData(): void {
    let body = this.resumeDetails;
    let profile = { profile: this.gptrevisedData };
    let options;
    this.subscription = this.userservice.updateUserprofile(body, profile)
      .subscribe({
        next: response => {
          if (response) {
            // this.getResumeDetails();
            this.IsmodelShow=true;
          }
        },
        error: error => {}
      });

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


}
