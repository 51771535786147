<div id="content" #content class="{{message === 'HAS_PARENT' ? 'container-with-parent' : 'container' }} mb-5">
    <div class="resume-wrapper">
        <div class="row">
            <div class="col-md-12">
                <h4 class="emp-name mb-0" style="font-weight: bold;">{{resumeDetails?.first_name}} {{resumeDetails?.last_name}}</h4>
                <h5 class="top-title" style="font-weight: 500;">{{resumeDetails?.title}}</h5>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <h5 class="contact-info" style="font-weight: 600;"><u>{{resumeDetails?.contact.email &&
                    resumeDetails?.contact.email.length > 0 ?
                    resumeDetails?.contact.email[0] : 'Not Indicated'}}</u></h5>
                <h5 class="contact-info" style="font-weight: 600;"><u><a href="https://www.linkedin.com/in/{{resumeDetails?.vanity_name}}/"
                    target="_blank">
                    {{resumeDetails?.vanity_name}}
                </a></u></h5>
                <h5 class="contact-info" style="font-weight: 600;"><u>{{resumeDetails?.contact.phone
                    &&resumeDetails?.contact.phone.length
                    > 0 ?
                    resumeDetails?.contact.phone[0] : 'None Indicated'}}</u></h5>
                <h5 class="contact-info" style="font-weight: 600;"><u>{{resumeDetails?.socials.twitter?resumeDetails?.socials.twitter :
                    'Not Indicated'}}</u></h5>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <p class="about-desc border-row pb-2" style="font-weight: 400;" [innerHTML]="resumeDetails?.about"></p>
            </div>
        </div>
        <div class="row" *ngIf="resumeDetails?.visibility?.experience === true && resumeDetails?.experience && resumeDetails?.experience?.length > 0">
            <div class="col-md-12">
                <div class="border-row pb-2">
                    <h4 class="sec-title" style="font-weight: bold;">Experience</h4>
                    <div *ngFor="let exp of resumeDetails?.experience">
                    <h4 class="emp-title" style="font-weight: bold;">{{exp?.company}} {{exp.location ? '(' +
                        exp.location + ')' : ''}} <span class="exp-period">{{displayExperienceDurationDateRange(exp)}}</span></h4>
                    <h4 class="exp-title" style="font-weight: 400;">{{exp?.title}}</h4>
                    <p class="exp-desc mb-4" [innerHTML]="exp?.description">
                    </p>
                </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="resumeDetails?.visibility?.education === true && resumeDetails?.education && resumeDetails?.education?.length > 0">
            <div class="col-md-12 ">
                <div class="border-row pb-2">                   
                    <h4 class="sec-title" style="font-weight: bold;">Education</h4>
                    <ng-container *ngFor="let edu of resumeDetails?.education">
                    <h4 class="emp-title" style="font-weight: bold;">{{edu.school}} <span style="font-weight: 500;" class="exp-period edu" style="font-weight: 500;">{{displayExperienceDurationDateRange(edu)}}
                        {{displayExperienceDurationDateRange(edu) ? '' : ''}}</span></h4>
                    <h4 class="exp-title" style="font-weight: 400;">{{edu.degree_name}}{{edu.field_of_study}}</h4>  
                </ng-container>           
                </div>
            </div>
        </div>
        <div class="row ">
            <div class="col-md-6 mt-2 border-row " *ngIf="resumeDetails?.visibility?.skills === true && resumeDetails?.skills && resumeDetails?.skills.length > 0">
                <div class="pb-2">
                    <h4 class="sec-title" style="font-weight: bold;">Skills</h4>
                    <p class="exp-desc">
                    <span *ngFor="let skills of resumeDetails?.skills">{{skills}}, </span>
                    </p>
                </div>
            </div>
            <div class="col-md-6 mt-2 border-row" *ngIf="resumeDetails?.languages && resumeDetails?.visibility?.languages === true">
                <div class=" pb-2">
                    <h4 class="sec-title" style="font-weight: bold;">Languages</h4>
                    <p class="exp-desc" *ngFor="let lang of resumeDetails?.languages">
                    {{lang}}
                    </p>
                </div>
            </div>
        </div>
        <div class="row " *ngIf="resumeDetails?.visibility?.projects === true && resumeDetails?.accomplishments.projects.length> 0">
            <div class="col-md-12 mt-2">
                <div class="border-row pb-2">
                    <h4 class="sec-title" style="font-weight: bold;">Projects</h4>
                    <ng-container *ngFor="let proj of resumeDetails?.accomplishments.projects">
                    <h4 class="emp-title mb-0" style="font-weight: bold;">{{proj.title}} </h4>
                    <h4 class="exp-period" style="font-weight: 500;">{{displayprojectDuration(proj)}}</h4>    
                </ng-container>         
                </div>

            </div>
        </div>
        <div class="row" *ngIf="resumeDetails?.visibility?.certifications === true && resumeDetails?.certifications && resumeDetails?.certifications?.length > 0">
            <div class="col-md-12 mt-2">
                <div class="border-row pb-2">
                    <h4 class="sec-title" style="font-weight: bold;">Certificates</h4>                
                    <h4 class="emp-title" style="font-weight: bold;" *ngFor="let courses of resumeDetails?.certifications">{{courses}}</h4>       
                </div>

            </div>
        </div>
        <div class="row" *ngIf="resumeDetails?.visibility?.courses === true && resumeDetails?.accomplishments.courses.length> 0">
            <div class="col-md-12 mt-2">
                <div class="border-row pb-2">
                    <h4 class="sec-title" style="font-weight: bold;">Courses</h4>
                    <ng-container *ngFor="let course of resumeDetails?.accomplishments.courses">
                        <h4 class="emp-title mb-0" style="font-weight: bold;">{{course.name}}</h4>
                        <h4 class="exp-period">{{course.number}}</h4>  
                    </ng-container>
                            
                </div>

            </div>
        </div>
        <div class="row" *ngIf="resumeDetails?.visibility?.publications === true && resumeDetails?.accomplishments.publications.length > 0">
            <div class="col-md-12 mt-2">
                <div class="pb-2">
                    <h4 class="sec-title" style="font-weight: bold;">Publications</h4>
                    <ng-container *ngFor="let pub of resumeDetails?.accomplishments.publications">
                        <h4 class="emp-title mb-0" style="font-weight: bold;">{{pub.name}} </h4>
                        <h4 class="exp-period">{{pub.url}}</h4>  
                    </ng-container>                        
                </div>

            </div>
        </div>
    </div>
</div>