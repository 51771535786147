import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, RendererFactory2, ViewChild } from '@angular/core';
import jspdf, { jsPDF } from "jspdf";

import html2canvas from 'html2canvas';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'app/core/local-storage.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { XcvUtilsService } from 'app/core/xcv-utils.service';
import { AytHttpParams } from 'app/helpers/http-config';
import { Constants } from 'app/helpers/app-settings';
import { AppUser, Profile } from 'app/model/Userprofile';
import { UserService } from 'app/core/user.service';
import { DataService } from 'app/helpers/data.service';
import { MessageService } from 'primeng/api';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
declare var html2pdf: any;
@Component({
  selector: 'app-template-base',
  templateUrl: './template-base.component.html',
  styleUrls: ['./template-base.component.scss']
})
export class TemplateBaseComponent implements OnInit, OnDestroy {
  @Input()
  message!: string;

  @Output() previewVisibleEvent = new EventEmitter<string>();    
  @Output() pdfPreviewVisibleEvent = new EventEmitter<string>();    
  @Output() pdfDownloadEvent = new EventEmitter<string>();    

  private subscription!: Subscription;
  private renderer: Renderer2;

  public template: any;
  public vanity_name = '';
  public title = '';
  public confirm = '';
  public consent = '';
  public processingYes = false;
  public processingNo = false;
  public token = '';
  public recruiter_email = '';  
  public name = '';
  public loading = false;
  public resumeDetails: any;
  public iframesrc: any;
  public pdf: any;
  public reloadTemplate = false;
  public previewVisibleChild2 = true;
  public pdfPreviewAction = false;
  public pdfPreviewOn = false;
  public fromReload = false;
  public processingDownload = false;
  public processingcoverDownload = false;
  public processingPreview = false;
  public processingDOC = false;
  public processing = false;
  public coverletter:any={};
  public myDate = new Date();
  public default! :boolean;
  public client: any;

  public prevOn = false;
  public coverModal = false;
  public covertitle:any;
  public appUser!: AppUser;
  public userType = '';

  @ViewChild('iframeContainer', { read: ElementRef, static: true })
  iframeContainer!: ElementRef;

  constructor(
    private rendererFactory: RendererFactory2,
    private router: Router,    
    private actRoute: ActivatedRoute,
    private userService: UserService,
    private messageService: MessageService,
    private dataService: DataService,
    private localStorageService: LocalStorageService, 
    private userservice: UserService) { 
      this.renderer = this.rendererFactory.createRenderer(null, null);

        // this section handles subscribing for parent action on pdf download
        this.subscription = this.dataService.getPdfDownload().subscribe(data => {
          let pdfDownloadAction = (/true/i).test(data);
          if (pdfDownloadAction) {
            this.captureScreen();
          }
        });
        
        // this section handles subscribing for parent action on removing pdf preview
        this.subscription = this.dataService.getRemovePdfPreview().subscribe(data => {
          let removePdfPreviewAction = (/true/i).test(data);
          if (removePdfPreviewAction) {
            this.removeIframe();
            this.pdfPreviewOn = false;
            this.pdfPreviewVisibleEvent.emit('false');
          }
        });      
        
        // this section handles subscribing for parent action on showing pdf preview
        this.subscription = this.dataService.getPdfPreview().subscribe(data => {
          this.pdfPreviewAction = (/true/i).test(data);

          if (this.pdfPreviewAction) {
            setTimeout(() => {
              this.captureScreenPreview();
              this.pdfPreviewAction = false;
              this.pdfPreviewOn = true;
              this.pdfPreviewVisibleEvent.emit('true');
            }, 500);
          }
        });

        // this section handles subscribing for parent action on saving changes during edit,
        // which should reload the selected template for it to reflect the latest changes as well
        this.subscription = this.dataService.getReloadTemplate().subscribe(data => {
          console.log('<<<<<<<<<<<<<<<<< EXECUTING getReloadTemplate');
          this.fromReload = true;
          this.reloadTemplate = (/true/i).test(data);
          if (this.reloadTemplate) {
            this.getREsumeDetails();
          } else {
            this.reloadTemplate = false;
          }
        });      


  }

  ngOnInit(): void {
    // get the vanity name from storage and use to get the detai;

    let userVanity: any = this.localStorageService.getData('userVanity');
    this.appUser = this.localStorageService.getData('appUser');
    if (this.appUser) {
      if (this.appUser.user_type) {
        this.userType = this.appUser.user_type;
      }
    }
    if (userVanity) {
      this.vanity_name = userVanity.vanity_name;
      this.name = userVanity.name;
    }

    console.log('this message base = ', this.message);
    if (this.message === Constants.HAS_PARENT) {
      // 
      let selectedTemplate = this.localStorageService.getData('selectedTemplate');
      console.log('temp = ', selectedTemplate.template);
      this.template = selectedTemplate.template;
    } else {
      this.subscribeToQueryParams();
    }
    this.getREsumeDetails();      
      this.loadCoverletter();
  }

  public isClientValid(): boolean {
    if (this.client) {
      let tokenLinit = Number(this.client.tokens_limit);
      let tokenConsumed = Number(this.client.tokens_consumed);
      if ( tokenConsumed < tokenLinit &&
        tokenLinit > 0
        ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  private subscribeToQueryParams(): void {
    this.subscription = this.actRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.hasOwnProperty('template') && queryParams.template !== '') {
        this.template = queryParams.template;
      }

      if (queryParams.hasOwnProperty('title') && queryParams.title !== '') {
        this.title = queryParams.title;
        console.log('this.title = ', this.title);
      }
      if (queryParams.hasOwnProperty('vanity_name') && queryParams.vanity_name !== '') {
        this.vanity_name = queryParams.vanity_name;
        console.log('this.vanity_name = ', this.vanity_name);
      }

      if (queryParams.hasOwnProperty('confirm') && queryParams.confirm !== '') {
        this.confirm = queryParams.confirm;
        console.log('this.confirm = ', this.confirm);
      }

      if (queryParams.hasOwnProperty('recruiter_email') && queryParams.recruiter_email !== '') {
        this.recruiter_email = queryParams.recruiter_email;
        console.log('this.recruiter_email = ', this.recruiter_email);
      }

      if (queryParams.hasOwnProperty('token') && queryParams.token !== '') {
        this.token = queryParams.token;
        console.log('this.token = ', this.token);
      }

    });
  }

  public getREsumeDetails() {

    if (this.confirm === 'true') {
      if (this.title && this.title.toUpperCase() === 'DEFAULT') {
        this.executeFetchResume();
      } else {
        this.executeSpecificResume();
      }
    } else {
      // check first if there is a stored current resume, if there is, its probably
      // a jd aligned resume and call "specificResume" to get the resume
      // if not do, the default routine
      let cr: any = this.localStorageService.getData('currentResume');

      if (cr) {
        if (XcvUtilsService.isNotNullOrEmpty(cr.id)) {
          // this is a jd aligned resume
          this.executeSpecificResume(cr);
        } else {
          this.executeFetchResume();
        }

      } else {
        this.executeFetchResume();
      }
    }
  }

  public executeSpecificResume(currentResume?: any): void {
    let p = new AytHttpParams();

    if (currentResume) {
      p.set('vanity_name', currentResume.vanity_name);
      p.set('title', currentResume.name);
      let au: any = this.localStorageService.getData('appUser');
      if (au && au.user_type === Constants.RECRUITER) {
        p.set('recruiter_email', au.email);
      }
    } else {
      // this will be for "confirm" flow
      p.set('vanity_name', this.vanity_name);
      p.set('title', this.title);
      p.set('recruiter_email', this.recruiter_email);
    }

    this.loading = true;
    this.subscription = this.userservice.getIndividualResume(p)
      .subscribe({
        next: (response: Profile) => {
          if (response) {
            this.loading = false;
            this.resumeData(response);

          }
        },
        error: (_error: any) => {
          this.loading = false;
        }
      });
  }

  public executeFetchResume(): void {
    let p = new AytHttpParams();

    if (this.confirm === 'true') {
      p.set('vanity_name', this.vanity_name);
      p.set('recruiter_email', this.recruiter_email);
    } else {
      p.set('vanity_name', this.vanity_name);
      let au: any = this.localStorageService.getData('appUser');
      if (au && au.user_type === Constants.RECRUITER) {
        p.set('recruiter_email', au.email);
      }
    }

    this.loading = true;
    this.subscription = this.userservice.getResumeDetails(p)
      .subscribe({
        next: (response: Profile) => {
          if (response) {
            this.loading = false;
            this.resumeData(response);

          }
        },
        error: (_error: any) => {
          this.loading = false;
        }
      });
  }

  resumeData(res: Profile) {
    res.full_name = res.first_name + ' ' + res.last_name;

    this.resumeDetails = res;
    XcvUtilsService.sortExperience(this.resumeDetails.experience, Constants.DESC);
    console.log('>>>>>> pdfPreviewOn = ', this.prevOn);
    console.log('>>>>>> fromReload = ', this.fromReload);
    console.log('>>>>>> message = ', this.message);

    // only do the automatic load of pdf preview if tempalte has parent
    // if (this.pdfPreviewOn && this.fromReload && this.message === Constants.HAS_PARENT) {
    if (this.prevOn && this.fromReload && this.message === Constants.HAS_PARENT) {
        this.removeIframe();
      setTimeout(() => {
        this.captureScreenPreview();
        this.pdfPreviewVisibleEvent.emit('true');
        this.fromReload = false;
      }, 2000);
    }

  }


  public captureScreen() {
    this.processingDownload = true;
    const data = document.getElementById('content')!;
    this.pdf = new jspdf('portrait', 'mm', 'a4');
    const docWidth = this.pdf.internal.pageSize.getWidth();

    this.pdf.html(data, {
      autoPaging: 'text',
      margin: [11, 4, 11, 0],
      width: docWidth,
      windowWidth: 1000
    }).then(
      () => {
        this.addPageNumbers();       
        this.pdf.save(`Resume.pdf`);
        this.pdfDownloadEvent.emit('true');
        this.processingDownload = false;
      });
  }
  public captureCoverletter() {
    this.processingcoverDownload = true;
    const data = document.getElementById('cover-letter')!;
    this.pdf = new jspdf('portrait', 'mm', 'a4');
    const docWidth = this.pdf.internal.pageSize.getWidth();

    this.pdf.html(data, {
      autoPaging: 'text',
      margin: [11, 4, 11, 0],
      width: docWidth,
      windowWidth: 1000
    }).then(
      () => {
            
        this.pdf.save(`Cover-letter.pdf`);
        this.processingcoverDownload = false;
      });
  }

  public captureScreenPreview() {
    this.processingPreview = true;
    const data = document.getElementById('content')!;
    this.pdf = new jspdf('portrait', 'mm', 'a4');
    const docWidth = this.pdf.internal.pageSize.getWidth();

    this.pdf.html(data, {
      autoPaging: 'text',
      margin: [8, 5, 8, 5],
      width: docWidth,
      windowWidth: 1000
    }).then(
      () => {
        this.addPageNumbers()
        // var iframe = document.createElement('iframe');
        // iframe.setAttribute('style','position:absolute;right:0; top:0; bottom:0; height:100%; width:650px; padding:20px;');
        // document.body.appendChild(iframe);
        // iframe.src = this.pdf.output('datauristring');

        let iframe = this.renderer.createElement('iframe');
        iframe.setAttribute('style', Constants.IFRAME_STYLE);
        this.renderer.appendChild(this.iframeContainer.nativeElement, iframe);
        iframe.src = this.pdf.output('datauristring');
        this.prevOn = true;
        this.processingPreview = false;
      });
  }

  public addPageNumbers() {
    var pageCount = this.pdf.internal.getNumberOfPages();
    for (var i = 1; i <= pageCount; i++) {
      // Set the current page
      this.pdf.setPage(i);
      this.pdf.setFontSize(10);
      // Add page number at the bottom
      this.pdf.text('Page ' + i + ' of ' + pageCount, 95, this.pdf.internal.pageSize.height - 4);
    }
  }

  public loadCoverletter():void {
    let payload: any = {};
    let cr: any = this.localStorageService.getData('currentResume');
    if(cr.name === "Default Resume")
    {
      this.default = true;
    }
    else if(cr.name === "Default")
    {
      this.default = true;
    }
    else
    {
      this.default = false;
      this.covertitle = cr.name;
      payload.vanity_name = this.vanity_name;
      payload.JD_title = cr.name;
      if(this.appUser && this.appUser.user_type === Constants.RECRUITER)
      {
        payload.recruiter_email = this.appUser.email;
      }
      this.subscription = this.userService.getCoverletter(payload).subscribe({
        next: response => {
          this.processing = false;    
          if (response) {
            if (response.hasOwnProperty('exception')) {
              // this is error
              this.messageService.add({ severity: 'error', summary: 'Error', detail: response.exception, life: 5000});
            } else {
              
              //this.coverletter.body = response.cover_letter;
              const sentences = response.cover_letter.split(/[.!?]\s/);
              this.coverletter.greeting = sentences[0].split(',')[0]
              this.coverletter.start = sentences[0].split(',')[1];
              this.coverletter.ending =  sentences[sentences.length - 1].split(',')[0];
      
               const startIndex = 1;
               const endIndex = sentences.length - 1;
               this.coverletter.body = sentences.slice(startIndex, endIndex ).join('. ');
            }
          }
        },
        error: error => {
          this.processing = false;
          XcvUtilsService.handleError(error, this.messageService);
        }     
      });
    }
  }

  public generateCoverletter(): void {
    this.coverModal = true;
  }

  public hidecoverModal() {
    this.coverModal = false;
  }

  removeIframe(): void {
    const iframes = this.iframeContainer.nativeElement.querySelectorAll('iframe');
    if (iframes.length > 0) {
      this.renderer.removeChild(this.iframeContainer.nativeElement, iframes[0]);
      this.prevOn = false;
    }
  }

  public toggleTemplateSide(): void {
    if (this.previewVisibleChild2) {
        this.previewVisibleChild2 = false;
        this.previewVisibleEvent.emit('false');
    } else {
        this.previewVisibleChild2 = true;
        this.previewVisibleEvent.emit('true');
    }
  }

  public executeConfirmResponse(consent: string) {
    this.consent = consent;

    let p = new AytHttpParams();
    p.set('consent', this.consent);
    p.set('resume_title', this.title);
    p.set('token', this.token);
    p.set('vanity_name', this.vanity_name);

    if (consent === Constants.YES) {
      this.processingYes = true;
    } else {
      this.processingNo = true;
    }

    this.subscription = this.userService.confirmConsent(p).subscribe({
      next: response => {
        this.processingYes = false;
        this.processingNo = false;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: `Response successfully registered`, life: 5000});

        // go to login
        this.router.navigate(['login'])
      },
      error: error => {
        XcvUtilsService.handleError(error, this.messageService);
        this.processingYes = false;
        this.processingNo = false;
      }
    });
  }

  get TOOLTIP_DEFAULT_POSITION() {
    return Constants.TOOLTIP_DEFAULT_POSITION;
  }

  public downloadDocx(element: any, filename = '') {
    this.processingDOC = true;
    var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:office'>";
    var postHtml = "</body></html>";
    var html = preHtml + document.getElementById(element)?.innerHTML + postHtml;

    var blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
    });
    var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

    filename = filename ? filename + '.doc' : 'document.doc';
    var downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    // if ((window.navigator as any).msSaveOrOpenBlob(blob, filename)) {

    // }
    // else {
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    //}
    document.body.removeChild(downloadLink);
    this.processingDOC = false;
  } 

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
