<div id="content" #content class="{{message === 'HAS_PARENT' ? 'container-with-parent' : 'container' }} mb-5">
    <div class="resume-wrapper">
    <div class="row">
      <!-- section-1 -->

      <div class="about col-md-12 d-flex flex-column align-items-center">
        <h1 class="fs-1">
          {{resumeDetails?.first_name}} {{resumeDetails?.last_name}}
        </h1>
        <h3 class="">{{resumeDetails?.title}}</h3>
        <div
          class="col-md-12 d-flex flex-row gap-2 justify-content-center flex-wrap"
        >
          <a href="" class="contact"
            >{{resumeDetails?.contact.email && resumeDetails?.contact.email.length > 0 ? resumeDetails?.contact.email : 'Not Indicated'}}
            — </a
          >
          <p class="contact">
            {{resumeDetails?.contact.phone
            &&resumeDetails?.contact.phone.length > 0 ?
            resumeDetails?.contact.phone[0] : 'Not Indicated'}} —
          </p>
          <a
            href="https://www.linkedin.com/in/{{resumeDetails?.vanity_name}}/"
            target="_blank"
            class="contact"
          >
            {{resumeDetails?.vanity_name}} —
          </a>
          <a href="" class="contact"
            >{{resumeDetails?.socials.twitter?resumeDetails?.socials.twitter :
            'Not Indicated'}} </a
          >
        </div>
        <p class="text-justify mt-2" [innerHTML]="resumeDetails?.about"></p>
      </div>
    </div>

    <div class="row">
      <!-- section-2 -->

      <div
        class="skills col-md-12 d-flex flex-column align-items-start gap-4"
        *ngIf="resumeDetails?.visibility?.skills === true && resumeDetails?.skills && resumeDetails?.skills.length > 0"
      >
        <div class="d-flex flex-column w-100 mt-2">
          <h3 class="text-uppercase">skills</h3>
          <hr class="custom-hr w-100" />
        </div>
        <div
          class="d-flex flex-row gap-2 align-items-start justify-content-start flex-wrap"
        >
          <p
            class="custom-skills-p"
            *ngFor="let skills of resumeDetails?.skills"
          >
            {{skills}}
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- section-3 -->

      <div
        class="languages col-md-12 d-flex flex-column align-items-start gap-4"
        *ngIf="resumeDetails?.languages && resumeDetails?.visibility?.languages === true"
      >
        <div class="d-flex flex-column w-100 mt-2">
          <h3 class="text-uppercase">languages</h3>
          <hr class="custom-hr w-100" />
        </div>
        <div
          class="d-flex flex-row gap-2 align-items-start justify-content-start flex-wrap"
        >
          <p
            class="custom-skills-p"
            *ngFor="let lang of resumeDetails?.languages"
          >
            {{lang}}
          </p>
        </div>
      </div>

    </div>

    <div class="row">
      <!-- section-4 -->

      <div
        class="experience col-md-12 d-flex flex-column align-items-start"
        *ngIf="resumeDetails?.visibility?.experience === true && resumeDetails?.experience && resumeDetails?.experience?.length > 0"
      >
        <div class="col-md-12 d-flex flex-column w-100 mb-3 mt-2">
          <h3 class="text-uppercase">experience</h3>
          <hr class="custom-hr w-100" />
        </div>
        <div
          class="d-flex flex-column align-items-start justify-content-start flex-wrap w-100"
          *ngFor="let exp of resumeDetails?.experience"
        >
          <div
            class="w-100 d-flex flex-row justify-content-between align-items-center"
          >
            <div
              class="d-flex flex-row align-items-start gap-1 align-items-center flex-wrap"
            >
              <h5 class="">{{exp?.company}}</h5>
              <h6 class="">-</h6>
              <h6 class="">
                {{exp.location ? '(' + exp.location + ')' : ''}}
              </h6>
            </div>
            <div class="d-flex flex-row gap-1">
              <p>{{displayExperienceDurationDateRange(exp)}}</p>
            </div>
          </div>
          <div>
            <h6 class="fw-normal fst-italic">{{exp?.title}}</h6>
          </div>
          <div>
            <p class="text-justify" [innerHTML]="exp?.description"></p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- section-5 -->

      <div
        class="education col-md-12 d-flex flex-column align-items-start"
        *ngIf="resumeDetails?.visibility?.education === true && resumeDetails?.education && resumeDetails?.education?.length > 0"
      >
        <div class="d-flex flex-column w-100 mb-3 mt-2">
          <h3 class="text-uppercase">education</h3>
          <hr class="custom-hr w-100" />
        </div>
        <div
          class="d-flex flex-column align-items-start justify-content-start flex-wrap w-100 my-0"
          *ngFor="let edu of resumeDetails?.education"
        >
          <div
            class="w-100 d-flex flex-row justify-content-between align-items-center my-0"
          >
            <div
              class="d-flex flex-row align-items-start gap-1 align-items-center flex-wrap my-0"
            >
              <h5 class="my-0">{{edu.degree_name}}</h5>
              <h6 class="my-0">-</h6>
              <h6 class="my-0">{{edu.field_of_study}}</h6>
            </div>
            <div class="d-flex flex-row gap-1">
              <p>
                {{displayExperienceDurationDateRange(edu)}}
                {{displayExperienceDurationDateRange(edu) ? '' : ''}}
              </p>
            </div>
          </div>
          <div>
            <h6 class="fw-normal fst-italic">{{edu.school}}</h6>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- section-6 -->

      <div
        class="projects col-md-12 d-flex flex-column align-items-start"
        *ngIf="resumeDetails?.visibility?.projects === true && resumeDetails?.accomplishments.projects.length> 0"
      >
        <div class="d-flex flex-column w-100 mb-3 mt-2">
          <h3 class="text-uppercase">projects</h3>
          <hr class="custom-hr w-100" />
        </div>
        <div
          class="d-flex align-items-center justify-content-between flex-wrap w-100"
          *ngFor="let proj of resumeDetails?.accomplishments.projects"
        >
          <h5 class="">{{proj.title}}</h5>

          <div>
            <p>{{displayprojectDuration(proj)}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- section-7 -->

      <div
        class="courses col-md-12 d-flex flex-column align-items-start"
        *ngIf="resumeDetails?.visibility?.courses === true && resumeDetails?.accomplishments.courses.length> 0"
      >
        <div class="d-flex flex-column w-100 mb-3 mt-2">
          <h3 class="text-uppercase">courses</h3>
          <hr class="custom-hr w-100" />
        </div>
        <ul class="px-3 my-0">
          <div
            class="d-flex gap-2"
            *ngFor="let course of resumeDetails?.accomplishments.courses"
          >
            <li class="fw-bold m-0">{{course.name}}:</li>
            <p>{{course.number}}</p>
          </div>
        </ul>
      </div>
    </div>

    <div class="row">
      <!-- section-8 -->

      <div
        class="certificates col-md-12 d-flex flex-column align-items-start "
        *ngIf="resumeDetails?.visibility?.certifications === true && resumeDetails?.certifications && resumeDetails?.certifications?.length > 0"
      >
        <div class="d-flex flex-column w-100 mb-3 mt-2">
          <h3 class="text-uppercase">certificates</h3>
          <hr class="custom-hr w-100" />
        </div>
        <div
          class="d-flex flex-column align-items-start flex-wrap gap-1"
          
        >
          <h6 class="" *ngFor="let courses of resumeDetails?.certifications">{{courses}}</h6>
        </div>
      </div>
    </div>
    <!-- section-9 -->
    <div class="row">
      <div
        class="publications col-md-12 d-flex flex-column align-items-start"
        *ngIf="resumeDetails?.visibility?.publications === true && resumeDetails?.accomplishments.publications.length > 0"
      >
        <div class="d-flex flex-column w-100 mb-3 mt-2">
          <h3 class="text-uppercase">publications</h3>
          <hr class="custom-hr w-100" />
        </div>
        <ul class="px-3">
          <div
            class="d-flex gap-2"
            *ngFor="let pub of resumeDetails?.accomplishments.publications"
          >
            <li class="fw-bold m-0">{{pub.name}}:</li>
            <a class="text-black" href="">{{pub.url}}</a>
          </div>
        </ul>
      </div>
    </div>
  </div>
  </div>