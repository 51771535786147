import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'app/core/local-storage.service';
import { UserService } from 'app/core/user.service';
import { DataService } from 'app/helpers/data.service';
import { Subscription } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppUser, Client, Profile } from 'app/model/Userprofile';
import { AytHttpParams } from 'app/helpers/http-config';
import { Constants } from 'app/helpers/app-settings';
import { FilterService } from 'primeng/api';
import { XcvUtilsService } from 'app/core/xcv-utils.service';

@Component({
  selector: 'app-jd-alignment',
  templateUrl: './jd-alignment.component.html',
  styleUrls: ['./jd-alignment.component.scss']
})

export class JdAlignmentComponent implements AfterViewInit, OnInit, OnDestroy {
  public vanity_name = '';
  public jdAlignForm !: FormGroup;
  public newVanityForm !: FormGroup;
  public submitted = false;
  public processing = false;
  public first_name = '';
  public clientList: any[] = [];
  public clientListComplete: any[] = [];
  public userType = Constants.CLIENT;
  public appUser: AppUser = {};
  public resumeListing: any[] = [];
  public resumeListingComplete: any[] = [];
  public loading = false;
  public clientListModal = false;
  public selectedClient: any = {};
  public deleting = false;
  public showClients = true;
  public searchStringClient = '';
  public searchStringResume = '';
  public userAgreementModal = false;
  public enterVanity = false;
  public processingConfirm = false;
  public displayDialog: boolean = false;
  public isSmallScreen = false;
  public agreeBtnDisabled = true;
  public client: Client = new Client();
  public isToJDAlign = false;
  public deletingClient = false;
  public title = '';
  public fileSelected = false;
  public selectedFile!: File;
  public processPdf = false;
  public fetchFromPdf = false;
  public disablevanitySubmit = true;
  public processinguseragreement = false;

  private subscription!: Subscription;

  constructor(private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dataService: DataService,
    private filterService: FilterService,
    private localStorageService: LocalStorageService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  private checkScreenSize() {
    const isSmallScreen = window.innerWidth <= 767; // Match the CSS media query width
    this.isSmallScreen = isSmallScreen;
    // You can also dynamically apply a CSS class if needed
    if (isSmallScreen) {
      this.displayDialog = false;
    } else {
      this.displayDialog = true;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
        this.dataService.setData('true');
        this.dataService.setName(this.first_name);
        this.dataService.setVanity('');
        if (this.appUser.subscription) {
          this.dataService.setSubscriptionPlan(this.appUser.subscription);
        }
        this.dataService.setClient(this.client);
        this.dataService.setUserType(this.userType);
      }, 500);
}

  public createJdAlignForm() {
    this.jdAlignForm = this.fb.group({
      jdTitle: ['', [Validators.required]],
      jd: ['', [Validators.required]],
      vanity_name: [''],
      additionalJd: ['']
    });

  }

  public createRecruiterJdAlignForm() {
    this.jdAlignForm = this.fb.group({
      jdTitle: ['', [Validators.required]],
      jd: ['', [Validators.required]],
      vanity_name: ['', [Validators.required]],
      additionalJd: ['']
    });
  }

  private subscribeToQueryParams() {
    this.subscription = this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.hasOwnProperty('jdalign') && queryParams.jdalign !== '') {
        this.isToJDAlign = (queryParams.jdalign.toLowerCase() === 'true');
      }
      if (queryParams.hasOwnProperty('title') && queryParams.title !== '') {
        this.title = queryParams.title;
      }
    });
  }

  ngOnInit(): void {
    this.checkScreenSize();
    this.subscribeToQueryParams();
    console.log('isToJDAlign = ', this.isToJDAlign);
    this.jdAlignForm = this.fb.group({
      jdTitle: [''],
      jd: [''],
      vanity_name: [''],
      additionalJd: ['']
    });
    this.newVanityForm = this.fb.group({
      newVanity: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]]
    })

    // make the 'job description' field required if its to jd align
    if (this.isToJDAlign) {
      this.jdAlignForm.controls['jd'].setValidators([Validators.required]);
      this.jdAlignForm.controls['jdTitle'].setValidators([Validators.required]);

      this.jdAlignForm.controls['jd'].updateValueAndValidity();
    } else {
      this.jdAlignForm.controls['jd'].setValidators(null);
      this.jdAlignForm.controls['jdTitle'].setValidators(null);
      this.jdAlignForm.controls['jd'].updateValueAndValidity();
    }

    // get user from storage
    this.appUser = this.localStorageService.getData('appUser');
    if (this.appUser && this.appUser.first_name) {
      this.first_name = this.appUser.first_name;
      this.dataService.setAppUser(this.appUser);
    }

    console.log('eddie appUser = ', this.appUser);

    // get the "client" and save it on storage, client has the token info
    if (this.appUser.vanity_name) {
      this.getClientThenSaveOnStorage(this.appUser.vanity_name);
    }


    if (this.title) {
      this.jdAlignForm.controls['jdTitle'].setValue(this.title);

    }

    if (this.appUser.user_type) {
      this.userType = this.appUser.user_type;
      if (this.userType === Constants.RECRUITER) {
        this.getClientList();

        // this.createRecruiterJdAlignForm();
        this.jdAlignForm.controls['vanity_name'].setValidators([Validators.required]);
        this.jdAlignForm.controls['vanity_name'].updateValueAndValidity();
      } else {
        // logged in user is the client himself
        if (this.appUser.vanity_name) {
          this.vanity_name = this.appUser.vanity_name;
        }
        console.log('>>> validator clear');
        this.jdAlignForm.controls['vanity_name'].clearValidators();
        this.jdAlignForm.controls['vanity_name'].updateValueAndValidity();

        // hide the client list
        this.showClients = false;
        // show the selected client resumes
        this.getResumeList(this.appUser.vanity_name);
      }
    }


    if (this.userType === Constants.RECRUITER) {
      this.dataService.setUserType(this.userType);
    }

    // do not get resume list, get it after selecting a client
    // this.getResumeList();
  }

  public executeSubmit(): void {
    this.submitted = true;
    if (this.jdAlignForm.valid || (this.selectedFile && this.fileSelected)) {
      if (this.userType === Constants.RECRUITER) {
        this.verifyVanityName();
      } else {
        // the logged in user is the vanity_name. Execute jd align directly
        this.executeJdAlign();
      }
    }
  }

  public verifyVanityName(): void {
    var urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    var vanity_name = this.jdAlignForm.controls['vanity_name'].value;
    if (urlRegex.test(vanity_name)) {
      this.vanity_name = this.extractVanityName(vanity_name);
  } else {
    this.vanity_name = this.jdAlignForm.controls['vanity_name'].value;
  }
    if (this.vanity_name) {
      // use the new endpoint to validate the vanity name from the backend
      let p = new AytHttpParams();
      p.set('vanity_name', this.vanity_name);
      this.processing = true;
      this.subscription = this.userService.verifyVanity(p).subscribe({
        next: response => {
          this.processing = false;

          // check if vanity_name is existing on the client list.
          // if it is, do not prommpt anymore.
          let existing = this.clientList.find((c: any) => c.vanity_name === this.vanity_name);
          if (existing) {
            this.fetchResume(this.vanity_name);
          } else {
            this.userAgreementModal = true;
          }

        },
        error: error => {
          this.processing = false;
          XcvUtilsService.handleError(error, this.messageService);
        }
      });
    }
    else {
      if (this.selectedFile && this.fileSelected) {
        // this.enterVanity = true;
        this.parsePDF()
      }
    }
  }

public extractVanityName(linkedinUrl:any) {
    // Regular expression to match the vanity name
    var regex = /\/in\/([^\/?]+)/;
    var match = linkedinUrl.match(regex);
    if (match && match[1]) {
        return match[1];
    } else {
        return null; // Vanity name not found
    }
}

public updateButtonState() {
    if (this.newVanityForm.valid) {
      this.disablevanitySubmit = false;
    }
    else {
      this.disablevanitySubmit = true;
    }
  }

  public createnewVanity() {
    this.processinguseragreement =true;
    this.vanity_name = this.newVanityForm.controls['newVanity'].value;
    if (this.vanity_name) {
      // use the new endpoint to validate the vanity name from the backend
      let p = new AytHttpParams();
      p.set('vanity_name', this.vanity_name);
      this.subscription = this.userService.verifyVanity(p).subscribe({
        next: response => {
          // check if vanity_name is existing on the client list.
          // if it is, do not prommpt anymore.
          let existing = this.clientList.find((c: any) => c.vanity_name === this.vanity_name);
          if (existing) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Vanity name already exists please enter new vanity name', life: 5000 });
          } else {
            this.updateResume(this.vanity_name);
          }
        },
        error: error => {
          
          //XcvUtilsService.handleError(error, this.messageService);
        this.updateResume(this.vanity_name);
        }
      });
    }
  }

  public updateResume(vanity: any): void {
    let payload = {
      vanity_name: vanity,
      payload: {
        section_attr: {
    first_name: this.newVanityForm.controls['firstName'].value,
    last_name: this.newVanityForm.controls['lastName'].value
        }
      }
    }
    this.subscription = this.userService.updateResume(payload).subscribe({
      next: response => {
        this.processinguseragreement = true;
        this.parsePDF();
      },
      error: error => {
        this.processing = false;
        XcvUtilsService.handleError(error, this.messageService);
      }
    });
  }

  public parsePDF() {
    this.processPdf =true;  
    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = () => {
      let payload: any = {};
      var base64result = (<string>reader.result).split(',')[1];
      payload.base64encoded = base64result;
      payload.recruiter_email = this.appUser.email;
      this.subscription = this.userService.parsePDF(payload).subscribe({
        next: response => {
          this.processPdf = false;
          this.processinguseragreement = true;
          this.vanity_name = response.vanity_name;
          this.userAgreementModal = true;
          this.fetchFromPdf = true;
          // this.verifyVanityforPDF(vanity_name);
          this.processinguseragreement = false;                                                                                 // // save to local stoage the scrapped resume
          const data = { vanity_name: response.vanity_name };
          this.localStorageService.saveData('userVanity', data);
          // save on the local storage the newly created default resume by creating new structure from response and payload
          let currentResume: any = {};
          currentResume.id = null;
          currentResume.name = 'Default';
          currentResume.vanity_name = response.vanity_name;
          this.localStorageService.saveData('currentResume', currentResume);
        },
        error: error => {
          this.processing = false;
          this.processPdf = false;
          XcvUtilsService.handleError(error, this.messageService);
        }
      });
    };
  }

  public closeenterVanity(): void {
    this.enterVanity = false;
  }

  public cancelAgree(): void {
    this.userAgreementModal = false;
    this.agreeBtnDisabled = true;
  }

  public confirmAgree(): void {
    this.processingConfirm = true;
    this.userAgreementModal = false;
    if (this.fetchFromPdf == true) {
      this.router.navigate(['resume-edit'], {
        queryParams: {
          vanity_name: this.vanity_name,
          recruiter_email: this.appUser.email
        }
      })
    }
    else {
      this.fetchResume(this.vanity_name);
    }

  }

  public fetchResume(vanity_name: any) {
    let p = new AytHttpParams();
    p.set('vanity_name', vanity_name);
    if (this.appUser.email) {
      p.set('recruiter_email', this.appUser.email);
    }

    this.processing = true;
    this.processingConfirm = true;
    this.subscription = this.userService.getResumeDetails(p)
      .subscribe({
        next: (response: any) => {
          this.processing = false;
          this.processingConfirm = false;


          if (this.isToJDAlign) {
            // after fetching (making sure the vanity_name exists on the system), now you can JD
            this.executeJdAlign();
          } else {

            this.messageService.add({ severity: 'success', summary: 'Success', detail: `Resume sucessfully retrieved`, life: 5000 });


            // // save to local stoage the scrapped resume
            const data = { vanity_name: response.vanity_name };
            this.localStorageService.saveData('userVanity', data);

            // save on the local storage the newly created default resume by creating new structure from response and payload
            let currentResume: any = {};
            currentResume.id = null;
            currentResume.name = 'Default';
            currentResume.vanity_name = response.vanity_name;
            this.localStorageService.saveData('currentResume', currentResume);


            // // // go to the preview
            this.router.navigate(['resume-edit'], {
              queryParams: {
                vanity_name: response.vanity_name,
                recruiter_email: this.appUser.email
              }
            })
          }


        },
        error: (_error: any) => {
          this.processing = false;
          this.processingConfirm = false;
          if (_error && _error.hasOwnProperty('error') && _error.error.hasOwnProperty('exception') &&
            _error.error.exception === 'Invalid vanity name') {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: _error.error.exception, life: 5000 });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: `Error encountered`, life: 5000 });
          }
        }
      });
  }

  public executeJdAlign(): void {
    this.submitted = true;
    var urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    var jd_vanity_name;
    var vanity_name = this.jdAlignForm.controls['vanity_name'].value;
    if (urlRegex.test(vanity_name)) {
      jd_vanity_name = this.extractVanityName(vanity_name);
  } else {
    jd_vanity_name = this.jdAlignForm.controls['vanity_name'].value;
  }
    
    if (this.jdAlignForm.valid) {

      let payload: any = {};

      if (this.userType === Constants.CLIENT) {

        payload.vanity_name = this.vanity_name;
      } else {
        payload.vanity_name = jd_vanity_name;
      }

      payload.JD_title = new String(this.jdAlignForm.controls['jdTitle'].value);
      payload.JD = this.jdAlignForm.controls['jd'].value;
      payload.recruiter_email = this.appUser.email;

      this.processing = true;
      this.processingConfirm = true;
      this.subscription = this.userService.alignJD(payload).subscribe({
        next: response => {
          this.processing = false;
          this.processingConfirm = false;
          if (response) {
            if (response.hasOwnProperty('exception')) {
              // this is error
              this.messageService.add({ severity: 'error', summary: 'Error', detail: response.exception, life: 5000 });
            } else {
              // relist the client or the resume, depending on the view
              if (this.userType === Constants.RECRUITER &&
                this.showClients) {
                // this.getClientList();
              } else if (this.userType === Constants.RECRUITER &&
                !this.showClients && this.selectedClient &&
                this.selectedClient.vanity_name) {
                // this.getResumeList(this.selectedClient.vanity_name);
              }
              this.submitted = false;
              // clear the form
              this.jdAlignForm = this.fb.group({
                jdTitle: ['', [Validators.required]],
                jd: ['', [Validators.required]],
                vanity_name: ['', [Validators.required]],
                additionalJd: ['']
              });
              this.messageService.add({ severity: 'success', summary: 'Success', detail: `JD successfully aligned`, life: 5000 });
              // save on the local storage the newly created jd aligned resume by creating new structure from response and payload
              let currentResume: any = {};
              currentResume.id = response.jd;
              currentResume.name = payload.JD_title.toLowerCase();
              currentResume.vanity_name = response.vanity_name;
              currentResume.jd = payload.JD;
              this.localStorageService.saveData('currentResume', currentResume);
              // go to the preview/edit mode of this new
              // get the jd id (jd) from the response, find it from the resume list to get the "title"
              this.router.navigate(['resume-edit'], {
                queryParams: {
                  'vanity_name': payload.vanity_name,
                  'title': payload.JD_title.toLowerCase(),
                  'recruiter_email': payload.recruiter_email
                }
              })
            }
          }

        },
        error: error => {
          this.processing = false;
          this.processingConfirm = false;
          XcvUtilsService.handleError(error, this.messageService);
        }

      });
    }
  }

  public getResumeList(client_vanity_name?: string): void {
    let p = new AytHttpParams();
    if (this.userType === Constants.CLIENT) {
      if (client_vanity_name) {
        p.set('vanity_name', client_vanity_name);
      } else {
        p.set('vanity_name', this.vanity_name);
      }
    } else {
      if (this.appUser.email) {
        p.set('recruiter_email', this.appUser.email);
      }
      if (client_vanity_name) {
        p.set('vanity_name', client_vanity_name);
      }
    }

    this.loading = true;
    this.subscription = this.userService.getAllResumes(p)
      .subscribe({
        next: response => {
          this.loading = false;

          console.log('>>> all JD resumes = ', response);



          if (response) {
            this.resumeListing = [];
            this.resumeListingComplete = [];
            if (response) {
              // if (this.userType === Constants.CLIENT) {
              // }

              // first record for the 'Default Resume'
              let def: any = {};
              def.id = null;
              def.name = 'Default Resume';
              def.vanity_name = client_vanity_name;
              this.resumeListing.push(def);


              response.forEach((el: any) => {
                let obj: any = {};
                obj.id = el.id;
                obj.name = el.title;
                obj.consent = '';

                if (el.consent_data && el.consent_data.consent) {
                  obj.consent = el.consent_data.consent;
                }

                if (el.consent_data && el.consent_data.date) {
                  obj.date = el.consent_data.date;
                }

                if (this.userType === Constants.CLIENT) {
                  obj.vanity_name = this.appUser.vanity_name;
                } else {
                  obj.vanity_name = client_vanity_name;
                }

                this.resumeListing.push(obj);
              });
            }

            console.log('>>>> resumeListing = ', this.resumeListing);
            this.resumeListingComplete = Object.assign([], this.resumeListing);
          }
        },
        error: error => {
          this.loading = false;
          XcvUtilsService.handleError(error, this.messageService);
        }
      });
  }

  public selectResume(obj: any): void {
    let p = new AytHttpParams();
    p.set('vanity_name', this.vanity_name);

    if (this.userType === Constants.CLIENT) {
      if (obj.value.id === null || obj.value.id === '' || obj.value.id === 'null') {
        p.set('title', 'default');
      } else {
        p.set('title', obj.value.name);
        // this.jd_title =  obj.value.name;
      }
    } else {
      if (this.appUser.first_name) {
        p.set('recruiter_email', this.appUser.first_name);
      }
    }

    this.getIndividualResume(p);
  }

  public getIndividualResume(p: any): void {
    this.subscription = this.userService.getIndividualResume(p)
      .subscribe({
        next: (response: Profile) => {
          if (response) {
          }
        },
        error: (_error: any) => {
        }
      });
  }

  public showClientList() {
    this.clientListModal = true;
    this.getClientList();
  }

  public hideClientList() {
    this.clientListModal = false;
  }

  public getClientList(): void {
    // get clients if any  //getRecruiterClients
    let p = new AytHttpParams();
    if (this.appUser.email) {
      p.set('email', this.appUser.email);
    } else if (this.appUser.first_name) {
      p.set('email', this.appUser.first_name);
    }
    this.loading = true;
    this.subscription = this.userService.getRecruiterClients(p).subscribe({
      next: response => {
        this.loading = false;
        if (response) {
          this.clientList = response;
          this.clientListComplete = response;
          console.log('>>> this.clientList = ', this.clientList);

          // if this is true, get the vanity info from local storage and load it as selcted client
          if (this.isToJDAlign) {
            let vanity = this.localStorageService.getData('userVanity');
            console.log('vanity = ', vanity);
            if (vanity && this.clientList && this.clientList.length > 0) {
              let selected = this.clientList.find((c: any) => c.vanity_name === vanity.vanity_name);
              if (selected) {
                this.selectedClient = selected;
                this.showClients = false;
                this.getResumeList(this.selectedClient.vanity_name);
              }
            }
            if (vanity) {
              this.jdAlignForm.controls['vanity_name'].setValue(vanity.vanity_name);
            }
          }

        }
      },
      error: error => {
        this.loading = false;
        XcvUtilsService.handleError(error, this.messageService);
      }
    });
  }

  public selectClient(client: any): void {
    this.showClients = false;
    this.selectedClient = client;
    this.getResumeList(client.vanity_name);
  }

  public editJdAlignedResume(resume: any) {
    // go to edit
    console.log('GOES TO editJdAlignedResume resume = ', resume);

    // save to local stoage
    const data = { vanity_name: resume.vanity_name };
    this.localStorageService.saveData('userVanity', data);

    // save to local storage current resume
    this.localStorageService.saveData('currentResume', resume);


    // // go to the preview
    if (resume.id === null || resume.name === 'Default Resume') {

      console.log('AAAAAAAAAAA resume.vanity_name = ', resume.vanity_name);

      this.router.navigate(['resume-edit'], {
        queryParams:
        {
          'vanity_name': resume.vanity_name,
          'recruiter_email': this.appUser.email
        }
      })
    } else {


      this.router.navigate(['resume-edit'], {
        queryParams: {
          'vanity_name': resume.vanity_name,
          'title': resume.name,
          'recruiter_email': this.appUser.email
        }
      })
    }

  }

  public confirmDeleteClient(vanity_name: string) {
    let p = new AytHttpParams();
    p.set('vanity_name', vanity_name);
    if (this.appUser.email) {
      p.set('recruiter_email', this.appUser.email);
    }

    this.deletingClient = true;
    this.subscription = this.userService.deleteClient(p).subscribe({
      next: response => {
        if (response) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: `Client ${vanity_name} successfully deleted`, life: 5000 });

          // re list the client list
          this.showClients = true;
          this.getClientList();
        }
      },
      error: error => {
        XcvUtilsService.handleError(error, this.messageService);
      }
    });

  }

  public confirmRemoveClient(selectedClient: any) {
    console.log('>> selectedClient = ', selectedClient);
    let vanity_name = selectedClient.vanity_name;

    this.confirmationService.confirm({
      message: `Are you sure that you want delete client '${vanity_name}'?`,
      accept: () => {
        //Actual logic to perform a confirmation
        this.confirmDeleteClient(vanity_name);
      },
      key: "confirmDelClientDialog"
    });
  }

  public confirm(resume: any) {
    let jd_id = resume.id;
    let vanity_name = resume.vanity_name;
    let name = resume.name;

    this.confirmationService.confirm({
      message: `Are you sure that you want delete resume '${name}'?`,
      accept: () => {
        //Actual logic to perform a confirmation
        this.confirmDeleteJDResume(jd_id, vanity_name);
      },
      key: "confirmDelJDResDialog"
    });
  }

  public confirmDeleteJDResume(jd_id: any, vanity_name: any) {
    let p = new AytHttpParams();
    p.set('jd_id', jd_id);
    p.set('vanity_name', vanity_name);
    this.loading = true;
    this.deleting = true;
    this.subscription = this.userService.deleteAlignedJD(p).subscribe({
      next: response => {
        this.deleting = false;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: `JD aligned resume successfully deleted`, life: 5000 });

        // relist the table
        this.getResumeList(vanity_name);
      },
      error: error => {
        this.deleting = false;
        XcvUtilsService.handleError(error, this.messageService);
      }
    });
  }

  public backToClient(): void {
    this.searchStringResume = '';
    this.showClients = true;
  }

  public searchClients(event: any): void {
    this.searchStringClient = event.target.value;
    this.clientList = this.filterService.filter(this.clientListComplete, ['last_name', 'first_name', 'vanity_name', 'title'], this.searchStringClient, 'contains');
  }

  public searchResumes(event: any): void {
    this.searchStringResume = event.target.value;
    this.resumeListing = this.filterService.filter(this.resumeListingComplete, ['name'], this.searchStringResume, 'contains');
  }

  public onScroll(event: Event) {
    // Check if the user has scrolled to the very bottom
    const element = event.target as HTMLElement;
    let m = element.scrollHeight - element.scrollTop;

    // i dont know where the .5 diff is coming from
    if ((element.scrollHeight - element.scrollTop) === element.clientHeight
      ||
      (m - element.clientHeight < 15)
    ) {
      // User has scrolled to the very bottom
      this.agreeBtnDisabled = false;
      // this.messageService.add({ severity: 'success', summary: '', detail: `Thank you for reading user agreement`, life: 5000});
    }
  }


  public getClientThenSaveOnStorage(vanity_name: string): void {
    if (this.userType === Constants.CLIENT) {
      // get also the client info and put it on the storage
      let p = new AytHttpParams();
      p.set('vanity_name', vanity_name);
      this.subscription = this.userService.getClient(p).subscribe({
        next: (response: Client) => {
          if (response) {
            console.log('response client = ', response);
            const data = {
              date_subscribed: response.date_subscribed,
              first_name: response.first_name,
              last_name: response.last_name,
              tokens_consumed: response.tokens_consumed,
              tokens_limit: response.tokens_limit,
              unique_id: response.unique_id,
              vanity_name: response.vanity_name
            };

            this.localStorageService.saveData('userClient', data);
            this.client = data;
            // this triggers the header to get the lateset
            console.log('>>>>>> TRIGGERING THE DATA ZSERVICE');
            this.dataService.setClient(data);
          }
        },
        error: error => {
          XcvUtilsService.handleError(error, this.messageService);
        }
      });


    }
  }

  public handleBoxClick(): void {
    // Trigger the file input when the box is clicked
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      // fileInput.value = null;
      fileInput.click();
    }
  }

  public handleFileInput(event: any): void {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      this.fileSelected = true;
    }
  }

  public removeSelectedPDF(): void {
    this.fileSelected = false;
  }
  get TOOLTIP_DEFAULT_POSITION() {
    return Constants.TOOLTIP_DEFAULT_POSITION;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
