<ng-container>


    <div class="row mt-5">
        <div class="col-md-12 justify-content-center d-flex mt-2">

          <!-- <button [disabled]="processing" (click)="triggerSaveProfile()" type="button" class="btn btn-success mt-4 mb-2 btn-resume chatgpt">Save
            <div *ngIf="processing" class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
          </button> -->

          <button *ngIf="
           (userType === 'CLIENT' && appUser && 
            appUser.subscription && appUser.subscription.plan_type === 'PREMIUM'
           && isClientValid())
           || 
           userType === 'RECRUITER'
          
          "  (click)="triggerJDAlign()"
          type="button" class="btn btn-primary mt-4 mb-2 btn-resume save">JD Align</button>

          <button (click)="triggerTemplateSelection()" *ngIf="previewVisible" type="button" class="btn btn-primary mt-4 pull-right mb-2 btn-resume edit">Templates</button>
          <button (click)="reloadResume()" *ngIf="previewVisible && startprofile" type="button" class="btn btn-primary mt-4 pull-right mb-2 btn-resume confirmauthorize">Resume Reload</button>

          <!-- <button (click)="triggerHidePreviewSide()" *ngIf="hasTemplateSelected && previewVisible"  type="button" class="btn btn-primary mt-4 pull-right mb-2 btn-resume edit">Hide Preview</button>
          <button (click)="triggerShowPreviewSde()" *ngIf="!previewVisible" type="button" class="btn btn-primary mt-4 pull-right mb-2 btn-resume edit">Show Preview</button> -->


          <!-- <button (click)="triggerTemplateDelete()" *ngIf="hasTemplateSelected && previewVisible" type="button" class="btn btn-primary mt-4 pull-right mb-2 btn-resume edit">Remove Template</button> -->

          <!-- <button [disabled]="processingPdfPreview" (click)="triggerPdfPreview()"  *ngIf="hasTemplateSelected && !pdfPreviewVisible" type="button" class="btn btn-primary mt-4 pull-right mb-2 btn-resume pdfpreview">PDF Preview
            <div *ngIf="processingPdfPreview" class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
          </button> -->
          <!-- <button (click)="triggerCancelPdfPreview()"  *ngIf="hasTemplateSelected && pdfPreviewVisible" type="button" class="btn btn-primary mt-4 pull-right mb-2 btn-resume pdfpreview">Remove PDF Preview</button> -->
             
          <!-- <button [disabled]="pdfDownloadProcessing" (click)="triggerPdfDownload()"  *ngIf="hasTemplateSelected" type="button" class="btn btn-primary mt-4 pull-right mb-2 btn-resume pdfdownload">PDF Download
            <div *ngIf="pdfDownloadProcessing" class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
          </button> -->

          <button *ngIf="hasTemplateSelected && allowAuthButton && userType === 'RECRUITER'" (click)="showConfirmAthModal()" type="button" class="btn btn-primary mt-4 pull-right mb-2 btn-resume confirmauthorize">Get Consent</button>
        </div>
    </div>
  
    <div class="row">

        <!-- start left side -->
        <div *ngIf="hasTemplateSelected" class="col ml-2">

          <div class="row mt-5 mb-4">
            <div class="col text-center ml">

              <span class="template-header">
                Template: {{selectedTemplate}}
              </span>

            </div>
          </div>

          <div class="row">
            <div class="col">

              <ng-container>
                <app-template-base
                [message]="parentMessage"
                (previewVisibleEvent)="previewToggleTriggered($event)"
                (pdfPreviewVisibleEvent)="pdfPreviewToggleTriggered($event)"
                (pdfDownloadEvent)="pdfDownloadTriggered($event)"
              >
              </app-template-base>
    
              </ng-container>
    
            </div>
          </div>
          

            <!-- <ng-container *ngIf="selectedTemplate === 'basic'">
                <app-template1
                [message]="parentMessage"
                (previewVisibleEvent)="previewToggleTriggered($event)"
                (pdfPreviewVisibleEvent)="pdfPreviewToggleTriggered($event)"

                (pdfDownloadEvent)="pdfDownloadTriggered($event)"

                >
                </app-template1>

            </ng-container>

            <ng-container *ngIf="selectedTemplate === 'classic'">
                <app-template2
                [message]="parentMessage"

                (previewVisibleEvent)="previewToggleTriggered($event)"
                (pdfPreviewVisibleEvent)="pdfPreviewToggleTriggered($event)"

                (pdfDownloadEvent)="pdfDownloadTriggered($event)"

                >
                </app-template2>
            </ng-container> -->

            

        </div>
        <!-- start right side -->

        <!-- start right side -->
        <div *ngIf="previewVisible" class="col">
            <app-resume-preview 
            [message]="parentMessage"

            [vanity_name_parent]="vanity_name"
            [title_parent]="title"
            [recruiter_email_parent]="recruiter_email"
            
            (messageEvent)="receiveMessage($event)"
            (templateSelectedEvent)="templateSelected($event)"
            (templateRemoveEvent)="templateRemoved($event)"
            (saveExecutedEvent)="saveTriggered($event)"
            (previewVisibleEvent)="previewToggleTriggered($event)"
            (clientEmailEvent)="clientEmailTriggered($event)"
            (resumeRetrievedEvent)="resumeRetrievedTriggered($event)"
            (clientRetrievedEvent)="clientRetrievedTriggered($event)"

                        

            >
            </app-resume-preview>
        </div>
        <!-- end right side -->

    </div>
</ng-container>


<!-- start confirm auth dialog -->
<form [formGroup]="confirmAuthForm" (ngSubmit)="executeAuthConfirm()">

<p-dialog  [(visible)]="confirmAuthModal" [draggable]="true" [closable]="true" [showHeader]="true"
          [blockScroll]="true" [modal]="true" 
          [styleClass]="displayDialog ? 'responsive-dialog' : ''"
          *ngIf="confirmAuthModal" #confAuthModal>


          <p-header>
            <div class="row">
              <div class="col-md-12 text-left">
                <h5 class="modal-title" id="confAuthLabel">Get Candidate Authorizations</h5>
              </div>
            </div>
          </p-header>

          <div class="row">
            <div class="col-10">
              <span>Enter the candidate email, to get his confirmation of the template.
              </span>
            </div>
          </div>
          
          <div class="row mt-email">
            <div class="col-10">
              <div class="form-group">

                <div>
                  <label for="email" class="login-labels">Candidate Email:</label>
                </div>


                <input class="mt form-control curved" type="text" id="email" formControlName="email">
                <div class="text-danger mt-2" *ngIf="submitted && (
                (confirmAuthForm.controls['email'].errors && confirmAuthForm.controls['email'].errors['required'])
                )">
                  Email is required
                </div>
                <div class="text-danger mt-2" *ngIf="submitted && (
                (confirmAuthForm.controls['email'].errors && confirmAuthForm.controls['email'].errors['invalidEmail'])
                )">
                  Email format is invalid
                </div>
                      


              </div>



            </div>
          </div>

          <p-footer>
            <div class="row">
              <div class="col-md-12">
                
                <div  class="row">
                  <div class="col">
                  </div>
                  <div class="col-auto">
                  </div>
                  <div class="col-auto">
                    <button [disabled]="processing" type="submit" class="btn btn-primary">
                      Seek Authorizatioion
                      <div *ngIf="processing" class="spinner-border spinner-border-sm" role="status">
                      </div>
                    </button>          
                  </div>
                </div>
        
              </div>
        
            </div>
          </p-footer>
        



</p-dialog>
</form>
<!-- end confirm auth dialog -->
