<div id="content" #content class="{{message === 'HAS_PARENT' ? 'container-with-parent' : 'container' }} mb-5">
    <div class="resume-wrapper">
        <div class="top-section">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="emp-name">{{resumeDetails?.first_name | uppercase}} {{resumeDetails?.last_name |
                        uppercase}}</h2>
                        <h4 class="text-center emp-title">{{resumeDetails?.title}}</h4>
                    <ul class="inline-contact mb-1 pl-0">
                        <li>

                            {{resumeDetails?.contact.phone &&resumeDetails?.contact.phone.length > 0 ?
                            resumeDetails?.contact.phone[0] : 'None Indicated'}}
                        </li>
                        <li>

                            {{resumeDetails?.location}}
                        </li>
                    </ul>
                    <ul class="inline-contact pl-0">

                        <li>
                            <span class="text">
                                {{resumeDetails?.contact.email && resumeDetails?.contact.email.length > 0 ?
                                resumeDetails?.contact.email[0] : 'Not Indicated'}}</span>
                        </li>
                        <li>
                            <span>
                                <a href="https://www.linkedin.com/in/{{resumeDetails?.vanity_name}}/" target="_blank">
                                    {{resumeDetails?.vanity_name}}
                                </a></span>
                        </li>
                        <li>
                            <span class="text">
                                {{resumeDetails?.socials.twitter?resumeDetails?.socials.twitter : 'Not
                                Indicated'}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="bottom-section">
            <div class="mini-section">
                <div class="row">
                    <h4 class="section-title pl-0 mb-0">ABOUT</h4>
                    <div class="col-md-12 border-row pl-0 pr-0 mt-2">
                        <p [innerHTML]="resumeDetails?.about" class="about-desc mt-2 "></p>
                    </div>
                </div>
            </div>
            <div class="mini-section"
                *ngIf="resumeDetails?.visibility?.education === true && resumeDetails?.education && resumeDetails?.education?.length > 0">
                <div class="row mb-3">
                    <h4 class="section-title pl-0 mb-0">EDUCATION</h4>
                    <div class="col-md-12 border-row pl-0 pr-0 mt-2">
                        <div class="row mt-2" *ngFor="let edu of resumeDetails?.education">
                            <div class="col-md-6">
                                <h4 class="edu-title"> <strong>{{edu.degree_name}}{{edu.field_of_study}}, </strong>
                                    <span>{{edu.school}}</span></h4>
                            </div>
                            <div class="col-md-6 edu-period">{{displayExperienceDurationDateRange(edu)}}
                                {{displayExperienceDurationDateRange(edu) ? '' : ''}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mini-section"
                *ngIf="resumeDetails?.visibility?.skills === true && resumeDetails?.skills && resumeDetails?.skills.length > 0">
                <div class="row mb-3">
                    <h4 class="section-title pl-0 mb-0">SKILLS</h4>
                    <div class="col-md-12 border-row pl-0 pr-0 mt-2">
                        <ul class="skill-list pl-0 pt-2">
                            <li *ngFor="let skills of resumeDetails?.skills">
                                <h4 class="edu-title">{{skills}}</h4>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="mini-section"
                *ngIf="resumeDetails?.visibility?.experience === true && resumeDetails?.experience && resumeDetails?.experience?.length > 0">
                <div class="row mb-3">
                    <h4 class="section-title pl-0 mb-0">EXPERIENCE</h4>
                    <div class="col-md-12 border-row pl-0 pr-0 mt-2 pt-2">
                        <div class="row" *ngFor="let exp of resumeDetails?.experience">
                            <div class="col-md-8">
                                <h4 class="edu-title"> <span style="font-weight: bold;">{{exp?.title}}</span></h4>
                                <h4 class="exp-company">{{exp?.company}} {{exp.location ? '(' +
                                    exp.location + ')' : ''}}</h4>
                                <p class="about-desc" [innerHTML]="exp?.description"></p>
                            </div>
                            <div class="col-md-4 edu-period">{{displayExperienceDurationDateRange(exp)}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mini-section" *ngIf="resumeDetails?.languages && resumeDetails?.visibility?.languages === true">
                <div class="row mb-3">
                    <h4 class="section-title pl-0 mb-0">LANGUAGES</h4>
                    <div class="col-md-12 border-row pl-0 pr-0 mt-2 pt-2">
                        <h4 class="edu-title" *ngFor="let lang of resumeDetails?.languages"> <strong>{{lang}}</strong>
                        </h4>
                    </div>
                </div>
            </div>
            <div class="mini-section"
                *ngIf="resumeDetails?.visibility?.projects === true && resumeDetails?.accomplishments.projects.length> 0">
                <div class="row mb-3">
                    <h4 class="section-title pl-0 mb-0">PROJECTS</h4>
                    <div class="col-md-12 border-row pl-0 pr-0 mt-2 pt-2">
                        <div class="row" *ngFor="let proj of resumeDetails?.accomplishments.projects">
                            <div class="col-md-12">
                                <h4 class="edu-title"> <strong>{{proj.title}}. </strong>
                                    <span>{{displayprojectDuration(proj)}}</span></h4>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="mini-section"
                *ngIf="resumeDetails?.visibility?.certifications === true && resumeDetails?.certifications && resumeDetails?.certifications?.length > 0">
                <div class="row mb-3">
                    <h4 class="section-title pl-0 mb-0">CERTIFICATES</h4>
                    <div class="col-md-12 border-row pl-0 pr-0 mt-2 pt-2">
                        <div class="row" *ngFor="let courses of resumeDetails?.certifications">
                            <div class="col-md-12">
                                <h4 class="edu-title"> <strong>{{courses}}</strong></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mini-section"
                *ngIf="resumeDetails?.visibility?.publications === true && resumeDetails?.accomplishments.publications.length > 0">
                <div class="row mb-3">
                    <h4 class="section-title pl-0 mb-0">PUBLICATIONS</h4>
                    <div class="col-md-12 border-row pl-0 pr-0 mt-2 pt-2">
                        <div class="row" *ngFor="let pub of resumeDetails?.accomplishments.publications">
                            <div class="col-md-12">
                                <h4 class="edu-title"> <strong>{{pub?.name}}</strong></h4>
                                <h4 class="edu-title"> <span>{{pub?.url}}</span></h4>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="mini-section"
                *ngIf="resumeDetails?.visibility?.courses === true && resumeDetails?.accomplishments.courses.length> 0">
                <div class="row mb-3">
                    <h4 class="section-title pl-0 mb-0">COURSES</h4>
                    <div class="col-md-12 border-row pl-0 pr-0 mt-2 pt-2">
                        <div class="row" *ngFor="let course of resumeDetails?.accomplishments.courses">
                            <div class="col-md-12">
                                <h4 class="edu-title"> <strong>{{course.name}}&nbsp;
                                    </strong><span>{{course.number}}</span></h4>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>